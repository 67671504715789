<template>
    <div class="container">
        <div class="row">
            <div class="col-sm-4 mb-2">
                <page-nav icn="fas fa-qrcode text-primary" lab="友達を招待する" @do_click="toInviteCode"></page-nav>
            </div>
            <div class="col-sm-4 mb-2">
                <page-nav icn="fas fa-user-friends text-primary" lab="招待した友達" @do_click="toInvitedStatus"></page-nav>
            </div>
            <div class="col-sm-4 mb-2">
                <page-nav icn="fas fa-comments-dollar text-primary" lab="ポイント交換履歴" @do_click="toExchangeInfo"></page-nav>
            </div>
        </div>
    </div>
</template>

<script>
    import PageNav from 'pages/commons/nav/PageNav'

    export default {
        components:{
            PageNav
        },
        props: {
            item: Object
        },
        computed: {
            isOverLimit () {
                // 招待人数上限超過の場合
                return this.item.invite_info.invited_cnt >= this.item.invite_info.can_invite_cnt
            },
            isBannedInvite () {
                // 招待権限禁止された場合
                return this.item.invite_info.can_invite == '1' ? true : false
            }
        },
        methods: {
            toInviteCode () {
                if (this.isBannedInvite) {
                    this.$parent.openInfoMsg(1, 'メンバー招待を行う権限がありません。詳しくは管理者にお問い合わせください。')

                } else if (this.isOverLimit) {
                    this.$parent.openInfoMsg(1, '招待可能人数は上限に到達しています。')

                } else {
                    this.$router.push({name: 'InviteCode'})
                }
            },
            toInvitedStatus () {
                this.setRefresh()
                this.$store.dispatch('resetListObj')
                this.$router.push({name: 'InvitedStatus'})
            },
            toExchangeInfo () {
                this.$router.push({name: 'ExchangeInfo'})
            },
            setRefresh () {
                this.$store.dispatch('setRefreshArr', 'inviteStatus')
            }
        }
    }
</script>

<style lang="stylus" scoped>

</style>
