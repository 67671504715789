import AppliesAPI from 'js/api/applies'

export default {

    /** 応募状況詳細画面(案件) **/

    loadApplyStatusDetail ({commit}, data) {
        commit('setApplyStatusDetailLoadStatus', 1)

        AppliesAPI.loadApplyStatusDetail(data.id)
            .then(resp => {
                commit('setApplyStatusDetail', resp.data)
                commit('setApplyStatusDetailLoadStatus', 2)
            })
            .catch(err => {
                commit('setApplyStatusDetail', {})
                commit('setApplyStatusDetailLoadStatus', 3)
            })
    },

    resetApplyStatusDetail ({commit}) {
        commit('resetApplyStatusDetail')
    },


    /** 応募詳細画面 **/

    loadAppDetail ({commit}, data) {
        commit('setAppDetailLoadStatus', 1)

        AppliesAPI.loadAppDetail(data.id)
            .then(resp => {
                commit('redirectCheck', resp.data)
                commit('setAppDetail', resp)
                commit('setAppDetailLoadStatus', 2)
            })
            .catch(err => {
                commit('setAppDetail', {})
                commit('setAppDetailLoadStatus', 3)
            })
    },

    resetAppDetail ({commit}) {
        commit('resetAppDetail')
    },

    addInterview ({commit, dispatch, state}, data) {
        commit('setAddInterviewStatus', 1)

        AppliesAPI.addInterview(data)
            .then(resp => {
                commit('setAddedInterview', data)
                commit('setNewApplyPost', resp)
                commit('setAllErrors', {})
                commit('setAddInterviewStatus', 2)

                dispatch('loadAppDetail', {id: state.appDetail.data.post.proj_id})
            })
            .catch(err => {
                commit('setAllErrors', err.response.data.errors)
                commit('setAddInterviewStatus', 3)
            })
    },

    addNote ({commit, dispatch, state}, data) {
        commit('setAddNoteStatus', 1)
        
        AppliesAPI.addNote(data)
            .then(resp => {
                commit('setAllErrors', {})
                commit('setAddNoteStatus', 2)

                dispatch('loadAppDetail', {id: state.appDetail.data.post.proj_id})
            })
            .catch(err => {
                commit('setAllErrors', err.response.data.errors)
                commit('setAddNoteStatus', 3)
            })
    },

    sendMailDocNG ({commit, dispatch, state}, data) {
        commit('setSendMailDocNGStatus', 1)

        AppliesAPI.sendMailDocNG(data)
            .then(resp => {
                commit('setNewApplyPost', resp)
                commit('setSendMailDocNGStatus', 2)
                
                dispatch('loadAppDetail', {id: state.appDetail.data.post.proj_id})
            })
            .catch(err => {
                commit('setSendMailDocNGStatus', 3)
            })
    },

    sendMailIntvNG ({commit, dispatch, state}, data) {
        commit('setSendMailIntvNGStatus', 1)

        AppliesAPI.sendMailIntvNG(data)
            .then(resp => {
                commit('setNewApplyPost', resp)
                commit('setSendMailIntvNGStatus', 2)

                dispatch('loadAppDetail', {id: state.appDetail.data.post.proj_id})
            })
            .catch(err => {
                commit('setSendMailIntvNGStatus', 3)
            })
    },

    sendMailIntvOK ({commit, dispatch, state}, data) {
        commit('setSendMailIntvOKStatus', 1)

        AppliesAPI.sendMailIntvOK(data)
            .then(resp => {
                commit('setAddedEntrance', data)
                commit('setAddedEntranceToIntv', data)
                commit('setNewApplyPost', resp)
                commit('setAllErrors', {})
                commit('setSendMailIntvOKStatus', 2)

                dispatch('loadAppDetail', {id: state.appDetail.data.post.proj_id})
            })
            .catch(err => {
                commit('setAllErrors', err.response.data.errors)
                commit('setSendMailIntvOKStatus', 3)
            })
    },

    sendMailIntvOKWithNext ({commit, dispatch, state}, data) {
        commit('setSendMailIntvOKWithNextStatus', 1)

        AppliesAPI.sendMailIntvOKWithNext(data)
            .then(resp => {
                commit('setAddedInterview', data)
                commit('setNewApplyPost', resp)
                commit('setAllErrors', {})
                commit('setSendMailIntvOKWithNextStatus', 2)

                dispatch('loadAppDetail', {id: state.appDetail.data.post.proj_id})
            })
            .catch(err => {
                commit('setAllErrors', err.response.data.errors)
                commit('setSendMailIntvOKWithNextStatus', 3)
            })
    },

    loadEnterDetail ({commit}, data) {
        commit('setEnterDetailLoadStatus', 1)

        AppliesAPI.loadEnterDetail(data.day)
            .then(resp => {
                commit('setEnterDetail', resp)
                commit('setEnterDetailLoadStatus', 2)
            })
            .catch(err => {
                commit('setEnterDetail', {})
                commit('setEnterDetailLoadStatus', 3)
            })
    },
    
    resetEnterDetail ({commit}) {
        commit('resetEnterDetail')
    },
}
