<template>
    <div v-if="techInfo" class="container pb-2 pt-4 px-1 px-lg-2">
        <div class="row mb-3">
            <div class="col-sm-12">
                <div class="card border-0 content-shadow">
                    <div v-if="techInfo.exist" class="card-header py-4">
                        <h5 class="card-title mb-0">要員詳細</h5>
                    </div>
                    <div class="card-body p-0">

                        <!-- プロジェクト詳細 -->
                        <div v-if="techInfo.exist" class="pl-lg-5 pb-md-4">
                            <div class="card-body pt-2 pt-lg-3 pt-md-3">
                                <form-item lab="営業状況" class="text-success">&nbsp;営業中</form-item>
                                <form-item lab="氏　　名">&nbsp;{{techInfo.name}}&nbsp;({{techInfo.gender == '0' ? '男性':'女性'}} {{techInfo.age}}歳)</form-item>
                                <form-item lab="所　　属">&nbsp;{{converVal('relation_cmp',techInfo.relation_cmp)}}</form-item>
                                <form-item lab="国　　籍">&nbsp;{{techInfo.country}}</form-item>
                                <form-item lab="日 本 語">&nbsp;{{converVal('jp', techInfo.jp_level)}}</form-item>
                                <form-item v-if="techInfo.en_level" lab="英　　語">&nbsp;{{converVal('en', techInfo.en_level)}}</form-item>
                                <form-item lab="最寄り駅">&nbsp;{{techInfo.nearest_station}}</form-item>
                                <form-item v-if="techInfo.exper" lab="経験年数">&nbsp;{{techInfo.exper}}年</form-item>
                                <form-item lab="稼　　働">{{converVal('proj_start_date',techInfo.work_start_date)}}</form-item>
                                <form-item lab="ス キ ル">&nbsp;{{techInfo.skill}}</form-item>
                                <form-item lab="希　　望">&nbsp;{{techInfo.hope}}</form-item>
                                <form-item lab="備　　考">&nbsp;{{techInfo.note}}</form-item>
                                <div class="form-group row justify-content-center">
                                    <label for="resume" class="col-md-3 col-form-label text-muted">
                                        <small> 履歴書 </small>
                                    </label>
                                    <div class="col-md-8 px-lg-3 px-1">
                                        <div class="card card-header p-2 let-space-1">
                                            <div class="row d-flex align-items-center">
                                                <div class="col-sm-9 col-md-7 mt-1">{{ techInfo.resume }}</div>
                                                <div class="col-sm-3 col-md-5 mt-1">
                                                    <a class="btn btn-secondary btn-block btn-lg" :href="techInfo.resume_url" target="_blank">閲覧</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div v-else class="card-body">
                            <!-- <hr> -->
                            <div class="form-group row py-md-4">
                                <div class="col-md-12">
                                    <div class="p-4 mb-1 text-center over-font-big d-lg-none">
                                        <b> 営業は終了しました </b>
                                    </div>
                                    <div class="p-4 mb-1 text-center over-font-lg d-none d-lg-block">
                                        <b> 営業は終了しました </b>
                                    </div>
                                    <p class="text-center over-font-small text-muted let-space-1">
                                        <span>案件をご紹介いただき</span><br>
                                        <span>ありがとうございました</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import FormItem from 'pages/commons/form/Confirm'
    import {converVal} from 'pages/commons/form/selectList'
    import {mapGetters} from 'vuex'

    export default {
        components: {
            FormItem
        },
        mounted () {
            this.loadShareTechDetail()
        },
        watch : {
            getShareInfoDetailLoadStatus (status) {
                if (status == 1) {
                    this.$store.dispatch('startProgress')
                }
                if (status == 2) {
                    this.$store.dispatch('doneProgress')
                }
                if (status == 3) {
                    this.$store.dispatch('doneProgress')
                }
            },
        },
        computed: {
            techInfo () {
                return this.getShareInfoDetail.data
            },
            ...mapGetters(['getShareInfoDetail'
                          ,'getShareInfoDetailLoadStatus'
            ])
        },
        methods: {
            loadShareTechDetail () {
                this.$store.dispatch('loadShareInfoDetail', {
                    page: 'tech',
                    id: this.$route.params.code
                })
            },
            converVal (name, val) {
                return converVal(name,val)
            },
        }
        
    }
</script>

<style lang="stylus" scoped>
.content-shadow
    box-shadow: 3px 3px 10px #192c7c33
    border-radius: 5px
    opacity: 1
.btn-see-more{
    color:black;
}
.btn-see-more:hover{
    background-color:#00a5f5!important;
    color:white!important;
    border-color:white!important;
    transition:0.5s;
}
.over-font-lg
    font-size:30px;
    color:black;
.over-font-big
    font-size:5vw;
    color:black;


</style>
