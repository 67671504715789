<template>
    <div>
        <div class="text-muted d-flex">
            <span class="text-nowrap">
                <i class="far fa-clock"></i>
                <small> {{item.in_h}}:{{item.in_m}}&nbsp; </small>
            </span>
            <span v-if="item.in_flg == 'y'" class="text-nowrap ml-3">
                <i class="fas fa-calendar-times text-danger"></i>
                <small class="text-danger"> 取消済み </small>
            </span>
            <span class="text-nowrap flex-fill text-right let-space-1" @click="controlInfo">
                <small>{{btnText}}</small>
            </span>
        </div>
        <div v-show="show">
            <div class="text-muted">
                <span class="text-nowrap">
                    <i class="fas fa-id-badge"></i>
                    <small> {{item.in_name}}さん&nbsp; </small>
                </span>
                <span class="text-nowrap">
                    <i class="fas fa-phone"></i>
                    <small> {{item.in_phone}}&nbsp; </small>
                </span>
            </div>
            <div class="text-muted">
                <span class="all-break-cust">
                    <i class="fas fa-map-marked-alt"></i>
                    <small> {{item.in_place}} </small>
                </span>
            </div>
            <div class="text-muted">
                <span v-if="item.price_pub == '0'">
                    <i class="fas fa-yen-sign"></i>
                    <small v-if="item.price_pub_start == item.price_pub_end"> {{ item.price_pub_end }}万円&nbsp; </small>
                    <small v-else>{{ item.price_pub_start }} ~ {{ item.price_pub_end }}万円&nbsp; </small>
                </span>
                <span v-if="item.gender == '0'" class="text-nowrap">
                    <i class="fas fa-male"></i>
                    <small>男性&nbsp;</small>
                </span>
                <span v-else class="text-nowrap">
                    <i class="fas fa-female"></i>
                    <small>女性&nbsp;</small>
                </span>
                <span class="text-nowrap">
                    <i class="fas fa-user-graduate"></i>
                    <small> {{item.age}}歳&nbsp; </small>
                </span>
                <span class="text-nowrap">
                    <i class="fas fa-user-tie"></i>
                    <small> {{item.exper}}年経験&nbsp; </small>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            item: Object
        },
        data () {
            return {
                // false:一部　true:All
                show: false
            }
        },
        computed: {
            btnText () {
                return this.show ? '一部を表示' : 'もっと見る'
            }
        },
        methods: {
            controlInfo () {
                this.show = !this.show
            }
        }
    }
</script>

<style>

</style>
