<template>
    <div id="app-layout">
        <nav-bar></nav-bar>

        <div class="main" :class="{'main-full-foot':fullFooter}">
            <keep-alive>
                <router-view v-if="$route.meta.keepAlive"></router-view>
            </keep-alive>
            <router-view v-if="!$route.meta.keepAlive"></router-view>
            
            <waiting-spinner></waiting-spinner>
            <waiting-progress></waiting-progress>
        </div>

        <div v-if="$route.meta.fullFooter">
            <com-footer show-icon show-info></com-footer>
        </div>
        <div v-else>
            <com-footer></com-footer>
        </div>

    </div>
</template>

<script>
    import NavBar from './commons/nav/NavBar'
    import WaitingSpinner from './commons/spinner/WaitingSpinner'
    import WaitingProgress from 'nprogress'
    import 'nprogress/nprogress.css'
    WaitingProgress.configure({showSpinner: false})
    
    import {EventBus} from 'js/event-bus'
    import ComFooter from './commons/footer/Footer'

    export default {
        name: 'Layout',
        components: {
            NavBar,
            WaitingProgress,
            WaitingSpinner,
            ComFooter
        },
        created () {
           // localStorage.getItem("userMsg") && this.$store.replaceState(Object.assign(this.$store.state,JSON.parse(localStorage.getItem("userMsg"))));
           // 
           // window.addEventListener("beforeunload",()=>{
           //     localStorage.setItem("userMsg",JSON.stringify(this.$store.state))
           // })

            this.getUser()
        },
        mounted () {
            EventBus.$on('startProgress', () => {
                WaitingProgress.start()
            })

            EventBus.$on('doneProgress', () => {
                WaitingProgress.done()
            })
        },
        computed: {
            fullFooter () {
                return this.$route.meta.fullFooter
            }
        },
        methods: {
            getUser () {
                if (this.$route.meta.needLogin != false) {
                    this.$store.dispatch('getUser')
                }
            }
        }
    }
</script>

<style lang="stylus" scoped>
.main
    padding-top:75px;
    box-sizing:border-box
    min-height:100vh
    padding-bottom:70px
.main-full-foot
    padding-bottom:200px!important
</style>
