<template>
    <contact-list 
        ref="list"
        header-text="連絡先"
        mt>
        <div v-if="getListObj">
            <div v-if="getListObj.data.length > 0">
                <div class="card mb-2" v-for="item in getListObj.data" :key="item.id">
                    <div class="card-body px-2 d-flex let-space-1">
                        <span>
                            <span class="text-nowrap">
                                <i class="fas fa-user"></i>&nbsp;{{item.name}}さん&nbsp; 
                            </span>
                            <span class="text-nowrap">
                                <i class="fas fa-phone"></i>&nbsp;{{item.phone}}
                            </span>
                        </span>
                        <span class="text-muted pl-2 ml-auto" @click="openMenu(item.id)">
                            <i class="fas fa-ellipsis-v"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <!-- メニュー -->
        <contact-menu ref="menu" list-nm="連絡先" act="app.contact.del"></contact-menu>

    </contact-list>
</template>

<script>
    import ContactMenu from 'pages/commons/form/Delete'
    import ContactList from 'pages/commons/List/List'
    import {mapGetters} from 'vuex'

    export default {
        components: {
            ContactList,
            ContactMenu
        },
        mounted () {
            this.$refs.list.loadListObj({
                url:'app.contact',
                data: '0' 
            })
            this.$refs.list.resetRefresh('contactList')
        },
        activated () {
            this.$refs.list.isGetList('contactList', { url:'app.contact', data: '0'})
            this.$refs.list.resetRefresh('contactList')
        },
        computed: {
            ...mapGetters(['getListObj'])
        },
        methods: {
            openMenu (id) {
                this.$refs.menu.openThis(id)
            },
            seeMoreMt () {
                this.$store.dispatch('loadListObjSeeMore', {
                    url:'app.contact',
                    skip: this.getListObj.data.length 
                })
            },
        }
    }
</script>
