import Vue from 'vue'
import Vuex from 'vuex'
//import createPersistedState from "vuex-persistedstate";
import {cmpProjs} from './modules/cmpProjs/index'
import {applies} from './modules/applies/index'
import {cmpInvites} from './modules/cmpInvites/index'

import {projs} from './modules/projs/index'
import {myPage} from './modules/myPage/index'
import {invites} from './modules/invites/index'
import {commons} from './modules/commons/index'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        cmpProjs,
        applies,
        cmpInvites,
        projs,
        myPage,
        invites,
        commons
    },
    //plugins: [createPersistedState({storage: window.sessionStorage, key: 'tech.promeraia'})],
})
