<template>
    <div class="container cust-sticky">
        <div class="header mb-2 mt-2 text-muted com-card my-lg-3">
            {{this.pageName}}

            <!-- 戻るボタン -->
            <div v-if="!istop" class="header-back item-hover" @click="back">
                <i class="fas fa-chevron-left"></i>
            </div>

            <!-- 新規案件追加ボタン -->
            <div v-if="hasAdd" class="header-act item-hover text-success" @click="add">
                <i class="fa fa-plus-circle"></i>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            pageName: String,
            istop: {
                type: Boolean,
                default: false
            },
            doCust: {
                type: Boolean,
                default: false
            },
            hasAdd: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            back () {
                if (!this.doCust) {
                    this.$router.go(-1)
                } else {
                    this.$emit('cust')
                }
            },
            add () {
                this.$emit('add')
            }


        }
    }
</script>

<style lang="stylus" scoped>
.cust-sticky
    position:sticky!important
    top:75px!important
    z-index:99
.header
    position: relative
    overflow: hidden
    height: 2.6rem
    line-height: 2.6rem
    text-align: center
    background-color: white;
    //border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    .header-back
        position: absolute
        top: 0
        left: 0
        width: 2.6rem
        font-size: 1.2rem
        text-align: center
    .header-act
        position: absolute
        top: 0
        right: 0
        width: 2.6rem
        font-size: 1.2rem
        text-align: center

</style>
