export default {
    getCmpProjHome (state) {
        return state.cmpProjHome
    },
    getCmpProjHomeLoadStatus (state) {
        return state.cmpProjHomeLoadStatus
    },

    /** 案件情報 **/
    getCmpProjOperat (state) {
        return state.cmpProjOperat
    },
    getCmpProjInfo (state) {
        return state.cmpProjInfo
    },
    getSaveCmpProjInfoStatus (state) {
        return state.saveCmpProjInfoStatus
    },
    getCmpProjInfoLoadStatus (state) {
        return state.cmpProjInfoLoadStatus
    },
    getCmpProjOffStatus (state, status) {
        return state.cmpProjOffStatus
    }
}

