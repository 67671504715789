<template>
    <div class="container">
        <p class="text-center">
            <small class="text-muted"> {{this.num}}&nbsp;{{this.lab}} </small>
        </p>
    </div>
</template>

<script>
    export default {
        props: {
            lab: {
                type: String,
                default: ""
            },
            num: {
                type: Number,
                default: 0
            }
        }
    }
</script>

<style>

</style>
