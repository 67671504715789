export default {
    
   // setInitHome (state, resp) {
   //     state.initHome = resp
   // },
   // setInitHomeLoadStatus (state, status) {
   //     state.initHomeLoadStatus = status
   // },

    /** 個人情報 **/
    setPrivateOperat (state, operat) {
        if (operat == 'success') {
            state.privateOperat = 'show'
        } else {
            state.privateOperat = operat == 'create_confirm' ? 'create' : 'edit'
        }
    },
    resetPrivateOperat (state) {
        state.privateOperat = ""
    },
    setSavePrivateInfoStatus (state, status) {
        state.savePrivateInfoStatus = status
    },
    setPrivateInfo (state, resp) {
        state.privateInfo = resp
    },
    setPrivateInfoLoadStatus (state, status) {
        state.privateInfoLoadStatus = status
    },


    /** 履歴書 **/
    setResumeOperat (state, operat) {
        if (operat == 'success') {
            state.resumeOperat = 'show'
        } else {
            state.resumeOperat = operat == 'create_confirm' ? 'create' : 'edit'
        }
    },
    resetResumeOperat (state) {
        state.resumeOperat = ""
    },
    setSaveResumeInfoStatus (state, status) {
        state.saveResumeInfoStatus = status
    },
    setResumeInfo (state, resp) {
        state.resumeInfo = resp
    },
    setResumeInfoLoadStatus (state, status) {
        state.resumeInfoLoadStatus = status
    },

    /** 口座情報 **/
    setBankOperat (state, operat) {
        if (operat == 'success') {
            state.bankOperat = 'show'
        } else {
            state.bankOperat = operat == 'create_confirm' ? 'create' : 'edit'
        }
    },
    resetBankOperat (state) {
        state.bankOperat = ""
    },
    setSaveBankInfoStatus (state, status) {
        state.saveBankInfoStatus = status
    },
    setBankInfo (state, resp) {
        state.bankInfo = resp
    },
    setBankInfoLoadStatus (state, status) {
        state.bankInfoLoadStatus = status
    },

    /** 応募履歴詳細 **/
    setApplyHisDetail (state, resp) {
        state.applyHisDetail = resp
    },
    resetApplyHisDetail (state) {
        state.applyHisDetail = {}
        state.applyHisDetailLoadStatus = 0
    },
    setApplyHisDetailLoadStatus (state, status) {
        state.applyHisDetailLoadStatus = status
    },
    setAgreeIntvPlan (state, resp) {
        state.agreeIntvPlan = resp
    },
    setAgreeIntvPlanStatus (state, status) {
        state.agreeIntvPlanStatus = status 
    },

    /** 本人確認 **/
    setIdentityInfo (state, resp) {
        state.identityInfo = resp
    },
    setIdentityInfoLoadStatus (state, status) {
        state.identityInfoLoadStatus = status
    },
    resetIdentityInfo (state) {
        state.identityInfo = {}
        state.identityInfoLoadStatus = 0
    },
    setSendIdentityMailStatus (state, status) {
        state.sendIdentityMailStatus = status
    },

    /** お気に入り **/
    setDeleteFavoriteStatus (state, status) {
        state.deleteFavoriteStatus = status
    },
}
