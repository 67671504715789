<template>
    <div>
        <nav-bar></nav-bar>

        <div class="container py-4">


            <div class="text-center my-4 pb-4"><span class="terms-title">利用規約</span></div>
            <p>
                本利用規約（以下「本規約」といいます。）には、本サービスの提供条件及び当社とユーザーの皆様との間の権利義務関係が定められています。本サービスの利用に際しては、本規約の全文をお読みいただいたうえで、本規約に同意いただく必要があります。
            </p>
            <dl>
                <dt>第1条（適用）</dt>
                <dd>
                <ol>
                    <li>
                        本規約は、本サービスの提供条件及び本サービスの利用に関する当社とユーザーとの間の権利義務関係を定めることを目的とし、ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されます。
                    </li>
                    <li>
                        本サイトを利用している者は、本規約に同意したものとみなします。
                    </li>
                    <li>
                        本規約の内容と、本規約外における本サービスの説明等とが異なる場合は、本規約の規定が優先して適用されるものとします。
                    </li>
                </ol>
                </dd>
                <dt> 第2条（定義） </dt>
                <p> 本規約中に使用される以下の用語は、以下に定める意味を有するものとします。 </p>
                <dd>
                <ol>
                    <li>
                        「本サービス」とは、当社が本サイトを通じて提供する一切のサービスをいいます。
                    </li>
                    <li>
                        「利用者」とは、本サイトを利用する者をいい、本サイトを閲覧する者も含みます。
                    </li>
                    <li>
                        「ユーザー」とは、利用者のうち、本サイトにおいて登録を行った者をいいます。
                    </li>
                </ol>
                </dd>
                <dt>第3条（パスワード及びユーザーIDの管理）</dt>
                <dd>
                <ol>
                    <li>
                        ユーザーは、ID及びパスワードを第三者に利用させたり、貸与、譲渡、売買等をすることはできません。
                    </li>
                    <li>
                        ID及びパスワードの管理不十分、使用上の過誤、第三者の使用等による損害の責任はユーザーが負うものとし、当社は一切責任を負わないものとします。
                    </li>
                </ol>
                </dd>
                <dt>第4条（禁止事項）</dt>
                <p>ユーザーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為又は該当すると当社が判断する行為をしてはなりません。</p>
                <dd>
                <ol>
                    <li>
                        法令に違反する行為又は犯罪行為に関連する行為
                    </li>
                    <li>
                        当社、本サービスの他の利用者又はその他の第三者に対する詐欺又は脅迫行為
                    </li>
                    <li>
                        公序良俗に反する行為
                    </li>
                    <li>
                        当社、本サービスの他の利用者又はその他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害する行為
                    </li>
                    <li>
                        本サービスのネットワーク又はシステム等に過度な負荷をかける行為
                    </li>
                    <li>
                        本サービスの運営を妨害するおそれのある行為
                    </li>
                    <li>
                        当社のネットワーク又はシステム等に不正にアクセスし、又は不正なアクセスを試みる行為
                    </li>
                    <li>
                        本サービスの他の利用者のID又はパスワードを利用する行為
                    </li>
                    <li>
                        本サービスの他の利用者の情報の収集を目的とした行為
                    </li>
                    <li>
                        反社会的勢力等への利益供与
                    </li>
                    <li>
                        前各号の行為を直接又は間接に惹起し、又は容易にする行為
                    </li>
                </ol>
                </dd>
                <dt>第5条（本サービスの停止等）</dt>
                <p>ユーザーが以下のいずれかに該当した場合は、当社の判断にて登録の取り消しを行ない、本サービスの提供を終了する場合があります。これらの措置に対し、当社は何らの責任も負いません。またユーザーはこれらの措置に対し、いかなる異議も申し立てることができません。</p>
                <dd>
                <ol>
                    <li>本規約の定めに違反した場合</li>
                    <li>登録事項に虚偽の事実があることが判明した場合</li>
                    <li>その他、当社が停止又は中断を必要と判断した場合</li>
                </ol>
                </dd>
                <dt>第6条（退会）</dt>
                <dd>
                <ol>
                    <li>
                        ユーザーは、当社所定の方法で当社に通知することにより、本サービスから退会し、自己のユーザーとしての登録を抹消することができます。
                    </li>
                </ol>
                </dd>
                <dt>第7条（本サービス及び規約の変更）</dt>
                <dd>
                <ol>
                    <li>
                        当社は、ユーザーへの事前の通知なくして、本サービスの内容変更または一時的な中断を行なうことができます。
                    </li>
                    <li>
                        当社は一定の予告期間をもってユーザーに通知のうえ、本サービスの提供を長期的に中断もしくは終了することができます。但し、やむを得ない事情がある場合、予告を行なわない場合があります。
                    </li>
                    <li>
                        当社は、ユーザーの事前の承認を得ることなく本規約を随時変更することができます。本規約の変更については、本サービスに関するサイト上に公示した時点から効力を発するものとします。なお、ユーザーが本規約の変更後に本サービスを利用することにより、本規約の変更に承諾したものとみなします。
                    </li>
                </ol>
                </dd>
                <dt>第8条（保証の否認及び免責）</dt>
                <dd>
                <ol>
                    <li>
                        当社は、本サービスが利用者の特定の目的に適合すること、期待する価値を提供すること、正確性、真実性、有用性、最新性を有すること、及び不具合が生じないことについて何らの保障をするものではありません。
                    </li>
                    <li>
                        当社は、本サービスの変更、利用停止、中断又は終了、本サービスに係る情報の削除、消失、当該ユーザーの登録の抹消その他本サービスに関してユーザーが被った損害について、いかなる責任も負わないものとします。
                    </li>
                    <li>
                        当社は、利用者間又は利用者と第三者との間で生じた取引、連絡、紛争等につき、いかなる責任も負わないものとします。
                    </li>
                </ol>
                </dd>
                <dt>第9条（反社会的勢力の排除）</dt>
                <p>ユーザーは、反社会的勢力（暴力団、暴力団員、暴力団員でなくなった時から5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロ又は特殊知能暴力集団等、その他これらに準ずる者をいいます。）に該当しないこと、また暴力的行為、詐術・脅迫行為、業務妨害行為等違法行為を行わないことを、将来にわたっても表明するものとします。かかる表明に違反した場合には、異議なく本サービスの提供の終了を受け入れるものとします。</p>
                <dt>第10条（機密保持）</dt>
                <p>ユーザーは、本サービスに関連して当社がユーザーに対して秘密に取り扱うことを求めて開示した非公知の情報について、当社の事前の書面による承諾がある場合を除き、秘密に取り扱うものとします。</p>
                <dt>第11条（個人情報の取り扱い）</dt>
                <p>当社によるユーザーの個人情報の取り扱いについては、「プライバシーポリシー」の定めによるものとし、ユーザーはこの「プライバシーポリシー」に従って当社がユーザーの利用者情報を取り扱うことについて同意するものとします。</p>
                <dt>第12条（損害賠償責任）</dt>
                <p>利用者が本規約の各事項に違反して、当社、関係者又は第三者に損害を与えた場合は、利用者はその損害を賠償するものとします。</p>
                <dt>第13条（連絡又は通知）</dt>
                <p>本サービスに関する問い合わせその他ユーザーから当社に対する連絡又は通知、及び本規約の変更に関する通知その他当社からユーザーに対する連絡又は通知は、当社の定める方法で行うものとします。</p>
                <dt>第14条（権利義務地位の譲渡）</dt>
                <p>ユーザーは、本サービスに基づく権利義務又は地位を、当社の承諾なく、譲渡、担保権の設定又はその他の処分をすることができません。</p>
                <dt>第15条（分離可能性）</dt>
                <p>本規約の一部が法令等により無効と判断された場合であっても、残部については、継続して効力を有するものとします。</p>
                <dt>第１6条（合意管轄）</dt>
                <p>本規約及び掲載契約に関して生じた紛争については、東京地方裁判所を専属的管轄裁判所とします。</p>
                <dt>第１7条（準拠法）</dt>
                <p>本規約の成立、解釈及び適用については、日本法を準拠法とします。</p>
                <dd class="pt-3">最終改訂日：2022年3月10日 </dd>
            </dl>
        </div>
    </div>
</template>

<script>
    import NavBar from 'pages/commons/nav/NavGuest'

    export default {
        components: {
            NavBar
        }
    }
</script>

<style lang="stylus" scoped>
    .terms-title
        font-size:2rem;
        border-bottom: 0.6rem solid #18a18e;
    dt
        padding-top: 1.8rem;
        padding-bottom: 0.5rem;
    li
        padding-top: 0.5rem;
</style>
