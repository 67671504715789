<template>
    <div id="modal-cmp-member-list-menu" class="modal fade show">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <ul class="list-group list-group-flush">
                        <li v-if="item.show_change_to_owner" class="list-group-item border-0" @click="changeOwner">
                            <div class="row">
                                <div class="col-2 pr-0 text-center">
                                    <i class="text-muted fas fa-user-tie icon-size"></i>
                                </div>
                                <div class="col pl-2">
                                    <span class="pl-2">オーナーに変更する</span>
                                </div>
                            </div>
                        </li>
                        <li v-if="item.show_transfer_req" class="list-group-item border-0" @click="transferOwner">
                            <div class="row">
                                <div class="col-2 pr-0 text-center">
                                    <i class="text-muted fas fa-retweet icon-size"></i>
                                </div>
                                <div class="col pl-2">
                                    <span class="pl-2">招待権限委譲依頼</span>
                                </div>
                            </div>
                        </li>
                        <li v-if="item.show_user_unlink" class="list-group-item border-0" @click="userUnlink">
                            <div class="row">
                                <div class="col-2 pr-0 text-center">
                                    <i class="text-muted fas fa-unlink icon-size"></i>
                                </div>
                                <div class="col pl-2">
                                    <span class="pl-2">利用者連携解除</span>
                                </div>
                            </div>
                        </li>
                        <li v-if="item.show_user_link" class="list-group-item border-0" @click="userLink">
                            <div class="row">
                                <div class="col-2 pr-0 text-center">
                                    <i class="text-muted fas fa-link icon-size"></i>
                                </div>
                                <div class="col pl-2">
                                    <span class="pl-2">利用者連携</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        data () {
            return {
                item: {}
            }
        },
        watch: {
            getCmpProjOffStatus (status) {
                if (status == 0) {
                    this.$store.dispatch('startSpinner')
                }
                if (status == 2) {
                    this.$store.dispatch('delItemFromList', {
                        listItem: 'id',
                        pageId: this.item.id
                    })
                    this.hideThis()
                    this.$store.dispatch('doneSpinner')
                }
                if (status == 3) {
                    this.hideThis()
                    this.$store.dispatch('doneSpinner')
                }
            }
        },
        computed: {
            ...mapGetters(['getCmpProjOffStatus'])
        },
        methods: {
            openThis (item) {
                this.resetThis()
                this.item = item
                $('#modal-cmp-member-list-menu').modal('show')
            },
            hideThis () {
                this.resetThis()
                $('#modal-cmp-member-list-menu').modal('hide')
            },
            resetThis () {
                this.item = {}
            },
            projOff (flg) {
                this.$store.dispatch('cmpProjOff', {
                    id: this.item.id,
                    flg: flg
                })
            },
            changeOwner () {
                let lev = 2
                let msg = 'このメンバーをオーナーにしますか？'
                let key = 'changeOwner' 
                this.$parent.openMsg(lev, msg, key, this.item.user_id)
                this.hideThis()
            },
            transferOwner () {
                let lev = 2
                let msg = '招待権限委譲依頼を送信しますか？'
                let key = 'transferOwner' 
                this.$parent.openMsg(lev, msg, key, this.item.user_id)
                this.hideThis()
            },
            userUnlink () {
                this.$parent.userUnlink(this.item)
                this.hideThis()
            },
            userLink () {
                this.$parent.userLink(this.item)
                this.hideThis()
            }
        }
    }
</script>

<style>

</style>
