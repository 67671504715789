<template>
    <div>
        <div class="row mb-1" v-if="getPost">
            <div class="col-sm-12">
                <div class="card mb-2">
                    <proj-header></proj-header>
                    <proj-body :item="getPost" :pub="false"></proj-body>
                </div>
            </div>
        </div>
        <!-- 0件メッセージ -->
        <not-found-msg v-else></not-found-msg>
    </div>
</template>

<script>
    import ProjHeader from './ProjHeader'
    import ProjBody from 'pages/commons/projBody/ProjBody'
    import NotFoundMsg from 'pages/commons/notFoundMsg/NotFoundMsg'

    import {mapGetters} from 'vuex'

    export default {
        components:{
            ProjHeader,
            ProjBody,
            NotFoundMsg
        },
        computed: {
            ...mapGetters(['getPost'])
        }
    }
</script>
