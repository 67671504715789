<template>
    <div class="container let-space-1">
        <div class="card card-body com-card mb-2 px-md-5 py-md-5">
            <div>
                <i class="fas fa-id-card icon-size-1 text-primary pr-2"></i>
                <span class="text-primary"><b>STEP 02</b></span>
                <hr>
            </div>
            <div class="d-flex">
                <div><span>本人確認書類の提出</span></div>
                <div v-if="needIdentity" class="text-right ml-auto text-danger">
                    <i class="fas fa-exclamation-circle icon-size text-danger"></i>
                    <span><small>未完了 </small></span>
                </div>
                <div v-else class="text-right ml-auto text-success">
                    <i class="fas fa-exclamation-circle icon-size text-success"></i>
                    <span><small>提出済 </small></span>
                </div>
            </div>
            <hr>
            <div>
                <small>
                    <identity-file mb-h="0.6"></identity-file>
                </small>
            </div>
        </div>
        <div class="card card-body com-card py-md-5">
            <div class="row d-flex justify-content-center">
                <div class="col-sm-4">
                    <button type="button" class="btn btn-primary btn-block" @click="sendIdentityMail">
                        今すぐ書類を提出する
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import IdentityFile from 'pages/commons/identity/IdentityFile'
    export default {
        props: {
            needIdentity: {
                type: Boolean,
                default: true
            }
        },
        components: {
            IdentityFile
        },
        methods: {
            sendIdentityMail () {
                this.$parent.sendIdentityMail()
            }
        }

    }
</script>

<style lang="stylus" scoped>
    .icon-size-1
        font-size 1.2rem
    .id-list
        p
            margin-bottom 0.6rem!important

</style>
