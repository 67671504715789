<template>
    <div v-if="show">
        <!-- home->create->confirm1->create->home -->
        <!-- home->show->edit->confirm2->edit->show->home -->
        <confirm-header v-if="opt == 'create_confirm'" :page-name="pageName" do-cust @cust="backPage"></confirm-header>
        <confirm-header v-else-if="opt == 'edit_confirm'" :page-name="pageName" do-cust @cust="backPage"></confirm-header>
        <confirm-header v-else :page-name="pageName"></confirm-header>
        <div class="container">
            <div class="card com-card mb-2 py-md-5">
                <div class="card-body">
                    <form-item lab="銀行名">{{form.bank_nm}}</form-item>
                    <form-item lab="支店名">{{form.branch_nm}}</form-item>
                    <form-item lab="支店番号">{{form.branch_id}}</form-item>
                    <form-item lab="口座番号">{{form.account_id}}</form-item>
                    <form-item lab="口座名義">{{form.first_kana}} {{form.last_kana}}</form-item>
                </div>
            </div>
            <div class="card mb-2 com-card py-md-4">
                <div class="card-body">
                    <div v-if="opt == 'create_confirm'" class="row form-group justify-content-center mb-0">
                        <div class="col-sm-4">
                            <button class="btn btn-danger btn-block btn-lg" @click="doSubmit">
                                <i class="fas fa-save"></i>&nbsp;銀行情報登録
                            </button>
                        </div>
                    </div>
                    <div v-if="opt == 'edit_confirm'" class="row form-group justify-content-center mb-0">
                        <div class="col-sm-4">
                            <button class="btn btn-danger btn-block btn-lg" @click="doSubmit">
                                <i class="fas fa-save"></i>&nbsp;銀行情報更新
                            </button>
                        </div>
                    </div>
                    <div v-if="opt == 'show'" class="row form-group justify-content-center mb-0">
                        <div class="col-sm-4">
                            <button class="btn btn-success btn-block btn-lg" @click="doEdit">
                                <i class="fas fa-edit"></i>&nbsp;銀行情報編集
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ConfirmHeader from 'pages/commons/header/Header'
    import FormItem from 'pages/commons/form/Confirm'

    export default {
        props: {
            form: Object,
            opt: String
        },
        components: {
            ConfirmHeader,
            FormItem
        },
        computed: {
            show () {
                return Object.keys(this.form).length > 0
            },
            pageName () {
                if (this.opt == 'create_confirm') {
                    return "入力内容確認"
                }
                if (this.opt == 'edit_confirm') {
                    return "編集内容確認"
                }
                return "口座情報"
            },
            showRegistBtn () {
                return (this.opt == 'create_confirm' || this.opt == 'edit_confirm') ? true : false
            }
        },
        methods: {
            backPage () {
                if (this.opt == 'create_confirm') {
                    this.$parent.changeOperat('create')
                }
                if (this.opt == 'edit_confirm') {
                    this.$parent.changeOperat('edit')
                }
            },
            doSubmit () {
                this.$parent.doSubmit()
            },
            doEdit () {
                this.$parent.changeOperat('edit')
            }
        }
    }
</script>

<style>

</style>
