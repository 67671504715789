<template>
    <div class="container pb-2">
        <div v-if="getActSalesInfo" class="card com-card py-0">
            <div v-if="getActSalesInfo.length > 0" class="card-body py-0">
                <div class="row p-3 justify-content-between">
                    <div class="col-md-4">
                        <button class="btn btn-success btn-block" :disabled="selectedTech.length == 0" @click="suggestion">案件に応募する（{{this.selectedTech.length}}名）</button>
                    </div>
                    <div class="col-md-4 d-none d-md-block">
                        <a :href="resumeUrl" target="_blank" class="btn btn-success btn-block" :class="{'disabled-link':!resumeUrl}" role="button">履歴書を確認する</a>
                    </div>
                </div>
                <div class="row py-0">
                    <div class="col-md-6 overflow-auto p-2 p-md-4" :style="{height:itemHeight + 'px'}">
                        <div v-for="item in getActSalesInfo" :key="item.id" class="card-body d-flex p-2 mb-2 border item-hover" :class="{'border-primary': clickedTechId == item.id}" >
                            <div v-if="!item.pj_id" class="form-group form-check py-md-4">
                                <input type="checkbox" class="form-check-input item-hover" @change="selectTech" :value="item.id">
                            </div>
                            <div @click="showItemInfo(item)" class="small-font flex-fill py-md-4">
                                <div>
                                    <span v-if="obj.id == item.pj_id" class="text-nowrap border border-danger rounded px-1 text-danger">
                                        <small>応募済み</small>
                                    </span>
                                    <span class="text-nowrap border rounded px-1">{{editStartDate(item.work_start_date)}}</span>
                                    <span class="text-nowrap border rounded px-1">{{item.nearest_station}}</span>
                                    <span class="text-nowrap border rounded px-1">{{converVal('relation_cmp', item.relation_cmp)}}</span>
                                    <span class="text-nowrap border rounded px-1">exp.{{item.exper}}年</span>
                                </div>
                                <div class="d-flex">
                                    <div>
                                        <span class="text-nowrap">
                                            {{item.name}} ({{item.gender == '0'?'男性':'女性'}} {{item.age+'歳'}} {{item.country}})
                                        </span>
                                        <span class="text-nowrap" v-if="item.hope_price_start == item.hope_price_end">{{ item.hope_price_end }}万</span>
                                        <span class="text-nowrap" v-else>{{ item.hope_price_start }}~{{ item.hope_price_end }}万</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 p-2 p-md-4 d-none d-md-block overflow-auto" :style="{height:itemHeight + 'px'}">
                        <div v-if="itemInfo" class="card-body p-2 break-row">{{itemInfo}}</div>
                    </div>
                </div>
            </div>
            <not-found-msg v-else :msg="zeroMsg"></not-found-msg>
        </div>
        <!-- 要員詳細モーダル画面 -->
        <tech-info ref="techModal"></tech-info>
    </div>
</template>

<script>
import {converVal} from 'pages/commons/form/selectList'
import {editProjStartDate} from 'pages/commons/form/comFunc'
import NotFoundMsg from 'pages/commons/notFoundMsg/NotFoundMsg'
import TechInfo from './components/TechInfo'
import {mapGetters} from 'vuex'
export default {
    props: {
        page:String,//proj/tech
        obj:Object
    },
    components:{
        NotFoundMsg,
        TechInfo,
    },
    mounted () {
        this.$store.dispatch('loadActSalesInfo',{
            page: 'proj',
            itemId: this.obj.id
        })
    },
    data () {
        return {
            zeroMsg: "営業中の要員が登録されていません。",
            itemInfo: null,
            itemHeight: $(window).width() >= 768 ? $(window).height() - 300 : $(window).height() - 280,
            clickedTechId: '',
            selectedTech: [],
            resumeUrl: ''
        }
    },
    watch: {
        getActSalesInfo (info) {
            if (info.length > 0) {
                this.clickedTechId = info[0].id
                this.itemInfo = this.makeItemInfo (info[0])
            }
        },
        getActSalesInfoStatus (status) {
            if (status == 1) {
                this.$store.dispatch('startProgress')
            }
            if (status == 2) {
                this.$store.dispatch('doneProgress')
            }
            if (status == 3) {
                this.$store.dispatch('doneProgress')
            }
        }
    },
    computed: {
        ...mapGetters(['getActSalesInfo', 'getActSalesInfoStatus'])
    },
    methods: {
        suggestion () {
            this.$parent.suggestion('3','0',this.selectedTech)
        },
        selectTech (event) {
            let checked = event.target.checked
            let id = event.target.value
            if (checked) {
                this.selectedTech.push(id)
            } else {
                let idx = this.selectedTech.findIndex((item)=>item == id)
                if (idx != -1) {
                    this.selectedTech.splice(idx, 1)
                }
            }
        },
        showItemInfo (info) {
            this.clickedTechId = info.id
            this.itemInfo = this.makeItemInfo(info)

            if ($(window).width() < 768) {
                this.$refs.techModal.openThis(this.itemInfo, info.resume)
            } else {
                this.resumeUrl = info.resume
            }
        },
        makeItemInfo (item) {
            let Str = ""
            Str += `氏　名：${item.name}（${item.age}歳 ${item.gender == '0' ? '男性':'女性'}）\n`
            Str += `国　籍：${item.country}\n`
            Str += `所　属：${this.converVal('relation_cmp',item.relation_cmp)}\n`
            Str += `最寄駅：${item.nearest_station}\n`
            if (item.exper) {
                Str += `経　験：${item.exper}年\n`
            }
            if (item.hope_price_start == item.hope_price_end) {
                Str += `単　金：${item.hope_price_end}万円\n`
            } else {
                Str += `単　金：${item.hope_price_end}~${item.hope_price_end}万円\n`
            }
            Str += `稼働日：${this.editStartDate(item.work_start_date)}\n`
            Str += `日本語：${this.converVal('jp',item.jp_level)}\n`
            if (item.en_level) {
                Str += `英　語：${this.converVal('en',item.en_level)}\n\n`
            }
            if (item.skill) {
                Str += `スキル：\n`
                Str += `${item.skill}\n\n`
            }
            if (item.hope) {
                Str += `希　望：\n`
                Str += `${item.hope}\n\n`
            }
            if (item.note) {
                Str += `備　考：\n`
                Str += `${item.note}\n`
            }
            return Str
        },
        editStartDate (val) {
            return editProjStartDate(val)
        },
        converVal (name, val) {
            return converVal(name,val)
        },
    }
}
</script>

<style lang="stylus" scoped>
.break-row
    white-space:pre-wrap
    word-wrap:break-word
.disabled-link {
    pointer-events:none
    opacity: 0.65
}
@media (max-width: 768px)
    .small-font
        font-size: 80%
        font-weight: 400
</style>
