export default {
    /**
     * Get ホーム情報取得
     */
    loadCmpInviteHome () {
        return axios.get(route('cmp.invite.home'))
    },

    /**
     * Get 招待情報取得
     */
    loadCmpInviteInfo () {
        return axios.get(route('cmp.invite.code'))
    },

    /**
     * Get 招待コード情報取得(30分期限)
     */
    loadQrUrlChatLimit () {
        return axios.get(route('cmp.invite.code.chat'))
    },

    /**
     * Post 招待コードメールシェア(24時間期限)
     */
    shareQrUrlMail (data) {
        return axios.post(route('cmp.invite.code.mail'), data)
    },

    /**
     * Post 通常メンバーをオーナーへ変更
     */
    cmpInvitedChangeOwner (data) {
        return axios.post(route('cmp.invite.change.owner'), data)
    },

    /**
    /* Post  通常メンバーから権限委譲依頼 
     */
    cmpInvitedTransferOwner (data) {
        return axios.post(route('cmp.invite.transfer.owner'), data)
    },

    /**
    /* Post  利用者連携解除
     */
    cmpInvitedUserUnlink (data) {
        return axios.post(route('cmp.invite.user.unlink'), data)
    },

    /**
    /* Post  利用者連携
     */
    cmpInvitedUserLink (data) {
        return axios.post(route('cmp.invite.user.link'), data)
    },

}
