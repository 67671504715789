<template>
    <div>
        <apply-status-header v-if="getListObj" page-name="応募履歴（案件）"></apply-status-header>
        <div class="container">
            <!-- Nav tabs -->
            <ul v-show="getListObj" class="nav nav-tabs tab-sticky breadcrumb p-0 mb-1 nav-justified com-card" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active text-center px-2 py-md-3 border-0" data-toggle="tab" href="#open">
                        進行中
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link text-center px-2 py-md-3 border-0" data-toggle="tab" href="#closed">
                        終了
                    </a>
                </li>
            </ul>
            <div class="tab-content">
                <div id="open" class="container tab-pane active p-0">
                    <!-- Open -->
                    <div v-if="getListObj && getListObj.applyOpen">
                        <div v-if="getListObj.applyOpen.length > 0">
                            <apply-row v-for="(item,idx) in getListObj.applyOpen" :key="item.id" :item="item" :idx="idx" :closed="false"
                              :class="{'com-card-focus':selectedProj == item.id}">
                            </apply-row>
                        </div>
                        <div v-else class="py-5 text-center text-muted">
                            <i class="fas fa-search h1"></i><br>
                            データが見つかりません。
                        </div>
                    </div>
                </div>
        
                <div id="closed" class="container tab-pane fade p-0">
                    <!-- Closed -->
                    <apply-closed-list 
                        ref="list"
                        :has-header="false"
                        mt
                        :mg="false"
                        >
                        <div v-if="getListObj">
                            <div v-if="getListObj.data.length > 0">
                                <apply-row v-for="(item,idx) in getListObj.data" :key="item.id" :item="item" :idx="idx" closed
                                  :class="{'com-card-focus':selectedProj == item.id}">
                                </apply-row>
                            </div>
                        </div>
                    </apply-closed-list>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import ApplyStatusHeader from 'pages/commons/header/Header'
import ApplyClosedList from 'pages/commons/List/List'
import ApplyRow from './components/Row'
import {mapGetters} from 'vuex'

export default {
    components: {
        ApplyStatusHeader,
        ApplyClosedList,
        ApplyRow
    },
    data () {
        return {
            tab: 'open',
            selectedProj:''
        }
    },
    mounted () {
        this.$refs.list.loadListObj({ 
            url:'app.pj',
            data: '0'
        })
        this.$refs.list.resetRefresh('cmpApplyPj')
    },
    activated () {
        this.$refs.list.isGetList('cmpApplyPj', { url:'app.pj', data:'0'})
        this.$refs.list.resetRefresh('cmpApplyPj')
    },
    watch: {
        $route (to, from) {
            if (from.name == 'ApplyStatusDetail') {
                this.projFromSession()
            } else {
                this.selectedProj = ''
            }
        }
    },
    computed: {
        ...mapGetters(['getListObj'])
    },
    methods: {
        seeMoreMt () {
            this.$store.dispatch('loadListObjSeeMore', {
                url:'app.pj',
                skip: this.getListObj.data.slice(-1)[0].id
            })
        },
        projFromSession () {
            this.selectedProj = sessionStorage.getItem('applyProjCmp')
        }
    }
}
</script>

<style lang="stylus" scoped>
 .tab-sticky
    position:sticky!important
    top:122px!important
    z-index:98
</style>

