<template>
    <div class="container">
        <div class="card card-body com-card let-space-1 mb-2">
            <div class="text-center">
                <div class="hd-1">かんたん確認の流れ</div>
                <div class="mb-4">
                    <small>2ステップで完了します</small>
                </div>
                <div class="mt-3">
                    <small>
                        <p class="mb-1">本人確認の審査完了目安は</p>
                        <p class="mb-1">最短当日から、3日程度です</p>
                        <p class="mb-1">完了次第メールでお知らせします</p>
                    </small>
                </div>
            </div>
            <div class="mt-4 text-center">
                <div>
                    <span class="badge badge-pill badge-primary step-txt">STEP 01</span>
                </div>
                <div>
                    <span><small>個人情報の登録</small></span>
                </div>
            </div>
            <div class="mt-4 mb-4 text-center">
                <div>
                    <span class="badge badge-pill badge-primary step-txt">STEP 02</span>
                </div>
                <div>
                    <span><small>本人確認書類の提出</small></span>
                </div>
            </div>
        </div>
        <div class="card card-body com-card py-md-5">
            <div class="row d-flex justify-content-center">
                <div class="col-sm-4">
                    <button type="button" class="btn btn-primary btn-block" @click="toNext">
                        はじめる
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            toNext () {
                this.$parent.setStep(1)
            }
        }
    }
</script>

<style lang="stylus" scoped>
    .hd-1
        font-size 1rem
    .hd-2-1
        color #3490dc 
        border-bottom solid #3490dc 2px
        padding-bottom .5rem
    .step-txt
        padding 0.3rem .9rem 0.3rem .9rem
        font-size .8rem
</style>
