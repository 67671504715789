<template>
    <div v-if="getApplyHisDetail">
        <detail-header page-name="応募詳細"></detail-header>
        <div class="container">
            <!-- Nav tabs -->
            <ul class="nav nav-tabs tab-sticky breadcrumb p-0 mb-1 nav-justified com-card" role="tablist">
                <li class="nav-item">
                    <a class="nav-link text-center px-2 py-md-3 border-0" data-toggle="tab" href="#project">
                        案件
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link active text-center px-2 py-md-3 border-0" data-toggle="tab" href="#apply">
                        メッセージ
                    </a>
                </li>
            </ul>
            <div class="tab-content">
                <div id="project" class="container tab-pane fade p-0">
                    <!-- 案件情報 -->
                    <proj-detail-content :proj="getApplyHisDetail.project"></proj-detail-content>
                </div>

                <div id="apply" class="container tab-pane active p-0">
                    <!-- メッセージ -->
                    <msg-detail-content :msgs="getApplyHisDetail.msgs" :user-nm="getApplyHisDetail.userNm" 
                        :apply-id="getApplyHisDetail.applyId" sender-type="0"></msg-detail-content>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
    import DetailHeader from 'pages/commons/header/Header'
    import MsgDetailContent from 'pages/commons/applyMsg/ApplyMsg'
    import ProjDetailContent from './components/ProjContent'
    import {mapGetters} from 'vuex'

    export default {
        components:{
            DetailHeader,
            ProjDetailContent,
            MsgDetailContent,
        },
        mounted () {
            this.loadApplyHisDetail()
        },
        destroyed () {
            this.$store.dispatch('resetApplyHisDetail')
        },
        watch: {
            getApplyHisDetailLoadStatus (status) {
                if (status == 1) {
                    this.$store.dispatch('startProgress')
                }
                if (status == 2) {
                    this.$store.dispatch('doneProgress')
                }
                if (status == 3) {
                    this.$store.dispatch('doneProgress')
                }
            },
        },
        computed: {
            ...mapGetters(['getApplyHisDetail'
                          ,'getApplyHisDetailLoadStatus'
                          ,'getAddApplyHisMsg', 
            ])
        },
        methods: {
            loadApplyHisDetail () {
                this.$store.dispatch('loadApplyHisDetail',{
                    id: this.$route.params.id
                })
            },
            pushCommentToList () {
                this.getApplyHisDetail.msgs.push(this.getAddApplyHisMsg)
            }
        }

    }
</script>
<style lang="stylus" scoped>
 .tab-sticky
    position:sticky!important
    top:122px!important
    z-index:98
</style>
