<template>
    <div class="form-group row justify-content-center" :class="{'mb-0': mb0, 'mb-1': mb1, 'mb-2': mb2}">
        <label class="col-md-3 col-form-label text-muted"
              :class="{'py-0': py0}"
        >
            <slot name="icon"></slot>
            <small>

                <slot name="label"></slot>

                <span class="text-danger" >
                    <slot name="mark"></slot>
                </span> 

                <slot name="size"></slot>

            </small>

        </label>
        <div class="col-md-8 px-lg-3 px-1">

            <err-msg-bar v-if="hasMsg" :name="errsNm" :local-errs="errs"></err-msg-bar>

            <div class="d-flex">
                <slot name="input"></slot>
                <slot name="note"></slot>
            </div>

            <!-- 履歴書uploadで使用 -->
            <slot name="input2"></slot>

        </div>
    </div>
</template>

<script>
    import ErrMsgBar from 'pages/commons/err/ErrMsgBar.vue'

    export default {
        props: {
            py0: Boolean,
            mb: String,
            hasMsg: Boolean,
            errsNm: Array,
            errs: Object
        },
        computed: {
            mb0 () {
                return this.mb == '0' ? true : false
            },
            mb1 () {
                return this.mb == '1' ? true : false
            },
            mb2 () {
                return this.mb == '2' ? true : false
            },
        },
        components: {
            ErrMsgBar,
        }
    }
</script>

<style>

</style>
