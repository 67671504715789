export default {
    
    setProjDetail (state, resp) {
        state.projDetail = resp
    },
    resetProjDetail (state) {
        state.projDetail = {}
        state.projDetailLoadStatus = 0
    },
    setProjDetailLoadStatus (state, status) {
        state.projDetailLoadStatus = status
    },
    /** 譯井ｻｶ蠢懷供 **/
    setProjApplyOperat (state, opt ) {
        state.projApplyOperat = opt
    },
    resetProjApplyOperat (state) {
        state.projApplyOperat = ""
    },
    setSaveProjApplyStatus (state, status) {
        state.saveProjApplyStatus = status
    },
    /** お気に入り**/
    setAddFavoriteStatus (state, status) {
        state.addFavoriteStatus = status
    }
}
