<template>
    <div v-if="show">
        <!-- home->create->confirm1->create->home -->
        <!-- home->show->edit->confirm2->edit->show->home -->
        <confirm-header v-if="opt == 'create_confirm'" :page-name="pageName" do-cust @cust="backPage"></confirm-header>
        <confirm-header v-else-if="opt == 'edit_confirm'" :page-name="pageName" do-cust @cust="backPage"></confirm-header>
        <confirm-header v-else :page-name="pageName"></confirm-header>
        <div class="container">
            <div class="card mb-2 com-card pt-md-4">
                <div class="card-body">
                    <form-item lab="ニックネーム">{{form.name}}</form-item>
                    <form-item lab="性別">{{form.gender == '0' ? '男' : '女'}}</form-item>
                    <form-item lab="年齢">{{form.age}} 歳</form-item>
                    <form-item lab="経験年数">{{form.exper}} 年</form-item>
                    <form-item v-if="form.country" lab="国籍">{{form.country}}</form-item>
                    <form-item v-else lab="国籍"><span class="text-danger">未入力</span></form-item>

                    <form-item v-if="form.work_start_date" lab="稼働開始">{{converDate('work_start_date', form.work_start_date)}}</form-item>
                    <form-item v-else lab="稼働開始"><span class="text-danger">未入力</span></form-item>

                    <form-item v-if="form.nearest_station" lab="最寄り駅">{{form.nearest_station}}</form-item>
                    <form-item v-else lab="最寄り駅"><span class="text-danger">未入力</span></form-item>

                    <form-item v-if="form.hope_price_start == form.hope_price_end" 
                               lab="希望単価">{{form.hope_price_end}} 万円</form-item>
                    <form-item v-else lab="希望単価">{{form.hope_price_start}} ~ {{form.hope_price_end}} 万円</form-item>

                    <form-item lab="日本語レベル">{{converVal('jp',form.jp_level)}}</form-item>
                    <form-item v-if="form.en_level" lab="英語レベル">{{converVal('en',form.en_level)}}</form-item>
                    <form-item v-else lab="英語レベル"><span class="text-danger">未入力</span></form-item>

                    <form-item v-if="form.skill" lab="スキル">{{form.skill}}</form-item>
                    <form-item v-else lab="スキル"><span class="text-danger">未入力</span></form-item>

                    <form-item v-if="form.hope" lab="希望">{{form.hope}}</form-item>
                    <form-item v-else lab="希望"><span class="text-danger">未入力</span></form-item>

                    <form-item v-if="form.note" lab="備考">{{form.note}}</form-item>
                    <form-item v-else lab="備考"><span class="text-danger">未入力</span></form-item>

                    <!-- 履歴書upload -->
                    <file-upload v-if="opt != 'show'" ref="file" :lab="resumeLab" :ext="['xls','xlsx']" :file-nm="form.resume" :required="false" :size="2"></file-upload>

                    <div v-else class="form-group row justify-content-center">
                        <label for="resume" class="col-md-3 col-form-label text-muted">
                            <small> 履歴書 </small>
                        </label>
                        <div class="col-md-8 px-lg-3 px-1">
                            <div class="card card-header p-2 let-space-1">
                                <div class="row d-flex align-items-center">
                                    <div class="col-sm-9 col-md-7 mt-1">{{ form.resume }}</div>
                                    <div class="col-sm-3 col-md-5 mt-1">
                                        <a class="btn btn-secondary btn-block btn-lg" :href="form.resume_url" target="_blank">ダウンロード</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

        
                    <div class="card mb-2 mt-5 border-0">
                        <div class="card-body">
                            <div v-if="opt == 'create_confirm'" class="row form-group justify-content-center mb-0">
                                <div class="col-sm-4">
                                    <button class="btn btn-danger btn-block btn-lg" @click="doSubmit">
                                        <i class="fas fa-save"></i>&nbsp;履歴情報登録
                                    </button>
                                </div>
                            </div>
                            <div v-if="opt == 'edit_confirm'" class="row form-group justify-content-center mb-0">
                                <div class="col-sm-4">
                                    <button class="btn btn-danger btn-block btn-lg" @click="doSubmit">
                                        <i class="fas fa-save"></i>&nbsp;履歴情報更新
                                    </button>
                                </div>
                            </div>
                            <div v-if="opt == 'show'" class="row form-group justify-content-center mb-0">
                                <div class="col-sm-4">
                                    <button class="btn btn-success btn-block btn-lg" @click="doEdit">
                                        <i class="fas fa-edit"></i>&nbsp;履歴情報編集
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ConfirmHeader from 'pages/commons/header/Header'
    import FormItem from 'pages/commons/form/Confirm'
    import FileUpload from 'pages/commons/form/File'

    export default {
        props: {
            form: Object,
            opt: String
        },
        components: {
            ConfirmHeader,
            FormItem,
            FileUpload
        },
        computed: {
            show () {
                return Object.keys(this.form).length > 0
            },
            resumeLab () {
                return this.opt == 'create_confirm' ? '履歴書Excel(必須)' : '履歴書'
            },
            pageName () {
                if (this.opt == 'create_confirm') {
                    return "入力内容確認"
                }
                if (this.opt == 'edit_confirm') {
                    return "編集内容確認"
                }
                return "履歴情報"
            },
            showRegistBtn () {
                return (this.opt == 'create_confirm' || this.opt == 'edit_confirm') ? true : false
            }
        },
        methods: {
            backPage () {
                if (this.opt == 'create_confirm') {
                    this.$parent.changeOperat('create')
                }
                if (this.opt == 'edit_confirm') {
                    this.$parent.changeOperat('edit')
                }
            },
            converDate (name, value) {
                if (!this.$parent.isOverStart(value)) {
                    return this.converVal(name, value)
                }
                return '即日～'
            },
            converVal (name, value) {
                return this.$parent.converVal(name,value)
            },
            doSubmit () {
                this.$parent.doSubmit()
            },
            checkFileExist () {
                return this.$refs.file.checkFileExist()
            },
            checkFileSize () {
                return this.$refs.file.checkFileSize()
            },
            getFile () {
                return this.$refs.file.getFile()
            },
            doEdit () {
                this.$parent.changeOperat('edit')
            },
            openMsg (msg, lev) {
                this.$parent.openMsg(msg, lev)
            },
            setFileMsg (data) {
                this.openMsg(data.msg, data.lev)
            }
        }
    }
</script>

<style>

</style>
