<template>
    <div class="container">
        <div class="row mb-4 d-flex justify-content-center">
            <div class="col-sm-4 col-md-5 col-lg-4">
                <button type="button" class="btn btn-success btn-block" @click="openMailShare">
                    <i class="far fa-paper-plane"></i>&nbsp;
                    メールでメンバーを招待
                </button>
            </div>
        </div>
        <div class="row d-flex justify-content-center mb-4">
            <div class="col-sm-4 col-md-5 col-lg-4">
                <button type="button" class="btn btn-primary btn-block" @click="loadQrUrlChatLimit">
                    <i class="far fa-copy"></i>&nbsp;
                    招待リンクをコピーする
                </button>
            </div>
        </div>

        <!-- メールシェア画面 -->
        <mail-share ref="share"></mail-share>

        <info-msg-title ref="msg" :tmsg="tmsg" :bmsg="bmsg" :btype="btype" :next="next" @ok="doCopy"></info-msg-title>


    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import MailShare from 'pages/commons/modal/inviteMailShare/inviteMailShare'
    import InfoMsgTitle from 'pages/commons/modal/infoMsg/InfoMsgTitle'

    export default {
        data () {
            return {
                tmsg: "",
                bmsg: "",
                btype: 3,
                next: ""
            }
        },
        components: {
            MailShare,
            InfoMsgTitle
        },
        watch: {
            getQrUrlChatLimitLoadStatus (status) {
                if (status == 1) {
                    this.$store.dispatch('startSpinner')
                }
                if (status == 2) {
                    this.$store.dispatch('doneSpinner')
                    this.inviteShareUrlCopy()
                }
                if (status == 1) {
                    this.$store.dispatch('doneSpinner')
                }
            }
        },
        computed: {
            ...mapGetters(['getQrUrlChatLimit', 'getQrUrlChatLimitLoadStatus'])
        },
        methods: {
            openMailShare () {
                this.$refs.share.openThis('company')
            },
            loadQrUrlChatLimit () {
                // 30分期限のQrコードUrl取得
                this.$store.dispatch('loadQrUrlChatLimit')
            },
            inviteShareUrlCopy () {
                this.openMsg('招待リンクをクリップボードへコピーしますか？'
                    ,'※リンクの有効期限は30分です。', 3, 'コピーする')
            },
            doCopy () {
                this.$copyText(this.getQrUrlChatLimit.qr_url_chat_limit).then(function (e) {}, function (e) {})
            },
            openMsg (tmsg, bmsg, btype, next="") {
                this.tmsg = tmsg
                this.bmsg = bmsg
                this.btype = btype
                this.next = next
                this.$refs.msg.openThis()
            }
        }
    }
</script>

<style lang="stylus" scoped>

</style>
