export default {

    /** ホーム情報 **/
    setCmpInviteHome (state, data) {
        state.cmpInviteHome = data
    },
    setCmpInviteHomeLoadStatus (state, status) {
        state.cmpInviteHomeLoadStatus = status
    },

    /** 招待コード情報 **/
    setCmpInviteInfo (state, data) {
        state.cmpInviteInfo = data
    },
    setCmpInviteInfoLoadStatus (state, status) {
        state.cmpInviteInfoLoadStatus = status
    },

    /** 招待コード情報取得(30分期限) **/
    setQrUrlChatLimit (state, data) {
        state.qrUrlChatLimit = data
    },
    setQrUrlChatLimitLoadStatus (state, status) {
        state.qrUrlChatLimitLoadStatus = status
    },

    /** 招待コード情報取得(法人：24時間期限) **/
    setShareQrUrlMailStatus (state, status) {
        state.shareQrUrlMailStatus = status
    },

    /** 通常メンバーをオーナーへ変更 **/
    setCmpInvitedListLaterChangeOwner (state, data) {
        state.cmpInvitedListLaterChangeOwner = data
    },
    setCmpInvitedChangeOwnerStatus (state, status) {
        state.cmpInvitedChangeOwnerStatus = status
    },

    /**  通常メンバーから権限委譲依頼 **/
    setCmpInvitedTransferOwnerStatus (state, status) {
        state.cmpInvitedTransferOwnerStatus = status
    },

    /**  利用者連携解除 **/
    setCmpInvitedListLaterUserUnlink (state, data) {
        state.cmpInvitedListLaterUserUnlink = data
    },
    setCmpInvitedUserUnlinkStatus (state, status) {
        state.cmpInvitedUserUnlinkStatus = status
    },

    /**  利用者連携 **/
    setCmpInvitedListLaterUserLink (state, data) {
        state.cmpInvitedListLaterUserLink = data
    },
    setCmpInvitedUserLinkStatus (state, status) {
        state.cmpInvitedUserLinkStatus = status
    },
    
}
