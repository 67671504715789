<template>
    <div class="container p-0 m-0" :style="{minHeight:minHeight + 'px'}">
        <nav>
            <ol class="breadcrumb p-1 my-1">
                <span class="ml-auto mr-2 text-muted"> {{ getTechs.length }}件</span>
            </ol>
        </nav>
        <div class="row mb-1" v-for="tech in getTechs" :key="tech.ai_id">
            <div class="col-sm mb-2">
                <div class="card">
                    <div class="card-header text-muted let-space-1 p-2 pt-3 pb-1">
                        <div class="p-0 pb-1">
                            <span v-if="tech.price_pub == '0'">
                                <i class="fas fa-yen-sign"></i>
                                <small v-if="tech.price_pub_start == tech.price_pub_end"> {{ tech.price_pub_end }}万円&nbsp; </small>
                                <small v-else>{{ tech.price_pub_start }} ~ {{ tech.price_pub_end }}万円&nbsp; </small>
                            </span>
                            <span v-if="tech.gender == '0'" >
                                <i class="fas fa-male"></i>
                                <small>男性&nbsp;</small>
                            </span>
                            <span v-else>
                                <i class="fas fa-female"></i>
                                <small>女性&nbsp;</small>
                            </span>
                            <span>
                                <i class="fas fa-user-graduate"></i>
                                <small> {{ tech.age }}歳&nbsp; </small>
                            </span>
                            <span>
                                <i class="fas fa-user-tie"></i>
                                <small> {{ tech.exper }}年経験&nbsp; </small>
                            </span>
                        </div>
                        <div>
                            <span class="text-nowrap border rounded px-1">
                                <small>稼働：{{editStartDate(tech.work_start_date)}}</small>
                            </span>
                            <span class="text-nowrap border rounded px-1">
                                <small>最寄駅：{{tech.nearest_station}}</small>
                            </span>
                            <span class="text-nowrap border rounded px-1">
                                <small>国籍：{{tech.country}}</small>
                            </span>
                        </div>
                        <div class="text-muted">
                            <small>
                                <i class="fas fa-calendar-alt"></i>
                                {{ tech.apply_at | dformatm }}
                            </small>
                        </div>
                    </div>
                    <div class="card-body px-2">
                        <div v-if="tech.name_pub == '0'">
                            <small class="text-muted">
                                名前：{{tech.first_nm}} {{tech.last_nm}} ({{tech.first_kana}} {{tech.last_kana}})
                            </small>
                        </div>
                        <p class="wrap-name m-0">
                            <span class="intv-name" data-toggle="tooltip" :title="tech.name">
                                {{tech.name}}
                            </span>
                            <span>さん</span>
                        </p>
                    </div>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item px-2 bg-light">
                            <div class="text-muted">
                                <span class="px-2">
                                    <a class="text-muted text-decoration-none" v-bind:href="tech.resume" target="_blank">
                                        <i class="fas fa-external-link-alt icon-size"></i>
                                        <small> 履歴書 </small>
                                    </a>
                                </span>
                                <span class="px-2" @click="openReplyTech(tech.ai_id, tech.tech_id, tech.user_id)" >
                                    <i class="fas fa-paper-plane icon-size"></i>
                                    <small> 返信 </small>
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <!-- 0件メッセージ -->
        <not-found-msg v-if="getTechs.length == 0"></not-found-msg>

    </div>
</template>

<script>
    import NotFoundMsg from 'pages/commons/notFoundMsg/NotFoundMsg'
    import {mapGetters} from 'vuex'

    export default {
        components: {
            NotFoundMsg
        },
        computed: {
            minHeight () {
                return $(window).height() - 54.22 - 63 - 111 - 55
            },
            ...mapGetters(['getTechs'])
        },
        methods: {
            openReplyTech (applyId, techId, userId) {
                this.$store.dispatch('openReplyTech', {
                    applyId: applyId,
                    techId: techId,
                    userId: userId
                })
            },
            editStartDate (val) {
                return this.$parent.editStartDate(val)
            },
        }
    }
</script>

<style lang="stylus" scoped>
    @import '~stylus/base/mixins.styl'
    .wrapper
       display: flex
       justify-content: center
       align-items: center
       position:absolute
       height:100%
       width:100%
    .wrap-name
        display:flex
        white-space: nowrap
        .intv-name
            ellipsis()
</style>
