<template>
    <div>
        <form-layout :errs-nm="errsNm" :errs="errs">
            <template v-slot:label> {{lab}} </template>
        
            <template v-slot:mark v-if="required"> ※ </template>
        
            <template v-slot:input>
                <div class="custom-file">
                    <input type="file" ref="fileSelect" class="custom-file-input" id="fileSelect" :accept="accept" @change="changeFile">
                    <label class="custom-file-label text-truncate let-space-1" for="fileSelect" data-browse="参照">{{fileLab}}</label>
                </div>
            </template>

            <template v-slot:input2>
                <div v-for="(item, idx) in fileArr" :value="item" :key="idx" class="mt-2">
                    <div v-if="item" @click="delFile" class="alert alert-success alert-dismissible show fade mb-1 pl-2 pr-4 py-2">
                        <span>{{item.name}} ({{sizeFormat(item.size, 'kb')}})</span>
                        <button type="button" class="close p-2">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </template>
        </form-layout>

    </div>

</template>

<script>
    import FormLayout from './Layout'
    import {mapGetters} from 'vuex'

    export default {
        components: {
            FormLayout
        },
        props: {
            // 必須mark有無
            required: {
                type: Boolean,
                default: true
            },
            // ラベル名
            lab: {
                type: String,
                default: ""
            },
            fileNm: {
                type: String,
                default: ""
            },
            // ファイル拡張子
            ext: Array,
            // ファイルサイズ(MB)
            size: Number
        },
        data () {
            return {
                fileLab: this.initFileNm(), 
                fileArr: [],
                accept: this.makeAccept(),
                errsNm: [],
                errs: {}
            }
        },
        methods: {
            getFile () {
                return this.$refs.fileSelect.files[0]
            },
            delFile() {
                this.resetThis()
            },
            resetThis () {
                let fileInput = this.$refs.fileSelect.value = ""
                this.fileLab = this.initFileNm()
                this.fileArr.shift()
            },
            changeFile () {
                // ファイル選択した場合チェック行う
                // ファイル選択画面開く、閉じるだけでチェックしない
                let flg = true
                let fileInput = this.$refs.fileSelect
                if (fileInput.value) {
                    if (flg && !this.checkExt()) {
                        this.openMsg('ext')
                        flg = false
                    }
                    if (flg && !this.checkFileSize()) {
                        this.openMsg('size')
                        flg = false
                    }
                    if (flg) {
                        this.fileArr.shift()
                        this.fileArr.push(fileInput.files[0])
                        this.fileLab = fileInput.files[0].name
                    } else {
                        this.resetThis()
                    }
                }
            },
            checkFileExist () {
                let fileInput = this.$refs.fileSelect
                if (fileInput.value) {
                    return true
                }
                this.openMsg('exist')
                return false
            },
            checkFileSize () {
                let file = this.$refs.fileSelect.files[0]
                //console.log('filesize:', file.size, '上限：', this.size*1024*1024)
                if (file.size > this.size * 1024 * 1024) {
                    return false
                }
                return true
                
            },
            sizeFormat (size, unit) {
                let temp = ""
                if (unit == 'kb') {
                    temp = size / 1024 
                }
                if (unit == 'mb') {
                    temp = size / 1024 * 1024
                }
                return Math.floor(temp) + unit
            },
            checkExt () {
                let fileInput = this.$refs.fileSelect
                let fileName = fileInput.files[0].name
                if (!this.doCheckExt(fileName)) {
                    return false
                } 
                return true
            },
            doCheckExt (filename) {
                // 拡張子の制限ない場合、スキップする
                if (this.ext.length == 1 && this.ext[0] == '*') {
                    return true
                }
                let index = filename.lastIndexOf(".");
                let fileExt = filename.substr(index + 1);
                return this.ext.includes(fileExt)
            },
            openMsg (val) {
                let lev = 1
                let msg = ""
                if (val == 'exist') {
                    msg = this.lab + "を選択してください。"
                }
                if (val == 'ext') {
                    msg = "拡張子は【" + this.ext.join('、') + "】のファイルを選択してください。"
                }
                if (val == 'size') {
                    msg = "アップロードサイズ上限(" + this.size + "MB)を超えています。"
                }
                this.$parent.setFileMsg({
                    lev: lev,
                    msg: msg
                })
            },
            makeAccept () {
                let newExtArr = this.ext.map(function (val, idx, arr) {
                    return '.' + val
                })
                return newExtArr.join(',')
            },
            initFileNm () {
                return this.fileNm ? this.fileNm : 'ファイルを選択...' 
            }
        }
    }
</script>

<style>

</style>
