<template>
    <div class="container">
        <div class="card com-card py-md-5 item-hover mb-2">
            <div class="card-body px-2">
                <div class="card-body p-2 text-muted text-center">
                    メンバー人数&nbsp;/&nbsp;上限
                </div>
                <div class="card-body p-2 text-center text-success" :class="{'text-danger': isOverLimit}">
                    <span class="point-num">
                        <b>{{item.handle_info.invited_cnt}}&nbsp;/&nbsp;{{item.handle_info.can_invite_cnt}}</b>
                    </span>&nbsp;
                    <span class="pt-2"><b>人</b></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        props: {
            item: Object
        },
        computed: {
            isOverLimit () {
                return this.item.handle_info.invited_cnt >= this.item.handle_info.can_invite_cnt
            },
        }
    }
</script>

<style lang="stylus" scoped>
    .point-num
        font-size: 1.8rem;

</style>
