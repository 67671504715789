export default {
    setCmpProjHome (state, resp) {
        state.cmpProjHome = resp
    },
    setCmpProjHomeLoadStatus (state, status) {
        state.cmpProjHomeLoadStatus = status
    },
    
    /** 案件情報 **/
    setCmpProjOperat (state, operat) {
        if (operat == 'success') {
            state.cmpProjOperat = 'show'
        } else {
            state.cmpProjOperat = operat == 'create_confirm' ? 'create' : 'edit'
        }
    },
    resetCmpProjOperat (state) {
        state.cmpProjOperat = ""
    },
    setCmpProjInfo (state, resp) {
        state.cmpProjInfo = resp
    },
    resetCmpProjInfo (state) {
        state.cmpProjInfo = {}
    },
    setSaveCmpProjInfoStatus (state, status) {
        state.saveCmpProjInfoStatus = status
    },
    setCmpProjInfoLoadStatus (state, status) {
        state.cmpProjInfoLoadStatus = status
    },
    setCmpProjOffStatus (state, status) {
        state.cmpProjOffStatus = status
    }
}
