<template>
    <div v-if="getIdentityInfo.data">
        <identity-header page-name="本人確認"></identity-header>
        <start-guide v-if="step == 0"></start-guide>
        <step-one v-else-if="step == 1" :need-private="getIdentityInfo.data.need_private"></step-one>
        <step-two v-else-if="step == 2" :need-identity="getIdentityInfo.data.need_identity"></step-two>
        <step-three v-else-if="step == 3" :email="getIdentityInfo.data.identity_mail"></step-three>
        <step-end v-else-if="step == 4" :approval-date="getIdentityInfo.data.approval_date"></step-end>

        <!-- メッセージ -->
        <info-msg ref="msg" :msg="msg"></info-msg>
    </div>
</template>

<script>
    import IdentityHeader from 'pages/commons/header/Header'
    import StartGuide from './components/StartGuide'
    import StepOne from './components/StepOne'
    import StepTwo from './components/StepTwo'
    import StepThree from './components/StepThree'
    import StepEnd from './components/StepEnd'
    import InfoMsg from 'pages/commons/modal/infoMsg/InfoMsg'

    import {mapGetters} from 'vuex'

    export default {
        components: {
            IdentityHeader,
            StartGuide,
            StepOne,
            StepTwo,
            StepThree,
            StepEnd,
            InfoMsg
        },
        data () {
            return {
                step: '',
                msg: ''
            }
        },
        mounted () {
            this.loadIdentityInfo()
        },
        destroyed () {
            this.$store.dispatch('resetIdentityInfo')
        },
        watch: {
            getIdentityInfoLoadStatus (status) {
                if (status == '1') {
                    this.$store.dispatch('startProgress')
                }
                if (status == '2') {
                    this.$store.dispatch('doneProgress')
                    this.initStep()
                }
                if (status == '3') {
                    this.$store.dispatch('doneProgress')
                }
            },
            getSendIdentityMailStatus (status) {
                if (status == '1') {
                    this.$store.dispatch('startSpinner')
                }
                if (status == '2') {
                    this.$store.dispatch('doneSpinner')
                    this.showMsg()
                    this.setStep(3)
                }
                if (status == '3') {
                    this.$store.dispatch('doneSpinner')
                }
            }
        },
        computed: {
            ...mapGetters(['getIdentityInfoLoadStatus', 'getIdentityInfo', 'getSendIdentityMailStatus'])   
        },
        methods: {
            loadIdentityInfo () {
                this.$store.dispatch('loadIdentityInfo')
            },
            initStep () {
                if (this.getIdentityInfo.data.need_identity) {
                    this.step = 0
                } else {
                    this.step = 4
                }
            },
            setStep (step) {
                this.step = step
            },
            sendIdentityMail () {
                this.$store.dispatch('sendIdentityMail')
            },
            showMsg () {
                if (this.step == 3) {
                    this.msg = 'メールを送信しました'
                    this.$refs.msg.openThis()
                }
            }
        }
    }
</script>

<style lang="stylus" scoped>

</style>
