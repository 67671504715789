<template>
    <div class="container">
        <!-- Nav tabs -->
        <ul class="nav nav-tabs sticky-top breadcrumb p-0 mb-1 nav-justified" role="tablist">
            <li class="nav-item">
                <a class="nav-link text-center px-2" data-toggle="tab" href="#project">案件情報</a>
            </li>
            <li class="nav-item">
                <a class="nav-link active text-center px-2" data-toggle="tab" href="#apply"><i class="fas fa-envelope text-danger"></i>&nbsp;応募者</a>
            </li>
            <li class="nav-item">
                <a class="nav-link text-center px-2" data-toggle="tab" href="#interview"><i class="fas fa-calendar-check text-success"></i>&nbsp;面接者</a>
            </li>
        </ul>
        <div class="tab-content">
            <div id="project" class="container tab-pane fade p-0">
                <!-- 案件情報 -->
                <proj-info></proj-info>
            </div>

            <div id="apply" class="container tab-pane active p-0">
                <!-- 応募者 -->
                <techs-info></techs-info>
            </div>

            <div id="interview" class="container tab-pane fade p-0">
                <!-- 面接者 -->
                <interviews-info></interviews-info>
            </div>
            <!-- 面接情報設定 -->
            <reply-tech :key="rtKey"></reply-tech>
        </div>
    </div>
</template>

<script>
    import ProjInfo from './ProjInfo'
    import TechsInfo from './TechsInfo'
    import ReplyTech from './ReplyTech'
    import InterviewsInfo from './InterviewsInfo'
    import {editProjStartDate} from 'pages/commons/form/comFunc'

    export default {
        components: {
            ProjInfo,
            TechsInfo,
            ReplyTech,
            InterviewsInfo
        },
        data () {
            return {
                rtKey: true
            }
        },
        methods: {
            resetReplyTech () {
                this.rtKey = !this.rtKey
            },
            editStartDate (val) {
                return editProjStartDate(val)
            },
        }
    }
</script>

<style>

</style>
