<template>
    <div class="container pb-3" v-if="item">
        <div class="card com-card pt-md-3">
            <div class="card-body">
                <form-item lab="ニックネーム">{{item.name}}</form-item>
                <form-item lab="性別">{{item.gender == '0' ? '男' : '女'}}</form-item>
                <form-item lab="年齢">{{item.age}} 歳</form-item>
                <form-item lab="経験年数">{{item.exper}} 年</form-item>
                <form-item v-if="item.country" lab="国籍">{{item.country}}</form-item>
                <form-item v-else lab="国籍"><span class="text-danger">未入力</span></form-item>

                <form-item v-if="item.work_start_date" lab="稼働開始">{{converDate('work_start_date', item.work_start_date)}}</form-item>
                <form-item v-else lab="稼働開始"><span class="text-danger">未入力</span></form-item>

                <form-item v-if="item.nearest_station" lab="最寄り駅">{{item.nearest_station}}</form-item>
                <form-item v-else lab="最寄り駅"><span class="text-danger">未入力</span></form-item>

                <form-item v-if="item.hope_price_start == item.hope_price_end" 
                           lab="希望単価"><span :class="{'text-danger':!is_price_ok}">{{pubFormat(item.hope_price_end)}} 円</span></form-item>
                <form-item v-else lab="希望単価"><span :class="{'text-danger':!is_price_ok}">{{pubFormat(item.hope_price_start)}} ~ {{pubFormat(item.hope_price_end)}} 円</span></form-item>

                <form-item lab="日本語レベル">{{converVal('jp',item.jp_level)}}</form-item>
                <form-item v-if="item.en_level" lab="英語レベル">{{converVal('en',item.en_level)}}</form-item>
                <form-item v-else lab="英語レベル"><span class="text-danger">未入力</span></form-item>

                <form-item v-if="item.skill" lab="スキル">{{item.skill}}</form-item>
                <form-item v-else lab="スキル"><span class="text-danger">未入力</span></form-item>

                <form-item v-if="item.hope" lab="希望">{{item.hope}}</form-item>
                <form-item v-else lab="希望"><span class="text-danger">未入力</span></form-item>

                <form-item v-if="item.note" lab="備考">{{item.note}}</form-item>
                <form-item v-else lab="備考"><span class="text-danger">未入力</span></form-item>

                <div class="form-group row justify-content-center">
                    <label for="resume" class="col-md-3 col-form-label text-muted">
                        <small> 履歴書 </small>
                    </label>
                    <div class="col-md-8 px-lg-3 px-1">
                        <div class="card card-header p-2 let-space-1">
                            <div class="row d-flwx align-items-center">
                                <div class="col-sm-9 col-md-7 mt-1">{{ item.resume }}</div>
                                <div class="col-sm-3 col-md-5 mt-1">
                                    <a class="btn btn-secondary btn-block btn-lg" :href="item.resume_url" target="_blank">ダウンロード</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card mt-4 border-0">
                    <div class="card-body">
                        <div class="row form-group justify-content-center mb-0">
                            <div class="col-sm-3">
                                <button class="btn btn-danger btn-block btn-lg" @click="toApply">
                                    <i class="fas fa-paper-plane"></i>&nbsp;応募する
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import FormItem from 'pages/commons/form/Confirm'
    import {getList, converVal} from 'pages/commons/form/selectList'
    import {numCommaFormat, isOverProjStartDate} from 'pages/commons/form/comFunc'

    export default {
        components: {
            FormItem
        },
        props: {
            item: Object,
            proj: Object
        },
        computed: {
            is_price_ok () {
                if (this.proj.proj_price_pub == '1') {
                    return true
                } else if (Number(this.item.hope_price_end) <= Number(this.proj.proj_price_pub_end)) {
                    return true
                } else {
                    return false
                }
            },
            is_require_ok () {
                if (this.item.name && this.item.country && this.item.nearest_station) {
                    return true
                } else {
                    return false
                }
            }
        },
        methods: {
            toApply () {
                if (this.checkRequire() && this.checkPrice()) {
                    this.$parent.suggestion('0','0',[this.item.id])
                } 
            },
            checkPrice () {
                if (this.is_price_ok) {
                    return true
                } else {
                    this.$parent.openMsg('月額単価より希望単価を上回っている為、希望単価を設定し直してください。', 1)
                    return false
                }
            },
            checkRequire () {
                if (this.is_require_ok) {
                    return true
                } else {
                    this.$parent.openMsg('未入力の履歴情報を完了してください。', 1)
                    return false
                }
            },
            converDate (name, value) {
                if (!this.isOverStart(value)) {
                    return this.converVal(name, value)
                }
                return '即日～'
            },
            converVal (name, val) {
                return converVal(name,val)
            },
            isOverStart (val) {
                return isOverProjStartDate(val)
            },
            pubFormat (val) {
                return numCommaFormat(val)
            }
        }
    }
</script>

<style></style>
