<template>
    <div>
        <form-header v-if="opt == 'create'" :page-name="pageName"></form-header>
        <form-header v-else :page-name="pageName" do-cust @cust="backPage"></form-header>
        <div class="container">
            <div class="card mb-2 com-card pt-md-4">
                <div class="card-body">
                    <!-- 氏名 -->
                    <form-input ref="name" ip-type="name" :errs-nm="['first_nm','last_nm']"
                                lab="氏名" slab1="氏名(姓)" slab2="氏名(名)" plhd="例：ぷろめい" plhd2="例：太郎"
                                required required2 no-mark no-mark2 :max-length="10" :max-length2="10"></form-input>
        
                    <!-- 氏名フリガナ -->
                    <form-input ref="name_kana" ip-type="name" :errs-nm="['first_kana','last_kana']"
                                lab="氏名フリガナ" slab1="氏名フリガナ(姓)" slab2="氏名フリガナ(名)" plhd="例：プロメイ" plhd2="例：タロウ"
                                required required2 no-mark no-mark2 kana kana2 :max-length="20" :max-length2="20"></form-input>
        
                    <!-- 性別 -->
                    <form-input ref="gender" ip-type="gender" :errs-nm="['gender']" lab="性別" required></form-input>
        
                    <!-- 生年月日 -->
                    <form-input ref="birthday" ip-type="birthday" :errs-nm="['year','month','day']" lab="生年月日"></form-input>
        
                    <!-- 郵便番号住所 -->
                    <zip-code ref="zipcode" my-nm="user"></zip-code>
        
                    <!-- 電話番号 -->
                    <form-input ref="phone" :errs-nm="['phone']" lab="電話番号(携帯)" plhd="例：08012340001" required numeric :max-length="11" :length="[true,11]"></form-input>
        
                    <!-- 契約種類 -->
                    <form-input ref="contract" ip-type="select" :errs-nm="['contract']" :s-opt="contractList" val-nm="val"
                                lab-nm="lab" dft="0" lab="希望契約種類" required numeric :max-length="1" @contractChange="contractChange"></form-input>
        
                    <!-- 会社情報 -->
                    <div v-show="selectedContract == '1'">

                        <form-input ip-type="comment" py0>
                            <span class="text-danger">
                                <i class="fas fa-info-circle"></i>
                                現在所属会社経由で契約する場合、自社の責任者と合意した上で以下会社情報を入力してください。
                            </span>
                        </form-input>
                        <hr>
        
                        <!-- 会社名 -->
                        <form-input ref="company_nm" :errs-nm="['company_nm']" lab="会社名" plhd="例：〇〇〇株式会社" required :max-length="50"></form-input>
        
                        <!-- (会社)郵便番号住所 -->
                        <zip-code ref="cmp_zipcode" my-nm="company"></zip-code>
        
                        <!-- 会社ホームページURL -->
                        <form-input ref="company_url" :errs-nm="['company_url']" lab="会社ホームページURL" plhd="例：https://tech.promeraia.com" url :max-length="100"></form-input>
        
                        <!-- 氏名 -->
                        <form-input ref="name_sales" ip-type="name" :errs-nm="['first_nm_sales','last_nm_sales']"
                                    lab="営業担当(氏名)" slab1="氏名(姓)" slab2="氏名(名)" plhd="例：ぷろめい" plhd2="例：太郎"
                                    required required2 no-mark no-mark2 :max-length="10" :max-length2="10"></form-input>
        
                        <!-- 氏名フリガナ -->
                        <form-input ref="name_kana_sales" ip-type="name" :errs-nm="['first_kana_sales','last_kana_sales']"
                                    lab="営業担当(氏名フリガナ)" slab1="氏名フリガナ(姓)" slab2="氏名フリガナ(名)" plhd="例：プロメイ" plhd2="例：タロウ"
                                    required required2 no-mark no-mark2 kana kana2 :max-length="20" :max-length2="20"></form-input>
        
                        <!-- 営業担当(携帯) -->
                        <form-input ref="sales_phone" :errs-nm="['sales_phone']" lab="営業担当(携帯)" plhd="例：08012340001" required numeric :max-length="11" :length="[true,11]"></form-input>
                        <!-- 営業担当(メール) -->
                        <form-input ref="sales_mail" :errs-nm="['sales_mail']" lab="営業担当(メール)" plhd="例：promeraia@tech.promeraia.com" required mail :max-length="100"></form-input>
        
                    </div>
        
                    <div class="card mb-2 border-0">
                        <div class="card-body">
                            <div class="row form-group justify-content-center mb-0">
                                <div class="col-sm-4">
                                    <button class="btn btn-success btn-block btn-lg" @click="dataCheck">
                                        <i class="fas fa-eye"></i>&nbsp;確認画面へ
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ZipCode from 'pages/commons/zipCode/ZipCode'
    import FormInput from 'pages/commons/form/Input'
    import FormHeader from 'pages/commons/header/Header'

    import {mapGetters} from 'vuex'

    export default {
        props: {
            opt: String
        },
        components: {
            ZipCode,
            FormInput,
            FormHeader
        },
        data () {
            return {
                contractList: this.makeContract(),
                selectedContract: "",
                reqForm: {},
                resultArr: [],
                formArr:[
                        'name'
                        ,'name_kana'
                        ,'gender'
                        ,'birthday'
                        ,'phone'
                        ,'contract'
                    ],
                formArrCmp:[
                        'company_nm'
                        ,'company_url'
                        ,'name_sales'
                        ,'name_kana_sales'
                        ,'sales_phone'
                        ,'sales_mail'
                    ],
            }
        },
        watch: {
            getPrivateInfo (info) {
                if (info.data.private) {
                    this.setDefaultVal(info.data.private)
                }
            }
        },
        computed: {
            pageName () {
                return this.opt == 'create' ? "個人情報入力" : "個人情報編集"
            },
            ...mapGetters(['getPrivateInfo'])
        },
        methods: {
            backPage () {
                this.$parent.changeOperat('show')
            },
            setDefaultVal (info) {
                // 編集ボダン押下
                this.$refs.name.setInputData(info.first_nm, info.last_nm)
                this.$refs.name_kana.setInputData(info.first_kana, info.last_kana)
                this.$refs.gender.setInputData(info.gender)
                this.$refs.birthday.setBirthday(info.year, info.month, info.day)
                this.$refs.zipcode.setDefaultVal(info.user_zipcode, info.user_address1, info.user_address2
                                    , info.user_address3, info.user_address1_kana, info.user_address2_kana)
                this.$refs.phone.setInputData(info.phone)
                this.$refs.contract.setInputData(info.contract)
                this.selectedContract = info.contract
                if (this.selectedContract == '1') {
                    this.$refs.company_nm.setInputData(info.company_nm)
                    this.$refs.cmp_zipcode.setDefaultVal(info.company_zipcode, info.company_address1
                                    , info.company_address2, info.company_address3, info.company_address1_kana, info.company_address2_kana)
                    if (info.company_url) {
                        this.$refs.company_url.setInputData(info.company_url)
                    }
                    this.$refs.name_sales.setInputData(info.first_nm_sales, info.last_nm_sales)
                    this.$refs.name_kana_sales.setInputData(info.first_kana_sales, info.last_kana_sales)
                    this.$refs.sales_phone.setInputData(info.sales_phone)
                    this.$refs.sales_mail.setInputData(info.sales_mail)
                }
            },
            getChildData () {
                // ユーザー情報
                this.formArr.forEach(item => {
                    var formData = this.$refs[item].getData()
                    this.reqForm = Object.assign({},this.reqForm, formData)
                })
                // 会社情報
                if (this.selectedContract == '1') {
                    this.formArrCmp.forEach(item => {
                        var formDataCmp = this.$refs[item].getData()
                        this.reqForm = Object.assign({},this.reqForm, formDataCmp)
                    })
                }

                // ユーザー住所
                var zipcodeData = this.$refs.zipcode.getData()
                var cmp_zipcodeData = {}
                
                // 会社住所
                if (this.selectedContract == '1') {
                    cmp_zipcodeData = this.$refs.cmp_zipcode.getData()
                }
                this.reqForm = Object.assign({},this.reqForm, zipcodeData, cmp_zipcodeData)
            },
            getData() {
                return this.reqForm
            },
            dataCheck () {

              //  this.getChildData()
              //  this.$parent.getChildData()

                this.checkForm()
                // ユーザー郵便フォーム
                this.userZipCheckForm()
                // 会社郵便フォーム
                this.cmpZipCheckForm()
                this.doDataCheck ()
            },
            doDataCheck () {
                Promise.all(this.resultArr)
                    .then((res) => {
                        this.getChildData()
                        this.$parent.getChildData()
                        this.$parent.changeOperat('auto')
                    })
            },
            checkForm () {
                this.resultArr = []
                this.formArr.forEach(formName => {
                    this.doCheckForm(formName)
                })
                if (this.selectedContract == '1') {
                    this.formArrCmp.forEach(formName => {
                        this.doCheckForm(formName)
                    })
                }
            },
            userZipCheckForm () {
                this.$refs.zipcode.checkForm()
                let zipcodeResult = this.$refs.zipcode.getResultArr()
                zipcodeResult.forEach(item => {
                    this.resultArr.push(item)
                })
            },
            cmpZipCheckForm () {
                if (this.selectedContract == '1') {
                    this.$refs.cmp_zipcode.checkForm()
                    let cmp_zipcodeResult = this.$refs.cmp_zipcode.getResultArr()
                    cmp_zipcodeResult.forEach(item => {
                        this.resultArr.push(item)
                    })
                }
            },
            doCheckForm (formName) {
                var result = new Promise((resolve, reject) => {
                    let valid = this.$refs[formName].dataCheck()           
                    if (valid) { 
                        resolve() }
                })
                this.resultArr.push(result)
            },
            doSubmit () {
                this.$store.dispatch('createPrivateInfo', this.reqForm)
            },
            makeContract () {
                return this.$parent.getList('contract')
            },
            contractChange (val) {
                this.selectedContract = val
            }
        }
    }
</script>

<style>

</style>
