<template>
    <favorite-list 
        ref="list"
        header-text="お気に入り"
        mt>
        <div v-if="getListObj">
            <div v-if="getListObj.data.length > 0">
                <div class="card mb-2 p-lg-4 p-md-3 com-card" v-for="item in getListObj.data" :key="item.id" :class="{'com-card-focus':selectedProj == item.pj_id}">
                    <!-- Header -->
                    <template v-if="item.proj_open_flg">
                        <div class="card-header text-muted let-space-1 p-2 pt-3">
                                <div class="d-flex">
                                    <!-- 単価 -->
                                    <head-price :item="item"></head-price>
                                    <!-- メニュー -->
                                    <div class="text-right flex-fill">
                                        <span class="text-muted pl-2 ml-auto item-hover" @click="openMenu(item.fa_id)">
                                            <i class="fas fa-ellipsis-v"></i>
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <!-- 基本情報 -->
                                    <head-info :item="item"></head-info>
                                </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="card-header border-0 p-2 pt-2">
                            <!-- メニュー -->
                            <div class="text-right flex-fill item-hover">
                                <span class="text-muted pl-2" @click="openMenu(item.fa_id)">
                                    <i class="fas fa-ellipsis-v"></i>
                                </span>
                            </div>
                        </div>
                    </template>
                    <!-- Body -->
                    <template v-if="item.proj_open_flg">
                        <div class="card-body pb-1 pt-3 px-2 item-hover" @click="toProjDetail(item.pj_id)">
                            <!-- 案件名 -->
                            <body-info :item="item"></body-info>
                        </div>
                    </template>
                    <template v-else>
                        <div class="card-body pb-1 pb-5 px-2 item-hover">
                            <div class="text-danger text-center py-2">
                                この案件は既に削除されました。
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <!-- メニュー -->
        <favorite-menu ref="menu"></favorite-menu>

    </favorite-list>
</template>

<script>
    import FavoriteMenu from './components/Menu'
    import FavoriteList from 'pages/commons/List/List'
    import HeadPrice from 'pages/commons/projList/CardHeadPrice'
    import HeadInfo from 'pages/commons/projList/CardHeadInfo'
    import BodyInfo from 'pages/commons/projList/CardBodyInfo'
    import {mapGetters} from 'vuex'

    export default {
        components: {
            FavoriteList,
            FavoriteMenu,
            HeadPrice,
            HeadInfo,
            BodyInfo
        },
        mounted () {
            this.$refs.list.loadListObj({
                url:'my.favorites',
                data: '0' 
            })
            this.$refs.list.resetRefresh('favorites')
        },
        data () {
            return {
                selectedProj:''
            }
        },
        activated () {
            this.$refs.list.isGetList('favorites', { url:'my.favorites', data: '0'})
            this.$refs.list.resetRefresh('favorites')
        },
        watch: {
            $route (to, from) {
                if (from.name == 'ProjDetail') {
                    this.projFromSession()
                } else {
                    this.selectedProj = ''
                }
            }
        },
        computed: {
            ...mapGetters(['getListObj'])
        },
        methods: {
            toProjDetail (id) {
                this.projToSession(id)

                this.$router.push({name:'ProjDetail', params:{
                    proc: 'favorite',
                    id:id
                }})
            },
            projToSession (id) {
                sessionStorage.setItem('favoriteProj', id)
            },
            projFromSession () {
                this.selectedProj = sessionStorage.getItem('favoriteProj')
            },
            openMenu (id) {
                this.$refs.menu.openThis(id)
            },
            seeMoreMt () {
                this.$store.dispatch('loadListObjSeeMore', {
                    url:'my.favorites',
                    skip: this.getListObj.data.length 
                })
            },
        }
    }
</script>
