<template>
    <div v-if="getCmpInviteInfo">
        <invite-code-header page-name="招待コード"></invite-code-header>
        <invite-code-qr></invite-code-qr>
        <invite-code-share></invite-code-share>
    </div>
</template>

<script>
    import InviteCodeHeader from 'pages/commons/header/Header'
    import InviteCodeQr from './components/Code'
    import InviteCodeShare from './components/Share'
    import {mapGetters} from 'vuex'

    export default {
        components: {
            InviteCodeHeader,
            InviteCodeQr,
            InviteCodeShare
        },
        watch: {
            getCmpInviteInfoLoadStatus (status) {
                if (status == 1) {
                    this.$store.dispatch('startProgress')
                }

                if (status == 2) {
                    this.$store.dispatch('doneProgress')
                }

                if (status == 3) {
                    this.$store.dispatch('doneProgress')
                }
            }
        },
        created () {
            this.loadCmpInviteInfo()
        },
        computed: {
            ...mapGetters(['getCmpInviteInfo', 'getCmpInviteInfoLoadStatus'])
        },
        methods: {
            loadCmpInviteInfo () {
                this.$store.dispatch('loadCmpInviteInfo')
            }
        }
    }

</script>

<style lang="stylus" scoped>

</style>
