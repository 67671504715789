<template>
    <div class="pd-content section-foot d-flex align-items-center" :class="{'section-foot-full':fullFooter}">
        <div class="container ft-family">
            <div v-if="showIcon" class="row">
                <div class="col text-center mb-4">
                    <span class="pr-3">
                        <a href="https://twitter.com/promeraia" class="a-social" target="_blank">
                            <i class="fab fa-twitter-square icon-4"></i>
                        </a>
                    </span>
                    <span class="pl-3">
                        <a href="https://www.facebook.com/profile.php?id=100078293725607" class="a-social" target="_blank">
                            <i class="fab fa-facebook-square icon-4"></i>
                        </a>
                    </span>
                </div>
            </div>
            <div v-if="showInfo" class="row text-center mb-2">
                    <div class="col">
                        <small>
                            <a href="https://promeraia.com" target="_blank" class="mr-3 a-font text-nowrap"> About us </a>
                            <a href="https://tech.promeraia.com/ja/privacy" target="_blank" class="mr-3 a-font text-nowrap">Privacy Policy</a>
                            <a href="https://tech.promeraia.com/ja/terms" target="_blank" class="mr-3 a-font text-nowrap">Terms of Service</a>
                        </small>
                    </div>
            </div>
            <div class="row text-center">
                    <div class="col">
                        <small>
                            © Promeraia Corp. All rights reserved.
                        </small>
                    </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        showIcon:{
            type:Boolean,
            default:false
        },
        showInfo:{
            type:Boolean,
            default:false
        }
    },
    computed:{
        fullFooter () {
            return this.$route.meta.fullFooter
        }
    }
}

</script>

<style lang="stylus" scoped>
.icon-4
    font-size:3rem
    transition:0.1s
.pd-content 
    padding-top:25px;
    padding-bottom:25px;
.section-foot
    height:70px
    margin-top:-70px
    background:white
.section-foot-full
    height:200px!important
    margin-top:-200px!important
.a-font
    color:black
.a-social
    text-decoration: none!important
.a-font:hover
    text-decoration: none!important
    color:#1e9bf0!important
.ft-family
    font-family:"メイリオ"
</style>
