<template>
    <div id="modal-user-link" class="modal fade" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">

                    <!-- エラー -->
                    <err-msg-bar :name="['linkInfo']" :local-errs="errs"></err-msg-bar>
                    <err-msg-bar :name="['email','name']" :local-errs="errs" class="mb-2"></err-msg-bar>

                    <input v-model.trim="name" type="text" class="form-control mb-2" maxlength="20" spellcheck=false placeholder="ユーザー名"> </input>
                    <input v-model.trim="email" type="text" class="form-control" maxlength="100" spellcheck=false placeholder="利用者メールアドレス"> </input>
                    <small class="text-muted">※ログインや通知の受け取りに利用する</small>
                </div>
                <div class="modal-footer">
                    <div class="container">
                        <div class="row">
                            <div class="col-6">
                                <button class="btn btn-danger btn-block m-0" type="button" @click="cmpInvitedUserLink">
                                    連携する
                                </button>
                            </div>
                            <div class="col-6">
                                <button class="btn btn-primary btn-block m-0" type="button" @click="closeThis">
                                    閉じる
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {required, maxLength, email} from "vuelidate/lib/validators"
    import ErrMsgBar from 'pages/commons/err/ErrMsgBar'
    import {mapGetters} from 'vuex'

    export default {
        data () {
            return {
                name: "",
                email: "",
                item: {},
                errs: {},
            }
        },
        components: {
            ErrMsgBar
        },
        watch: {
            getCmpInvitedUserLinkStatus (status) {
                if (status == 1) {
                    this.$store.dispatch('startSpinner')
                }
                if (status == 2) {
                    this.$store.dispatch('setListObj', this.getCmpInvitedListLaterUserLink)
                    this.$store.dispatch('doneSpinner')
                    this.closeThis()
                }
                if (status == 3) {
                    this.$store.dispatch('doneSpinner')
                }
            },
        },
        computed: {
            ...mapGetters(['getCmpInvitedUserLinkStatus', 'getCmpInvitedListLaterUserLink'])
        },
        methods: {
            cmpInvitedUserLink () {
                if (this.dataCheck()) {
                    this.$store.dispatch('cmpInvitedUserLink',{
                        id: this.item.user_id,
                        name: this.name,
                        email: this.email,
                    })
                }
            },
            doCheck () {
                this.$v.$touch()
                return !this.$v.$invalid
            },
            dataCheck () {
                if (this.doCheck()) {
                    this.clearErrs()
                    return true
                } else {
                    this.showErrs()
                    return false
                }
            },
            showErrs () {
                this.clearErrs()
                let arr = []

                if (!this.$v.name.required) { arr.push("ユーザー名を入力してください。") }  
                if (!this.$v.name.maxLength) { arr.push("ユーザー名は20文字以内を入力してください。") }  

                if (!this.$v.email.required) { arr.push("メールアドレスを入力してください。") }  
                if (!this.$v.email.email) { arr.push("正しいメールアドレスを入力してください。") }  
                if (!this.$v.email.maxLength) { arr.push("メールアドレスは100文字以内を入力してください。") }  
                if (arr.length > 0) {this.errs.linkInfo = arr}
            },
            clearErrs () {
                this.errs = {}
            },
            openThis (item) {
                this.item = item
                $('#modal-user-link').modal('show')
            },
            closeThis () {
                this.resetThis()
                $('#modal-user-link').modal('hide')
            },
            resetThis () {
                this.name = "",
                this.email = "",
                this.item = {},
                this.errs = {}
            }
        },
        validations: {
            name: {
                required,
                maxLength: maxLength(20),
            },
            email: {
                required,
                maxLength: maxLength(100),
                email
            }
        }
    }
</script>

<style lang="stylus">

</style>
