<template>
    <div class="container">
        <div class="row">
            <div class="col-sm mb-2">
                <page-nav icn="fas fa-comment text-success" lab="募集中の案件" @do_click="toActProj"></page-nav>
            </div>
            <div class="col-sm mb-2">
                <page-nav icn="fas fa-comment-slash text-danger" lab="募集終了の案件" @do_click="toInactProj"></page-nav>
            </div>
        </div>
        <div class="row">
            <div class="col-sm mt-2">
                <i class="far fa-lightbulb text-warning-cust"></i>
                <small class="text-muted">
                    募集終了案件の[求人状況]を[募集中]に変更することで募集再開することができます。
                </small>
            </div>
        </div>
    </div>
</template>

<script>
    import PageNav from 'pages/commons/nav/PageNav'
    import {mapGetters} from 'vuex'

    export default {
        components: {
            PageNav
        },
       // computed: {
       //     ...mapGetters(['getInitHome'])
       // },
        methods: {
            toActProj () {
                this.$store.dispatch('setRefreshArr', 'cmpActProjs')
                this.$router.push({name: 'CmpActProj', params: {do: 'open'}})
            },
            toInactProj () {
                this.$store.dispatch('setRefreshArr', 'cmpActProjs')
                this.$router.push({name: 'CmpActProj', params: {do: 'close'}})
            }
        }
    }
</script>

<style lang="stylus" scoped>

</style>
