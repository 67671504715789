<template>
        <form-layout :py0="py0" :mb="mb" :has-msg="hasMsgBar" :errs-nm="errsNm" :errs="errs">

            <template v-slot:label> {{lab}} </template>

            <template v-slot:mark v-if="required"> ※ </template>

            <template v-slot:size v-if="hasLen">{{textSize}}</template>

            <template v-slot:input>

                <!-- modal select Input -->
                <div v-if="ipType == 'inputmodal'" class="form-control let-space-1" @click="inputModalOpen">{{inputData2}}</div>

                <!-- Input -->
                <input v-if="ipType == 'input'" v-model.trim="inputData" :type="type" class="form-control" :class="{disitem:isdisabled}" :style="custStyle" 
                    @blur='inputBlur' :maxLength="maxLength" spellcheck=false :placeholder="plhd" :disabled="isdisabled">

                <!-- textarea -->
                <div v-if="ipType == 'textarea'" class="w-100">
                    <small v-if="hasTrim" class="btn btn-link pl-0 pb-0 item-hover" @click="textRowTrim">全行トリム</small>
                    <small v-if="noBlank" class="btn btn-link pl-0 pb-0 item-hover" @click="textBlankDel">空行削除</small>
                    <textarea v-model.trim="inputData" class="form-control" :rows="textareaRow" :maxlength="maxLength" spellcheck=false :placeholder="plhd"></textarea>
                </div>

                <!-- コメント -->
                <span v-if="ipType == 'comment'" class="text-muted">
                    <small>
                        <slot></slot>
                    </small>
                </span>

                <!-- Select -->
                <select v-if="ipType == 'select'" v-model.trim="inputData" class="form-control" :class="{disitem:isdisabled}" @change="selectChange" :disabled="isdisabled">
                        <template v-if="idxNm">
                            <option v-for="item in sOpt" :key="item[idxNm]" :value="item[valNm]">{{item[labNm]}}</option>
                        </template>
                        <template v-else>
                            <option v-for="(item,index) in sOpt" :key="index" :value="item[valNm]">{{item[labNm]}}</option>
                        </template>
                </select>

                <!-- Name -->
                <div v-if="ipType == 'name'" class="row">
                    <div class="col-6 d-flex pr-1">
                        <label class="pr-1 col-form-label text-muted">
                            <small> 姓</small>
                        </label>
                        <input v-model.trim="inputData" :type="type" class="form-control" :maxLength="maxLength" :disabled="isdisabled" :class="{disitem:isdisabled}" spellcheck=false :placeholder="plhd">
                    </div>
                    <div class="col-6 d-flex pl-1">
                        <label class="pr-1 col-form-label text-muted">
                            <small> 名</small>
                        </label>
                        <input v-model.trim="inputData2" :type="type" class="form-control" :maxLength="maxLength" :disabled="isdisabled" :class="{disitem:isdisabled}" spellcheck=false :placeholder="plhd2">
                    </div>
                </div>

                <!-- Gender -->
                <div v-if="ipType == 'gender'" class="col-6 col-md-3 col-sm-3 px-0">
                    <select v-model.trim="inputData" class="form-control" >
                        <option value="0">男</option>
                        <option value="1">女</option>
                    </select>
                </div>

                <!-- Birthday -->
                <template v-if="ipType == 'birthday'">
                    <div class="col-5 d-flex p-0">
                        <select v-model.trim="birthday.year" class="form-control px-1" @change="birthdayChange" >
                            <option v-for="(n,idx) in birthday.yearList" :value="n" :key="idx">{{n}}</option>
                        </select>
                        <label class="col-form-label text-muted pl-1">
                            <small>年</small>
                        </label>
                    </div>
                    <div class="col-7 d-flex p-0">
                        <div class="d-flex p-0 w-50">
                            <select v-model.trim="birthday.month" class="form-control px-1" @change="birthdayChange" >
                                <option v-for="(n,idx) in 12" :value="addZero(n)" :key="idx">{{addZero(n)}}</option>
                            </select>
                            <label class="col-form-label text-muted pl-1">
                                <small>月</small>
                            </label>
                        </div>
                        <div class="d-flex p-0 w-50">
                            <select v-model.trim="birthday.day" class="form-control px-1" >
                                <option v-for="(n,idx) in daysOfBirthday" :value="addZero(n)" :key="idx">{{addZero(n)}}</option>
                            </select>
                            <label class="col-form-label text-muted pl-1">
                                <small>日</small>
                            </label>
                        </div>
                    </div>
                </template>

                <!-- 郵便番号 -->
                <div v-if="ipType == 'zipcode'">
                    <div class="row">
                        <div class="col-6">
                            <input v-model.trim="inputData" type="text" @input="zipcodeAsyn" class="form-control" :maxLength="maxLength" spellcheck=false placeholder="例：1230011"></input>
                        </div>
                        <div class="col-5 pl-0">
                            <button type="button" class="btn btn-success flex-fill" @click="loadAddress">
                                住所検索
                            </button>
                        </div>
                    </div>
                    <span class="text-muted let-space-1">
                        <small> <small>※(半角数字) ハイフン(-)なしで入力してください。</small> </small>
                    </span>
                </div>

            </template>

            <template v-slot:note v-if="hasBtn">
                <button class="btn btn-link text-nowrap pr-0" @click="btnClick" :class="{'text-danger':actBtn == errsNm[0]}">
                    <i class="fas fa-edit icon-size"></i>
                </button>
            </template>

        </form-layout>
    </div>
</template>

<script>
    /**
     * form項目専用コンポーネント
     **/
    import FormLayout from './Layout'
    import {requiredIf, required, maxLength, numeric} from "vuelidate/lib/validators"
    import {isNumeric, isKana, isMail, isNoMark, length, isUrl, isZipcode, isUpUnder} from './customValidators.js'

    export default {
        props: {
            // textare各行trim
            hasTrim: {
                type: Boolean,
                default: true
            },
            noBlank: {
                type: Boolean,
                default: true
            },
            // 備考ボタン
            hasBtn: {
                type: Boolean,
                default: false
            },
            // 押下時ボタン色変える為に、actボタン認識用
            actBtn: {
                type: String,
                default: ""
            },
            // 文字サイズ表示
            hasLen: {
                type: Boolean,
                default: false
            },
            // input/select/checkboxなど
            ipType: {
                type: String,
                default: 'input'
            },
            // select用options(idxNm:index用オブジェクトkey, valNm:value用オブジェクトkey, labNm: lab用オブジェクトkey)
            sOpt: Array,
            idxNm: {
                type: String,
                default: ""
            },
            valNm: {
                type: String,
                default: ""
            },
            labNm: {
                type: String,
                default: ""
            },
            //項目英語名
            itemNm: {
                type: String,
                default: ""
            },
            itemNm2: {
                type: String,
                default: ""
            },
            // inputDataディフォルト値
            dft: {
                type: String,
                default: ""
            },
            // inputData2ディフォルト値
            dft2: {
                type: String,
                default: ""
            },
            // birthday year ディフォルト値の 遡る年数
            by: {
                type: Number,
                default: 18
            },
            // birthday month ディフォルト値
            bm: {
                type: String,
                default: "01"
            },
            // birthday day ディフォルト値
            bd: {
                type: String,
                default: "01"
            },

            // Label設定しないときのspaceを消去
            py0: {
                type: Boolean,
                default: false
            },
            // 項目間間隔(default:mb-2)
            mb: {
                type: String,
                default: "2"
            },
            // エラー用項目名配列
            errsNm: Array,
            // 項目ラベル文言
            lab: {
                type: String,
                default: ""
            },
            // サブ項目ラベル文言
            slab1: {
                type: String,
                default: ""
            },
            // サブ項目ラベル文言
            slab2: {
                type: String,
                default: ""
            },

            // inputのtype属性
            type: {
                type: String,
                default: "text"
            },
            // placeholder属性
            plhd: {
                type: String,
                default: ""
            },
            plhd2: {
                type: String,
                default: ""
            },
            // textareaのrow属性
            row: {
                type: Number,
                default: 2
            },
            // disabled属性
            disb: {
                type : Boolean,
                default: false
            },
            disb2: {
                type : Boolean,
                default: false
            },


            /** validate **/
            required: {
                type: Boolean,
                default: false
            },
            required2: {
                type: Boolean,
                default: false
            },
            numeric: {
                type: Boolean,
                default: false
            },
            numeric2: {
                type: Boolean,
                default: false
            },
            mail: {
                type: Boolean,
                default: false
            },
            mail2: {
                type: Boolean,
                default: false
            },
            // 特殊文字以外
            noMark: {
                type: Boolean,
                default: false
            },
            noMark2: {
                type: Boolean,
                default: false
            },
            // 全角フリガナ
            kana: {
                type: Boolean,
                default: false
            },
            kana2: {
                type: Boolean,
                default: false
            },
            maxLength: {
                type: Number,
                default: 1000
            },
            maxLength2: {
                type: Number,
                default: 1000
            },
            length: {
                type: Array,
                default: function () {
                    return [false]
                }
            },
            length2: {
                type: Array,
                default: function () {
                    return [false]
                }
            },
            url: {
                type: Boolean,
                default: false
            },
            url2: {
                type: Boolean,
                default: false
            },
            zipcode: {
                type: Boolean,
                default: false
            },
            zipcode2: {
                type: Boolean,
                default: false
            },
            upunder: {
                type: Boolean,
                default: false
            },
            upunder2: {
                type: Boolean,
                default: false
            },
        },
        mounted () {
            this.makeBirthdayYearList()
        },
        data () {
            return {
                // エラーメッセージbar有無
                hasMsgBar: this.ipType == 'comment' ? false : true,
                // エラーメッセージ格納
                errs: {},
                birthday: {
                    yearList: [],
                    year: this.$moment(new Date).subtract(this.by,'years').format('YYYY'),
                    month: this.bm,
                    day: this.bd,
                },
                inputData: this.setInitInputData(),
                inputData2: this.dft2,
                isdisabled: this.disb,
                isdisabled2: this.disb2,
                custStyle: {}
            }
        },
        components: {
            FormLayout
        },
        validations () {
            return {
                inputData: {
                    required: requiredIf(function () {
                        return this.required
                    }),
                    isNumeric: isNumeric(this.numeric),
                    isMail: isMail(this.mail),
                    isNoMark: isNoMark(this.noMark),
                    isKana: isKana(this.kana),
                    maxLength: maxLength(this.maxLength),
                    length: length(this.length),
                    isUrl: isUrl(this.url),
                    isZipcode: isZipcode(this.zipcode),
                    isUpUnder: isUpUnder(this.upunder),
                },
                inputData2: {
                    required: requiredIf(function () {
                        return this.required2
                    }),
                    isNumeric: isNumeric(this.numeric2),
                    isMail: isMail(this.mail2),
                    isNoMark: isNoMark(this.noMark2),
                    isKana: isKana(this.kana2),
                    maxLength: maxLength(this.maxLength2),
                    length: length(this.length2),
                    isUrl: isUrl(this.url2),
                    isZipcode: isZipcode(this.zipcode2),
                    isUpUnder: isUpUnder(this.upunder2),

                }
            }
        },
        computed: {
            textSize () {
                //let size = this.inputData ? this.inputData.length : "0"
                let size = this.inputData ? this.inputData.replace(/\r?\n/g,'\r').length : "0"
                return  "(" + size + "/" + this.maxLength + ")"
            },
            daysOfBirthday () {
                let date = this.birthday.year + '-' + this.birthday.month
                return this.$moment(date).daysInMonth()
            },
            textareaRow () {
                let newRow = this.countRow(this.inputData)
                return newRow > this.row ? newRow : this.row
            },
        },
        methods: {
            formTouch () {
                this.$v.$touch()
            },
            resetFormTouch () {
                this.$store.dispatch('resetFormTouch')
            },
            doCheck () {
                this.formTouch()
                if (!this.$v.$invalid) {
                    return true
                } else {
                    return false
                }
            },
            dataCheck () {
                if (this.doCheck()) {
                //if (true) {
                    this.clearErrs()
                    return true
                } else {
                    this.showErrs()
                    return false
                }
            },
            showErrs () {
                this.clearErrs()
                let lab1 = this.slab1 == "" ? this.lab : this.slab1
                let lab2 = this.slab2 == "" ? this.lab : this.slab2
                let arr1 = []
                let arr2 = []

                if (!this.$v.inputData2.required) { arr2.push(lab2 + "を入力してください。") }  
                if (!this.$v.inputData2.isNumeric) { arr2.push(lab2 + "は数字を入力してください。") }  
                if (!this.$v.inputData2.isMail) { arr2.push("正しいメールアドレスを入力してください。") }  
                if (!this.$v.inputData2.isNoMark) { arr2.push(lab2 + "は特殊記号以外を入力してください。") }  
                if (!this.$v.inputData2.isKana) { arr2.push(lab2 + "は全角フリガナを入力してください。") }  
                if (!this.$v.inputData2.maxLength) { arr2.push(lab2 + "は" + this.maxLength2 + "文字以内を入力してください。") }  
                if (!this.$v.inputData2.length) { arr2.push(lab2 + "は" + this.length[1] + "桁を入力してください。") }  
                if (!this.$v.inputData2.isUrl) { arr2.push("正しいURLを入力してください。") }  
                if (!this.$v.inputData2.isZipcode) { arr2.push("正しい郵便番号を入力してください。") }  
                if (!this.$v.inputData2.isUpUnder) { arr2.push(lab2 + "は[A~Z]、[0-9]、[.]のみ使えます。") }  

                let err_nm2 = this.errsNm[1]
                if (arr2.length > 0) {this.errs[err_nm2] = arr2}

                if (!this.$v.inputData.required) { arr1.push(lab1 + "を入力してください。") }  
                if (!this.$v.inputData.isNumeric) { arr1.push(lab1 + "は数字を入力してください。") }  
                if (!this.$v.inputData.isMail) { arr1.push("正しいメールアドレスを入力してください。") }  
                if (!this.$v.inputData.isNoMark) { arr1.push(lab1 + "は特殊記号以外を入力してください。") }  
                if (!this.$v.inputData.isKana) { arr1.push(lab1 + "は全角フリガナを入力してください。") }  
                if (!this.$v.inputData.maxLength) { arr1.push(lab1 + "は" + this.maxLength + "文字以内を入力してください。") }  
                if (!this.$v.inputData.length) { arr1.push(lab1 + "は" + this.length[1] + "桁を入力してください。") }  
                if (!this.$v.inputData.isUrl) { arr1.push("正しいURLを入力してください。") }  
                if (!this.$v.inputData.isZipcode) { arr1.push("正しい郵便番号を入力してください。") }  
                if (!this.$v.inputData.isUpUnder) { arr1.push(lab1 + "は[A~Z]、[0-9]、[.]のみ使えます。") }  

                let err_nm1 = this.errsNm[0]
                if (arr1.length > 0) {this.errs[err_nm1] = arr1}
            },
            getData () {
                let form = {}
                if (this.ipType == 'input'
                   || this.ipType == 'textarea'
                   || this.ipType == 'select'
                   || this.ipType == 'gender'
                   || this.ipType == 'zipcode') {
                    let itNm = this.errsNm[0]
                    form[itNm] = this.inputData
                }
                if (this.ipType == 'name'
                   || this.ipType == 'inputmodal') {
                    let itNm = this.errsNm[0]
                    let itNm2 = this.errsNm[1]
                    form[itNm] = this.inputData
                    form[itNm2] = this.inputData2
                }
                if (this.ipType == 'birthday') {
                    let itNm = this.errsNm[0]
                    let itNm2 = this.errsNm[1]
                    let itNm3 = this.errsNm[2]
                    form[itNm] = this.birthday.year
                    form[itNm2] = this.birthday.month
                    form[itNm3] = this.birthday.day
                }
                return form
            },
            makeBirthdayYearList () {
                for (let i=0; i < 100; i++) {
                    this.birthday.yearList.push(this.$moment(new Date).subtract(i,'years').format('YYYY'))
                }
            },
            addZero (data) {
               return ('00' + data).slice(-2)
            },
            setInitInputData () {
                return this.ipType == 'gender' ? '0' : this.dft
            },
            // 親から値設定用
            setInputData (val, val2 = "") {
                this.inputData = val
                this.inputData2 = val2
            },
            // 親からisdisabled設定用
            setDisabled (val, val2 = false) {
                this.isdisabled = val
                this.isdisabled2 = val2
            },
            // 親からstyle設定用
            setStyle(val) {
                this.custStyle = val
            },
            setBirthday (year, month, day) {
                this.birthday.year = year
                this.birthday.month = month
                this.birthday.day = day
            },
            clearErrs () {
                this.errs = {}
            },
            // 親から設定用
            setErrs (errs) {
                this.errs = Object.assign({}, this.errs, errs)
            },
            loadAddress () {
                this.$emit('loadAddress', this.inputData)
            },
            zipcodeAsyn () {
                this.$v.inputData.isZipcode.$touch
            },
            selectChange () {
                let methodNm = this.errsNm[0] + 'Change'
                this.$emit(methodNm, this.inputData)
            },
            birthdayChange () {
                let date = this.birthday.year + '-' + this.birthday.month
                let days = this.$moment(date).daysInMonth()
                if (Number(this.birthday.day) > Number(days)) {
                    this.birthday.day = days
                }
            },
            btnClick () {
                let methodNm = this.errsNm[0] + '_btn'
                this.$emit(methodNm, this.inputData)
            },
            inputBlur () {
                let methodNm = this.errsNm[0] + '_blur'
                this.$emit(methodNm, this.inputData)
            },
            inputModalOpen() {
                let methodNm = this.errsNm[0] + '_modal_open'
                this.$emit(methodNm)
            },
            countRow (txt) {
                let num = null
                if (txt) {
                    num = txt.match(/\r\n|\n/g)
                }
                return num != null ? num.length + 2 : 2
            },
            textRowTrim () {
                let arr_new = []
                let arr = this.inputData.split(/\n/)
                let hasEmptRow = false
                arr.forEach(item => {
                    if (hasEmptRow) {
                        if (item.trim().length != 0) {
                            hasEmptRow = false
                            arr_new.push(item.trim())
                        }
                    } else {
                        hasEmptRow = item.trim().length == 0 ? true : false
                        arr_new.push(item.trim())
                    }
                })
                this.inputData = arr_new.join('\n')
            },
            textBlankDel () {
                let arr_new = []
                let arr = this.inputData.split(/\n/)
                arr.forEach(item => {
                    if (item.trim().length != 0) {
                        arr_new.push(item)
                    }
                })
                this.inputData = arr_new.join('\n')
            }
        }
    }
</script>

<style lang="stylus" scoped>
    .disitem 
        opacity:0.4 !important
</style>
