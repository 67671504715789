<template>
    <div>
        <div class="mb-2 text-muted">
            <h4>
                <span class="badge badge-secondary p-1" :class="{'badge-success':item.proj_remote_ok == '0'}">
                    <small>リモートOK</small>
                </span>
                <span class="badge badge-secondary p-1" :class="{'badge-success':item.proj_foreign_ok == '0'}">
                    <small>外国籍OK</small>
                </span>
                <span class="badge badge-secondary p-1" :class="{'badge-success':item.proj_is_adjust == '0'}">
                    <small>精算あり</small>
                </span>
            </h4>
        </div>
        <div class="pt-1">
            <span>
                {{item.proj_nm}}
            </span>
            <template v-if="hasNew">
                <span v-if="!viewed && showNewMark(item.created_at)" class="badge badge-danger let-space-1">
                    <small>NEW</small>
                </span>
            </template>
        </div>
        <div v-if="hasTime" class="text-right text-muted let-space-1 pt-2">
            <small>
                <small>
                    <i class="fas fa-sync"></i>
                    {{item.updated_at | dformato}}
                </small>
            </small>
        </div>
    </div>
</template>

<script>
    import {editProjStartDate} from 'pages/commons/form/comFunc'

    export default {
        props: {
            item: Object,
            hasNew: {
                type: Boolean,
                default: false
            },
            hasTime: {
                type: Boolean,
                default: true
            }
        },
        mounted () {
            this.checkViewed()
        },
        activated () {
            this.checkViewed()
        },
        data () {
            return {
                viewed: false
            }
        },
        watch: {
            //$route (to, from) {
            //    this.checkViewed()
            //}
        },
        methods: {
            showNewMark (created_at) {
                let create = this.$moment(created_at)
                let now = this.$moment(new Date())
                return now.diff(create, 'days') <= 2
            },
            checkViewed () {
                let viewedProjs = []
                try {
                    let list = localStorage.getItem('viewedProjs')
                    if (list) {
                        viewedProjs = JSON.parse(list)
                        let proj = this.item.id + '-' + this.$moment(this.item.created_at).format('YYYYMMDD')
                        if (viewedProjs.includes(proj)) {
                            this.viewed = true
                        } else {
                            this.viewed = false
                        }
                    }
                } catch (e) {
                    this.viewed = true
                }
            },
        }
    }
</script>

<style lang="stylus" scoped>

</style>
