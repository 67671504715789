<template>
<div id="apply-modal-reply" class="modal fade" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5><i class="fas fa-paper-plane"></i>&nbsp;結果返信</h5>
                <button class="close" type="button" @click="closeThis">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <div class="accordion" id="accordion" role="tablist" aria-multiselectable="true">

                    <!-- NG通知送信 -->
                    <mail-NG :apply-id="applyId"
                             :intv-id="intvId"
                             :post-id="postId">
                    </mail-NG>

                    <!-- OK通知送信 -->
                    <mail-OK :process-id="processId"></mail-OK>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    import MailNG from './MailNG'
    import MailOK from './MailOK'
    import {mapGetters} from 'vuex'

    import {EventBus} from 'js/event-bus'

    export default {
        components: {
            MailNG,
            MailOK
        },
        data () {
            return {
                processId: {
                    id: "",
                    child: "not"
                },
                postId: "",
                intvId: "",
                applyId: "",
                techId: "" ,
                userId: "" ,
            }
        },
        mounted () {
            EventBus.$on('openReplyTech', (data) => {
                this.intvId = data.intvId
                this.processId.id = this.intvId ? 'Intv' : 'Doc'
                this.applyId = data.applyId
                this.techId = data.techId
                this.userId = data.userId
                this.postId = this.getPost.proj_id
                this.openThis()
            })
        },
        computed: {
            ...mapGetters(['getPost'])
        },
        methods: {
            getData () {
                return {
                    postId: this.postId,
                    intvId: this.intvId,
                    applyId: this.applyId,
                    techId: this.techId,
                    userId: this.userId,
                }
            },
            openThis () {
                $('#apply-modal-reply').modal('show')
                $('#apply-modal-reply').find('.collapse').collapse('hide')
            },
            closeThis () {
                $('#apply-modal-reply').modal('hide')
                this.resetThis()
            },
            resetThis () {
                this.$parent.resetReplyTech()
            }
        },
    }
</script>

<style lang="stylus" scoped>
</style>
