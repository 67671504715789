export default {

    getProjDetail (state) {
        return state.projDetail.data
    },
    getProjDetailLoadStatus (state) {
        return state.projDetailLoadStatus
    },
    getProjApplyOperat (state) {
        return state.projApplyOperat
    },
    getSaveProjApplyStatus (state) {
        return state.saveProjApplyStatus
    }
}

