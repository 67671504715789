<template>
    <div class="container">
        <div class="accordion mb-2" id="accordion-items" role="tablist" aria-multiselectable="true">
            <!-- 案件コンポーネント -->
            <item v-for="(proj,index) in list" :key="index" :proj="proj"></item>
        </div>
    </div>
</template>

<script>
    import Item from './Item'
    export default {
        components: {
            Item
        },
        props: {
            list: Array
        }
    }
</script>

<style></style>
