<template>
    <div id="modal-info-msg-title" class="modal fade" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <div>
                        {{tmsg}}
                    </div>
                    <div class="card card-body p-0 border-0 mt-2">
                        <small class="text-muted">
                            {{bmsg}}
                        </small>
                    </div>
                </div>
                <div v-if="btype == 1" class="modal-footer justify-content-center">
                    <button class="btn px-5" type="button" @click="handleOKBtn"
                    :class="{'btn-success':nlev == 0, 'btn-danger':nlev == 1, 'btn-primary':nlev == 2, 'btn-secondary':nlev == 3}"
                    >
                        {{next}}
                    </button>
                </div>
                <div v-else-if="btype == 2" class="modal-footer justify-content-center">
                    <button class="btn px-5" type="button" data-dismiss="modal"
                    :class="{'btn-success':clev == 0, 'btn-danger':clev == 1, 'btn-primary':clev == 2, 'btn-secondary':clev == 3}"
                    >
                        {{close}}
                    </button>
                </div>
                <div v-else class="modal-footer">
                    <div class="container">
                        <div class="row">
                            <div class="col-6">
                                <button class="btn btn-block m-0" type="button" @click="handleOKBtn"
                                :class="{'btn-success':nlev == 0, 'btn-danger':nlev == 1, 'btn-primary':nlev == 2, 'btn-secondary':nlev == 3}"
                                >
                                    {{next}}
                                </button>
                            </div>
                            <div class="col-6">
                                <button class="btn btn-block m-0" type="button" @click="hideThis"
                                :class="{'btn-success':clev == 0, 'btn-danger':clev == 1, 'btn-primary':clev == 2, 'btn-secondary':clev == 3}"
                                >
                                    {{close}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        props: {
            tmsg: String,
            bmsg: {
                type: String,
                default: null
            },
            btype: { // ボタンの組み合わせ
                // 1:next, 2:close, 3:next-close
                type: Number,
                default: 3
            },
            next: {
                type: String,
                default: "次へ"
            },
            nlev: { // 0: success, 1: danger, 2:primary, 3:secondary
                type: Number,
                default: 0
            },
            close: {
                type: String,
                default: "閉じる"
            },
            clev: {
                type: Number,
                default: 2 
            }
        },
        methods: {
            openThis () {
                $('#modal-info-msg-title').modal('show')
            },
            hideThis () {
                $('#modal-info-msg-title').modal('hide')
            },
            handleOKBtn () {
                this.hideThis()
                this.$emit('ok')
            }
        }
    }
</script>

<style lang="stylus">

</style>
