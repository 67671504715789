var moment = require('moment');

export const getList = function(name) {
    // 契約種類
    if (name == 'contract') {
        return [ 
               {val:'0',lab:'個人事業主'},
               {val:'1',lab:'現在所属会社経由(日本国内限定)'},
                //{val:'2', lab:'契約社員'}
        ]
    }

    // 営業状況
    if (name == 'open_flg') {
        return [{val:'0', lab:'営業中'}
               ,{val:'1',lab:'営業終了'}]
    }

    // 所属関係
    if (name == 'relation_cmp') {
        return [ 
               {val:'',lab:'-- 選択 --'},
               {val:'1',lab:'正社員'},
               {val:'2',lab:'契約社員'},
               {val:'3',lab:'個人'},
               {val:'4',lab:'一社先正社員'},
               {val:'5',lab:'一社先契約社員'},
               {val:'6',lab:'一社先個人'},
               {val:'7',lab:'BP正社員'},
               {val:'8',lab:'BP契約社員'},
               {val:'9',lab:'BP個人'},
        ]
    }

    // 実務経験年数
    if (name == 'exper') {
        let arr = []
        let dft = {}
        dft['val'] = ""
        dft['lab'] = "-- 選択 --"
        arr.push(dft)
        for (let i = 1; i < 101; i++) {
            let obj = {}
            obj['val'] = i * 0.5
            obj['lab'] = i * 0.5 + ' 年'
            arr.push(obj)
        }
        return arr
    }

    // 稼働開始日
    if (name == 'work_start_date') {
        let arr = []
        let dft = {}
        dft['val'] = "100099"
        dft['lab'] = "即日～"
        arr.push(dft)
        for (let i = 0; i < 6; i++) {
            let obj = {}
            obj['val'] = moment(new Date).add(i,'months').format('YYYYMM')
            obj['lab'] = moment(new Date).add(i,'months').format('YYYY年MM月')
            arr.push(obj)
        }
        return arr
    }

    // 年齢
    if (name == 'age') {
        let arr = []
        let dft = {}
        dft['val'] = ""
        dft['lab'] = "-- 選択 --"
        arr.push(dft)
        for (let i = 18; i < 81; i++) {
            let obj = {}
            obj['val'] = i
            obj['lab'] = i + ' 歳'
            arr.push(obj)
        }
        return arr
    }

    // 希望単価
    if (name == 'price') {
        let arr = []
        let dft = {}
        dft['val'] = ""
        dft['lab'] = "-- 選択 --"
        arr.push(dft)
        for (let i = 0; i < 201; i++) {
            let obj = {}
            obj['val'] = i
            obj['lab'] = i + ' 万円'
            arr.push(obj)
        }
        return arr
    }

    // 日本語レベル
    if (name == 'jp') {
        return [{val:'0', lab:'ネイティブ'}
               ,{val:'1',lab:'1 級'}
               ,{val:'2',lab:'2 級'}
               ,{val:'3',lab:'3 級'}
               ,{val:'4',lab:'4 級'}
               ,{val:'5',lab:'5 級'}]
    }

    // 英語レベル
    if (name == 'en') {
        return [{val:'', lab:'-- 選択 --'}
               ,{val:'0', lab:'ネイティブ'}
               ,{val:'1',lab:'上級'}
               ,{val:'2',lab:'一般会話'}
               ,{val:'3',lab:'読み書き'}]
    }

    /** 案件情報 **/

    // 求人状況
    if (name == 'proj_open_flg') {
        return [{val:'0', lab:'募集中'}
               ,{val:'1',lab:'募集終了'}]
    }

    // 募集終了予定日
    if (name == 'proj_finish_date') {
        let arr = []
        let dft = {}
        dft['val'] = ""
        dft['lab'] = "-- 選択 --"
        arr.push(dft)
        for (let i = 0; i < 365; i++) {
            let obj = {}
            obj['val'] = moment(new Date).add(i,'days').format('YYYYMMDD')
            obj['lab'] = moment(new Date).add(i,'days').format('YYYY年MM月DD日 (dd)')
            arr.push(obj)
        }
        return arr
    }

    // 開発言語
    if (name == 'proj_lang') {
        return [{val:'', lab:'-- 選択 --'}
               ,{val:'PM',lab:'PM'}
               ,{val:'PMO',lab:'PMO'}
               ,{val:'Tester',lab:'テスター'}
               ,{val:'HelpDesk',lab:'ヘルプデスク'}
               ,{val:'LowSkill',lab:'ロースキル'}
               ,{val:'Java',lab:'Java'}
               ,{val:'PHP',lab:'PHP'}
               ,{val:'Python',lab:'Python'}
               ,{val:'ASP.NET',lab:'ASP.NET'}
               ,{val:'VB.NET',lab:'VB.NET'}
               ,{val:'VBA',lab:'VBA'}
               ,{val:'C',lab:'C'}
               ,{val:'C#',lab:'C#'}
               ,{val:'C++',lab:'C++'}
               ,{val:'Ruby',lab:'Ruby'}
               ,{val:'Go',lab:'Go'}
               ,{val:'Scala',lab:'Scala'}
               ,{val:'HTML5',lab:'HTML5'}
               ,{val:'CSS3',lab:'CSS3'}
               ,{val:'Vue',lab:'Vue'}
               ,{val:'React',lab:'React'}
               ,{val:'Angular',lab:'Angular'}
               ,{val:'JavaScript',lab:'JavaScript'}
               ,{val:'TypeScript',lab:'TypeScript'}
               ,{val:'Flutter',lab:'Flutter'}
               ,{val:'PL/SQL',lab:'PL/SQL'}
               ,{val:'SQL',lab:'SQL'}
               ,{val:'COBOL',lab:'COBOL'}
               ,{val:'SAP',lab:'SAP'}
               ,{val:'AWS',lab:'AWS'}
               ,{val:'Azure',lab:'Azure'}
               ,{val:'GCP',lab:'GCP'}
               ,{val:'Linux',lab:'Linux'}
               ,{val:'VMware',lab:'VMware'}
               ,{val:'Shell',lab:'Shell'}
               ,{val:'iOS',lab:'iOS'}
               ,{val:'Swift',lab:'Swift'}
               ,{val:'Objective-C',lab:'Objective-C'}
               ,{val:'Android',lab:'Android'}
               ,{val:'Kotlin',lab:'Kotlin'}
               ,{val:'Salesforce',lab:'Salesforce'}
               ,{val:'Unity',lab:'Unity'}
               ,{val:'Cisco',lab:'Cisco'}
               ,{val:'DNS',lab:'DNS'}
               ,{val:'NetWork',lab:'NetWork'}
               ,{val:'UiPath',lab:'UiPath'}
               ,{val:'intra-mart',lab:'intra-mart'}
               ,{val:'Excel',lab:'Excel'}
               ,{val:'Word',lab:'Word'}
               ,{val:'PowerPoint',lab:'PowerPoint'}
               ,{val:'ExchangeOnline',lab:'ExchangeOnline'}
               ,{val:'Office365',lab:'Office365'}
               ,{val:'Microsoft365',lab:'Microsoft365'}
            ]
    }

    // ポジション
    if (name == 'proj_position') {
        return [{val:'', lab:'-- 選択 --'}
               ,{val:'PG',lab:'プログラマ'}
               ,{val:'FE',lab:'フロントエンドエンジニア'}
               ,{val:'DE',lab:'データベスエンジニア'}
               ,{val:'IF',lab:'インフラエンジニア'}
               ,{val:'NW',lab:'ネットワークエンジニア'}
               ,{val:'SE',lab:'システムエンジニア'}
               ,{val:'WE',lab:'Webエンジニア'}
               ,{val:'TE',lab:'テストエンジニア'}
               ,{val:'SPE',lab:'サポートエンジニア'}
               ,{val:'BSE',lab:'ブリッジSE'}
               ,{val:'PMO',lab:'プロジェクトマネジメント・オフィス'}
               ,{val:'PM',lab:'プロジェクトマネージャ'}
            ]
    }

    // 開始時期
    if (name == 'proj_start_date') {
        let arr = []
        let dft = {}
        dft['val'] = "100099"
        dft['lab'] = "即日～"
        arr.push(dft)
        for (let i = 0; i < 12; i++) {
            let obj = {}
            obj['val'] = moment(new Date).add(i,'months').format('YYYYMM')
            obj['lab'] = moment(new Date).add(i,'months').format('YYYY年MM月')
            arr.push(obj)
        }
        return arr
    }

    // 面談回数
    if (name == 'proj_intv') {
        return [{val:'',lab:'-- 選択 --'}
               ,{val:'1',lab:'1回'}
               ,{val:'2',lab:'2回'}
               ,{val:'3',lab:'3回'}
               ,{val:'4',lab:'4回'}]
    }

    // 月額単価
    if (name == 'proj_price_set') {
        return [{val:'0',lab:'あり'}
               ,{val:'1',lab:'スキル見合い'}]
    }

    // 月額単価start
    if (name == 'proj_price_start') {
        let arr = []
        for (let i = 0; i < 201; i++) {
            let obj = {}
            obj['val'] = i
            obj['lab'] = i == 0 ? '~' : (i + ' 万円')
            arr.push(obj)
        }
        return arr
    }

    // 月額単価end
    if (name == 'proj_price_end') {
        let arr = []
        for (let i = 0; i < 201; i++) {
            let obj = {}
            obj['val'] = i
            obj['lab'] = i == 0 ? '~' : (i + ' 万円')
            arr.push(obj)
        }
        return arr
    }

    // 精算有無
    if (name == 'proj_is_adjust') {
        return [{val:'0',lab:'あり'}
               ,{val:'1',lab:'固定'}
               ,{val:'2',lab:'あり(精算幅確認中)'}
               ,{val:'3',lab:'不明'}]
    }

    // 精算基準時間start
    if (name == 'proj_time_start') {
        let arr = []
        let dft = {}
        dft['val'] = ""
        dft['lab'] = "-- 選択 --"
        arr.push(dft)
        for (let i = 50; i <= 200; i++) {
            let obj = {}
            obj['val'] = i
            obj['lab'] = i + ' h'
            arr.push(obj)
        }
        return arr
    }

    // 精算基準時間end
    if (name == 'proj_time_end') {
        let arr = []
        let dft = {}
        dft['val'] = ""
        dft['lab'] = "-- 選択 --"
        arr.push(dft)
        for (let i = 100; i <= 250; i++) {
            let obj = {}
            obj['val'] = i
            obj['lab'] = i + ' h'
            arr.push(obj)
        }
        return arr
    }

    // リモートワーク
    if (name == 'proj_remote_ok') {
        return [{val:'',lab:'-- 選択 --'}
               ,{val:'0',lab:'可'}
               ,{val:'1',lab:'不可'}]
    }

    // 募集人数
    if (name == 'proj_members') {
        let arr = []
        let dft = {}
        dft['val'] = ""
        dft['lab'] = "-- 選択 --"
        arr.push(dft)
        for (let i = 1; i <= 50; i++) {
            let obj = {}
            obj['val'] = i
            obj['lab'] = i > 1 ? ('~ ' + i + ' 名') : (i + ' 名')
            arr.push(obj)
        }
        return arr
    }

    // 始業時間
    if (name == 'proj_start_time') {
        let arr = []
        let dft = {}
        dft['val'] = ""
        dft['lab'] = "-- 選択 --"
        arr.push(dft)
        for (let i = 0; i < 25; i++) {
            let obj = {}
            let min = i * 30
            obj['val'] = moment('6:00:00', 'HH:mm').add(min,'minutes').format('HHmm')
            obj['lab'] = moment('6:00:00', 'HH:mm').add(min,'minutes').format('HH:mm')
            arr.push(obj)
        }
        return arr
    }

    // 外国籍
    if (name == 'proj_foreign_ok') {
        return [{val:'',lab:'-- 選択 --'}
               ,{val:'0',lab:'可'}
               ,{val:'1',lab:'不可'}]
    }
}

export const converVal = function (name,value) {
    let list = getList(name)
    if (list) {
        let obj = list.find(item => item.val == value)
        if (obj) {
            return obj.lab
        } else {
            if (name == 'proj_finish_date') {
                return moment(value).format('YYYY年MM月DD日 (dd)')
            }
            if (name == 'proj_start_date') {
                return moment(value + '01').format('YYYY年MM月')
            }
        }
    } 
}
