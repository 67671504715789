<template>
    <div class="card border border-bottom-0">
        <div class="card-header" role="tab" id="headingTwo" data-toggle="collapse" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
            <h5 class="mb-0">
                <a v-if="processId.id == 'Doc'" class="collapsed text-body d-block p-3 m-n3 text-decoration-none" >
                    面接設定
                </a>
                <a v-if="processId.id == 'Intv'" class="collapsed text-body d-block p-3 m-n3 text-decoration-none" >
                    OK通知
                </a>
            </h5>
            <p v-if="processId.id == 'Doc'" class="mb-0"><small class="text-muted">応募者へ面接日程のメールを送信します。</small></p>
            <p v-if="processId.id == 'Intv'" class="mb-0"><small class="text-muted">応募者へ合格通知のメールを送信します。</small></p>
        </div>
        <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordion">

            <!-- 情報確認 -->
            <plan-confirm v-show="stepNum == 1" :items="reqData" :process-id="processId" @toPrev="toPrev" @doSubmit="doSubmit"></plan-confirm>
            <div v-show="stepNum == 0" class="card-body p-0">
                <ul class="list-group list-group-flush">

                    <!-- 次回面接選択 -->
                    <plan-next v-show="processId.id == 'Intv'" @hasNext="handleHasNext"></plan-next>

                    <!-- 予定日付・時刻 -->
                    <plan-time ref="time" :process-id="processId"></plan-time>

                    <!-- 予定場所 -->
                    <plan-place ref="place" :process-id="processId"></plan-place>

                    <!-- 連絡先 -->
                    <plan-contact ref="contact"></plan-contact>

                    <div class="card-body row d-flex justify-content-center">
                        <div class="col-sm-4">
                            <button @click="dataCheck"  class="btn btn-primary btn-block btn-lg" type="button">
                                &nbsp;次へ&nbsp;
                            </button>
                        </div>
                    </div>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import PlanConfirm from './PlanConfirm'
    import PlanNext from './PlanNext'
    import PlanTime from './PlanTime'
    import PlanPlace from './PlanPlace'
    import PlanContact from './PlanContact'

    import {required} from "vuelidate/lib/validators"
    import {mapGetters} from 'vuex'

    export default {
        props: {
            processId: Object
        },
        data () {
            return {
                stepNum: 0,
                loadStatus: 0,
                reqData: {
                    postId: "",
                    applyId: "",
                    intvId: "",
                    techId: "",
                    userId: "",
                    inputDay: "",
                    inputHour: "",
                    inputMinute: "",
                    inputPlace: "",
                    inputName: "",
                    inputPhone: ""
                }
            }
        },
        components: {
            PlanConfirm,
            PlanNext,
            PlanTime,
            PlanPlace,
            PlanContact,
        },
        watch: {
            getAddInterviewStatus () {
                this.loadStatus = this.getAddInterviewStatus
            },
            getSendMailIntvOKStatus () {
                this.loadStatus = this.getSendMailIntvOKStatus
            },
            getSendMailIntvOKWithNextStatus () {
                this.loadStatus = this.getSendMailIntvOKWithNextStatus
            },
            loadStatus () {
                if (this.loadStatus == 2) {
                    // 最新のapplyPostを一覧へ反映
                    this.$store.dispatch('reflectList', this.getNewApplyPost.data)
                    this.$parent.closeThis()
                    this.$store.dispatch('doneSpinner')
                }
                if (this.loadStatus == 3) {
                    this.$store.dispatch('doneSpinner')
                }
            }
        },
        computed: {
            ...mapGetters([
                'getAddInterviewStatus',
                'getSendMailIntvOKStatus',
                'getSendMailIntvOKWithNextStatus',
                'getNewApplyPost'
            ])
        },
        methods: {
            handleHasNext (data) {
                this.processId.child = data == '0' ? 'not' : 'has'
            },
            toNext () {
                this.stepNum++
            },
            toPrev () {
                this.stepNum--
            },
            getChildData () {
                let planTime = this.$refs.time.getData()
                let planPlace = this.$refs.place.getData()
                let planContact = this.$refs.contact.getData()
                let parentData = this.$parent.getData()
                this.reqData = Object.assign(this.reqData, planTime, planPlace, planContact, parentData)
            },
            dataCheck () {
                Promise.all([this.planTimeCheck(), this.planPlaceCheck(), this.planContactCheck()])
                    .then((res) => {
                        this.getChildData()
                        this.toNext()
                    })
            },
            doSubmit () {

                this.$store.dispatch('startSpinner')

                if (this.processId.id == 'Doc') {
                    this.$store.dispatch('addInterview', this.reqData)
                } else {
                    if (this.processId.child == 'not') { 
                        this.$store.dispatch('sendMailIntvOK', this.reqData)
                    } else {
                        this.$store.dispatch('sendMailIntvOKWithNext', this.reqData)
                    }
                }
            },
            planTimeCheck () {
                return new Promise((resolve, reject) => {
                    let valid = this.$refs.time.dataCheck()           
                    if (valid) {
                        resolve()
                    }
                })
            },
            planPlaceCheck () {
                return new Promise((resolve, reject) => {
                    let valid = this.$refs.place.dataCheck()           
                    if (valid) {
                        resolve()
                    }
                })
            },
            planContactCheck () {
                return new Promise((resolve, reject) => {
                    let valid = this.$refs.contact.dataCheck()
                    if (valid) {
                        resolve()
                    }
                })
            }
        }
    }
</script>

<style>

</style>
