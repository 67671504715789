<template>
    <div>
        <form-header v-if="opt == 'create'" :page-name="pageName"></form-header>
        <form-header v-else :page-name="pageName" do-cust @cust="backPage"></form-header>
        <div class="container">
            <div class="card com-card pt-md-4 mb-2 mb-md-3">
                <div class="card-body">

                    <!-- 求人状況 -->
                    <form-input ref="proj_open_flg" ip-type="select" :errs-nm="['proj_open_flg']" :s-opt="projOpenFlgList" val-nm="val"
                                lab-nm="lab" dft="0" lab="求人状況" required numeric :max-length="1"></form-input>

                    <!-- 募集終了予定日 -->
                    <form-input ref="proj_finish_date" ip-type="select" :errs-nm="['proj_finish_date']" :s-opt="projFinishDateList" val-nm="val"
                                lab-nm="lab" lab="募集終了予定日" required numeric :max-length="8"></form-input>

                    <form-input ip-type="comment" py0>
                        <i class="fas fa-info-circle"></i>
                        期限を過ぎてしまうと、管理者によって[募集終了]に設定される場合があります。
                    </form-input>
                    <hr>

                    <!-- 案件名 -->
                    <form-input ref="proj_nm" ip-type="textarea" :errs-nm="['proj_nm']" lab="案件名" :row="2" plhd="例：〇〇〇システム設計構築" 
                                required :max-length="150" has-len :has-trim="false" :no-blank="false"></form-input>

                    <!-- 開発言語 -->
                    <form-input ref="proj_language" ip-type="select" :errs-nm="['proj_language']" :s-opt="projLangList" val-nm="val"
                                lab-nm="lab" lab="開発言語"></form-input>

                    <form-input ip-type="comment" py0>
                        <i class="fas fa-info-circle"></i>
                        [開発言語]に存在しない場合、備考欄に記載してください。
                    </form-input>

                    <!-- ポジション -->
                    <form-input ref="proj_position" ip-type="select" :errs-nm="['proj_position']" :s-opt="projPositionList" val-nm="val"
                                lab-nm="lab" lab="ポジション" has-btn @proj_position_btn="addBtnNote('proj_position')" :act-btn="actBtn"></form-input>

                    <!-- 開始時期 -->
                    <form-input ref="proj_start_date" ip-type="select" :errs-nm="['proj_start_date']" :s-opt="projStartDateList" val-nm="val"
                                lab-nm="lab" dft="100099" lab="開始時期" required numeric has-btn @proj_start_date_btn="addBtnNote('proj_start_date')" :act-btn="actBtn"></form-input>
                            
                    <!-- 面談回数 -->
                    <form-input ref="proj_interview" ip-type="select" :errs-nm="['proj_interview']" :s-opt="projIntvList" val-nm="val"
                                lab-nm="lab" lab="面談回数" numeric has-btn @proj_interview_btn="addBtnNote('proj_interview')" :act-btn="actBtn"></form-input>

                    <!-- 月額単価 -->
                    <form-input ref="proj_price_set" ip-type="select" :errs-nm="['proj_price_set']" :s-opt="projPriceSetList" val-nm="val"
                                lab-nm="lab" dft="0" lab="月額単価" required numeric 
                                @proj_price_setChange="projPriceSetChange" has-btn @proj_price_set_btn="addBtnNote('proj_price_set')" :act-btn="actBtn"></form-input>

                    <!-- 月額単価(start) -->
                    <form-input ref="proj_price_start" ip-type="select" :errs-nm="['proj_price_start']" :s-opt="projPriceStartList" val-nm="val"
                                lab-nm="lab" dft="100" lab="月額単価(start)" numeric @proj_price_startChange="projPriceStartChange"></form-input>

                    <!-- 月額単価(end) -->
                    <form-input ref="proj_price_end" ip-type="select" :errs-nm="['proj_price_end']" :s-opt="projPriceEndList" val-nm="val"
                                lab-nm="lab" dft="100" lab="月額単価(end)" numeric @proj_price_endChange="projPriceEndChange"></form-input>

                    <form-input ip-type="comment" py0>
                        <i class="fas fa-info-circle"></i>
                        消費税を含めない弊社(Promeraia)にお渡しする金額です。
                    </form-input>

                    <!-- 精算有無 -->
                    <form-input ref="proj_is_adjust" ip-type="select" :errs-nm="['proj_is_adjust']" :s-opt="projIsAdjustList" val-nm="val"
                                lab-nm="lab" dft="0" lab="精算有無" required numeric :max-length="1" @proj_is_adjustChange="projIsAdjustChange" 
                                has-btn @proj_is_adjust_btn="addBtnNote('proj_is_adjust')" :act-btn="actBtn"></form-input>

                    <!-- 精算時間(start) -->
                    <form-input ref="proj_time_start" ip-type="select" :errs-nm="['proj_time_start']" :s-opt="projTimeStartList" val-nm="val"
                                lab-nm="lab" dft="140" lab="精算基準時間(start)" numeric @proj_time_startChange="projTimeStartChange"></form-input>

                    <!-- 精算時間(end) -->
                    <form-input ref="proj_time_end" ip-type="select" :errs-nm="['proj_time_end']" :s-opt="projTimeEndList" val-nm="val"
                                lab-nm="lab" dft="180" lab="精算基準時間(end)" numeric @proj_time_endChange="projTimeEndChange"></form-input>

                    <!-- リモートワーク -->
                    <form-input ref="proj_remote_ok" ip-type="select" :errs-nm="['proj_remote_ok']" :s-opt="projRemoteStatusList" val-nm="val"
                                lab-nm="lab" lab="リモートワーク" has-btn @proj_remote_ok_btn="addBtnNote('proj_remote_ok')" :act-btn="actBtn"></form-input>

                    <!-- 最寄り駅 -->
                    <form-input ref="proj_place" :errs-nm="['proj_place']" lab="最寄り駅" plhd="例：品川駅" :max-length="20" 
                                has-btn has-len @proj_place_btn="addBtnNote('proj_place')" :act-btn="actBtn"></form-input>

                    <!-- 募集人数 -->
                    <form-input ref="proj_members" ip-type="select" :errs-nm="['proj_members']" :s-opt="projMemberList" val-nm="val"
                                lab-nm="lab" lab="募集人数" numeric has-btn @proj_members_btn="addBtnNote('proj_members')" :act-btn="actBtn"></form-input>

                    <!-- 最低経験年数 -->
                    <form-input ref="proj_min_exper" ip-type="select" :errs-nm="['proj_min_exper']" :s-opt="projMinExperList" val-nm="val"
                                lab-nm="lab" lab="経験年数" has-btn @proj_min_exper_btn="addBtnNote('proj_min_exper')" :act-btn="actBtn"></form-input>

                    <!-- 始業時間 -->
                    <form-input ref="proj_start_time" ip-type="select" :errs-nm="['proj_start_time']" :s-opt="projStartTimeList" val-nm="val"
                                lab-nm="lab" lab="始業時間" numeric has-btn @proj_start_time_btn="addBtnNote('proj_start_time')" :act-btn="actBtn"></form-input>

                    <!-- 外国籍 -->
                    <form-input ref="proj_foreign_ok" ip-type="select" :errs-nm="['proj_foreign_ok']" :s-opt="projForeignStatusList" val-nm="val"
                                lab-nm="lab" lab="外国籍" has-btn @proj_foreign_ok_btn="addBtnNote('proj_foreign_ok')" :act-btn="actBtn"></form-input>

                    <!-- 案件概要 -->
                    <form-input ref="proj_detail" ip-type="textarea" :errs-nm="['proj_detail']" lab="案件概要" :row="8" 
                                plhd="例：〇〇〇システム設計・製造・テスト" required :max-length="500" has-len></form-input>

                    <!-- 必須スキル -->
                    <form-input ref="proj_skill_need" ip-type="textarea" :errs-nm="['proj_skill_need']" lab="必須スキル" :row="5" 
                                plhd="例：〇〇〇等の構築経験" required :max-length="500" has-len></form-input>

                    <!-- 歓迎スキル -->
                    <form-input ref="proj_skill_option" ip-type="textarea" :errs-nm="['proj_skill_option']" lab="歓迎スキル" :row="5" 
                                plhd="例：〇〇〇の経験" :max-length="500" has-len></form-input>

                    <!-- 備考 -->
                    <form-input ref="proj_note" ip-type="textarea" :errs-nm="['proj_note']" lab="備考" :row="5" 
                                plhd="必要に応じて記入してくさだい。" :max-length="500" has-len></form-input>

                    <!-- 備考ボタン画面 -->
                    <btn-note ref="btn_note"></btn-note>

                    <div class="card mb-2 mt-5 border-0">
                        <div class="card-body">
                            <div class="row form-group justify-content-center mb-0">
                                <div class="col-sm-4">
                                    <button class="btn btn-success btn-block btn-lg" @click="dataCheck">
                                        <i class="fas fa-eye"></i>&nbsp;確認画面へ
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import FormInput from 'pages/commons/form/Input'
    import FormHeader from 'pages/commons/header/Header'
    import BtnNote from './Note'

    import {mapGetters} from 'vuex'

    export default {
        props: {
            opt: String
        },
        components: {
            FormInput,
            FormHeader,
            BtnNote
        },
        mounted() {
            this.disabledOpenFlg()
        },
        data () {
            return {
                projOpenFlgList: this.makeOpenFlgList(),
                projFinishDateList: this.makeFinishDateList(),
                projLangList: this.makeProjLangList(),
                projPositionList: this.makePositionList(),
                projStartDateList: this.makeProjStartDateList(),
                projIntvList: this.makeProjIntvList(),
                projPriceSetList: this.makePriceSetList(),
                projPriceStartInit: "100",
                projPriceStartList: this.makePriceStartList(),
                projPriceEndInit: "100",
                projPriceEndList: this.makePriceEndList(),
                projIsAdjustList: this.makeIsAdjustList(),
                projTimeStartInit: "140",
                projTimeStartList: this.makeTimeStartList(),
                projTimeEndInit: "180",
                projTimeEndList: this.makeTimeEndList(),
                projRemoteStatusList: this.makeRemoteStatusList(),
                projMemberList: this.makeMemberList(),
                projMinExperList: this.makeMinExperList(),
                projStartTimeList: this.makeStartTimeList(),
                projForeignStatusList: this.makeForeignStatusList(),
                reqForm: {},
                resultArr: [],
                formArr:[
                         'proj_open_flg'
                        ,'proj_finish_date'
                        ,'proj_nm'
                        ,'proj_language'
                        ,'proj_position'
                        ,'proj_start_date'
                        ,'proj_interview'
                        ,'proj_price_set'
                        ,'proj_price_start'
                        ,'proj_price_end'
                        ,'proj_is_adjust'
                        ,'proj_time_start'
                        ,'proj_time_end'
                        ,'proj_remote_ok'
                        ,'proj_place'
                        ,'proj_members'
                        ,'proj_min_exper'
                        ,'proj_start_time'
                        ,'proj_foreign_ok'
                        ,'proj_detail'
                        ,'proj_skill_need'
                        ,'proj_skill_option'
                        ,'proj_note'
                    ],
                actBtn: ""
            }
        },
        watch: {
            getCmpProjInfo (info) {
                if (info.data.proj) {
                    this.setDefaultVal(info.data.proj)
                }
            }
        },
        computed: {
            pageName () {
                return this.opt == 'create' ? "案件情報入力" : "案件情報編集"
            },
            ...mapGetters(['getCmpProjInfo'])
        },
        methods: {
            disabledOpenFlg () {
                if (this.opt == 'create') {
                    this.$refs.proj_open_flg.setDisabled(true)
                }
            },
            backPage () {
                this.$parent.changeOperat('show')
            },
            setDefaultVal (info) {
                // 編集ボダン押下
                this.$refs.proj_open_flg.setInputData(info.proj_open_flg)
                if (!this.$parent.isOverFinish(info.proj_finish_date)) {
                    this.$refs.proj_finish_date.setInputData(info.proj_finish_date)
                }
                this.$refs.proj_nm.setInputData(info.proj_nm)
                if (info.proj_language) {
                    this.$refs.proj_language.setInputData(info.proj_language)
                }
                if (info.proj_position) {
                    this.$refs.proj_position.setInputData(info.proj_position)
                }
                if (!this.$parent.isOverStart(info.proj_start_date)) {
                    this.$refs.proj_start_date.setInputData(info.proj_start_date)
                }
                if (info.proj_interview) {
                    this.$refs.proj_interview.setInputData(info.proj_interview)
                }
                this.$refs.proj_price_set.setInputData(info.proj_price_set)
                if (info.proj_price_set == '1') {
                    this.$refs.proj_price_start.setDisabled(true)
                    this.$refs.proj_price_end.setDisabled(true)
                }
                this.$refs.proj_price_start.setInputData(info.proj_price_start)
                this.$refs.proj_price_end.setInputData(info.proj_price_end)
                this.$refs.proj_is_adjust.setInputData(info.proj_is_adjust)
                if (info.proj_is_adjust != '0') {
                    this.$refs.proj_time_start.setInputData('')
                    this.$refs.proj_time_end.setInputData('')
                    this.$refs.proj_time_start.setDisabled(true)
                    this.$refs.proj_time_end.setDisabled(true)
                } else {
                    this.$refs.proj_time_start.setInputData(info.proj_time_start)
                    this.$refs.proj_time_end.setInputData(info.proj_time_end)
                }
                if (info.proj_remote_ok) {
                    this.$refs.proj_remote_ok.setInputData(info.proj_remote_ok)
                }
                if (info.proj_place) {
                    this.$refs.proj_place.setInputData(info.proj_place)
                }
                if (info.proj_members) {
                    this.$refs.proj_members.setInputData(info.proj_members)
                }
                if (info.proj_min_exper) {
                    this.$refs.proj_min_exper.setInputData(info.proj_min_exper)
                }
                if (info.proj_start_time) {
                    this.$refs.proj_start_time.setInputData(info.proj_start_time)
                }
                if (info.proj_foreign_ok) {
                    this.$refs.proj_foreign_ok.setInputData(info.proj_foreign_ok)
                }
                this.$refs.proj_detail.setInputData(info.proj_detail)
                this.$refs.proj_skill_need.setInputData(info.proj_skill_need)
                this.$refs.proj_skill_option.setInputData(info.proj_skill_option)
                this.$refs.proj_note.setInputData(info.proj_note)
            },
            getChildData () {
                this.formArr.forEach(item => {
                    var formData = this.$refs[item].getData()
                    this.reqForm = Object.assign({},this.reqForm, formData)
                })
            },
            getData() {
                return this.reqForm
            },
            dataCheck () {

                  /** test 用start **/
                    //this.getChildData()
                    //this.$parent.getChildData()
                    //this.$parent.changeOperat('auto')
                  /** test 用end **/

                this.checkForm()
                this.doDataCheck ()
            },
            doDataCheck () {
                Promise.all(this.resultArr)
                    .then((res) => {
                        this.getChildData()
                        this.$parent.getChildData()
                        this.$parent.changeOperat('auto')
                    })
            },
            checkForm () {
                this.resultArr = []
                this.formArr.forEach(formName => {
                    this.doCheckForm(formName)
                })
            },
            doCheckForm (formName) {
                var result = new Promise((resolve, reject) => {
                    let valid = this.$refs[formName].dataCheck()           
                    if (valid) { 
                        resolve() }
                })
                this.resultArr.push(result)
            },
            // 単価有無Change
            projPriceSetChange (val) {
                let start = this.$refs.proj_price_start.getData()['proj_price_start']
                let end = this.$refs.proj_price_end.getData()['proj_price_end']
                this.projPriceStartInit = start == '0' ? this.projPriceStartInit : start
                this.projPriceEndInit = end == '0' ? this.projPriceEndInit : end
                if (val == '0') {
                    this.$refs.proj_price_start.setInputData(this.projPriceStartInit)
                    this.$refs.proj_price_end.setInputData(this.projPriceEndInit)
                    this.$refs.proj_price_start.setDisabled(false)
                    this.$refs.proj_price_end.setDisabled(false)
                }
                if (val == '1') {
                    this.$refs.proj_price_start.setInputData('0')
                    this.$refs.proj_price_end.setInputData('0')
                    this.$refs.proj_price_start.setDisabled(true)
                    this.$refs.proj_price_end.setDisabled(true)
                }
            },

            // 精算有無Change
            projIsAdjustChange (val) {
                let start = this.$refs.proj_time_start.getData()['proj_time_start']
                let end = this.$refs.proj_time_end.getData()['proj_time_end']
                this.projTimeStartInit = start == '' ? this.projTimeStartInit : start
                this.projTimeEndInit = end == '' ? this.projTimeEndInit : end
                if (val == '0') {
                    this.$refs.proj_time_start.setInputData(this.projTimeStartInit)
                    this.$refs.proj_time_end.setInputData(this.projTimeEndInit)
                    this.$refs.proj_time_start.setDisabled(false)
                    this.$refs.proj_time_end.setDisabled(false)
                } else {
                    this.$refs.proj_time_start.setInputData('')
                    this.$refs.proj_time_end.setInputData('')
                    this.$refs.proj_time_start.setDisabled(true)
                    this.$refs.proj_time_end.setDisabled(true)
                }
            },
            // 精算時間相関チェック
            projTimeStartChange (val) {
                if (this.isBlankOK(val)) {
                    let obj_end = this.$refs.proj_time_end.getData()
                    let end = obj_end['proj_time_end']
                    if (this.isBiggerOk(val, end)) {
                        this.projTimeStartInit = val
                    } else {
                        this.$parent.openMsg('精算基準時間(start)には精算基準時間(end)より小さい時間を指定してください。', 1)
                        this.$refs.proj_time_start.setInputData(this.projTimeStartInit)
                    }
                } else {
                    this.$parent.openMsg('精算ありの場合、精算基準時間(start)の入力は必須です。', 1)
                    this.$refs.proj_time_start.setInputData(this.projTimeStartInit)
                }
            },
            projTimeEndChange (val) {
                if (this.isBlankOK(val)) {
                    let obj_start = this.$refs.proj_time_start.getData()
                    let start = obj_start['proj_time_start']
                    if (this.isBiggerOk(start, val)) {
                        this.projTimeEndInit = val
                    } else {
                        this.$parent.openMsg('精算基準時間(end)には精算基準時間(start)より大きい時間を指定してください。', 1)
                        this.$refs.proj_time_end.setInputData(this.projTimeEndInit)
                    }
                } else {
                    this.$parent.openMsg('精算ありの場合、精算基準時間(end)の入力は必須です。', 1)
                    this.$refs.proj_time_end.setInputData(this.projTimeEndInit)
                }
            },
            projPriceStartChange (val) {
                let obj_end = this.$refs.proj_price_end.getData()
                let end = obj_end['proj_price_end']
                if (val > end) {
                    this.$parent.openMsg('月額単価(start)には月額単価(end)より小さい金額を指定してください。', 1)
                    this.$refs.proj_price_start.setInputData(this.projPriceStartInit)
                } else {
                    this.projPriceStartInit = val
                }
            },
            projPriceEndChange (val) {
                let obj_start = this.$refs.proj_price_start.getData()
                let start = obj_start['proj_price_start']
                if (val < start) {
                    this.$parent.openMsg('月額単価(end)には月額単価(start)より大きい金額を指定してください。', 1)
                    this.$refs.proj_price_end.setInputData(this.projPriceEndInit)
                } else {
                    if (val == '0') {
                        this.$parent.openMsg('単価ありの場合、月額単価(end)の入力は必須です。', 1)
                        this.$refs.proj_price_end.setInputData(this.projPriceEndInit)
                    } else {
                        this.projPriceEndInit = val
                    }
                }
            },
            isBlankOK (val) {
                let obj_adj = this.$refs.proj_is_adjust.getData()
                let adj = obj_adj['proj_is_adjust']
                if (adj == '0') {
                    return val == "" ? false : true
                }
                return true
            },
            isBiggerOk (start, end) {
                return start < end ? true : false
            },
            addBtnNote (actBtn) {
                this.actBtn = actBtn
                let note = this.$refs.proj_note.getData()['proj_note']
                this.$refs.btn_note.openThis(note)
            },
            syncBtnNote (note) {
                this.$refs.proj_note.setInputData(note)
            },
            makeOpenFlgList () {
                return this.$parent.getList('proj_open_flg')
            },
            makeFinishDateList () {
                return this.$parent.getList('proj_finish_date')
            },
            makeProjLangList () {
                return this.$parent.getList('proj_lang')
            },
            makePositionList () {
                return this.$parent.getList('proj_position')
            },
            makeProjStartDateList () {
                return this.$parent.getList('proj_start_date')
            },
            makeProjIntvList () {
                return this.$parent.getList('proj_intv')
            },
            makePriceSetList () {
                return this.$parent.getList('proj_price_set')
            },
            makePriceStartList () {
                return this.$parent.getList('proj_price_start')
            },
            makePriceEndList () {
                return this.$parent.getList('proj_price_end')
            },
            makeIsAdjustList () {
                return this.$parent.getList('proj_is_adjust')
            },
            makeTimeStartList () {
                return this.$parent.getList('proj_time_start')
            },
            makeTimeEndList () {
                return this.$parent.getList('proj_time_end')
            },
            makeRemoteStatusList () {
                return this.$parent.getList('proj_remote_ok')
            },
            makeMemberList () {
                return this.$parent.getList('proj_members')
            },
            makeMinExperList () {
                return this.$parent.getList('exper')
            },
            makeStartTimeList () {
                return this.$parent.getList('proj_start_time')
            },
            makeForeignStatusList () {
                return this.$parent.getList('proj_foreign_ok')
            },
        }
    }
</script>

<style>

</style>
