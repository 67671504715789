<template>
    <div class="container pb-3">
        <div class="card com-card pt-md-3">

            <!-- プロジェクト詳細 -->
            <proj-body :item="proj.project" class="px-lg-5"></proj-body>

            <div class="card-footer text-right text-muted justify-content-between d-flex py-md-3 py-lg-4">
                <span class="px-2 item-hover" @click="openApply">
                    <i class="fas fa-paper-plane icon-size"></i>
                    <small>応募する</small>
                </span>
                <span v-if="showFavorite" class="px-2 item-hover" @click="addFavorite">
                    <i class="fas fa-heart icon-size" :class="{'text-danger':proj.is_favorite == true}"></i>
                    <small> お気に入り </small>
                </span>
                <span class="px-2 item-hover" @click="openShare">
                    <i class="fas fa-share-alt icon-size"></i>
                    <small> 共有 </small>
                </span>
            </div>

            <!-- 案件シェア -->
            <proj-share ref="share"></proj-share>

        </div>
    </div>
</template>

<script>
    import ProjBody from 'pages/commons/projBody/ProjBody'
    import ProjShare from 'pages/commons/projShare/ProjShare'
    import {mapGetters} from 'vuex'

    export default {
        props: {
            proj: Object
        },
        computed: {
            showFavorite () {
                return this.$route.params.proc == 'favorite' ? false : true
            },
            ...mapGetters(['getUser']),
        },
        components: {
            ProjBody,
            ProjShare
        },
        methods: {
            openShare () {
                this.$refs.share.openThis(this.proj.project)
            },
            openApply () {
                // 営業担当の場合
                if (this.getUser.cmpGroup) {
                   this.$parent.techList() 
                } else {
                   this.$parent.projApply() 
                }
            },
            addFavorite () {
                this.$parent.addFavorite()
            }
        }

    }
</script>

<style></style>
