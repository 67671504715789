export default {
    /**
     * Get 各種リスト情報取得
     */
    loadListObj (obj) {
        let url = obj.url
        let data = obj.data
        if (obj.data) {
            return axios.get(route(url, data))
        } else {
            return axios.get(route(url))
        }
    },

    /**
     * Get 各種リスト情報取得(see more)
     */
    loadListObjSeeMore (data) {
        if (typeof(data) == 'string') {
            return axios.get(data)
        }
        // 手動モード
        let url = data.url
        let skip = data.skip
        return axios.get(route(url, skip))
    },

    /**
     * Post リストから削除
     */
    listItemDelete (data) {
        let url = data.url
        let obj = new Object()
        obj.key = data.key
        return axios.post(route(url), obj)
    },

    /**
     * Get ユーザー情報取得
     */
    getUser () {
        return axios.get(route('user.get'))
    },

    /**
     * Get 郵便住所
     */
    loadAddress (data) {
        return axios.post(route('com.address'), data) 
    },

    /**
     * Get 案件情報シェア詳細取得
     */
    loadShareInfoDetail (data) {
        return axios.get(route('post.share.detail', data)) 
    },

    /**
     * unique チェック
     */
    uniqueCheck (data) {
        return axios.post(route('com.unique', data)) 
    },

    /**
     * 応募詳細メッセージ
     */
    addApplyHisMsg (data) {
        return axios.post(route('com.apply.his.msg.add'), data) 
    },

    /**
     * Post 案件情報登録/更新
     */
    saveComProjInfo (data) {
        return axios.post(route('com.proj.save'), data)
    },

    /**
     * Get 案件情報詳細取得
     */
    loadComProjInfo (data) {
        return axios.get(route('com.proj.detail', data.id))
    },
    
    /**
     * Post [案件・要員共通]終了/削除
     */
    comMenuQuickOff (data) {
        return axios.post(route('com.menu.quick.off'), data)
    },

    /**
     * Post 履歴書情報登録/更新
     */
    saveComTechInfo (data) {
        return axios.post(route('com.tech.save'), data)
    },

    /**
     * Get 履歴書情報取得
     */
    loadComTechInfo (data) {
        return axios.get(route('com.tech.detail', data.id))
    },

    /**
     * 営業中要員・案件リスト取得
     */
    loadActSalesInfo (data) {
        return axios.post(route('com.act.sales.info'),data)
    },

    /**
     * 個人・法人提案共通
     */
    comSuggestion (data) {
        return axios.post(route('com.suggestion'), data)
    },

}
