<template>
    <point-detail-list 
        ref="list"
        header-text="ポイント詳細"
        >
        <div v-if="getListObj">
            <div class="card com-card" v-if="getListObj.data.length > 0">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item pb-1 let-space-1 p-md-4" v-for="item in getListObj.data" :key="item.id">
                        <div class="d-flex align-items-center">
                            <span><i class="fas fa-donate text-primary icon-size"></i></span>
                            <span v-if="item.relation == '1'" class="badge badge-pill badge-success py-1 mx-3 text-truncate">FROM {{item.name}}</span>
                            <span v-if="item.relation == '2'" class="badge badge-pill badge-success py-1 mx-3 text-truncate">FROM {{item.invite_code_from}}</span>
                            <span class="flex-fill text-right">
                                <b>{{toLocaleString(item.points)}}</b>P
                            </span>
                        </div>
                        <div class="text-right text-muted">
                            <small><small>{{item.created_at | dformatm}}</small></small>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </point-detail-list>
</template>

<script>
    import PointDetailList from 'pages/commons/List/List'
    import {mapGetters} from 'vuex'

    export default {
        components: {
            PointDetailList
        },
        mounted () {
            this.$refs.list.loadListObj({
                url: 'invite.point.detail'
            })
        },
        computed: {
            ...mapGetters(['getListObj'])
        },
        methods: {
            toLocaleString (num) {
                return Number(num).toLocaleString()
            },
        }
    }
</script>
