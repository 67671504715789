<template>
    <div v-if="projInfo" class="container pb-2 pt-4 px-1 px-lg-2">
        <!-- <div v-if="projInfo.exist" class="row page-title-row"> -->
        <!--     <div class="col-md"> -->
        <!--         <nav> -->
        <!--             <ol class="breadcrumb mb-2"> -->
        <!--                 <li class="breadcrumb-item active"><small>案件の最新状況は[求人状況]欄をご確認下さい。</small></li> -->
        <!--             </ol> -->
        <!--         </nav> -->
        <!--     </div> -->
        <!-- </div> -->
        <div class="row mb-3">
            <div class="col-sm-12">
                <div class="card border-0 content-shadow">
                    <div v-if="projInfo.exist" class="card-header py-4">
                        <h5 class="card-title mb-0">案件詳細</h5>
                    </div>
                    <div class="card-body p-0">

                        <!-- プロジェクト詳細 -->
                        <div v-if="projInfo.exist" class="pl-lg-5">
                            <proj-body :item="projInfo"></proj-body>
                        </div>

                        <div v-else class="card-body">
                            <!-- <hr> -->
                            <div class="form-group row">
                                <div class="col-md-12">
                                    <div class="p-4 mb-1 text-center over-font-big d-lg-none">
                                        <b> 募集は終了しました </b>
                                    </div>
                                    <div class="p-4 mb-1 text-center over-font-lg d-none d-lg-block">
                                        <b> 募集は終了しました </b>
                                    </div>
                                    <p class="text-center over-font-small text-muted let-space-1">
                                        <span>たくさんのご応募</span><br>
                                        <span>ありがとうございました</span>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div v-if="projInfo.proj_has_see_more" class="container mb-4">
                            <div class="row justify-content-center">
                                <div class="col col-md-5">
                                    <div class="card-body p-0">
                                        <a :href="projInfo.proj_see_more_url" class="card card-body text-center rounded-pill p-2 mb-2 text-decoration-none btn-see-more">もっと見る</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="projInfo.proj_has_contact">
                            <div v-if="projInfo.proj_has_contact == 'c1'" class="card-body let-space-1">
                                <div class="card-body p-0">
                                    <div class="text-center mb-4">お問い合わせ</div>
                                    <div class="row text-md-center d-flex justify-content-center">
                                        <div class="col-lg-5 col-md-7">
                                            <small>
                                                当社では長期的な展望をもって信頼関係を構築し、共に発展・成長を目指せるパートナー様(個人・企業)を募集しております。 
                                            </small>
                                        </div>
                                    </div>
                                    <div class="card-body text-center text-primary mt-3">
                                        <b>
                                            partner@promeraia.com
                                        </b>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="projInfo.proj_has_contact == 'c2'" class="card-body pt-0">
                                <button class="btn btn-primary btn-lg btn-block">問い合わせ</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div v-else class="card"> -->
                <!--     <div class="card-body"> -->
                <!--         <div class="text-center text-danger-cust"> -->
                <!--             <b> -->
                <!--                 応募終了 -->
                <!--             </b> -->
                <!--         </div> -->
                <!--         <hr> -->
                <!--         <div class="form-group row"> -->
                <!--             <div class="col-md-12"> -->
                <!--                 <div class="card-body p-4 mb-1 text-muted text-center"> -->
                <!--                     <small> -->
                <!--                         この案件は、現在閲覧できません。 -->
                <!--                     </small> -->
                <!--                 </div> -->
                <!--             </div> -->
                <!--         </div> -->
                <!--     </div> -->
                <!-- </div> -->
            </div>
        </div>
    </div>
</template>

<script>
    import ProjBody from 'pages/commons/projBody/ProjBody'
    import {mapGetters} from 'vuex'

    export default {
        components: {
            ProjBody
        },
        mounted () {
            this.loadShareProjDetail()
        },
        watch : {
            getShareInfoDetailLoadStatus (status) {
                if (status == 1) {
                    this.$store.dispatch('startProgress')
                }
                if (status == 2) {
                    this.$store.dispatch('doneProgress')
                }
                if (status == 3) {
                    this.$store.dispatch('doneProgress')
                }
            },
        },
        computed: {
            projInfo () {
                return this.getShareInfoDetail.data
            },
            ...mapGetters(['getShareInfoDetail'
                          ,'getShareInfoDetailLoadStatus'
            ])
        },
        methods: {
            loadShareProjDetail () {
                this.$store.dispatch('loadShareInfoDetail', {
                    page:'proj',
                    id: this.$route.params.code
                })
            }
        }
        
    }
</script>

<style lang="stylus" scoped>
.content-shadow
    box-shadow: 3px 3px 10px #192c7c33
    border-radius: 5px
    opacity: 1
.btn-see-more{
    color:black;
}
.btn-see-more:hover{
    background-color:#00a5f5!important;
    color:white!important;
    border-color:white!important;
    transition:0.5s;
}
.over-font-lg
    font-size:30px;
    color:black;
.over-font-big
    font-size:5vw;
    color:black;


</style>
