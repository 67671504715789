export default {
    /**
     * Get 初期情報取得
     */
    initHome () {
        return axios.get(route('my.home'))
    },

    /**
     * Post 個人情報登録/更新
     */
    savePrivateInfo (data) {
        return axios.post(route('my.private.save'), data)
    },

    /**
     * Get 個人情報取得
     */
    loadPrivateInfo () {
        return axios.get(route('my.private.show'))
    },

    /**
     * Post 履歴情報登録/更新
     */
    saveResumeInfo (data) {
        return axios.post(route('my.resume.save'), data)
    },

    /**
     * Get 履歴情報取得
     */
    loadResumeInfo () {
        return axios.get(route('my.resume.show'))
    },

    /**
     * Get 履歴情報取得
     */
    loadApplyHisDetail (data) {
        return axios.get(route('my.apply.his.detail',data.id))
    },

    /**
     * Get 本人確認情報
     */
    loadIdentityInfo () {
        return axios.get(route('my.identity.show'))
    },

    /**
     * Post 本人確認メール送信
     */
    sendIdentityMail () {
        return axios.post(route('my.identity.mail'))
    },

    /**
     * Post 口座情報登録/更新
     */
    saveBankInfo (data) {
        return axios.post(route('my.bank.save'), data)
    },

    /**
     * Get 口座情報取得
     */
    loadBankInfo () {
        return axios.get(route('my.bank.show'))
    },

    /**
     * Post 面接・入場予定承諾
     */
    agreeIntvPlan (data) {
        return axios.post(route('my.apply.his.agree'), data)
    },

    /**
     * Get お気に入りメニューから削除
     */
    deleteFavorite (data) {
        return axios.post(route('my.favorites.del'), data)
    }
}
