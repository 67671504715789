import ProjsAPI from 'js/api/projs'

export default {
    loadProjDetail ({commit}, data) {
        commit('setProjDetailLoadStatus', 1)

        ProjsAPI.loadProjDetail(data.id)
            .then(resp => {
                commit('setProjDetail', resp)
                commit('setProjDetailLoadStatus', 2)
            })
            .catch(err => {
                commit('setProjDetail', {})
                commit('setProjDetailLoadStatus', 3)
            })
    },

    resetProjDetail ({commit}) {
        commit('resetProjDetail')
    },

    //saveProjApply ({commit, dispatch, state}, data) {
    //    commit('resetProjApplyOperat')
    //    commit('setSaveProjApplyStatus', 1)

    //    ProjsAPI.saveProjApply(data)
    //        .then(resp => {
    //            if (resp.data.rest) {
    //                commit('setProjApplyOperat', 'success')
    //            } else {
    //                if (resp.data.is_deleted) {
    //                    commit('setProjApplyOperat', 'deleted')
    //                }
    //                if (resp.data.is_closed) {
    //                    commit('setProjApplyOperat', 'closed')
    //                }
    //            }
    //            commit('setSaveProjApplyStatus', 2)
    //            dispatch('loadProjDetail',state.projDetail.data.project)
    //        })
    //        .catch(err => {
    //            commit('setSaveProjApplyStatus', 3)
    //        })
    //},

    addFavorite ({commit, dispatch}, data) {
        //commit('resetProjApplyOperat')
        commit('resetSuggestionOperat')
        commit('setAddFavoriteStatus', 1)

        ProjsAPI.addFavorite(data.id)
            .then(resp => {
                if (resp.data.rest) {
                    //commit('setProjApplyOperat', resp.data.status)
                    commit('setSuggestionOperat', resp.data.status)
                }
                commit('setAddFavoriteStatus', 2)
                dispatch('loadProjDetail',data)
            })
            .catch(err => {
                commit('setAddFavoriteStatus', 3)
            })
    },
}
