<template>
    <div class="container">
        <div class="row">
            <div class="col-sm-6 mb-2">
                <page-nav icn="fas fa-qrcode text-primary" lab="メンバーを招待" @do_click="toInviteCode">
                    <i v-if="!item.is_handle_user" class="fas fa-lock icon-size opacity-3"></i>
                </page-nav>
            </div>
            <div class="col-sm mb-2">
                <page-nav icn="fas fa-user-friends text-primary" lab="メンバーリスト" @do_click="toInvitedStatus"></page-nav>
            </div>
        </div>
    </div>
</template>

<script>
    import PageNav from 'pages/commons/nav/PageNav'
    import {mapGetters} from 'vuex'

    export default {
        components:{
            PageNav
        },
        props: {
            item: Object
        },
        computed: {
            isOverLimit () {
                // 招待人数上限超過の場合
                return this.item.handle_info.invited_cnt >= this.item.handle_info.can_invite_cnt
            },
            isBannedInvite () {
                // 招待権限禁止された場合
                return this.item.handle_info.can_invite == '1' ? true : false
            }
        },
        methods: {
            toInviteCode () {
                if (this.item.is_handle_user) {

                    if (this.isBannedInvite) {
                        this.$parent.openInfoMsg(1, 'メンバー招待を行う権限がありません。詳しくは管理者にお問い合わせください。')

                    } else if (this.isOverLimit) {
                        this.$parent.openInfoMsg(1, '招待可能人数は上限に到達しています。人数を増やすには管理者にお問い合わせください。')

                    } else {
                        this.$router.push({name: 'CmpInviteCode'})
                    }
                } else {
                    this.$parent.openInfoMsg(1, 'オーナーとなるメンバーのみ招待を行う権限があります。')
                }
            },
            toInvitedStatus () {
                this.$router.push({name: 'CmpInvitedList'})
            },
        }
    }
</script>

<style lang="stylus" scoped>

</style>
