<template>
    <div id="modal-share-tech" class="modal fade">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <!-- <div class="modal-header"> -->
                <!--     <h5><i class="fas fa-share-alt"></i>&nbsp;共有</h5> -->
                <!--     <button class="close" type="button" data-dismiss="modal"> -->
                <!--         <span aria-hidden="true">&times;</span> -->
                <!--     </button> -->
                <!-- </div> -->
                <div class="modal-body">
                    <p class="text-center"><small class="text-muted">クリップボードへ<br>コピーする項目を選択してください。</small></p>
                    <ul class="list-group lsit-group-flush">
                        <!-- <li class="list-group-item pr-0 border-0"> -->
                        <!--     <div class="custom-control custom-switch"> -->
                        <!--         <input type="checkbox" v-model="infoChecked" class="custom-control-input" id="proj_simple_info_check" checked> -->
                        <!--         <label class="custom-control-label" for="proj_simple_info_check"> -->
                        <!--             [言語][開始時期][人数] -->
                        <!--             <span class="text-nowrap"> -->
                        <!--                 [最寄り駅] -->
                        <!--             </span> -->
                        <!--         </label> -->
                        <!--     </div> -->
                        <!-- </li> -->
                        <!-- <li class="list-group-item border-0"> -->
                        <!--     <div class="custom-control custom-switch"> -->
                        <!--         <input type="checkbox" v-model="nameChecked" class="custom-control-input" id="proj_nm_check" checked> -->
                        <!--         <label class="custom-control-label" for="proj_nm_check">案件名</label> -->
                        <!--     </div> -->
                        <!-- </li> -->
                        <!-- <li class="list-group-item border-0"> -->
                        <!--     <div class="custom-control custom-switch"> -->
                        <!--         <input type="checkbox" class="custom-control-input" id="proj_share_url_check" checked disabled> -->
                        <!--         <label class="custom-control-label" for="proj_share_url_check">案件詳細共有URL</label> -->
                        <!--     </div> -->
                        <!-- </li> -->

                        <li class="list-group-item border-0">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" v-model="infoChecked" class="custom-control-input" id="tech_simple_info_check" checked>
                                <label class="custom-control-label" for="tech_simple_info_check">[稼働][最寄駅][所属][経験]</label>
                            </div>
                        </li>
                        <li class="list-group-item border-0">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" v-model="nameChecked" class="custom-control-input" id="tech_nm_check" checked>
                                <label class="custom-control-label" for="tech_nm_check">[氏名][性別][年齢][国籍]</label>
                            </div>
                        </li>
                        <li class="list-group-item border-0">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" id="tech_share_url_check" checked disabled>
                                <label class="custom-control-label" for="tech_share_url_check">履歴書URL</label>
                            </div>
                        </li>


                    </ul>
                </div>
                <div class="modal-footer justify-content-center">
                    <button class="btn btn-primary" type="button" data-dismiss="modal" id="modal-share-btn"
                            v-clipboard:copy="shareInfo"
                            v-clipboard:success="copySuccess"
                    >
                        &nbsp;コピー&nbsp;
                    </button>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
    import {editProjStartDate} from 'pages/commons/form/comFunc'

    export default {
        data(){
            return {
                item: {},
                infoChecked:true,
                nameChecked:true
            }
        },
        computed: {
            shareInfo () {
                let info = "";

                if (this.infoChecked) {
                    info += `[${this.editStartDate(this.item.work_start_date)}][${this.item.nearest_station}][${this.converVal('relation_cmp', this.item.relation_cmp)}]`
                    if (this.item.exper) {
                        info += `[exp.${this.item.exper}年]`
                    }
                    info += "\n\n"
                }

                if (this.nameChecked) {
                    info += `${this.item.name} (${this.item.gender == '0' ? '男性':'女性'} ${this.item.age}歳 ${this.item.country})`
                    info += "\n\n"
                }

                info += this.item.signed_url

                return info
            }
        },
        methods:{
            openThis (item) {
                this.resetThis()
                this.item = item
                $('#modal-share-tech').modal('show')
            },
            resetThis () {
                this.infoChecked = true
                this.nameChecked = true
            },
            editStartDate (val) {
                return editProjStartDate(val)
            },
            converVal (name,value) {
                return this.$parent.converVal(name,value)
            },
            copySuccess () {
                this.resetThis();
            },

        }
    }
</script>
