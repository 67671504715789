<template>
    <div id="modal-share-proj" class="modal fade">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <!-- <div class="modal-header"> -->
                <!--     <h5><i class="fas fa-share-alt"></i>&nbsp;共有</h5> -->
                <!--     <button class="close" type="button" data-dismiss="modal"> -->
                <!--         <span aria-hidden="true">&times;</span> -->
                <!--     </button> -->
                <!-- </div> -->
                <div class="modal-body">
                    <p class="text-center"><small class="text-muted">クリップボードへ<br>コピーする項目を選択してください。</small></p>
                    <ul class="list-group lsit-group-flush">
                        <li class="list-group-item pr-0 border-0">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" v-model="infoChecked" class="custom-control-input" id="proj_simple_info_check" checked>
                                <label class="custom-control-label" for="proj_simple_info_check">
                                    [言語][開始時期][人数]
                                    <span class="text-nowrap">
                                        [最寄り駅]
                                    </span>
                                </label>
                            </div>
                        </li>
                        <li class="list-group-item border-0">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" v-model="nameChecked" class="custom-control-input" id="proj_nm_check" checked>
                                <label class="custom-control-label" for="proj_nm_check">案件名</label>
                            </div>
                        </li>
                        <li class="list-group-item border-0">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" v-model="urlChecked" class="custom-control-input" id="proj_share_url_check" checked disabled>
                                <label class="custom-control-label" for="proj_share_url_check">案件詳細URL</label>
                            </div>
                        </li>
                        <li class="list-group-item border-0">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" v-model="infoAllChecked" class="custom-control-input" id="proj_info_all">
                                <label class="custom-control-label" for="proj_info_all">案件詳細TEXT</label>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="modal-footer justify-content-center">
                    <button class="btn btn-primary" type="button" data-dismiss="modal" id="modal-share-btn"
                            v-clipboard:copy="shareInfo"
                            v-clipboard:success="copySuccess"
                    >
                        &nbsp;コピー&nbsp;
                    </button>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
    import {editProjStartDate} from 'pages/commons/form/comFunc'
    import {converVal} from 'pages/commons/form/selectList'

    export default {
        data(){
            return {
                item: {},
                infoChecked:true,
                nameChecked:true,
                urlChecked:true,
                infoAllChecked:false
            }
        },
        watch: {
            infoAllChecked (val) {
                if (val) {
                    this.infoChecked = false
                    this.nameChecked = false
                    this.urlChecked = false
                } else {
                    this.infoChecked = true
                    this.nameChecked = true
                    this.urlChecked = true
                }
            }
        },
        computed: {
            shareInfo () {
                if (this.infoAllChecked) {
                    return this.makeTextInfo(this.item)
                } else {
                    return this.makeUrlInfo()
                }
            }
        },
        methods:{
            openThis (item) {
                this.resetThis()
                this.item = item
                $('#modal-share-proj').modal('show')
            },
            resetThis () {
                this.infoChecked = true
                this.nameChecked = true
                this.urlChecked = true
                this.infoAllChecked = false
            },
            editStartDate (val) {
                return editProjStartDate(val)
            },
            copySuccess () {
                this.resetThis();
            },
            makeUrlInfo () {
                let info = "";

                if (this.infoChecked) {
                    if (this.item.proj_language) {
                        info += "[ " + this.item.proj_language + " ]"
                    }
                    info += "[ " + this.editStartDate(this.item.proj_start_date) + " ]" 
                    if (this.item.proj_members) {
                        info += "[ " + this.item.proj_members + "名 ]"
                    }
                    if (this.item.proj_place) {
                        info += "[ " + this.item.proj_place + " ]"
                    }
                    info += "\n\n"
                }

                if (this.nameChecked) {
                    info += this.item.proj_nm + "\n\n"
                }

                info += this.item.proj_signed_url_2

                return info
            },
            makeTextInfo (item) {
                let str = ''
                str += `■案件名：${item.proj_nm}\n`
                str += `■概　要：\n${item.proj_detail}\n`
                str += `■必須スキル：\n${item.proj_skill_need}\n`
                str += item.proj_skill_option ? `■尚可スキル：\n${item.proj_skill_option}\n` : ''
                str += item.proj_remote_ok ? `■リモート：${this.converVal('proj_remote_ok',item.proj_remote_ok)}\n` : ''
                str += item.proj_place ? `■最寄り駅：${item.proj_place}\n` : ''
                str += `■開始時期：${this.converVal('proj_start_date',item.proj_start_date)}\n`
                str += item.proj_members ? `■募集人数：${item.proj_members}名\n` : ''
                str += `■単　　金：スキル見合い\n`
                if (item.proj_is_adjust != '3') {// 3:不明
                    str += `■精算条件：${this.converVal('proj_is_adjust',item.proj_is_adjust)}`
                    str += item.proj_is_adjust == '0' ? `　${item.proj_time_start}～${item.proj_time_end}時間\n` : '\n'
                }
                str += item.proj_foreign_ok ? `■外 国 籍：${this.converVal('proj_foreign_ok',item.proj_foreign_ok)}\n` : ''
                str += item.proj_start_time ? `■始業時間：${this.converVal('proj_start_time',item.proj_start_time)}\n` : ''
                str += item.proj_interview ? `■面談回数：${item.proj_interview}回\n` : ''
                str += item.proj_min_exper ? `■経験の目安：${item.proj_min_exper}年\n` : ''
                str += item.proj_note ? `■そ の 他：\n${item.proj_note}\n` : ''
                return str
            },
            converVal (name, val) {
                return converVal(name,val)
            },
        }
    }
</script>
