<template>
    <div v-if="getBankInfo.data">
        <input-form v-show="showForm" ref="inputForm" :form="getBankInfo.data" :opt="operat"></input-form>
        <input-confirm v-show="!showForm" :form="reqForm" :opt="operat"></input-confirm>
        <success-msg ref="success" :msg="msg"></success-msg>
    </div>
</template>

<script>
    import InputForm from './components/Form'
    import InputConfirm from './components/Confirm'
    import SuccessMsg from 'pages/commons/modal/infoMsg/InfoMsg'
    import {mapGetters} from 'vuex'

    export default {
        components: {
            InputForm,
            InputConfirm,
            SuccessMsg
        },
        data () {
            return {
                msg: "",
                operat: 'show',
                showForm: false,
                reqForm: {}
            }
        },
        mounted() {
            this.loadBank()
        },
        watch: {
            getBankOperat (operat) {
                this.changeOperat(operat)
            },
            getBankInfo (info) {
                if (info.data.bank_info) {
                    this.reqForm = info.data.bank_info  
                } else {
                    this.operat = 'create'
                    this.showForm = true
                }
            },
            getBankInfoLoadStatus (status) {
                if (status == '1') {
                    this.$store.dispatch('startProgress')
                }
                if (status == '2') {
                    this.$store.dispatch('doneProgress')
                }
                if (status == '3') {
                    this.$store.dispatch('doneProgress')
                }
            },
            getSaveBankInfoStatus (status) {
                if (status == '1') {
                    this.$store.dispatch('startSpinner')
                }
                if (status == '2') {
                    this.$store.dispatch('doneSpinner')
                    this.setMsg()
                    this.$refs.success.openThis()
                }
                if (status == '3') {
                    this.$store.dispatch('doneSpinner')
                }
            }
        },
        computed: {
            ...mapGetters(['getSaveBankInfoStatus', 'getBankOperat', 'getBankInfoLoadStatus', 'getBankInfo'])
        },
        methods: {
            loadBank () {
                this.$store.dispatch('loadBankInfo')
            },
            doSubmit () {
                this.reqForm['operat'] = this.operat
                this.$store.dispatch('saveBankInfo', this.reqForm)
            },
            getChildData () {
                let inputForm = this.$refs.inputForm.getData()
                this.reqForm = Object.assign({}, this.reqForm, inputForm)
            },
            setMsg () {
                this.msg = "完了しました。"
            },
            changeOperat (opt) {
                if (opt == 'auto') {
                    this.operat = (this.operat == 'create') ? 'create_confirm' : 'edit_confirm'
                } else {
                    this.operat = opt 
                }
                if (this.operat == 'create' || this.operat == 'edit') {
                    this.showForm = true
                }
                if (this.operat == 'create_confirm' || this.operat == 'edit_confirm' || this.operat == 'show') {
                    this.showForm = false
                }
                // スクロールをトップへ移動
                document.documentElement.scrollTop = 0
            },
        }
    }
</script>

<style></style>
