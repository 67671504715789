<template>
        <form-layout :errs-nm="errsNm" :mb="mb">

            <!-- icon -->
            <template v-slot:icon> <i :class="icn"></i> </template>

            <!-- label -->
            <template v-slot:label> {{lab}} </template>

            <!-- 内容 -->
            <template v-slot:input>

                <div class="card p-2 card-header break-row w-100">
                    <slot></slot>
                </div>

            </template>

        </form-layout>
    </div>
</template>

<script>
    /**
     * form項目確認コンポーネント
     **/
    import FormLayout from './Layout'

    export default {
        components: {
            FormLayout
        },
        props: {
            lab: String,
            icn: String,
            // 項目間間隔(default:mb-2)
            mb: {
                type: String,
                default: ""
            },
        },
        data () {
            return {
                errsNm: []
            }
        }
    }
</script>

<style lang="stylus" scoped>
    .break-row
        white-space:pre-wrap
        word-wrap:break-word
</style>
