<template>
    <div>
        <invited-list 
            ref="list"
            header-text="メンバーリスト"
            lab="人"
        >
            <div v-if="getListObj">
                <div class="card com-card" v-if="getListObj.data.length > 0">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item pr-2 p-md-4" v-for="item in getListObj.data" :key="item.next_id">
                            <div class="wrapper-name">
                                <span class="mr-2">
                                    <span v-if="item.is_owner" >
                                        <i class="fas fa-user-tie icon-size text-danger-cust"></i>
                                        <small class="let-space-1">(オーナー)</small>
                                    </span>
                                    <span v-else-if="item.is_unlink" >
                                        <i class="fas fa-user-alt icon-size text-secondary"></i>
                                        <small class="let-space-1">(解除済)</small>
                                    </span>
                                    <i v-else class="fas fa-user-alt icon-size text-success"></i>
                                </span>
                                <span class="name text-right flex-fill">
                                    {{item.user_name}}
                                </span>
                                <span v-if="item.show_menu" class="text-muted pl-3 ml-auto item-hover" @click="openMenu(item)">
                                    <i class="fas fa-ellipsis-v"></i>
                                </span>
                                <span v-else class="text-muted pl-3 ml-auto opacity0">
                                    <i class="fas fa-ellipsis-v"></i>
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </invited-list>

        <!-- メニュー -->
        <invited-menu ref="menu"></invited-menu>
        <!-- メッセージ -->
        <info-msg ref="msg" :lev="lev" :msg="msg" @ok="msgOk"></info-msg>
        <!-- 利用者連携解除 -->
        <user-unlink ref="userUnlink"></user-unlink>
        <!-- 利用者連携 -->
        <user-link ref="userLink"></user-link>

    </div>
</template>

<script>
    import InvitedList from 'pages/commons/List/List'
    import InvitedMenu from './components/Menu'
    import InfoMsg from 'pages/commons/modal/infoMsg/InfoMsg'
    import UserUnlink from 'pages/commons/modal/userUnlink/UserUnlink'
    import UserLink from 'pages/commons/modal/userLink/UserLink'

    import {mapGetters} from 'vuex'

    export default {
        components: {
            InvitedList,
            InvitedMenu,
            InfoMsg,
            UserUnlink,
            UserLink
        },
        data () {
            return {
                lev: 0,
                msg: "",
                msgKey: "",
                selectId: ""
            }
        },
        mounted () {
            this.$refs.list.loadListObj({ url: 'cmp.invite.list' })
        },
        watch: {
            getCmpInvitedChangeOwnerStatus (status) {
                if (status == 0) {
                    this.$store.dispatch('startSpinner')
                }
                if (status == 2) {
                    this.$store.dispatch('setListObj', this.getCmpInvitedListLaterChangeOwner)
                    this.$store.dispatch('doneSpinner')
                }
                if (status == 3) {
                    this.$store.dispatch('doneSpinner')
                }
            },
            getCmpInvitedTransferOwnerStatus (status) {
                if (status == 0) {
                    this.$store.dispatch('startSpinner')
                }
                if (status == 2) {
                    this.$store.dispatch('doneSpinner')
                }
                if (status == 3) {
                    this.$store.dispatch('doneSpinner')
                }
            },
            getCmpInvitedUserUnlinkStatus (status) {
                if (status == 0) {
                    this.$store.dispatch('startSpinner')
                }
                if (status == 2) {
                    this.$store.dispatch('setListObj', this.getCmpInvitedListLaterUserUnlink)
                    this.$store.dispatch('doneSpinner')
                }
                if (status == 3) {
                    this.$store.dispatch('doneSpinner')
                }
            }
        },
        computed: {
            ...mapGetters(['getListObj'
                ,'getCmpInvitedListLaterChangeOwner'
                ,'getCmpInvitedChangeOwnerStatus'
                ,'getCmpInvitedTransferOwnerStatus'
                ,'getCmpInvitedListLaterUserUnlink'
                ,'getCmpInvitedUserUnlinkStatus'
            ])
        },
        methods: {
            userUnlink (item) {
                this.$refs.userUnlink.openThis(item)
            },
            userUnlinkDo (item) {
                this.$store.dispatch('cmpInvitedUserUnlink', {
                    id: item.user_id,
                })
            },
            userLink (item) {
                this.$refs.userLink.openThis(item)
            },
            openMenu (item) {
                this.$refs.menu.openThis(item)
            },
            openMsg (lev, msg, key, id="") {
                this.lev = lev
                this.msg = msg
                this.msgKey = key
                this.selectId = id
                this.$refs.msg.openThis()
            },
            msgOk () {
                if (this.msgKey == 'changeOwner') {
                    this.$store.dispatch('cmpInvitedChangeOwner', {
                        id: this.selectId,
                    })
                }
                if (this.msgKey == 'transferOwner') {
                    this.$store.dispatch('cmpInvitedTransferOwner', {
                        id: this.selectId,
                    })
                }
            },
        }
    }
</script>

<style lang="stylus" scoped>
    @import '~stylus/base/mixins.styl'
    .opacity0
        opacity: 0
    .wrapper-name
        display:flex
        white-space: nowrap
        .name
            ellipsis()
</style>
