export default {

    /** point情報 **/
    getPoint (state) {
        return state.point.data
    },
    getPointLoadStatus (state) {
        return state.pointLoadStatus
    },

    /** ポイント換金 **/
    getExchangeInit (state) {
        return state.exchangeInit
    },
    getExchangeInitLoadStatus (state) {
        return state.exchangeInitLoadStatus
    },
    getExchangePointStatus (state) {
        return state.exchangePointStatus
    },

    /** 招待情報 **/
    getInviteInfo (state) {
        return state.inviteInfo.data
    },
    getInviteInfoLoadStatus (state) {
        return state.inviteInfoLoadStatus
    },
    // getShareInviteCodeStatus (state) {
    //     return state.shareInviteCodeStatus
    // },
}

