<template>
    <div v-if="getApplyStatusDetail.msgs">
        <!-- 案件 -->
        <app-msg :msgs="getApplyStatusDetail.msgs"
            :apply-id="getApplyStatusDetail.appId" 
            sender-type="2"></app-msg>
    </div>
</template>

<script>
    import AppMsg from 'pages/commons/applyMsg/ApplyMsg'
    import {mapGetters} from 'vuex'

    export default {
        components: {
            AppMsg,
        },
        computed: {
            ...mapGetters(['getApplyStatusDetail', 'getAddApplyHisMsg'])
        },
        methods: {
            pushCommentToList () {
                this.getApplyStatusDetail.msgs.push(this.getAddApplyHisMsg)
            },
        }
        
    }

</script>

<style lang="stylus" scoped>

</style>
