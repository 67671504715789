import CmpProjsAPI from 'js/api/cmpProjs'

export default {

    loadCmpProjHome ({commit}) {
        commit('setCmpProjHomeLoadStatus', 1)

        CmpProjsAPI.loadCmpProjHome()
            .then(resp => {
                commit('setCmpProjHome', resp)
                commit('setCmpProjHomeLoadStatus', 2)
            })
            .catch(err => {
                commit('setCmpProjHome', {})
                commit('setCmpProjHomeLoadStatus', 3)
            })
    },

    saveCmpProjInfo ({commit}, data) {
        commit('resetCmpProjOperat')
        commit('setSaveCmpProjInfoStatus', 1)

        CmpProjsAPI.saveCmpProjInfo(data)
            .then(resp => {
                commit('setAllErrors', {})
                commit('setCmpProjOperat', 'success')
                commit('setCmpProjInfo', resp)
                commit('setSaveCmpProjInfoStatus', 2)
            })
            .catch(err => {
                commit('setCmpProjOperat', data.operat)
                commit('setAllErrors', err.response.data.errors)
                commit('setSaveCmpProjInfoStatus', 3)
            })
    },

    loadCmpProjInfo ({commit}, data) {
        commit('setCmpProjInfoLoadStatus', 1)

        CmpProjsAPI.loadCmpProjInfo(data)
            .then(resp => {
                commit('setCmpProjInfo', resp)
                commit('setCmpProjInfoLoadStatus', 2)
            })
            .catch(err => {
                commit('setCmpProjInfo', {})
                commit('setCmpProjInfoLoadStatus', 3)
            })
    },

    resetCmpProjInfo ({commit}) {
        commit('resetCmpProjInfo')
    },

    cmpProjOff ({commit}, data) {
        commit('setCmpProjOffStatus', 1)

        CmpProjsAPI.cmpProjOff(data)
            .then(resp => {
                commit('setCmpProjOffStatus', 2)
            })
            .catch(err => {
                commit('setCmpProjOffStatus', 3)
            })
    },
}
