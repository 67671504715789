import Vue from 'vue'
import Router from 'vue-router'
import Layout from 'pages/Layout'

/**
 * === 個人 ===
 */

// 個人：案件機能
import ProjsAll from 'pages/projs/projsAll/ProjsAll'
import ProjDetail from 'pages/projs/projDetail/ProjDetail'

// 個人：マイページ機能
import MyHome from 'pages/myPage/myHome/MyHome'
import PrivateCreate from 'pages/myPage/privateCreate/PrivateCreate'
import ResumeCreate from 'pages/myPage/resumeCreate/ResumeCreate'
import IdentityCheck from 'pages/myPage/identityCheck/IdentityCheck'
import BankInput from 'pages/myPage/bankInput/BankInput'
import ApplyHis from 'pages/myPage/applyHis/ApplyHis'
import ApplyHisDetail from 'pages/myPage/applyHisDetail/ApplyHisDetail'
import Favorites from 'pages/myPage/favorites/Favorites'

// 個人：招待機能
import InviteHome from 'pages/invites/inviteHome/InviteHome'
import ExchangeInput from 'pages/invites/exchangeInput/ExchangeInput'
import PointDetail from 'pages/invites/pointDetail/PointDetail'
import ExchangeInfo from 'pages/invites/exchangeInfo/ExchangeInfo'
import InviteCode from 'pages/invites/inviteCode/InviteCode'
import InvitedStatus from 'pages/invites/invitedStatus/InvitedStatus'
import InvitedChildStatus from 'pages/invites/invitedChildStatus/InvitedChildStatus'

// 個人：貢献
import ServiceHome from 'pages/service/serviceHome'
import ServiceProj from 'pages/service/serviceProj'
import ServiceProjCreate from 'pages/service/serviceProjCreate'
import ServiceTech from 'pages/service/serviceTech'
import ServiceTechCreate from 'pages/service/serviceTechCreate'

// 個人：パートナー募集
import Partner from 'pages/partners/partner/Partner'

/**
 *  === 法人 ===
 */

// 法人：案件機能
import CmpPjHome from 'pages/cmpProjs/home/Home'
import CmpActProj from 'pages/cmpProjs/actProj/ActProj'
import CmpProjCreate from 'pages/cmpProjs/projCreate/ProjCreate'

// 法人：応募機能
import ApplyHome from 'pages/applies/applyHome/ApplyHome'
import ApplyPj from 'pages/applies/applyPj/ApplyPj'
import ApplyStatusDetail from 'pages/applies/applyStatusDetail/ApplyStatusDetail'
// 以下廃棄
import Apply from 'pages/applies/apply/Apply'
import ApplyDetail from 'pages/applies/detail/Detail'
import EnterPlan from 'pages/applies/enterPlan/EnterPlan'
import EnterDetail from 'pages/applies/enterDetail/EnterDetail'
import IntvContact from 'pages/applies/contact/Contact'
import IntvPlace from 'pages/applies/intvPlace/IntvPlace'

// 法人：招待機能
import CmpInviteHome from 'pages/cmpInvites/cmpInviteHome/CmpInviteHome'
import CmpInviteCode from 'pages/cmpInvites/cmpInviteCode/CmpInviteCode'
import CmpInvitedList from 'pages/cmpInvites/cmpInvitedList/CmpInvitedList'

/**
 *  === 共通 ===
 */
import HttpError from 'pages/httpError/HttpError'
import ProjShareDetail from 'pages/commons/projShare/ProjShareDetail'
import TechShareDetail from 'pages/commons/techShare/TechShareDetail'
import Terms from 'pages/commons/termsAndPolicy/Terms'
import Privacy from 'pages/commons/termsAndPolicy/Privacy'

Vue.use(Router)

export default new Router({
    mode:'history',
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
     },
    routes:[
        {
            path: '/',
            name: 'Layout',
            component: Layout,
            children:[
                {
                    path: 'login',
                    name: 'login'
                },
                {
                    // URL送信画面：http://tech.local/password/reset
                    // 新password設定画面：http://tech.local/password/reset/64b26c2ce11b74eae9031615e713e3f8c2c6a3b69d092da7a41bf58a5004eafe
                    path: 'password/reset/:revision?',
                    name: 'passwordReset'
                },
                {
                    /**
                     * === 個人 ===
                     */

                    // 個人：案件機能開始
                    path: 'admin/projs',
                    name: 'ProjsAll',
                    component: ProjsAll,
                    meta: {
                        title: '案件一覧tech',
                        role: 'person',
                        keepAlive: true
                    }
                },
                {
                    path: 'admin/projs/:id/:proc?',
                    name: 'ProjDetail',
                    component: ProjDetail,
                    meta: {
                        title: '案件詳細tech',
                        role: 'person',
                        keepAlive: false
                    }
                },
                {
                    // 個人：マイページ機能開始
                    path: 'admin/my',
                    name: 'MyHome',
                    component: MyHome,
                    meta: {
                        title: 'マイページtech',
                        role: 'person',
                        keepAlive: false
                    }
                },
                {
                    path: 'admin/my/private',
                    name: 'PrivateCreate',
                    component: PrivateCreate,
                    meta: {
                        title: '個人情報入力',
                        role: 'person',
                        keepAlive: false
                    }
                },
                {
                    path: 'admin/my/resume',
                    name: 'ResumeCreate',
                    component: ResumeCreate,
                    meta: {
                        title: '履歴書情報入力',
                        role: 'person',
                        keepAlive: false
                    }
                },
                {
                    path: 'admin/my/identity',
                    name: 'IdentityCheck',
                    component: IdentityCheck,
                    meta: {
                        title: '本人確認',
                        role: 'person',
                        keepAlive: false
                    }
                },
                {
                    path: 'admin/my/bank',
                    name: 'BankInput',
                    component: BankInput,
                    meta: {
                        title: '銀行情報入力',
                        role: 'person',
                        keepAlive: false
                    }
                },
                {
                    path: 'admin/my/apply/his',
                    name: 'ApplyHis',
                    component: ApplyHis,
                    meta: {
                        title: '応募履歴(mypage)',
                        role: 'person',
                        keepAlive: true
                    }
                },
                {
                    path: 'admin/my/apply/his/:id',
                    name: 'ApplyHisDetail',
                    component: ApplyHisDetail,
                    meta: {
                        title: '応募履歴詳細(mypage)',
                        role: 'person',
                        keepAlive: false
                    }
                },
                {
                    path: 'admin/my/favorites',
                    name: 'Favorites',
                    component: Favorites,
                    meta: {
                        title: 'お気に入り(mypage)',
                        role: 'person',
                        keepAlive: true
                    }
                },
                {
                    // 個人：招待機能開始
                    path: 'admin/invites',
                    name: 'InviteHome',
                    component: InviteHome,
                    meta: {
                        title: '招待',
                        role: 'person',
                        keepAlive: false
                    }
                },
                {
                    path: 'admin/point/exchange',
                    name: 'ExchangeInput',
                    component: ExchangeInput,
                    meta: {
                        title: 'ポイン交換情報入力',
                        role: 'person',
                        keepAlive: false
                    }
                },
                {
                    path: 'admin/point/detail',
                    name: 'PointDetail',
                    component: PointDetail,
                    meta: {
                        title: 'ポイント詳細',
                        role: 'person',
                        keepAlive: false
                    }
                },
                {
                    path: 'admin/invites/code',
                    name: 'InviteCode',
                    component: InviteCode,
                    meta: {
                        title: '招待コード',
                        role: 'person',
                        keepAlive: false
                    }
                },
                {
                    path: 'admin/invites/status',
                    name: 'InvitedStatus',
                    component: InvitedStatus,
                    meta: {
                        title: '友達',
                        role: 'person',
                        keepAlive: true
                    }
                },
                {
                    path: 'admin/invites/status/:code',
                    name: 'InvitedChildStatus',
                    component: InvitedChildStatus,
                    meta: {
                        title: 'サブ友達',
                        role: 'person',
                        keepAlive: false
                    }
                },
                {
                    path: 'admin/invites/exchange',
                    name: 'ExchangeInfo',
                    component: ExchangeInfo,
                    meta: {
                        title: 'ポイント交換履歴',
                        role: 'person',
                        keepAlive: false
                    }
                },
                {
                    // 個人：貢献
                    path: 'admin/service',
                    name: 'ServiceHome',
                    component: ServiceHome,
                    meta: {
                        title: '貢献',
                        role: 'person',
                        keepAlive: false
                    }
                },
                {
                    path: 'admin/service/projs/list/:do',
                    name: 'ServiceProj',
                    component: ServiceProj,
                    meta: {
                        title: '応募中案件',
                        role: 'person',
                        keepAlive: true
                    }
                },
                {
                    path: 'admin/service/projs/item/:id?',
                    name: 'ServiceProjCreate',
                    component: ServiceProjCreate,
                    meta: {
                        title: '新規案件入力/案件詳細',
                        role: 'person',
                        keepAlive: false
                    }
                },
                {
                    path: 'admin/service/tech/list/:do',
                    name: 'ServiceTech',
                    component: ServiceTech,
                    meta: {
                        title: '営業中要員',
                        role: 'person',
                        keepAlive: true
                    }
                },
                {
                    path: 'admin/service/tech/item/:do/:id?',
                    name: 'ServiceTechCreate',
                    component: ServiceTechCreate,
                    meta: {
                        title: '新規要員入力/要員詳細',
                        role: 'person',
                        keepAlive: false
                    }
                },
                {
                    // 個人：パートナー募集開始
                    path: 'admin/partners',
                    name: 'Partner',
                    component: Partner,
                    meta: {
                        title: 'パートナー募集',
                        role: 'person',
                        keepAlive: false
                    }
                },
                {
                    /**
                     * 法人start
                     */

                    // 法人：案件機能開始
                    path: 'c/projs/home',
                    name: 'CmpPjHome',
                    component: CmpPjHome,
                    meta: {
                        title: '案件ホーム',
                        role: 'organization',
                        keepAlive: false
                    }
                },
                {
                    path: 'c/projs/home/act/:do',
                    name: 'CmpActProj',
                    component: CmpActProj,
                    meta: {
                        title: '応募中案件',
                        role: 'organization',
                        keepAlive: true
                    }
                },
                {
                    path: 'c/projs/home/post/:id?',
                    name: 'CmpProjCreate',
                    component: CmpProjCreate,
                    meta: {
                        title: '新規案件入力/案件詳細',
                        role: 'organization',
                        keepAlive: false
                    }
                },
                {
                    // 法人：応募機能開始
                    path: 'admin/apply',
                    name: 'ApplyHome',
                    component: ApplyHome,
                    meta: {
                        title: '応募ホーム',
                        role: 'organization',
                        keepAlive: false
                    }
                },
                {
                    path: 'admin/apply/pj',
                    name: 'ApplyPj',
                    component: ApplyPj,
                    meta: {
                        title: '応募状況',
                        role: 'organization',
                        keepAlive: true
                    }
                },
                {
                    path: 'admin/apply/status/:id',
                    name: 'ApplyStatusDetail',
                    component: ApplyStatusDetail,
                    meta: {
                        title: '応募状況詳細',
                        role: 'organization',
                        keepAlive: false
                    }
                },
                {
                    path: 'admin/apply/request',
                    name: 'Apply',
                    component: Apply,
                    meta: {
                        title: '応募一覧',
                        role: 'organization',
                        keepAlive: true
                    }
                },
                {
                    path: 'admin/apply/request/:id',
                    name: 'ApplyDetail',
                    component: ApplyDetail,
                    meta: {
                        title: '応募詳細',
                        role: 'organization',
                        keepAlive: false
                    }
                },
                {
                    path: 'admin/apply/enter',
                    name: 'EnterPlan',
                    component: EnterPlan,
                    meta: {
                        title: '入場日程',
                        role: 'organization',
                        keepAlive: true
                    }
                },
                {
                    path: 'admin/apply/enter/:day',
                    name: 'EnterDetail',
                    component: EnterDetail,
                    meta: {
                        title: '入場日程詳細',
                        role: 'organization',
                        keepAlive: false
                    }
                },
                {
                    path: 'admin/apply/contact',
                    name: 'IntvContact',
                    component: IntvContact,
                    meta: {
                        title: '連絡先(面接/入場)',
                        role: 'organization',
                        keepAlive: true
                    }
                },
                {
                    path: 'admin/apply/place',
                    name: 'IntvPlace',
                    component: IntvPlace,
                    meta: {
                        title: '場所(面接/入場)',
                        role: 'organization',
                        keepAlive: true
                    }
                },
                {
                    // 法人：招待機能開始
                    path: 'c/invites',
                    name: 'CmpInviteHome',
                    component: CmpInviteHome,
                    meta: {
                        title: '招待',
                        role: 'organization',
                        keepAlive: false
                    }
                },
                {
                    path: 'c/invites/code',
                    name: 'CmpInviteCode',
                    component: CmpInviteCode,
                    meta: {
                        title: '招待コード',
                        role: 'organization',
                        keepAlive: false
                    }
                },
                {
                    path: 'c/invites/members',
                    name: 'CmpInvitedList',
                    component: CmpInvitedList,
                    meta: {
                        title: '友達',
                        role: 'organization',
                        keepAlive: false
                    }
                },
                {
                    // 案件情報シェア画面(vue版)
                    path:'admin/post/share/:code',
                    name:'ProjShareDetail',
                    component: ProjShareDetail,
                    meta: {
                        title: '案件情報シェア詳細',
                        needLogin: false,
                        keepAlive: false,
                        fullFooter:true
                    }
                },
                {
                    // 要員情報シェア画面(vue版)
                    path:'admin/tech/share/:code',
                    name:'TechShareDetail',
                    component: TechShareDetail,
                    meta: {
                        title: '要員情報シェア詳細',
                        needLogin: false,
                        keepAlive: false,
                        fullFooter:true
                    }
                },
            ]
        },
        {
            path: '/error/:status?',
            name: 'HttpError',
            component: HttpError
        },
       // {
       //     // 案件情報シェア画面
       //     path:'/admin/post/share/:code',
       //     name:'projShare'
       // },
        {
            // 招待用QRコード画面
            path:'/admin/invites/share/:code',
            name:'qrcodeShare'
        },
        {
            // 登録画面表示
            path:'/register',
            name:'register'
        },
        {
            path:'/ja/terms',
            name:'Terms',
            component: Terms
        },
        {
            path:'/ja/privacy',
            name:'Privacy',
            component: Privacy
        },
        {
            path: '*',
            redirect: {
                name: 'HttpError',
            }
        }
     ]
});
