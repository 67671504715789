<template>
    <div>
        <!-- エラー画面用Nav -->
        <nav-bar></nav-bar>

        <div class="text-center">
                <div class="container pt-5 mt-5">

                    <err-body v-if="code == undefined" :ico="ico">
                        <template v-slot:status>404</template>
                        <template v-slot:en_text>Not Found</template>
                        <template v-slot:jp_text>ページが見つかりません。</template>
                    </err-body>

                    <err-body v-if="code == 401" :ico="ico">
                        <template v-slot:status>401</template>
                        <template v-slot:en_text>Unauthorized</template>
                        <template v-slot:jp_text>認証に失敗しました。</template>
                        <template v-slot:reason>
                            原因：セッションがタイムアウトしました。
                        </template>
                    </err-body>

                    <err-body v-if="code == 500" :ico="ico">
                        <template v-slot:status>500</template>
                        <template v-slot:en_text>Server Error</template>
                        <template v-slot:jp_text>
                            サーバー内部のエラーにより表示できません。 
                        </template>
                    </err-body>

                    <hr>
                    <div>
                        <a class="btn btn-outline-secondary" href="/login">ホーム画面に戻る</a>
                    </div>
                </div>
        </div>

    </div>
</template>

<script>
    import NavBar from 'pages/commons/nav/NavGuest'
    import ErrBody from './components/layout'

    export default {
        components: {
            NavBar,
            ErrBody
        },
        mounted () {
                this.$store.dispatch('doneProgress')
                this.$store.dispatch('doneSpinner')
        },
        computed: {
            code () {
                return this.$route.params.status
            },
            ico () {
                if (this.code == undefined) {
                    return 'fas fa-search'
                }
                if (this.code == 401) {
                    return 'fas fa-user-shield'
                }
                if (this.code == 500) {
                    return 'fas fa-laptop-medical'
                }
                return ""
            },
        }
    }
</script>

<style>

</style>
