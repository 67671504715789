<template>
    <div class="py-5 text-center text-muted">
        <i class="fas fa-search h1"></i><br>
        <div class="py-md-4">
            {{msg}}
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            msg: {
                type: String,
                default: "データが見つかりません。"
            }
        }
    }
</script>

<style>

</style>
