<template>
    <div id="modal-exchange-warning" class="modal fade">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header py-2">
                    <h6 class="modal-title">ご注意事項（必読）</h6>
                    <button type="button" class="close" data-dismiss="modal" aria-label="閉じる">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body p-2">
                    <div class="card card-body border-0 p-2">
                        <warn-item lab="対象者">現在、日本在住の方のみ</warn-item>
                        <warn-item lab="交換レート">
                            <p>
                                10,000P ⇒&nbsp;{{pointFormat(item.exchange_rate)}} 円
                            </p>
                            <p>
                                <span class="text-muted">
                                    ※交換レートは変更する場合があります。
                                </span>
                            </p>
                        </warn-item>
                        <warn-item lab="交換単位">10,000P単位</warn-item>
                        <warn-item lab="交換数量">全ポイント一括交換</warn-item>
                        <warn-item lab="交換回数">
                            １回/月
                        </warn-item>
                        <warn-item lab="申込受付期間">毎月6日～20日</warn-item>
                        <warn-item lab="申込処理期間">当月21日～来月5日</warn-item>
                        <warn-item lab="交換に必要な期間">
                            <p> 約2週間 </p>
                            <p>
                                <span class="text-muted">
                                    ※手続き完了後、ユーザー様の普通預金口座へ振り込まれます。<br>
                                      恐れ入りますが振込手数料はご負担下さい。
                                </span>
                            </p>
                        </warn-item>
                        <warn-item lab="交換に必要な条件">
                            <p>・交換可能ポイントが{{pointFormat(item.exchange_mini)}}P以上であること。</p>
                            <p>・ご本人様確認書類提出済みであること。</p>
                            <p>・振込先口座登録済みであること。</p>
                            <p>
                                <span class="text-muted">
                                    ※他者名義の口座へはポイント交換はできません。
                                </span>
                            </p>
                        </warn-item>
                        <warn-item lab="確認方法">ポイントの交換状況は「ポイント交換履歴」で確認できます。</warn-item>
                        <warn-item lab="本人確認書類">
                            <identity-file mb-h="0.3"></identity-file>
                            <span class="text-muted">
                                <p>※住所変更等、また本人確認書類の有効期限切れや登録情報の再確認が必要となった場合は、再度本人確認を行う場合があります。</p>
                            </span>
                        </warn-item>
                        <warn-item lab="注意事項">
                            <p>・ご提出いただく本人確認書類の記載事項は「個人情報」と一致しない場合、再度ご提出をお願いすることがございます。</p>
                            <p>・システムメンテナンス中は、ご利用いただけませんので、あらかじめご了承ください。</p>
                            <!-- <p>・申込処理期間内に必要書類の提出がない場合、交換依頼を取り消すことになる場合があります。</p> -->
                        </warn-item>
                    </div>
                </div>
                <div class="modal-footer justify-content-center">
                    <button class="btn btn-primary" type="button" @click="hideThis">
                        &nbsp;&nbsp;閉じる&nbsp;&nbsp;
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {commaFormat} from 'pages/commons/form/comFunc'
    import WarnItem from './ExchangeWarningLayout'
    import IdentityFile from 'pages/commons/identity/IdentityFile'

    export default {
        components: {
           WarnItem,
           IdentityFile
        },
        props: {
            item: Object
        },
        methods: {
            openThis () {
                $('#modal-exchange-warning').modal('show')
            },
            hideThis () {
                $('#modal-exchange-warning').modal('hide')
            },
            pointFormat (val) {
                return commaFormat(val)
            }
        }
    }
</script>

<style lang="stylus" scoped>
    p
        margin-bottom: 0.3rem !important
</style>
