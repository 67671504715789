<template>
    <div id="modal-act-proj-menu" class="modal fade show">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <ul class="list-group list-group-flush">
                        <li v-if="item.open_flg == '0'" class="list-group-item border-0 item-hover" @click="techOff('1')">
                            <div class="row">
                                <div class="col-2 pr-0 text-center">
                                    <i class="text-muted fas fa-user-times icon-size"></i>
                                </div>
                                <div class="col pl-2">
                                    <span class="pl-2">営業終了にする</span>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item border-0 item-hover" @click="techShare">
                            <div class="row">
                                <div class="col-2 pr-0 text-center">
                                    <i class="text-muted fas fa-share-alt icon-size"></i>
                                </div>
                                <div class="col pl-2">
                                    <span class="pl-2">共有</span>
                                </div>
                            </div>
                        </li>
                        <li v-if="item.open_flg == '1'" class="list-group-item border-0 item-hover" @click="techOff('2')">
                            <div class="row">
                                <div class="col-2 pr-0 text-center">
                                    <i class="text-muted fas fa-trash icon-size"></i>
                                </div>
                                <div class="col pl-2">
                                    <span class="pl-2">削除</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        data () {
            return {
                item: {}
            }
        },
        watch: {
            getComMenuQuickOffStatus (status) {
                if (status == 0) {
                    this.$store.dispatch('startSpinner')
                }
                if (status == 2) {
                    this.$store.dispatch('delItemFromList', {
                        listItem: 'id',
                        pageId: this.item.id
                    })
                    this.hideThis()
                    this.$store.dispatch('doneSpinner')
                }
                if (status == 3) {
                    this.hideThis()
                    this.$store.dispatch('doneSpinner')
                }
            }
        },
        computed: {
            ...mapGetters(['getComMenuQuickOffStatus'])
        },
        methods: {
            openThis (item) {
                this.resetThis()
                this.item = item
                $('#modal-act-proj-menu').modal('show')
            },
            hideThis () {
                this.resetThis()
                $('#modal-act-proj-menu').modal('hide')
            },
            resetThis () {
                this.item = {}
            },
            techOff (flg) {
                this.$store.dispatch('comMenuQuickOff', {
                    page: 'tech',
                    id: this.item.id,
                    flg: flg
                })
            },
            techShare () {
                this.$parent.techShare(this.item)
                this.hideThis()
            }
        }
    }
</script>

<style>

</style>
