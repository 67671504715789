<template>
    <div :class="{'container': mg}">
        <div v-if="showSpinner" class="text-center text-primary">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div v-else @click="seeMore" class="card card-body text-center rounded-pill p-2 mb-2 item-hover com-card mx-md-5">
            <span class="py-md-2">もっと見る</span>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            spinnerStatus: {
                type: Number,
                default: 0
            },
            // 手動モード
            mt: {
                type: Boolean,
                default: false
            },
            // container margin
            mg: {
                type: Boolean,
                default: true
            }
        },
        data () {
            return {
                showSpinner: false
            }
        },
        watch: {
            spinnerStatus () {
                if (this.spinnerStatus == 1) {
                    this.showSpinner = true
                } else {
                    this.showSpinner = false
                }
            }
        },
        methods: {
            seeMore () {
                if (this.mt) {
                    // 削除付きのリスト画面で
                    // 項目を削除しでも正しく次ページを取得できるための存在
                    this.$emit('seeMoreMt')
                } else {
                    this.$emit('seeMore')
                }
            },
        }
    }
</script>

<style>

</style>
