<template>
    <exchange-info-list 
        ref="list"
        header-text="換金情報"
    >
        <div v-if="getListObj">
            <div class="card com-card" v-if="getListObj.data.length > 0">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item pb-1 p-md-4" v-for="item in getListObj.data" :key="item.id">
                            <div class="d-flex align-items-center">
                                <span><i class="fas fa-donate text-primary icon-size"></i></span>
                                <span class="flex-fill px-3">
                                    <b>{{toLocaleString(item.points)}}</b>P
                                </span>
                                <span v-if="item.status == '0'" class="badge badge-pill badge-warning py-1 px-3">依頼済み</span>
                                <span v-if="item.status == '1'" class="badge badge-pill badge-success py-1 px-3">処理中</span>
                                <span v-if="item.status == '2'" class="badge badge-pill badge-secondary py-1 px-3">換金済み</span>
                            </div>
                            <div class="text-right text-muted let-space-1">
                                <small><small>{{item.created_at | dformatm}}</small></small>
                            </div>
                        </li>
                    </ul>
                </div>
        </div>
    </exchange-info-list>
</template>

<script>
    import ExchangeInfoList from 'pages/commons/List/List'
    import {mapGetters} from 'vuex'

    export default {
        components: {
            ExchangeInfoList
        },
        mounted () {
            this.$refs.list.loadListObj({
                url: 'invite.point.exchange.info'
            })
        },
        computed: {
            ...mapGetters(['getListObj'])
        },
        methods: {
            toLocaleString (num) {
                return Number(num).toLocaleString()
            },
        }
    }
</script>
