import InvitesAPI from 'js/api/invites'

export default {

    loadPoint ({commit}) {
        commit('setPointLoadStatus', 1)

        InvitesAPI.loadPoint()
            .then(resp => {
                commit('setPoint',resp)
                commit('setPointLoadStatus', 2)
            })
            .catch(err => {
                commit('setPoint', {})
                commit('setPointLoadStatus', 3)
            })
    },

    loadExchangeInit ({commit}) {
        commit('setExchangeInitLoadStatus', 1)

        InvitesAPI.loadExchangeInit()
            .then(resp => {
                commit('setExchangeInit',resp)
                commit('setExchangeInitLoadStatus', 2)
            })
            .catch(err => {
                commit('setExchangeInit', {})
                commit('setExchangeInitLoadStatus', 3)
            })
    },

    exchangePoint ({commit}) {
        commit('setExchangePointStatus', 1)

        InvitesAPI.exchangePoint()
            .then(resp => {
                commit('setExchangePointStatus', 2)
            })
            .catch(err => {
                commit('setExchangePointStatus', 3)
            })
    },

    /**  招待情報取得 **/

    loadInviteInfo ({commit}) {
        commit('setInviteInfoLoadStatus', 1)

        InvitesAPI.loadInviteInfo()
            .then(resp => {
                commit('setInviteInfo',resp)
                commit('setInviteInfoLoadStatus', 2)
            })
            .catch(err => {
                commit('setInviteInfo', {})
                commit('setInviteInfoLoadStatus', 3)
            })
    },

    // shareInviteCode ({commit}, data) {
    //     commit('setShareInviteCodeStatus', 1)

    //     InvitesAPI.shareInviteCode(data)
    //         .then(resp => {
    //             commit('setAllErrors', {})
    //             commit('setShareInviteCodeStatus', 2)
    //         })
    //         .catch(err => { 
    //             commit('setAllErrors', err.response.data.errors)
    //             commit('setShareInviteCodeStatus', 3)
    //         })
    // },


}
