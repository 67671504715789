<template>
    <div class="mb-2">
        <span v-if="item.proj_price_pub == '0' && item.proj_price_pub_end != '0'" class="text-nowrap">
            <i class="fas fa-yen-sign"></i>
            <span class="icon-size" :class="{'text-danger-cust': item.proj_open_flg == '0'}">
                <span v-if="item.proj_price_pub_start == '0'">~ {{pubFormat(item.proj_price_pub_end)}}</span>
                <span v-else-if="item.proj_price_pub_start == item.proj_price_pub_end">{{pubFormat(item.proj_price_pub_end)}}</span>
                <span v-else>{{pubFormat(item.proj_price_pub_start)}} ~ {{pubFormat(item.proj_price_pub_end)}}</span>
            </span>
            <span class="text-muted">
                円<small><small>/月</small></small>
            </span>
        </span>
    </div>
</template>

<script>
    import {numCommaFormat} from 'pages/commons/form/comFunc'

    export default {
        props: {
            item: Object
        },
        methods: {
            pubFormat (val) {
                return numCommaFormat(val)
            }
        }
    }
</script>

<style lang="stylus" scoped>

</style>
