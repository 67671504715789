<template>
    <tech-list type="sales"></tech-list>
</template>

<script>
import TechList from 'pages/commons/techList'
 export default {
    components:{
       TechList
    }
 }

</script>

<style></style>
