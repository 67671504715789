<template>
    <div class="card border border-bottom mb-2">
        <a class="card-header pt-3 pb-1 px-2 text-dark text-decoration-none" role="tab" id="headingOne" data-toggle="collapse" :href="'#collapseOne' + proj.pj_id" aria-expanded="true" aria-controls="collapseOne"
            @click="toggleIcon(proj.pj_id)">
            <div> {{proj.pj_nm}} </div>
            <div class="text-right text-primary">
                {{proj.per_cnt}}人
                <i v-if="downIcon" class="fas fa-angle-down"></i>
                <i v-else class="fas fa-angle-up"></i>
            </div>
        </a>
        <div :id="'collapseOne' + proj.pj_id" class="collapse" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion-items">
            <ul class="list-group list-group-flush border-top">
                <li v-for="item in proj.list" :key="item.id" class="list-group-item px-2 pb-1 pt-3">

                    <!-- もっとを見る -->
                    <more-info :item="item"></more-info>
                    
                    <div class="text-muted">
                       <span>
                           <small> 名前: {{item.first_nm}} {{item.last_nm}} </small>
                       </span>
                    </div>
                    <div class="text-truncate  d-flex">
                       <span class="text-truncate mr-2">
                           {{item.name}}
                       </span>
                       <span class="ml-auto text-primary">
                            <small>
                                <i class="fas fa-external-link-alt"></i>
                                <a class="text-primary text-decoration-none" v-bind:href="item.resume" target="_blank">
                                    履歴書
                                </a>
                            </small>
                       </span>
                    </div>
                    <div class="text-muted">
                        <small>
                            最終面接：{{item.in_d_last | dformatw}} {{item.in_h_last}}:{{item.in_m_last}}
                        </small>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import MoreInfo from './More'
    export default {
        components: {
            MoreInfo
        },
        props: {
            proj: Object
        },
        data () {
            return {
                downIcon: true
            }
        },
        methods: {
            toggleIcon (id) {
                let list = '#collapseOne' + id
                if ($(list).hasClass('show')) {
                    this.downIcon = true
                } else {
                    this.downIcon = false
                }
            },
        }
    }
</script>

<style>
</style>
