<template>
    <nav class="navbar navbar-expand-md navbar-light bg-secondary">
        <div class="container">
            <a class="navbar-brand mr-auto mr-lg-0 text-white brand-font" href="#">
                Promeraia
            </a>
        </div>
    </nav>
</template>

<script>
    export default {

    }
</script>
