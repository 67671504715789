<template>
    <div v-if="getExchangeInit.data">
        <ex-input-header page-name="ポイント換金"></ex-input-header>
        <ex-content v-if="showIn" ref="money" :item="getExchangeInit.data"></ex-content>
        <ex-end v-else></ex-end>


        <!-- メッセージ -->
        <info-msg ref="infoMsg" :lev="lev" :msg="msg" @ok="exchangePoint"></info-msg>
    </div>
</template>

<script>
    import ExInputHeader from 'pages/commons/header/Header'
    import ExContent from './components/ExchangeContent'
    import ExEnd from './components/ExchangeEnd'
    import InfoMsg from 'pages/commons/modal/infoMsg/InfoMsg'

    import {mapGetters} from 'vuex'

    export default {
        components: {
            ExInputHeader,
            ExContent,
            ExEnd,
            InfoMsg
        },
        data () {
            return {
                lev: 0,
                msg : "",
                showIn: true
            }
        },
        mounted () {
            this.loadExchangeInit()
        },
        watch: {
            getExchangeInitLoadStatus (status) {
                if (status == 1) {
                    this.$store.dispatch('startProgress')
                }
                if (status == 2) {
                    this.$store.dispatch('doneProgress')
                    if (this.getExchangeInit.data.is_exchanged) {
                        this.showIn = false
                    }
                }
                if (status == 3) {
                    this.$store.dispatch('doneProgress')
                }
            },
            getExchangePointStatus (status) {
                if (status == 1) {
                    this.$store.dispatch('startSpinner')
                }
                if (status == 2) {
                    this.$store.dispatch('doneSpinner')
                    this.showIn = false
                }
                if (status == 3) {
                    this.$store.dispatch('doneSpinner')
                }
            }
        },
        computed: {
                ...mapGetters(['getExchangeInit'
                    ,'getExchangeInitLoadStatus'
                    ,'getExchangePointStatus'
                ])
        },
        methods: {
            loadExchangeInit () {
                this.$store.dispatch('loadExchangeInit')
            },
            openInfoMsg (lev, msg) {
                this.lev = lev
                this.msg = msg
                this.$refs.infoMsg.openThis()
            },
            exchangePoint () {
                this.$refs.money.exchangePoint()
            }
        }
    }

</script>

<style lang="stylus" scoped>

</style>
