<template>
    <div id="modal-qr-share" class="modal fade" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content py-3">
                <div class="modal-body">

                    <!-- エラー -->
                    <err-msg-bar :name="['mail']" :local-errs="errs" class="mb-2"></err-msg-bar>

                    <input v-model.trim="mail" type="text" class="form-control" maxlength="100" spellcheck=false placeholder="メールアドレス"> </input>
                </div>
                <div class="modal-footer">
                    <div class="container">
                        <div class="row">
                            <div class="col-6">
                                <button class="btn btn-danger btn-block m-0" type="button" @click="shareQrUrlMail">
                                    送信
                                </button>
                            </div>
                            <div class="col-6">
                                <button class="btn btn-primary btn-block m-0" type="button" @click="closeThis">
                                    閉じる
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {required, maxLength, email} from "vuelidate/lib/validators"
    import ErrMsgBar from 'pages/commons/err/ErrMsgBar'
    import {mapGetters} from 'vuex'

    export default {
        data () {
            return {
                mail: "",
                errs: {},
                role: ""
            }
        },
        components: {
            ErrMsgBar
        },
        watch: {
            getShareQrUrlMailStatus (status) {
                if (status == 1) {
                    this.$store.dispatch('startSpinner')
                }
                if (status == 2) {
                    this.$store.dispatch('doneSpinner')
                    this.openMsg()
                    this.closeThis()
                }
                if (status == 3) {
                    this.$store.dispatch('doneSpinner')
                }
            }
        },
        computed: {
            ...mapGetters(['getShareQrUrlMailStatus'])
        },
        methods: {
            openMsg () {
                if (this.role == 'company') {
                    this.$parent.openMsg('招待メールを送信しました。'
                        ,'※有効期限は24時間です。', 2)
                } else {
                    this.$parent.openMsg('招待メールを送信しました。')
                }
            },
            shareQrUrlMail () {
                if (this.dataCheck()) {
                    this.$store.dispatch('shareQrUrlMail',{
                        mail: this.mail,
                        role: this.role 
                    })
                }
            },
            doCheck () {
                this.$v.mail.$touch()
                return !this.$v.$invalid
            },
            dataCheck () {
                if (this.doCheck()) {
                    this.clearErrs()
                    return true
                } else {
                    this.showErrs()
                    return false
                }
            },
            showErrs () {
                this.clearErrs()
                let arr = []

                if (!this.$v.mail.required) { arr.push("メールアドレスを入力してください。") }  
                if (!this.$v.mail.email) { arr.push("正しいメールアドレスを入力してください。") }  
                if (!this.$v.mail.maxLength) { arr.push("100文字以内を入力してください。") }  
                if (arr.length > 0) {this.errs.mail = arr}
            },
            clearErrs () {
                this.errs = {}
            },
            openThis (role='tech') {
                this.role = role
                $('#modal-qr-share').modal('show')
            },
            closeThis () {
                this.resetThis()
                $('#modal-qr-share').modal('hide')
            },
            resetThis () {
                this.mail = ""
                this.errs = {}
                this.role = ""
            }
        },
        validations: {
            mail: {
                required,
                maxLength: maxLength(100),
                email
            }
        }
    }
</script>

<style lang="stylus">

</style>
