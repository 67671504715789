<template>
    <div class="container">
        <div class="row mb-4 d-flex justify-content-center">
            <div class="col-sm-4 col-md-5 col-lg-4">
                <button type="button" class="btn btn-success btn-block" @click="openMailShare">
                    <i class="far fa-paper-plane"></i>&nbsp;
                    メールで友達にシェアする
                </button>
            </div>
        </div>
        <div class="row d-flex justify-content-center mb-4">
            <div class="col-sm-4 col-md-5 col-lg-4">
                <button type="button" class="btn btn-primary btn-block" 
                    v-clipboard:copy="getInviteInfo.invite_url"
                    v-clipboard:success="copySuccess"
                    v-clipboard:error="copyError"
                >
                    <i class="far fa-copy"></i>&nbsp;
                    QRコードリンクをコピーする
                </button>
            </div>
        </div>

        <!-- メールシェア画面 -->
        <mail-share ref="share"></mail-share>

        <modal-info-msg ref="msg" :msg="msg" :lev="lev"></modal-info-msg>


    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import MailShare from 'pages/commons/modal/inviteMailShare/inviteMailShare'
    import ModalInfoMsg from 'pages/commons/modal/infoMsg/InfoMsg'

    export default {
        data () {
            return {
                msg: "",
                lev: 0
            }
        },
        components: {
            MailShare,
            ModalInfoMsg
        },
        computed: {
            ...mapGetters(['getInviteInfo'])
        },
        methods: {
            openMailShare () {
                this.$refs.share.openThis()
            },
            copySuccess () {
                this.openMsg("QRコードリンクをクリップボードへコピーしました。")
            },
            copyError () {
                this.openMsg("QRコードリンクのコピーに失敗しました。再度試してください。", 1)
            },
            openMsg (msg, lev=0) {
                this.msg = msg
                this.lev = lev
                this.$refs.msg.openThis()
            }
        }
    }
</script>

<style lang="stylus" scoped>

</style>
