<template>
    <proj-create></proj-create>
</template>

<script>
import ProjCreate from 'pages/commons/projCreate'
export default {
    components:{
        ProjCreate
    }
}
</script>

<style></style>
