import MyPageAPI from 'js/api/myPage'

export default {
   // initHome ({commit}) {
   //     commit('setInitHomeLoadStatus', 1)

   //     MyPageAPI.initHome()
   //         .then(resp => {
   //             commit('setInitHome', resp)
   //             commit('setInitHomeLoadStatus', 2)
   //         })
   //         .catch(err => {
   //             commit('setInitHome', {})
   //             commit('setInitHomeLoadStatus', 3)
   //         })
   // },

    savePrivateInfo ({commit}, data) {
        commit('resetPrivateOperat')
        commit('setSavePrivateInfoStatus', 1)

        MyPageAPI.savePrivateInfo(data)
            .then(resp => {
                commit('setAllErrors', {})
                commit('setPrivateOperat', 'success')
                commit('setPrivateInfo', resp)
                commit('setSavePrivateInfoStatus', 2)
            })
            .catch(err => {
                commit('setPrivateOperat', data.operat)
                commit('setAllErrors', err.response.data.errors)
                commit('setSavePrivateInfoStatus', 3)
            })
    },

    loadPrivateInfo ({commit}, data) {
        commit('setPrivateInfoLoadStatus', 1)

        MyPageAPI.loadPrivateInfo(data)
            .then(resp => {
                commit('setPrivateInfo', resp)
                commit('setPrivateInfoLoadStatus', 2)
            })
            .catch(err => {
                commit('setPrivateInfo', {})
                commit('setPrivateInfoLoadStatus', 3)
            })
    },

    saveResumeInfo ({commit}, data) {
        commit('resetResumeOperat')
        commit('setSaveResumeInfoStatus', 1)

        MyPageAPI.saveResumeInfo(data)
            .then(resp => {
                commit('setAllErrors', {})
                commit('setResumeOperat', 'success')
                commit('setResumeInfo', resp)
                commit('setSaveResumeInfoStatus', 2)
            })
            .catch(err => {
                commit('setResumeOperat', data.get('operat'))
                commit('setAllErrors', err.response.data.errors)
                commit('setSaveResumeInfoStatus', 3)
            })
    },

    loadResumeInfo ({commit}, data) {
        commit('setResumeInfoLoadStatus', 1)

        MyPageAPI.loadResumeInfo(data)
            .then(resp => {
                commit('setResumeInfo', resp)
                commit('setResumeInfoLoadStatus', 2)
            })
            .catch(err => {
                commit('setResumeInfo', {})
                commit('setResumeInfoLoadStatus', 3)
            })
    },

    loadIdentityInfo ({commit}) {
        commit('setIdentityInfoLoadStatus', 1)

        MyPageAPI.loadIdentityInfo()
            .then(resp => {
                commit('setIdentityInfo', resp)
                commit('setIdentityInfoLoadStatus', 2)
            })
            .catch(err => {
                commit('setIdentityInfo', {})
                commit('setIdentityInfoLoadStatus', 3)
            })
    },

    resetIdentityInfo ({commit}) {
        commit('resetIdentityInfo')
    },

    sendIdentityMail ({commit}) {
        commit('setSendIdentityMailStatus', 1)

        MyPageAPI.sendIdentityMail()
            .then(resp => {
                commit('setSendIdentityMailStatus', 2)
            })
            .catch(err => {
                commit('setSendIdentityMailStatus', 3)
            })
    },

    saveBankInfo ({commit}, data) {
        commit('resetBankOperat')
        commit('setSaveBankInfoStatus', 1)

        MyPageAPI.saveBankInfo(data)
            .then(resp => {
                commit('setAllErrors', {})
                commit('setBankOperat', 'success')
                commit('setBankInfo', resp)
                commit('setSaveBankInfoStatus', 2)
            })
            .catch(err => {
                commit('setBankOperat', data.operat)
                commit('setAllErrors', err.response.data.errors)
                commit('setSaveBankInfoStatus', 3)
            })
    },

    loadBankInfo ({commit}, data) {
        commit('setBankInfoLoadStatus', 1)

        MyPageAPI.loadBankInfo(data)
            .then(resp => {
                commit('setBankInfo', resp)
                commit('setBankInfoLoadStatus', 2)
            })
            .catch(err => {
                commit('setBankInfo', {})
                commit('setBankInfoLoadStatus', 3)
            })
    },


    agreeIntvPlan ({commit}, data) {
        commit('setAgreeIntvPlanStatus', 1)

        MyPageAPI.agreeIntvPlan(data)
            .then(resp => {
                commit('setAgreeIntvPlan', resp)
                commit('setAgreeIntvPlanStatus', 2)
            })
            .catch(err => {
                commit('setAgreeIntvPlan', {})
                commit('setAgreeIntvPlanStatus', 3)
            })
    },

    loadApplyHisDetail ({commit}, data) {
        commit('setApplyHisDetailLoadStatus', 1)

        MyPageAPI.loadApplyHisDetail(data)
            .then(resp => {
                commit('setApplyHisDetail', resp)
                commit('setApplyHisDetailLoadStatus', 2)
            })
            .catch(err => {
                commit('setApplyHisDetail', {})
                commit('setApplyHisDetailLoadStatus', 3)
            })
    },

    resetApplyHisDetail ({commit}) {
        commit('resetApplyHisDetail')
    },

    deleteFavorite ({commit}, data) {
        commit('setDeleteFavoriteStatus', 1)

        MyPageAPI.deleteFavorite(data)
            .then(resp => {
                commit('setDeleteFavoriteStatus', 2)
            })
            .catch(err => {
                commit('setDeleteFavoriteStatus', 3)
            })
    },
}
