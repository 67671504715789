<template>
    <nav class="navbar navbar-expand-lg navbar-light fixed-top">
        <div class="container">
            <a class="navbar-brand mr-auto mr-lg-0 text-white brand-font nav-font" href="/login">
                Promeraia
            </a>
            <button class="navbar-toggler p-0 border-0 nav-btn" type="button" data-toggle="collapse" data-target="#navbar-menu"
                    aria-controls="navbar-menu" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div v-if="getUserLoadStatus == 2" class="collapse navbar-collapse" id="navbar-menu">
                <ul class="navbar-nav ml-auto justify-content-between font-weight-normal">
                    <template v-if="getRole == 'organization'">
                        <li class="nav-item px-2 head-link"> <a class="nav-link text-dark" href="/c/projs/home"> <i class="fas fa-code"></i> <span> 案件 </span> </a> </li>
                        <li class="nav-item px-2 head-link"> <a class="nav-link text-dark" href="/admin/apply"> <i class="fas fa-comment-alt"></i> <span> 応募 </span> </a> </li>
                        <li class="nav-item px-2 head-link"> <a class="nav-link text-dark" href="/c/invites"> <i class="fas fa-glass-cheers"></i> <span> 招待 </span> </a> </li>

                        <!-- <li class="nav-item px-2"><a class="nav-link text-dark" id="homeUrl" href="/c/projs/home"><i class="fas fa-scroll"></i>&nbsp;案件</a></li> -->
                        <!-- <li class="nav-item px-2"><a class="nav-link text-dark" href="/admin/apply"><i class="fas fa-comment-alt"></i>&nbsp;応募</a></li> -->
                        <!-- <li class="nav-item px-2"><a class="nav-link text-dark" href="/c/invites"><i class="fas fa-leaf"></i>&nbsp;招待</a></li> -->
                    </template>
                    <template v-if="getRole == 'person'">
                        <li class="nav-item px-2 head-link"> <a class="nav-link text-dark" href="/admin/projs"> <i class="fas fa-code"></i> <span> 案件 </span> </a> </li>
                        <li class="nav-item px-2 head-link"> <a class="nav-link text-dark" href="/admin/my"> <i class="fas fa-address-card"></i> <span>マイページ</span> </a> </li>
                        <li class="nav-item px-2 head-link"> <a class="nav-link text-dark" href="/admin/invites"> <i class="fas fa-glass-cheers"></i> <span>招待</span> </a> </li>
                        <li v-if="getUser.cmpGroup" class="nav-item px-2 head-link"> <a class="nav-link text-dark" href="/admin/service"> <i class="fas fa-ribbon"></i> <span>貢献</span> </a> </li>
                        <li class="nav-item px-2 head-link"> <a class="nav-link text-dark" href="/admin/partners"> <i class="fas fa-question-circle"></i> <span>お問合せ</span> </a> </li>

                        <!-- <li class="nav-item px-2"><a class="nav-link text-dark" href="/admin/my"><i class="fas fa-id-card-alt"></i>&nbsp;マイページ</a></li> -->
                        <!-- <li class="nav-item px-2"><a class="nav-link text-dark" href="/admin/invites"><i class="fas fa-leaf"></i>&nbsp;招待</a></li> -->
                        <!-- <li class="nav-item px-2"><a class="nav-link text-dark" href="/admin/partners"><i class="fas fa-question-circle"></i>&nbsp;お問い合わせ</a></li> -->
                    </template>
                </ul>

                <ul class="navbar-nav font-weight-normal">
                    <li class="nav-item" v-if="!getUser"><a class="nav-link text-white" href="/login"><i class="fas fa-sign-in-alt"></i>&nbsp;Login</a></li>
                    <li class="nav-item dropdown head-link-name" v-else>
                        <a href="#" class="nav-link dropdown-toggle text-dark" data-toggle="dropdown" role="button" aria-expanded="false">
                            <i class="fas fa-user-circle"></i>
                            <span> {{this.getUser.name}} </span>
                            <span class="caret"></span>
                        </a>
                        <div class="dropdown-menu com-card" role="menu">
                            <a class="dropdown-item" href="/logout">
                                <i class="fas fa-door-open"></i>   ログアウト</a>
                        </div>
                    </li>
                </ul>
            </div>
            <div v-else class="collapse navbar-collapse" id="navbar-menu">
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item"><a class="btn btn-outline-dark btn-nav-login" href="/login">Login</a></li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
    import {mapGetters} from 'vuex'

    import {EventBus} from 'js/event-bus'

    export default {
        mounted () {
            EventBus.$on('routerToHome', () => {
                location.href = $('#homeUrl').attr('href')
            })
        },
        computed: {
            ...mapGetters(['getUser']),
            ...mapGetters(['getRole']),
            ...mapGetters(['getUserLoadStatus'])
        }
    }
</script>

<style lang="stylus" scoped>
.nav-btn:focus
    outline:none!important
.navbar
    background-color: rgba(255, 255, 255, .8) !important
    font-weight: 700
.nav-font
    font-size:1.7rem!important
    color:#343a40 !important
    transition:0.5s
.nav-font:hover
    font-size:1.8rem!important
    color:#3490dc!important
    transition:0.5s
.btn-nav-login
    color:black
.btn-nav-login:focus
    box-shadow:none!important
.btn-nav-login:hover
    background-color:#00a5f5!important
    color:white!important
    border-color:white!important
    transition:0.5s

@media (min-width: 992px)
    .head-link
        height:54px
        min-width:100px
        display:flex
        justify-content:center
        align-items:center
    .head-link a
        display:flex
        flex-direction: column
        text-align: center
        padding-top:0
        padding-bottom:0
        transition:0.2s
        i
            font-size:1.7rem
            transition:0.2s
        span
            height:0
            opacity:0
            transition:0.2s
    .head-link:hover a
        i
            font-size:1.2rem
            color:#3490dc
            transition:0.2s
        span
            height:100%
            opacity:1
            transition:0.2s
    .head-link-name
        height:54px
        display:flex
        align-items:center
        padding-left:35px
    .head-link-name a
        padding-top:0
        padding-bottom:0
        i
            vertical-align:middle
            font-size:1.7rem
        span
            vertical-align:middle
    .head-link-name:hover
        .fa-user-circle
            color:#3490dc
</style>
