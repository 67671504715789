<template>
    <div v-if="getPoint">
        <invite-home-header page-name="招待" :istop="true"> </invite-home-header>

        <invite-home-point></invite-home-point>

        <invite-home-list :item="getPoint"></invite-home-list>

        <info-msg ref="infoMsg" :lev="lev" :msg="msg" @ok="toExchangeInput"></info-msg>

        <!-- ポイント獲得 -->
        <get-point ref="getPoint"></get-point>

        <!-- ポイント交換注意事項 -->
        <exchange-warn ref="exchangeWarn" :item="getPoint"></exchange-warn>
    </div>
</template>

<script>
    import InviteHomeHeader from 'pages/commons/header/Header'
    import InviteHomePoint from './components/Point'
    import InviteHomeList from './components/List'
    import InfoMsg from 'pages/commons/modal/infoMsg/InfoMsg'
    import ExchangeWarn from './components/ExchangeWarning'
    import GetPoint from './components/GetPoint'

    import {mapGetters} from 'vuex'

    export default {
        data () {
            return {
                lev: 0,
                msg : ""
            }
        },
        created () {
            this.loadPoint()
        },
        mounted () {
            this.$store.dispatch('resetListObj')
        },
        components: {
            InviteHomeHeader,
            InviteHomePoint,
            InviteHomeList,
            InfoMsg,
            ExchangeWarn,
            GetPoint
        },
        watch: {
            getPointLoadStatus (status) {
                if (status == 1) {
                    this.$store.dispatch('startProgress')
                }
                if (status == 2) {
                    this.$store.dispatch('doneProgress')
                }
                if (status == 3) {
                    this.$store.dispatch('doneProgress')
                }
            }
        },
        computed: {
            ...mapGetters(['getPointLoadStatus', 'getPoint'])
        },
        methods: {
            loadPoint () {
                this.$store.dispatch('loadPoint')
            },
            openInfoMsg (lev, msg) {
                this.lev = lev
                this.msg = msg
                this.$refs.infoMsg.openThis()
            },
            openGetPoint () {
                this.$refs.getPoint.openThis()
            },
            openExchangeWarning () {
                this.$refs.exchangeWarn.openThis()
            },
            toExchangeInput () {
                this.$router.push({name: 'ExchangeInput'})
                //this.$store.dispatch('exchangePoint')
            }
        }
    }
</script>

<style></style>
