<template>
    <div class="card-body pt-2 pt-lg-3 pt-md-3" v-if="item">

        <form-item v-if="item.proj_open_flg == '0'" lab="求人状況" icn="far fa-comment icon-size" class="text-success">&nbsp;募集中</form-item>
        <form-item v-else lab="求人状況" icn="far fa-comment icon-size" class="text-danger-cust">&nbsp;募集終了</form-item>

        <form-item lab="案件名" icn="fas fa-laptop-code icon-size">{{item.proj_nm}}</form-item>
        <form-item v-if="item.proj_language" lab="開発言語" icn="fas fa-code-branch icon-size">{{item.proj_language}}</form-item>
        <form-item v-if="item.proj_position" lab="ポジション" icn="fas fa-sitemap icon-size">{{converVal('proj_position',item.proj_position)}}</form-item>
        <form-item lab="開始時期" icn="fas fa-calendar-alt icon-size">{{converVal('proj_start_date',item.proj_start_date)}}</form-item>
        <form-item v-if="item.proj_interview"
                   lab="面談回数" icn="fas fa-user-tie icon-size">{{converVal('proj_intv', item.proj_interview)}}</form-item>

        <template v-if="pub">
            <div v-if="item.proj_price_pub == '0' && item.proj_price_pub_end != '0'">
                <form-item v-if="item.proj_price_pub_start == '0'"
                       lab="月額単価" icn="fas fa-yen-sign icon-size" mb="2">~ {{pubFormat(item.proj_price_pub_end)}}&nbsp;円</form-item>
                <form-item v-else-if="item.proj_price_pub_start == item.proj_price_pub_end"
                       lab="月額単価" icn="fas fa-yen-sign icon-size" mb="2">{{pubFormat(item.proj_price_pub_end)}}&nbsp;円</form-item>
                <form-item v-else 
                       lab="月額単価" icn="fas fa-yen-sign icon-size" mb="2">{{pubFormat(item.proj_price_pub_start)}} ~ {{pubFormat(item.proj_price_pub_end)}}&nbsp;円</form-item>
                <!-- <form-input ip-type="comment" py0> -->
                <!--     <i class="fas fa-info-circle"></i> -->
                <!--     消費税を含めた参画者にお渡しする金額です、現在所属会社経由の場合除外。 -->
                <!-- </form-input> -->
                <!-- <hr> -->
            </div>
        </template>
        <template v-else>
            <form-item v-if="item.proj_price_set == '1'" 
                       lab="月額単価">スキル見合い</form-item>
            <form-item v-else-if="item.proj_price_start == '0'" 
                       lab="月額単価" icn="fas fa-yen-sign icon-size" mb="2">~ {{converVal('proj_price_end', item.proj_price_end)}}</form-item>
            <form-item v-else-if="item.proj_price_start == item.proj_price_end" 
                       lab="月額単価" icn="fas fa-yen-sign icon-size" mb="2">{{converVal('proj_price_end', item.proj_price_end)}}</form-item>
            <form-item v-else 
                       lab="月額単価" icn="fas fa-yen-sign icon-size" mb="2">{{converVal('proj_price_start', item.proj_price_start)}} ~ {{converVal('proj_price_end', item.proj_price_end)}} </form-item>

            <form-input ip-type="comment" py0>
                <i class="fas fa-info-circle"></i>
                    消費税を含めない弊社(Promeraia)にお渡しする金額です。
            </form-input>
            <hr>
        </template>


        <form-item v-if="item.proj_is_adjust == '0'" 
                   lab="精算条件" icn="fas fa-calculator icon-size"
                   >あり&nbsp;({{item.proj_time_start}} ~ {{item.proj_time_end}}&nbsp;時間)</form-item>
        <form-item v-else-if="item.proj_is_adjust == '1'" lab="精算条件" icn="fas fa-calculator icon-size">固定</form-item>
        <form-item v-else-if="item.proj_is_adjust == '2'" lab="精算条件" icn="fas fa-calculator icon-size">あり(精算幅確認中)</form-item>

        <form-item v-if="item.proj_remote_ok" 
                    lab="リモートワーク" icn="fas fa-house-user icon-size">{{converVal('proj_remote_ok', item.proj_remote_ok)}}</form-item>

        <form-item v-if="item.proj_place" lab="最寄り駅" icn="fas fa-map-marker icon-size">{{item.proj_place}}</form-item>

        <form-item v-if="item.proj_members" 
                   lab="募集人数" icn="fas fa-user icon-size"
                   >{{converVal('proj_members', item.proj_members)}}</form-item>

        <form-item v-if="item.proj_min_exper" 
                   lab="経験年数" icn="far fa-lightbulb icon-size"
                   >{{converVal('exper', item.proj_min_exper)}}</form-item>

        <form-item v-if="item.proj_start_time" 
                   lab="始業時間" icn="fas fa-clock icon-size"
                   >{{converVal('proj_start_time', item.proj_start_time)}}</form-item>

        <form-item v-if="item.proj_foreign_ok" 
                    lab="外国籍" icn="fas fa-globe icon-size">{{converVal('proj_foreign_ok', item.proj_foreign_ok)}}</form-item>

        <form-item lab="案件概要" icn="fas fa-quote-left icon-size">{{item.proj_detail}}</form-item>
        <form-item lab="必須スキル" icn="fas fa-code icon-size">{{item.proj_skill_need}}</form-item>
        <form-item v-if="item.proj_skill_option" 
                   lab="歓迎スキル" icn="fas fa-book-open icon-size">{{item.proj_skill_option}}</form-item>
        <form-item v-if="item.proj_note" 
                   lab="備考" icn="fas fa-pen-alt icon-size">{{item.proj_note}}</form-item>
    </div>
</template>

<script>
    import FormItem from 'pages/commons/form/Confirm'
    import FormInput from 'pages/commons/form/Input'
    import {converVal} from 'pages/commons/form/selectList'
    import {numCommaFormat} from 'pages/commons/form/comFunc'
    import {mapGetters} from 'vuex'

    export default {
        components: {
            FormItem,
            FormInput
        },
        props: {
            // 公開単価表示
            pub: {
                type: Boolean,
                default: true
            },
            item: Object
        },
        methods: {
            converVal (name, val) {
                return converVal(name,val)
            },
            pubFormat (val) {
                return numCommaFormat(val)
            }
        }
    }
</script>

<style>
</style>
