<template>
    <div>
        <input-form v-show="showForm" ref="userForm" :opt="operat"></input-form>
        <input-confirm v-show="!showForm" ref="userConfirm" :form="reqForm" :opt="operat"></input-confirm>
        <success-msg ref="success" :lev="lev" :msg="msg"></success-msg>
    </div>
</template>

<script>
    import {isWarningProjFinishDate
           ,isOverProjFinishDate
           ,isOverProjStartDate} from 'pages/commons/form/comFunc'
    import InputForm from './components/Form'
    import InputConfirm from './components/Confirm'
    import SuccessMsg from 'pages/commons/modal/infoMsg/InfoMsg'
    import {getList, converVal} from 'pages/commons/form/selectList'
    import {mapGetters} from 'vuex'

    export default {
        components: {
            InputForm,
            InputConfirm,
            SuccessMsg
        },
        data () {
            return {
                msg: "",
                lev: 0,
                operat: this.initOperat(),
                showForm: this.initShow(),
                // DB->詳細画面、入力画面->詳細画面へデータ反映用
                reqForm: {},
                reqFormBk: {},//show->edit->confirm->show時、showのデータ表示が上書きしないよう保持する
            }
        },
        created () {
            // リフレッシュ後listObjがなくなる為、
            // sessionに保存しておく
            this.$store.dispatch('setListObjSession')
        },
        mounted() {
            this.loadComProjInfo()
        },
        destroyed () {
            // confirm画面の初期表示制御
            this.$store.dispatch('resetComProjInfo')
        },
        watch: {
            // サーバーエラーで画面制御
            getComProjOperat (operat) {
                this.changeOperat(operat, "success")
            },
            getComProjInfo (info) {
                // 詳細画面のデータを(DB/inputPage)と同期する
                if (info.data.proj) {
                    this.reqForm = info.data.proj
                    this.reqFormBk = info.data.proj
                }
            },
            getComProjInfoLoadStatus (status) {
                if (status == '1') {
                    this.$store.dispatch('startProgress')
                }
                if (status == '2') {
                    this.$store.dispatch('doneProgress')
                }
                if (status == '3') {
                    this.$store.dispatch('doneProgress')
                }
            },
            getSaveComProjInfoStatus (status) {
                if (status == '1') {
                    this.$store.dispatch('startSpinner')
                }
                if (status == '2') {
                    // 新規・更新した案件を一覧リストに反映
                    this.$store.dispatch('reflectList', this.getComProjInfo.data.proj)
                    // opne_flg変更したら一覧から削除
                    //if (this.getComProjInfo.data.proj.open_flg_changed) {
                    //    this.$store.dispatch('delItemFromList', {
                    //        listItem: 'id',
                    //        pageId: this.getComProjInfo.data.proj.id
                    //    })
                    //}
                    this.$store.dispatch('doneSpinner')
                    this.openMsg()
                }
                if (status == '3') {
                    this.$store.dispatch('doneSpinner')
                }
            }
        },
        computed: {
            ...mapGetters(['getSaveComProjInfoStatus', 'getComProjOperat', 'getComProjInfoLoadStatus', 'getComProjInfo'])
        },
        methods: {
            loadComProjInfo () {
                if (this.$route.params.id) {
                    this.$store.dispatch('loadComProjInfo', {
                        id: this.$route.params.id
                    })
                }
            },
            doSubmit () {
                this.reqForm['operat'] = this.operat

                if (this.operat == 'edit_confirm' 
                    || this.operat == 'create_confirm') {
                    this.$store.dispatch('saveComProjInfo', this.reqForm)
                }
            },
            getChildData () {
                let userForm = this.$refs.userForm.getData()
                this.reqForm = Object.assign({}, this.reqForm, userForm)
            },
            openMsg (msg="完了しました。", lev=0) {
                this.msg = msg
                this.lev = lev
                this.$refs.success.openThis()
            },
            initOperat () {
                return this.$route.params.id ? 'show' : 'create'
            },
            initShow () {
                return this.$route.params.id ? false : true
            },
            changeOperat (opt, res="") {
                if (opt == 'auto') {
                    this.operat = (this.operat == 'create') ? 'create_confirm' : 'edit_confirm'
                } else {
                    this.operat = opt 
                }
                if (this.operat == 'create' || this.operat == 'edit') {
                    this.showForm = true
                }
                if (this.operat == 'create_confirm' || this.operat == 'edit_confirm' || this.operat == 'show') {
                    this.showForm = false

                    // showのデータを上書きされないよう復旧する
                    // save後、最新のデータ(reqForm)で表示する
                    if (this.operat == 'show' && res !='success') {
                        this.reqForm = this.reqFormBk
                    }
                }
                // スクロールをトップへ移動
                document.documentElement.scrollTop = 0
            },
            getList (name) {
                return getList(name)
            },
            converVal (name, val) {
                return converVal(name,val)
            },
            isWarnFinish (val) {
                return isWarningProjFinishDate(val)
            },
            isOverFinish (val) {
                return isOverProjFinishDate(val)
            },
            isOverStart (val) {
                return isOverProjStartDate(val)
            }
        }
    }
</script>

<style></style>
