<template>
    <div>
        <nav-bar></nav-bar>

        <div class="container py-4">

            <div class="text-center my-4 pb-4"><span class="terms-title">プライバシーポリシー</span></div>
            <dl>
                <dt>個人情報の管理</dt>
                <p>個人情報を取り扱うにあたっては、「個人情報の保護に関する法律」をはじめとする個人情報の保護に関する法令、ガイドラインおよび本プライバシーポリシーを遵守いたします。</p>
                <dt>個人情報の利用目的</dt>
                <p>本サービス（以下、「当サイト」とします）では、ご本人様から直接取得した個人情報、または間接的に取得するもの、又は書面以外で取得したご本人様の個人情報について、次の利用目的の範囲内で取り扱わせていただきます。</p>
                <dd>
                    <ol>
                        <li>
ご意見、ご要望、お問い合わせなどに対応するため
                        </li>
                        <li>
本人確認または本人確認のための認証サービスを利用するため
                        </li>
                        <li>
当社サービスのアンケート、キャンペーン、イベントなどを実施するため
                        </li>
                        <li>
当社サービスの利用状況、実施施策などマーケティング調査、分析のため
                        </li>
                        <li>
当社サービスの品質改善、サービス向上のための調査、分析のため
                        </li>
                        <li>
当社の新サービス、新機能を企画するための調査、分析のため
                        </li>
                        <li>
当社サービスやネットワーク等のシステム障害、不具合、事故発生時の調査、対応のため
                        </li>
                        <li>
その他、当社サービスを円滑に運営するため
                        </li>
                    </ol>
                </dd>
                <dt>個人情報の第三者への開示</dt>
                <p>
                    当サイトでは、個人情報は適切に管理し、以下に該当する場合を除いて第三者に開示することはありません。
                </p>
                <dd>
                    <ol>
                        <li>本人のご了解がある場合</li>
                        <li>法令等への協力のため、開示が必要となる場合</li>
                    </ol>
                </dd>
                <dt>個人情報の開示、訂正、追加、削除、利用停止</dt>
                <p>
ご本人からの個人データの開示、訂正、追加、削除、利用停止のご希望の場合には、当社が当該請求に応じる必要があると判断した場合は、本人確認を行った上で、当該個人情報の削除を行い、その旨を本人に通知します。
                </p>
                <dt>Cookie（クッキー）</dt>
                <p>
当社のWebサイトでは、サービスの提供、利用者の利便性の向上、利用状況の把握を目的として、WebブラウザのCookieを利用することがあります。Webブラウザの設定によりCookieの機能を無効とした場合、サービスの全部又は一部が利用できなくなることがあります。
                </p>
                <dt>プライバシーポリシーの変更について</dt>
                <p>
当サイトは、個人情報に関して適用される日本の法令を遵守するとともに、本ポリシーの内容を適宜見直しその改善に努めます。

修正された最新のプライバシーポリシーは常に本ページにて開示されます。
                </p>
                <dd class="pt-3">最終改訂日：2022年3月10日</dd>
            </dl>

        </div>
    </div>

    </div>
</template>

<script>
    import NavBar from 'pages/commons/nav/NavGuest'

    export default {
        components: {
            NavBar
        }
    }
</script>

<style lang="stylus" scoped>
    .terms-title
        font-size:2rem;
        border-bottom: 0.6rem solid #18a18e;
    dt
        padding-top: 1.8rem;
        padding-bottom: 0.5rem;
    li
        padding-top: 0.5rem;
</style>
