export default {

    /** 共通エラーメッセージ **/
    allErrors: {},

    /** 共通リスト**/
    listObj: {},
    listObjKeep: {},
    // 0:waiting 1:loading 2:success 3:failed
    listObjLoadStatus: 0,
    // 0:waiting 1:loading 2:success 3:failed
    listObjSeeMoreLoadStatus: 0,
    // 0:waiting 1:loading 2:success 3:failed
    listItemDeleteStatus: 0,


    // user
    user: {},
    // 0:waiting 1:loading 2:success 3:failed
    userLoadStatus: 0,

    // 画面リフレッシュ
    refreshArr: {
        // 応募中案件(共通)
        comActProjs: false,
        // 応募中案件(共通)
        comActTech: false,
        // 応募状況一覧(会社)
        cmpApplyPj: false,
        // 応募情報一覧(会社)
        cmpApplys: false,
        // 入場予定一覧
        enterPlan: false,
        // 連絡先(面接・入場)
        contactList: false,
        // 場所(面接・入場)
        placeList: false,
        // 招待済み友達
        inviteStatus: false,
        // 案件一覧
        projsAll: false,
        // 応募中案件(会社)
        cmpActProjs: false,
        // 応募履歴
        applyHis: false,
        // お気に入り
        favorites: false,
    },

    /** 郵便住所 **/
    addressInfo:{},
    zipcodeErrors: {},
    // 0:waiting 1:loading 2:success 3:failed
    addressInfoLoadStatus: 0,

    /** 案件情報シエア詳細取得 **/
    shareInfoDetail:{},
    // 0:waiting 1:loading 2:success 3:failed
    shareInfoDetailLoadStatus: 0,

    /** uniqueチェック **/
    uniqueCheckErr:{},
    uniqueCheckStatus: 0,

    /** 応募詳細メッセージ  **/
    addApplyHisMsg: {},
    addApplyHisMsgStatus: 0,

    /** 案件情報 **/
    comProjInfo: {},
    // create/create_confirm/show/edit/edit_confirm
    comProjOperat: "",
    // 0:waiting 1:loading 2:success 3:failed
    saveComProjInfoStatus: 0,
    // 0:waiting 1:loading 2:success 3:failed
    comProjInfoLoadStatus: 0,

    /** 案件・要員共通 **/
    // 0:waiting 1:loading 2:success 3:failed
    comMenuQuickOffStatus: 0,

    /** 要員情報 **/
    comTechInfo: {},
    // create/create_confirm/show/edit/edit_confirm
    comTechOperat: "",
    // 0:waiting 1:loading 2:success 3:failed
    saveComTechInfoStatus: 0,
    // 0:waiting 1:loading 2:success 3:failed
    comTechInfoLoadStatus: 0,

    /** 営業中要員・案件リスト取得 **/
    actSalesInfo: {},
    // 0:waiting 1:loading 2:success 3:failed
    actSalesInfoStatus: 0,

    /** 個人・法人提案共通**/
    suggestionOperat: "",
    // 0:waiting 1:loading 2:success 3:failed
    suggestionStatus: 0,

}
