<template>
    <li class="list-group-item">

        <!-- エラーメッセージ -->
        <err-msg-bar :name="['contact']" :local-errs="errs"></err-msg-bar>
        <err-msg-bar :name="['inputName', 'inputPhone']" :local-errs="errs"></err-msg-bar>

        <div class="custom-control custom-switch p-0">
            <div class="form-group row m-0">
                <label for="contact" class="col-3 col-form-label py-2 px-0">
                    <small>
                        連絡先
                    </small>
                    <div v-if="getContactCnt != 0" class="custom-control custom-switch mt-2">
                        <input type="checkbox" v-model="contactChecked" class="custom-control-input" id="contact-check">
                        <label class="custom-control-label" for="contact-check"></label>
                    </div>
                </label>
                <div class="col-9 p-0">
                    <select v-if="getContactCnt != 0" v-model.trim="selectContact" :disabled="selectContactDisabled" :class="{disitem:selectContactDisabled}" class="form-control mb-2" >
                        <option v-for="contact in getContacts" :value="contact.name + '$' + contact.phone" :key="contact.id">{{contact.name}}さん&nbsp;{{contact.phone}}</option>
                    </select>
                    <div>
                        <input v-model.trim="inputName" :class="{disitem:inputContactDisabled}" :disabled="inputContactDisabled" type="text" class="form-control mb-2" maxlength="6" spellcheck=false placeholder="お名前">
                    </div>
                    <div>
                        <input v-model.trim="inputPhone" :class="{disitem:inputContactDisabled}" :disabled="inputContactDisabled" type="text" class="form-control" maxlength="11" spellcheck=false value="" placeholder="電話番号">
                    </div>
                </div>
            </div>
        </div>
    </li>
</template>

<script>
    import {mapGetters} from 'vuex'

    import {required, maxLength, numeric} from "vuelidate/lib/validators"
    import ErrMsgBar from 'pages/commons/err/ErrMsgBar.vue'

    export default {
        components: {
            ErrMsgBar
        },
        data () {
            return {
                selectContact: "",
                inputName: "",
                inputPhone: "",
                contactChecked: false,
                errs: {}
            }
        },
        mounted () {
            this.getAddedInterview.inputName ? this.updateContactSelected() : this.setContactSelected()
        },
        computed: {
            selectContactDisabled () {
                return this.contactChecked
            },
            inputContactDisabled () {
                if (this.getContactCnt == 0) {
                    return false
                } else {
                    return !this.contactChecked
                }
            },
            ...mapGetters([
                'getContactCnt',
                'getContacts',
                'getAddedInterview',
                'getAddInterviewStatus'
            ]),
        },
        methods: {
            setContactSelected () {
                this.selectContact = this.getContactCnt > 0 ? this.getContacts[0].name + "$" + this.getContacts[0].phone : ""
            },
            updateContactSelected () {
                this.selectContact = this.getAddedInterview.inputName + "$" + this.getAddedInterview.inputPhone
            },
            doCheck () {
                if (!this.inputContactDisabled) {
                    this.$v.$touch()
                    if (!this.$v.$invalid) {
                        return true
                    }
                    return false
                } else {
                    return true
                }
            },
            dataCheck () {
                if (this.doCheck()) {
                    this.clearErrs()
                    return true
                } else {
                    this.showErrs()
                    return false
                }
            },
            showErrs () {
                this.clearErrs()
                let arr = []

                if (!this.$v.inputName.required) { arr.push("お名前を入力してください。") }  
                if (!this.$v.inputName.maxLength) { arr.push("お名前は6文字以内を入力してください。") }  
                if (!this.$v.inputPhone.required) { arr.push("電話番号を入力してください。") }  
                if (!this.$v.inputPhone.maxLength) { arr.push("電話番号は11桁以内を入力してください。") }  
                if (!this.$v.inputPhone.numeric) { arr.push("電話番号は半角数字を入力してください。") }  
                if (arr.length > 0) {this.errs.contact = arr}
            },
            clearErrs () {
                this.errs = {}
            },
            getData () {
                let reqName = this.inputContactDisabled ? this.selectContact.split('$')[0].trim() : this.inputName
                let reqPhone = this.inputContactDisabled ? this.selectContact.split('$')[1].trim() : this.inputPhone
                return {
                    inputName: reqName,
                    inputPhone: reqPhone
                }
            }
        },
        validations: {
            inputName: {
                required,
                maxLength: maxLength(6)
            },
            inputPhone: {
                required,
                maxLength: maxLength(11),
                numeric
            }
        }
    }
</script>

<style lang="stylus" scoped>
    .disitem 
        opacity:0.4 !important
</style>
