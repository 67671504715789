<template>
    <div class="container">
        <div class="row">
            <div class="col-sm-6 mb-2">
                <page-nav icn="fas fa-paper-plane text-primary" lab="応募履歴（案件）" @do_click="toApplyPjHis"></page-nav>
            </div>
        <!--     <div class="col-sm-4 mb-2"> -->
        <!--         <div class="card h-100" @click="toApplyList"> -->
        <!--             <ul class="list-group list-group-flush"> -->
        <!--                 <li class="list-group-item px-2"> -->
        <!--                     <div class="row"> -->
        <!--                         <div class="col-2 pr-0 text-center"> -->
        <!--                             <i class="fas fa-paper-plane text-primary icon-size"></i> -->
        <!--                         </div> -->
        <!--                         <div class="col pl-2"> -->
        <!--                             <span> 応募一覧 </span> -->
        <!--                         </div> -->
        <!--                         <div class="col text-right"> -->
        <!--                             <i class="fas fa-angle-right text-muted"></i> -->
        <!--                         </div> -->
        <!--                     </div> -->
        <!--                 </li> -->
        <!--             </ul> -->
        <!--         </div> -->
        <!--     </div> -->
        <!--     <div class="col-sm-4 mb-2"> -->
        <!--         <div class="card h-100" @click="toEnterPlan"> -->
        <!--             <ul class="list-group list-group-flush"> -->
        <!--                 <li class="list-group-item px-2"> -->
        <!--                     <div class="row"> -->
        <!--                         <div class="col-2 pr-0 text-center"> -->
        <!--                             <i class="fas fa-business-time text-primary icon-size"></i> -->
        <!--                         </div> -->
        <!--                         <div class="col pl-2"> -->
        <!--                             <span> 入場予定 </span> -->
        <!--                         </div> -->
        <!--                         <div class="col text-right"> -->
        <!--                             <i class="fas fa-angle-right text-muted"></i> -->
        <!--                         </div> -->
        <!--                     </div> -->
        <!--                 </li> -->
        <!--             </ul> -->
        <!--         </div> -->
        <!--     </div> -->
        <!--     <div class="col-sm-4 mb-2"> -->
        <!--         <div class="card h-100" @click="toContact"> -->
        <!--             <ul class="list-group list-group-flush"> -->
        <!--                 <li class="list-group-item px-2"> -->
        <!--                     <div class="row"> -->
        <!--                         <div class="col-2 pr-0 text-center"> -->
        <!--                             <i class="fas fa-address-card text-primary icon-size"></i> -->
        <!--                         </div> -->
        <!--                         <div class="col-8 pl-2"> -->
        <!--                             <span> 連絡先(面接/入場) </span> -->
        <!--                         </div> -->
        <!--                         <div class="col text-right"> -->
        <!--                             <i class="fas fa-angle-right text-muted"></i> -->
        <!--                         </div> -->
        <!--                     </div> -->
        <!--                 </li> -->
        <!--             </ul> -->
        <!--         </div> -->
        <!--     </div> -->
        <!--     <div class="col-sm-4 mb-2"> -->
        <!--         <div class="card h-100" @click="toPlace"> -->
        <!--             <ul class="list-group list-group-flush"> -->
        <!--                 <li class="list-group-item px-2"> -->
        <!--                     <div class="row"> -->
        <!--                         <div class="col-2 pr-0 text-center"> -->
        <!--                             <i class="fas fa-map-marked-alt text-primary icon-size"></i> -->
        <!--                         </div> -->
        <!--                         <div class="col-8 pl-2"> -->
        <!--                             <span> 場所(面接/入場) </span> -->
        <!--                         </div> -->
        <!--                         <div class="col text-right"> -->
        <!--                             <i class="fas fa-angle-right text-muted"></i> -->
        <!--                         </div> -->
        <!--                     </div> -->
        <!--                 </li> -->
        <!--             </ul> -->
        <!--         </div> -->
        <!--     </div> -->
        </div>
    </div>
</template>

<script>
    import PageNav from 'pages/commons/nav/PageNav'

    export default {
        components:{
            PageNav
        },
        methods: {
            toApplyPjHis () {
                this.$store.dispatch('setRefreshArr', 'cmpApplyPj')
                this.$router.push({name: 'ApplyPj'})
            },
            toApplyList () {
                this.$store.dispatch('setRefreshArr', 'cmpApplys')
                this.$router.push({name: 'Apply'})
            },
            toEnterPlan () {
                this.$store.dispatch('setRefreshArr', 'enterPlan')
                this.$router.push({name: 'EnterPlan'})
            },
            toContact () {
                this.$store.dispatch('setRefreshArr', 'contactList')
                this.$router.push({name: 'IntvContact'})
            },
            toPlace () {
                this.$store.dispatch('setRefreshArr', 'placeList')
                this.$router.push({name: 'IntvPlace'})
            }
        }
    }
</script>

<style lang="stylus" scoped>

</style>
