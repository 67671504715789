<template>
    <place-list 
        ref="list"
        header-text="場所"
        mt>
        <div v-if="getListObj">
            <div v-if="getListObj.data.length > 0">
                <div class="card mb-2" v-for="item in getListObj.data" :key="item.id">
                    <div class="card-body px-2 d-flex let-space-1">
                        <div class="all-break-cust"> 
                            <i class="fas fa-map-marker"></i>&nbsp;
                            <span>
                               {{item.in_place}} 
                            </span>
                        </div>
                        <div class="flex-fill text-right">
                            <span class="text-muted pl-2" @click="openMenu(item.id)">
                                <i class="fas fa-ellipsis-v"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- メニュー -->
        <place-menu ref="menu" list-nm="場所" act="app.place.del"></place-menu>

    </place-list>
</template>

<script>
    import PlaceMenu from 'pages/commons/form/Delete'
    import PlaceList from 'pages/commons/List/List'
    import {mapGetters} from 'vuex'

    export default {
        components: {
            PlaceList,
            PlaceMenu
        },
        mounted () {
            this.$refs.list.loadListObj({
                url:'app.place',
                data: '0' 
            })
            this.$refs.list.resetRefresh('placeList')
        },
        activated () {
            this.$refs.list.isGetList('placeList', { url:'app.place', data: '0'})
            this.$refs.list.resetRefresh('placeList')
        },
        computed: {
            ...mapGetters(['getListObj'])
        },
        methods: {
            openMenu (id) {
                this.$refs.menu.openThis(id)
            },
            seeMoreMt () {
                this.$store.dispatch('loadListObjSeeMore', {
                    url:'app.place',
                    skip: this.getListObj.data.length 
                })
            },
        }
    }
</script>
