<template>
    <div class="container">
        <div class="card com-card item-hover mb-2 py-md-5">
            <div class="card-body px-2">
                <div class="card-body p-2 text-muted text-center">
                    募集中/終了
                </div>
                <div class="card-body p-2 text-center">
                    <span class="point-num">
                        <b><span class="text-success">{{getCmpProjHome.data.open_cnt}}</span>
                            <span class="text-muted">
                                /
                            </span>
                            <span class="text-danger">{{getCmpProjHome.data.close_cnt}}</span>
                        </b>
                    </span>&nbsp;
                    <span class="pt-2 text-muted"><b>件</b></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        computed: {
            ...mapGetters(['getCmpProjHome'])
        }
    }
</script>

<style lang="stylus" scoped>
    .point-num
        font-size: 1.8rem;

</style>
