<template>
    <div id="modal-note" class="modal fade" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5><i class="fas fa-pen"></i>&nbsp;備考</h5>
                    <p class="wrap-name">
                        <span class="intv-name">
                            <small>&nbsp;{{this.name}} </small>
                        </span>
                        <span><small>さん</small></span>
                    </p>
                    <button class="close" type="button" @click="addNote">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <!-- エラー -->
                    <err-msg-bar :name="['note']"></err-msg-bar>
                    <small v-if="editFlg"><span class="text-muted">※入力内容が自動保存されます。({{this.noteLength}}/500)</span></small>
                    <textarea v-model.trim="note" :disabled="disabledFlg" :class="{disabledBg:disabledFlg}" class="form-control" rows="7" maxlength="500" spellcheck=false :placeholder="plhoder"></textarea>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary" type="button" @click="addNote">
                        &nbsp;閉じる&nbsp;
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ErrMsgBar from 'pages/commons/err/ErrMsgBar.vue'
    import {mapGetters} from 'vuex'

    import {EventBus} from 'js/event-bus'

    export default {
        components: {
            ErrMsgBar
        },
        mounted () {
            EventBus.$on('openIntvNote', (data) => {
                this.note = data.note
                this.noteHist = data.note
                this.intvId = data.intvId
                this.name = data.name
                this.editFlg = data.editFlg
                this.showThis()
            })
        },
        data () {
            return {
                note: "",
                noteHist: "",
                intvId: "",
                name: "",
                editFlg: true,
            }
        },
        watch: {
            getAddNoteStatus () {
                if (this.getAddNoteStatus == 1) {
                    this.$store.dispatch('startSpinner')
                }
                if (this.getAddNoteStatus == 2) {
                    this.update = false
                    this.closeThis()
                    this.$store.dispatch('doneSpinner')
                }
                if (this.getAddNoteStatus == 3) {
                    this.$store.dispatch('doneSpinner')
                }
            }
        },
        computed: {
            plhoder () {
                if (this.disabledFlg) {
                    return "備考はありません。"
                } else {
                    return "メモを記入してください。"
                }
            },
            disabledFlg () {
                return !this.editFlg
            },
            updateFlg () {
                return this.note != this.noteHist
            },
            noteLength () {
                return this.note.length   
            },
            ...mapGetters(['getAddNoteStatus'])
        },
        methods: {
            addNote () {
                if (this.updateFlg) {
                    this.$store.dispatch('addNote', {
                        note: this.note,
                        intvId: this.intvId,
                    })
                } else {
                    this.closeThis()
                }
            },
            closeThis () {
                $('#modal-note').modal('hide')
            },
            showThis () {
                $('#modal-note').modal('show')
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import '~stylus/base/mixins.styl'
    .wrap-name
        display: flex
        flex: 1
        white-space: nowrap
        overflow: hidden
        .intv-name
            ellipsis()
    .disabledBg
        background-color: #fff
</style>
