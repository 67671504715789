<template>
    <div class="card mb-3 com-card pt-md-3">

        <!-- プロジェクト詳細 -->
        <proj-body :item="proj"></proj-body>

        <div v-if="proj.proj_open_flg == '0'" class="card-footer text-right text-muted">
            <span class="px-2" @click="openShare">
                <i class="fas fa-share-alt icon-size"></i>
                <small> 共有 </small>
            </span>
        </div>
        <!-- プロジェクト情報シェア -->
        <proj-share ref="share"></proj-share>
    </div>
</template>

<script>
    import ProjBody from 'pages/commons/projBody/ProjBody'
    import ProjShare from 'pages/commons/projShare/ProjShare'

    export default {
        props: {
            proj: Object
        },
        components: {
            ProjBody,
            ProjShare
        },
        methods: {
            openShare () {
                this.$refs.share.openThis(this.proj)
            },
        }

    }
</script>

<style></style>
