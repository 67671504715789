<template>
    <invited-list 
        ref="list"
        header-text="友達"
        lab="人"
    >
        <div v-if="getListObj">
            <div class="card com-card" v-if="getListObj.data.length > 0">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item p-md-4 item-hover" v-for="item in getListObj.data" :key="item.next_id" @click="toChildStatus(item.next_code)">
                        <div class="wrapper-name">
                            <span class="name">
                                {{item.next_name}}
                            </span>
                            <span class="flex-fill text-right ml-2">
                                <i class="fas fa-user-alt icon-size" :class="{'text-success':item.next_status == '1'}"></i>
                                <span class="m-0 p-0" :class="{opacity0:item.next_invited_cnt == 0, 'text-success':item.next_child_active_cnt != 0}">
                                    &nbsp;&nbsp;
                                    <i class="fas fa-angle-double-right"></i>
                                    &nbsp;&nbsp;
                                    <i class="fas fa-users icon-size"></i>
                                </span>
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </invited-list>
</template>

<script>
    import InvitedList from 'pages/commons/List/List'
    import {mapGetters} from 'vuex'

    export default {
        components: {
            InvitedList
        },
        mounted () {
            this.$refs.list.loadListObj({ url: 'invite.status' })
            this.$refs.list.resetRefresh('inviteStatus')
        },
        activated () {
            this.$refs.list.isGetList('inviteStatus', { url: 'invite.status' })
            this.$refs.list.resetRefresh('inviteStatus')
        },
        computed: {
            ...mapGetters(['getListObj', 'getRole'])
        },
        methods: {
            toChildStatus (code) {
                this.$router.push({name:'InvitedChildStatus', params:{code: code}})
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import '~stylus/base/mixins.styl'
    .opacity0
        opacity: 0
    .wrapper-name
        display:flex
        white-space: nowrap
        .name
            ellipsis()
</style>
