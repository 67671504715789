<template>
    <div class="container">
        <div class="row">
            <div class="col-sm-6 mb-2">
                <div class="card card-body com-card p-md-5">
                    <div class="d-flex">
                        <span>
                            <i class="fas fa-user-circle icon-size text-primary pr-2"></i>
                        </span>
                        <span>本人確認</span>
                        <div v-if="item.identity_ok" class="text-right ml-auto text-success mr-2">
                            <i class="fas fa-check-circle icon-size"></i>
                            <span><small>確認済 </small></span>
                        </div>
                        <div v-else class="text-right ml-auto text-danger mr-2">
                            <i class="fas fa-exclamation-circle icon-size"></i>
                            <span><small>未完了 </small></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 mb-2">
                <div class="card card-body com-card p-md-5">
                    <div class="d-flex">
                        <span>
                            <i class="fas fa-university icon-size text-primary pr-2"></i>
                        </span>
                        <span>銀行口座</span>
                        <div v-if="item.bank_ok" class="text-right ml-auto text-success mr-2">
                            <i class="fas fa-check-circle icon-size"></i>
                            <span><small>登録済 </small></span>
                        </div>
                        <div v-else class="text-right ml-auto text-danger mr-2">
                            <i class="fas fa-exclamation-circle icon-size"></i>
                            <span><small>未登録 </small></span>
                        </div>
                        <!-- <span class="arrow"> -->
                        <!--     <i class="fas fa-angle-right text-muted"></i> -->
                        <!-- </span> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="card card-body mb-2 com-card p-md-5">
            <div class="card border-0 text-muted">
                <span class="text-muted">
                    換金ポイント
                </span>
            </div>
            <div class="card mt-3 border-0 mb-3 text-right">
                <span>
                    <b><span class="point_lab">{{moneyComma}}</span>pt</b>
                </span>
            </div>
        </div>
        <div class="card card-body com-card py-md-5">
            <div class="row d-flex justify-content-center">
                <div class="col-sm-4">
                    <button type="button" class="btn btn-primary btn-block" @click="btnExchange" :disabled="!item.next_ok">
                        申し込む
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {commaFormat} from 'pages/commons/form/comFunc'

    export default {
        props: {
            item: Object
        },
        computed: {
            moneyComma () {
                return this.moneyFormat(this.item.point_sum)
            }
        },
        methods: {
            moneyFormat (val) {
                return commaFormat(val)
            },
            btnExchange () {
                this.$parent.openInfoMsg(2, this.moneyComma + 'ptを現金へ交換しますか？')
            },
            exchangePoint () {
                this.$store.dispatch('exchangePoint')
            }
        }

    }
</script>

<style lang="stylus" scoped>
    .point_lab
        font-size 1.7rem
        padding-right .5rem
        letter-spacing .1rem
        font-weight bold
</style>
