<template>
    <div>
        <div class="row mb-1" v-if="item">
            <div class="col-sm-12">
                <div class="card mb-3 com-card pt-md-3">
                    <!-- <proj-header :item="item"></proj-header> -->
                    <proj-body :item="item" :pub="false"></proj-body>
                </div>
            </div>
        </div>
        <!-- 0件メッセージ -->
        <not-found-msg v-else></not-found-msg>
    </div>
</template>

<script>
    //import ProjHeader from './ProjHeader'
    import ProjBody from 'pages/commons/projBody/ProjBody'
    import NotFoundMsg from 'pages/commons/notFoundMsg/NotFoundMsg'

    export default {
        props: {
            item: Object
        },
        components:{
            //ProjHeader,
            ProjBody,
            NotFoundMsg
        },
    }
</script>
