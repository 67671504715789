<template>
    <div v-if="getListObj">
        <list-header v-if="hasHeader" :page-name="headerText" :istop="istop" :has-add="hasAdd" @add="add"> </list-header>

        <div class="mb-2" :class="{'container': mg}">
            <slot></slot>
            <not-found-msg v-if="showNotFoundMsg"></not-found-msg>
        </div>

        <see-more v-if="showSeeMore"
                  :mt="mt"
                  :mg="mg"
                  @seeMore="seeMore"
                  @seeMoreMt="seeMoreMt"
                  :spinner-status="getListObjSeeMoreLoadStatus">
        </see-more>
        <show-total v-if="showTotal" :num="getListObj.data.length" :lab="lab"></show-total>
    </div>
</template>

<script>
    import ListHeader from 'pages/commons/header/Header'
    import SeeMore from 'pages/commons/pagination/SeeMore'
    import ShowTotal from 'pages/commons/pagination/ShowTotal'
    import NotFoundMsg from 'pages/commons/notFoundMsg/NotFoundMsg'
    import {mapGetters} from 'vuex'

    export default {
        props: {
            hasHeader: {
                type: Boolean,
                default: true
            },
            headerText: String,
            // header
            istop: {
                type: Boolean,
                default: false
            },
            hasAdd: {
                type: Boolean,
                default: false
            },
            // see more
            mt: {
                type: Boolean,
                default: false
            },
            // container margin
            mg: {
                type: Boolean,
                default: true
            },
            // show total
            lab: {
                type: String,
                default: '件'
            },
        },
        components: {
            ListHeader,
            SeeMore,
            ShowTotal,
            NotFoundMsg
        },
        destroyed () {
            this.$store.dispatch('resetListObj')
        },
        data () {
            return {
                lastId: null
            }
        },
        watch: {
            getListObjLoadStatus (status) {
                if (status == 1) {
                    this.$store.dispatch('startProgress')
                }
                if (status == 2) {
                    this.$store.dispatch('doneProgress')
                }
                if (status == 3) {
                    this.$store.dispatch('doneProgress')
                }
            },
        },
        computed: {
            showNotFoundMsg () {
                if (this.getListObj.data.length == 0) {
                    if (this.getListObj.next_page_url != null) {
                        this.seeMoreMt()
                    } else {
                        return true
                    }
                } else {
                    this.setLastId()
                }
                return false
            },
            showTotal () {
                return this.getListObj.data.length > 0 && this.getListObj.next_page_url == null
            },
            showSeeMore () {
                return this.getListObj.next_page_url != null
            },
            ...mapGetters(['getListObj'
                          ,'getListObjLoadStatus'
                          ,'getListObjSeeMoreLoadStatus'
                          ,'getRefreshArr'
                        ])
        },
        methods: {
            loadListObj (data) {
                this.$store.dispatch('loadListObj', data)
            },
            seeMore () {
                this.$store.dispatch('loadListObjSeeMore', this.getListObj.next_page_url)
            },
            setLastId () {
                this.lastId = this.getListObj.data.slice(-1)[0].id
            },
            seeMoreMt () {
                this.$parent.seeMoreMt()
            },
            isGetList (pageName, data, reset="") {
                if (this.getRefreshArr[pageName]) {
                    if (reset === "reset") {
                        this.$parent.resetThis()
                    }
                    this.loadListObj(data)
                } else {
                    this.$store.dispatch('backListFromKeep')
                }
            },
            resetRefresh (pageName) {
                this.$store.dispatch('resetRefreshArr', pageName)
            },
            add () {
                this.$emit('add')
            }
        }
    }
</script>

<style>

</style>
