<template>
    <fade-animation>
        <div class="alert alert-danger alert-dismissible show fade mb-1 p-2" v-show="show">
            <small><p class="p-0 m-0 pt-1 pr-3">
                    {{this.msg}}</p>
            </small>
            <button type="button" class="close p-2" @click="doneMsgBar">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </fade-animation>
</template>

<script>
    import FadeAnimation from 'pages/commons/fade/FadeAnimation'
    import {mapGetters} from 'vuex'

    export default {
        props: {
            name: Array,
            localErrs: Object
        },
        components: {
            FadeAnimation
        },
        data () {
            return {
                show: false,
                msg: ''
            }
        },
        watch: {
            localErrs () {
                this.showErrMsg(this.localErrs)
            },
            getAllErrors () {
                this.showErrMsg(this.getAllErrors)
            }
        },
        computed: {
            ...mapGetters(['getAllErrors'])
        },
        methods: {
            doneMsgBar () {
                this.show = false
            },
            showErrMsg (errObj) {
                let cnt = 0
                for (const key in errObj) {
                    if (this.name.includes(key)) {
                        this.show = true
                        this.msg = errObj[key][0]
                        cnt++
                    }
                }
                if (cnt == 0) {
                    this.show = false
                }
            }
        }
    }
</script>
