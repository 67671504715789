<template>
    <div v-if="item">
        <div class="card h-100 com-card p-md-3">
            <div class="card-header px-2 py-3 p-md-4">
                要員情報
                <i class="fas fa-external-link-alt pl-2"></i>
                <a class="btn btn-link py-0 pl-0" :href="item.resume_pdf" target="_blank">履歴書</a>
            </div>
            <div class="card-body px-2 py-3 p-md-4 let-space-1">
                <div class="break-row">{{item.tech_info_pub}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            item: Object
        },
    }
</script>

<style lang="stylus" scoped>
    .break-row
        white-space:pre-wrap
        word-wrap:break-word
</style>
