<template>
    <div id="modal-user-unlink" class="modal fade" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <div>
                        利用者とメンバーアカウントの紐付けを解除しますか？
                    </div>
                    <div>
                        <small class="text-muted">
                            解除後の元利用者はログインできなくなります。連携が解除されたメンバーアカウントは、あらためてほかの利用者と連携することができます。
                        </small>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="container">
                        <div class="row">
                            <div class="col-6">
                                <button class="btn btn-danger btn-block m-0" type="button" @click="userUnlinkDo">
                                    解除する
                                </button>
                            </div>
                            <div class="col-6">
                                <button class="btn btn-primary btn-block m-0" type="button" @click="closeThis">
                                    閉じる
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        data () {
            return {
                item:{},
            }
        },
        methods: {
            userUnlinkDo () {
                this.$parent.userUnlinkDo(this.item)
                this.closeThis()
            },
            openThis (item) {
                this.item = item
                $('#modal-user-unlink').modal('show')
            },
            closeThis () {
                this.resetThis()
                $('#modal-user-unlink').modal('hide')
            },
            resetThis () {
                this.item = {}
            }
        }
    }
</script>

<style lang="stylus">

</style>
