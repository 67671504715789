<template>
    <div>
        <partner-header page-name="お問い合わせ" :istop="true"></partner-header>
        <div class="container">
            <div class="card card-body com-card let-space-1 p-2 py-md-5">
                <!-- <div> -->
                <!--     <div class="mb-3"> -->
                <!--         <b> ビジネスパートナー募集 </b> -->
                <!--     </div> -->
                <!--     <div> -->
                <!--         <small> -->
                <!--             当社では長期的な展望をもって信頼関係を構築し、共に発展・成長を目指せるパートナー様を募集しております。 -->
                <!--         </small> -->
                <!--     </div> -->
                <!-- </div> -->
                <!-- <hr> -->
                <div class="container">
                    <div class="row justify-content-center text-md-center">
                        <div class="mb-2 py-2 col-sm col-md-6 px-2">
                            <div class="mb-3">
                                <small>
                                    協業に関するお問い合わせのご連絡は、メールにて下記アドレスまでお願い致します。
                                </small>
                            </div>
                            <div class="card card-body text-primary mb-3 my-md-5 mx-md-6 mx-lg-5">
                                <div class="d-flex justify-content-center">
                                    <span class="mt-1 mr-2">
                                        <i class="fas fa-envelope icon-size text-muted"></i>
                                    </span>
                                    <span>
                                        <b> partner@promeraia.com </b>
                                    </span>
                                </div>
                            </div>
                            <div class="text-md-center">
                                <small>
                                    担当の者より折り返しご連絡させていただきます。
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PartnerHeader from 'pages/commons/header/Header'

    export default {
        components: {
            PartnerHeader
        }
    }
</script>

<style lang="stylus" scoped>

</style>
