<template>
    <div>
        <resume-form v-show="showForm" ref="userForm" :opt="operat"></resume-form>
        <resume-confirm v-show="!showForm" ref="userConfirm" :form="reqForm" :opt="operat"></resume-confirm>
        <success-msg ref="success" :lev="lev" :msg="msg"></success-msg>
    </div>
</template>

<script>
    import {isOverProjStartDate} from 'pages/commons/form/comFunc'
    import ResumeForm from './components/Form'
    import ResumeConfirm from './components/Confirm'
    import SuccessMsg from 'pages/commons/modal/infoMsg/InfoMsg'
    import {getList, converVal} from 'pages/commons/form/selectList'
    import {mapGetters} from 'vuex'

    export default {
        components: {
            ResumeForm,
            ResumeConfirm,
            SuccessMsg
        },
        data () {
            return {
                msg: "",
                lev: 0,
                operat: this.initOperat(),
                showForm: this.initShow(),
                reqForm: {},
                reqFormBk: {},//show->edit->confirm->show時、showのデータ表示が上書きしないよう保持する
            }
        },
        created () {
            // リフレッシュ後techResume(一覧データ)がなくなる為、新規履歴情報を一覧画面のリストに反映する際にエラーとなる
            // sessionに保存しておく
            this.$store.dispatch('setListObjSession')
        },
        mounted() {
            this.loadResume()
        },
        destroyed () {
            // confirm画面の初期表示制御
            this.$store.dispatch('resetComTechInfo')
        },
        watch: {
            getComTechOperat (operat) {
                this.changeOperat(operat, 'success')
            },
            getComTechInfo (info) {
                if (info.data.resume) {
                    this.reqForm = info.data.resume
                    this.reqFormBk = info.data.resume
                }
            },
            getComTechInfoLoadStatus (status) {
                if (status == '1') {
                    this.$store.dispatch('startProgress')
                }
                if (status == '2') {
                    this.$store.dispatch('doneProgress')
                }
                if (status == '3') {
                    this.$store.dispatch('doneProgress')
                }
            },
            getSaveComTechInfoStatus (status) {
                if (status == '1') {
                    this.$store.dispatch('startSpinner')
                }
                if (status == '2') {
                    // 新規・更新した要員を一覧リストに反映
                    this.$store.dispatch('reflectList', this.getComTechInfo.data.resume)
                    // opne_flg変更したら一覧から削除
                    //if (this.getComTechInfo.data.resume.open_flg_changed) {
                    //    console.log(this.getComTechInfo.data.resume.id)

                    //    this.$store.dispatch('delItemFromList', {
                    //        listItem: 'id',
                    //        pageId: this.getComTechInfo.data.resume.id
                    //    })
                    //}
                    this.$store.dispatch('doneSpinner')
                    this.openMsg()
                }
                if (status == '3') {
                    this.$store.dispatch('doneSpinner')
                }
            }
        },
        computed: {
            ...mapGetters(['getSaveComTechInfoStatus', 'getComTechOperat', 'getComTechInfoLoadStatus', 'getComTechInfo'])
        },
        methods: {
            loadResume () {
                if (this.$route.params.id) {
                    this.$store.dispatch('loadComTechInfo', {
                        id: this.$route.params.id
                    })
                }
            },
            doSubmit () {
                this.reqForm['file'] = this.getFile()
                this.reqForm['operat'] = this.operat
                let formData = this.makeFormData(this.reqForm)

                if (this.checkFile()) {
                    this.$store.dispatch('saveComTechInfo', formData)
                }
            },
            makeFormData (form) {
                let formData = new FormData();

                Object.keys(form).forEach(function (key) {
                    formData.append(key, form[key])
                })
                return formData
            },
            getChildData () {
                let userForm = this.$refs.userForm.getData()
                this.reqForm = Object.assign({}, this.reqForm, userForm)
            },
            getFile () {
                return this.$refs.userConfirm.getFile()
            },
            checkFile () {
                if (this.operat == 'edit_confirm') {
                    return true
                }
                if (this.operat == 'create_confirm') {
                    if ( this.checkFileExist()) {
                        return true
                    }
                }
                return false
            },
            checkFileExist () {
                return this.$refs.userConfirm.checkFileExist()
            },
            openMsg (msg="完了しました。", lev=0) {
                this.msg = msg
                this.lev = lev
                this.$refs.success.openThis()
            },
            initOperat () {
                return this.$route.params.do
            },
            initShow () {
                return this.$route.params.do == 'create' ? true : false
            },
            changeOperat (opt, res = "") {
                if (opt == 'auto') {
                    this.operat = (this.operat == 'create') ? 'create_confirm' : 'edit_confirm'
                } else {
                    this.operat = opt 
                }
                if (this.operat == 'create' || this.operat == 'edit') {
                    this.showForm = true
                }
                if (this.operat == 'create_confirm' || this.operat == 'edit_confirm' || this.operat == 'show') {
                    this.showForm = false

                    // showのデータを上書きされないよう復旧する
                    // save後、最新のデータ(reqForm)で表示する
                    if (this.operat == 'show' && res !='success') {
                        this.reqForm = this.reqFormBk
                    }
                }
                // スクロールをトップへ移動
                document.documentElement.scrollTop = 0
            },
            getList (name) {
                return getList(name)
            },
            converVal (name, val) {
                return converVal(name,val)
            },
            isOverStart (val) {
                return isOverProjStartDate(val)
            }
        }
    }
</script>

<style></style>
