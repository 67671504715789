<template>
    <div>
        <div class="d-flex">
            <info-item v-if="item.proj_open_flg == '0'" class="text-success" icn="fas fa-comment" left>
                <small>募集中</small>
            </info-item>
            <info-item v-else class="text-muted" icn="fas fa-comment-slash">
                <small>募集終了</small>
            </info-item>
            <info-item icn="fas fa-calendar-alt">
                <small> {{editStartDate(item.proj_start_date)}} </small>
            </info-item>
            <span class="text-nowrap text-truncate">
                <info-item v-if="item.proj_language" icn="fas fa-code-branch">
                    <small>{{item.proj_language}}</small>
                </info-item>
                <info-item v-if="item.proj_place" icn="fas fa-map-marker">
                    <small>{{item.proj_place}}</small>
                </info-item>
                <info-item v-if="item.proj_members" icn="fas fa-user">
                    <small>{{item.proj_members}}名</small>
                </info-item>
                <info-item v-if="item.proj_min_exper" icn="far fa-lightbulb">
                    <small>exp.{{item.proj_min_exper}}</small>
                </info-item>
                <info-item v-if="item.proj_position" icn="fas fa-sitemap">
                    <small>{{converVal('proj_position', item.proj_position)}}</small>
                </info-item>
            </span>
        </div>
    </div>
</template>

<script>
    import {editProjStartDate} from 'pages/commons/form/comFunc'
    import {converVal} from 'pages/commons/form/selectList'
    import InfoItem from 'pages/commons/projList/CardHeadInfoItem'

    export default {
        components:{
            InfoItem
        },
        props: {
            item: Object
        },
        methods: {
            converVal (name, val) {
                return converVal(name,val)
            },
            editStartDate (val) {
                return editProjStartDate(val)
            },
        }
    }
</script>

<style lang="stylus" scoped>

</style>
