<template>
    <div v-if="getPost.proj_open_flg != '2'">
        <div class="card-header text-right text-muted">
            <span @click="openShare">
                <i class="fas fa-share-alt icon-size"></i>
                <small> 共有 </small>
            </span>
        </div>
        <proj-share ref="share"></proj-share>
    </div>
</template>

<script>
    import ProjShare from 'pages/commons/projShare/ProjShare'
    import {mapGetters} from 'vuex'

    export default {
        components: {
            ProjShare
        },
        computed: {
            ...mapGetters(['getPost'])
        },
        methods: {
            openShare () {
                this.$refs.share.openThis(this.getPost)
            }
        }
    }
</script>

<style></style>
