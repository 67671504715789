<template>
    <div id="modal-tech-info" class="modal fade" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body p-1">
                    <div class="card card-body break-row p-2">{{info}}</div>
                </div>
                <div class="modal-footer justify-content-center">
                    <div class="container">
                        <div class="row">
                            <div class="col-6">
                                <a :href="resume" target="_blank" class="btn btn-success btn-block m-0" role="button">
                                    履歴書
                                </a>
                            </div>
                            <div class="col-6">
                                <button class="btn btn-primary btn-block m-0" type="button" @click="closeThis">
                                    閉じる
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                info:"",
                resume: ""
            }
        },
        methods: {
            closeThis () {
                this.resetThis()
                $('#modal-tech-info').modal('hide')
            },
            openThis (info, resume) {
                this.info = info
                this.resume = resume
                $('#modal-tech-info').modal('show')
            },
            resetThis () {
                this.info = ""
                this.resume = ""
            }
        }
    }
</script>

<style lang="stylus" scoped>
.break-row
    white-space:pre-wrap
    word-wrap:break-word
</style>
