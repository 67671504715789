<template>
    <div class="container let-space-1">
        <div class="card card-body mb-2 com-card px-md-5 py-md-5">
            <div>
                <i class="fas fa-file-signature icon-size-1 text-primary pr-2"></i>
                <span class="text-primary"><b>STEP 01</b></span>
                <hr>
            </div>
            <div class="d-flex">
                <div><span>個人情報の登録</span></div>
                <div v-if="needPrivate" class="text-right ml-auto text-danger">
                    <i class="fas fa-exclamation-circle icon-size text-danger"></i>
                    <span><small>未完了 </small></span>
                </div>
                <div v-else class="text-right ml-auto text-success">
                    <i class="fas fa-check-circle icon-size text-success"></i>
                    <span><small>登録済 </small></span>
                </div>
                <!-- <div> -->
                <!--     <i class="fas fa-angle-right text-muted pl-3"></i> -->
                <!-- </div> -->
            </div>
        </div>
        <div class="card card-body com-card py-md-5">
            <div class="row d-flex justify-content-center">
                <div class="col-sm-4">
                    <button type="button" class="btn btn-primary btn-block"
                        @click="toNext"
                        :disabled="needPrivate"
                        >
                        STEP 02 へ
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            needPrivate: {
                type: Boolean,
                default: true
            }
        },
        methods: {
            toNext () {
                this.$parent.setStep(2)
            }
        }
    }
</script>

<style lang="stylus" scoped>
    .icon-size-1
        font-size 1.2rem
</style>
