<template>
    <div v-if="getAppDetail">
        <detail-header page-name="応募詳細"></detail-header>
        <detail-content></detail-content>
        <!-- <detail-footer></detail-footer> -->
    </div>
</template>

<script>
    import DetailHeader from 'pages/commons/header/Header'
    import DetailContent from './components/TabContent'
    //import DetailFooter from './components/Footer'

    import {mapGetters} from 'vuex'

    export default {
        components:{
            DetailHeader,
            DetailContent,
            //DetailFooter
        },
        created () {
            this.loadAppDetail()
        },
        destroyed () {
            this.$store.dispatch('resetAppDetail')
        },
        watch: {
            getAppDetailLoadStatus () {
                if (this.getAppDetailLoadStatus == 1) {
                    this.$store.dispatch('startProgress')
                }

                if (this.getAppDetailLoadStatus == 2) {
                    this.$store.dispatch('doneProgress')
                }

                if (this.getAppDetailLoadStatus == 3) {
                    this.$store.dispatch('doneProgress')
                }
            }
        },
        computed:{
            ...mapGetters(['getAppDetailLoadStatus', 'getAppDetail'])
        },
        methods:{
            loadAppDetail () {
                this.$store.dispatch('loadAppDetail', {
                    id: this.$route.params.id
                })
            }
        }
    }
</script>
