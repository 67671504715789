<template>
    <div class="container">
        <template v-if="getUser.name">
            <div v-if="getUser.cmpGroup" class="row">
                <div class="col-sm-4 mb-2">
                    <page-nav icn="fas fa-user-circle text-primary" lab="個人情報" @do_click="toPrivateInfo"></page-nav>
                </div>
                <div class="col-sm-4 mb-2">
                    <page-nav icn="fas fa-address-card text-primary" lab="本人確認" @do_click="toIdentityCheck"></page-nav>
                </div>
                <div class="col-sm-4 mb-2">
                    <page-nav icn="fas fa-university text-primary" lab="銀行口座" @do_click="toBank"></page-nav>
                </div>
                <div class="col-sm-4 mb-2">
                    <page-nav icn="fas fa-heart text-primary" lab="お気に入り" @do_click="toFavorite"></page-nav>
                </div>
            </div>
            <div v-else class="row">
                <div class="col-sm-4 mb-2">
                    <page-nav icn="fas fa-user-circle text-primary" lab="個人情報" @do_click="toPrivateInfo"></page-nav>
                </div>
                <div class="col-sm-4 mb-2">
                    <page-nav icn="fas fa-file-alt text-primary" lab="履歴書" @do_click="toResumeInfo"></page-nav>
                </div>
                <div class="col-sm-4 mb-2">
                    <page-nav icn="fas fa-address-card text-primary" lab="本人確認" @do_click="toIdentityCheck"></page-nav>
                </div>
                <div class="col-sm-4 mb-2">
                    <page-nav icn="fas fa-university text-primary" lab="銀行口座" @do_click="toBank"></page-nav>
                </div>
                <div class="col-sm-4 mb-2">
                    <page-nav icn="fas fa-paper-plane text-primary" lab="応募履歴" @do_click="toApplyHis"></page-nav>
                </div>
                <div class="col-sm-4 mb-2">
                    <page-nav icn="fas fa-heart text-primary" lab="お気に入り" @do_click="toFavorite"></page-nav>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import PageNav from 'pages/commons/nav/PageNav'
    import {mapGetters} from 'vuex'

    export default {
        components:{
            PageNav
        },
        computed: {
            ...mapGetters(['getUser']),
        },
        methods: {
            toPrivateInfo () {
                this.$router.push({name: 'PrivateCreate'})
            },
            toResumeInfo () {
                this.$router.push({name: 'ResumeCreate'})
            },
            toIdentityCheck () {
                this.$router.push({name: 'IdentityCheck'})
            },
            toBank () {
                this.$router.push({name:'BankInput'})
            },
            toApplyHis () {
                this.$store.dispatch('setRefreshArr', 'applyHis')
                this.$router.push({name: 'ApplyHis'})
            },
            toFavorite () {
                this.$store.dispatch('setRefreshArr', 'favorites')
                this.$router.push({name: 'Favorites'})
            }
        }
    }
</script>

<style lang="stylus" scoped>

</style>
