<template>
    <tech-create></tech-create>
</template>

<script>
import TechCreate from 'pages/commons/techCreate'
export default {
    components:{
        TechCreate
    }
}
</script>

<style></style>
