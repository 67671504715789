<template>
    <div class="row">
        <div class="col pb-2">
            <div class="hd-lab pl-2 py-1">
                <div class="lab-txt">{{lab}}</div>
            </div>
            <div class="card card-body p-1 border-0">
                <small>
                    <slot></slot>
                </small>
            </div>
            <!-- <hr class="mt-1 mb-1 hr-dashed"> -->
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            lab: String
        }
    }
</script>

<style lang="stylus" scoped>
    .hd-lab
        background-color: #fae3d3
        border-left: solid 3px #e67323
        .lab-txt
            color: #e67323


</style>
