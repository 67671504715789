<template>
    <div class="container">
        <div class="card com-card p-md-5 mb-4">
            <div class="card-body px-md-5">
                <small>
                    メンバー招待して共同作業を実現しましょう。
                    <table class="table table-bordered mb-1 mt-1">
                        <tr>
                            <td>権限</td>
                            <td>メンバー</td>
                        </tr>
                        <tr>
                            <td>案件に関する操作</td>
                            <td>○</td>
                        </tr>
                        <tr>
                            <td>応募に関する操作</td>
                            <td>○</td>
                        </tr>
                        <tr>
                            <td>友達招待<br><span class="text-muted">(権限委譲必要)</span></td>
                            <td>☓</td>
                        </tr>
                    </table>
                    <span class="text-muted">※上記すべてはリアルタイムで共有する</span>
                </small>

            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        computed: {
            ...mapGetters(['getCmpInviteInfo'])
        }
    }
</script>

<style lang="stylus" scoped>
</style>
