<template>
    <div>
        <enter-header :page-name="hdText"></enter-header>
        <template v-if="getEnterDetail.data">
            <enter-list v-if="getEnterDetail.data.length > 0" :list="getEnterDetail.data" ></enter-list>
            <not-found-msg v-else></not-found-msg>
        </template>
    </div>
</template>

<script>
    import EnterHeader from 'pages/commons/header/Header'
    import EnterList from './components/List'
    import NotFoundMsg from 'pages/commons/notFoundMsg/NotFoundMsg'

    import {mapGetters} from 'vuex'

    export default {
        components: {
            EnterHeader,
            EnterList,
            NotFoundMsg
        },
        mounted () {
            this.loadEnterDetail()
        },
        destroyed () {
            this.$store.dispatch('resetEnterDetail')
        },
        watch: {
            getEnterDetailLoadStatus (status) {
                if (status == '1') {
                    this.$store.dispatch('startProgress')
                }
                if (status == '2') {
                    this.$store.dispatch('doneProgress')
                }
                if (status == '3') {
                    this.$store.dispatch('doneProgress')
                }
            }
        },
        computed: {
            hdText () {
                let day = this.$route.params.day
                return this.$moment(day).format('YYYY/MM/DD (dd)')
            },
            ...mapGetters(['getEnterDetail', 'getEnterDetailLoadStatus'])
        },
        methods: {
            loadEnterDetail () {
                this.$store.dispatch('loadEnterDetail', {
                    day: this.$route.params.day
                })
            }
        }
    }
</script>

<style lang="stylus" scoped>
</style>

