<template>
    <proj-list type="sales"></proj-list>
</template>

<script>
import ProjList from 'pages/commons/projList/projList'
 export default {
    components:{
       ProjList
    }
 }

</script>

<style></style>
