export default {
    
    /** 共通エラー **/
    setAllErrors (state, errs) {
        state.allErrors = errs
    },

    /** 共通リスト **/
    setListObj (state, data) {
        state.listObj = data
    },
    reflectList (state, newObj) {
        if (!state.listObj.data) {
            try {
                if (sessionStorage.getItem('listObjSession')) {
                    state.listObj = JSON.parse(sessionStorage.getItem('listObjSession'))
                }
            } catch (e) {}
        }
        if (state.listObj.data) {
            let idx = state.listObj.data.data.findIndex((item)=>item.id == newObj.id)
            if (idx != -1) {
                let obj = state.listObj.data.data[idx]
                let combin = Object.assign({}, obj, newObj)
                state.listObj.data.data.splice(idx, 1, combin)
            } else {
                // 先頭に追加
                state.listObj.data.data.unshift(newObj)
                // 最後の要素削除(しないと改ページ後DBから取得した要素と画面最後の要素重複)
                if (state.listObj.data.next_page_url != null) {
                    // 次ページある場合、現在ページの最後レコード削除
                    state.listObj.data.data.pop()
                }
            }
        }
    },
    /** リストの内の特定項目更新 **/
    setListObjSpecificItem (state, data) {
        let idx = state.listObj.data.data.findIndex((item)=>item.id == data.id)
        let obj = state.listObj.data.data[idx]
        obj[data.targetItem] = data.targetValue
    },
    setListObjKeep (state) {
        state.listObjKeep = Object.assign({},state.listObj) 
        state.listObj = {}
        state.listObjLoadStatus = 0
    },
    setListObjSession (state) {
        try {
            // create()で全画面のデータ保存の為、
            // リフレッシュしたら、全画面データがnullになり
            // 子画面再描画の際に、nullで保存済みのsession情報を
            // 上書きの恐れがあるため、null判断必要
            if (state.listObj.data) {
                const list = JSON.stringify(state.listObj)
                sessionStorage.setItem('listObjSession',list)
            }
        } catch (e) {}
    },
    delItemFromList (state, data) {
        let listItem = data.listItem
        let pageId = data.pageId
        state.listObj.data.data = state.listObj.data.data.filter((item)=>item[listItem] != pageId)
    },
    backListFromKeep (state) {
        if (state.listObjKeep.data) {
            state.listObj = Object.assign({},state.listObjKeep)
        }
    },
    resetListObj (state) {
        state.listObj = {}
        state.listObjLoadStatus = 0
    },
    setListObjLoadStatus (state, status) {
        state.listObjLoadStatus = status
    },
    setListObjAddSeeMore (state, resp ) {
        let new_next_page = resp.data.next_page_url
        let new_list = resp.data.data
        let old_list = state.listObj.data.data
        state.listObj.data.next_page_url = new_next_page
        state.listObj.data.data = old_list.concat(new_list)
    },
    setListObjSeeMoreLoadStatus (state, status) {
        state.listObjSeeMoreLoadStatus = status
    },
    setListItemDeleteStatus (state, status) {
        state.listItemDeleteStatus = status
    },

    /** ユーザー情報 **/
    setUser (state, user) {
        state.user = user
    },
    setUserLoadStatus (state, status) {
        state.userLoadStatus = status
    },
    setRefreshArr (state, item) {
        state.refreshArr[item] = true
    },
    resetRefreshArr (state, item) {
        state.refreshArr[item] = false
    },

    /** 郵便住所 **/
    setAddressInfo (state, resp) {
        state.addressInfo = resp
    },
    setMyNm (state, myNm) {
        state.addressInfo.data['myNm'] = myNm
    },
    setZipErrNotFound (state, myNm) {
        let errs = {}
        errs[myNm + '_zipcode'] = ["正しい郵便番号を入力してください。"]
        state.zipcodeErrors = Object.assign({},state.zipcodeErrors, errs)
    },
    setZipCodeErrors (state, errs) {
        state.zipcodeErrors = Object.assign({},state.zipcodeErrors, errs)
    },
    clearZipCodeErrors (state, errs) {
        state.zipcodeErrors = {}
        // const errors = Object.assign({},state.allErrors)
        // delete errors['zipcode']
        // delete errors['address1']
        // delete errors['address2']
        // state.allErrors = errors
    },
    setAddressInfoLoadStatus (state, status) {
        state.addressInfoLoadStatus = status
    },

    /** 案件情報シエア詳細取得 **/
    setShareInfoDetail (state, resp) {
        state.shareInfoDetail = resp
    },
    setShareInfoDetailLoadStatus (state, status) {
        state.shareInfoDetailLoadStatus = status
    },

    /** unique check **/
    setUniqueCheckErr (state, resp) {
        state.uniqueCheckErr = resp
    },
    setUniqueCheckStatus (state, status) {
        state.uniqueCheckStatus = status
    },

    /** 応募詳細メッセージ  **/
    setAddApplyHisMsg (state, data) {
        state.addApplyHisMsg = data
    },
    setAddApplyHisMsgStatus (state, status) {
        state.addApplyHisMsgStatus = status
    },

    /** 案件情報 **/
    setComProjOperat (state, operat) {
        if (operat == 'success') {
            state.comProjOperat = 'show'
        } else {
            state.comProjOperat = operat == 'create_confirm' ? 'create' : 'edit'
        }
    },
    resetComProjOperat (state) {
        state.comProjOperat = ""
    },
    setComProjInfo (state, resp) {
        state.comProjInfo = resp
    },
    resetComProjInfo (state) {
        state.comProjInfo = {}
    },
    setSaveComProjInfoStatus (state, status) {
        state.saveComProjInfoStatus = status
    },
    setComProjInfoLoadStatus (state, status) {
        state.comProjInfoLoadStatus = status
    },
    
    /** 案件・要員共通 **/
    setComMenuQuickOffStatus (state, status) {
        state.comMenuQuickOffStatus = status
    },

    /** 履歴書詳細 **/

    setComTechOperat (state, operat) {
        if (operat == 'success') {
            state.comTechOperat = 'show'
        } else {
            state.comTechOperat = operat == 'create_confirm' ? 'create' : 'edit'
        }
    },
    resetComTechOperat (state) {
        state.comTechOperat = ""
    },
    setSaveComTechInfoStatus (state, status) {
        state.saveComTechInfoStatus = status
    },
    setComTechInfo (state, resp) {
        state.comTechInfo = resp
    },
    resetComTechInfo (state) {
        state.comTechInfo = {}
    },
    setComTechInfoLoadStatus (state, status) {
        state.comTechInfoLoadStatus = status
    },

    /** 営業中要員・案件リスト取得 **/
    setActSalesInfo (state, resp) {
        state.actSalesInfo = resp
    },
    setActSalesInfoStatus (state, status) {
        state.actSalesInfoStatus = status
    },

    /** 個人・法人提案共通**/
    setSuggestionOperat (state, opt ) {
        state.suggestionOperat = opt
    },
    resetSuggestionOperat (state) {
        state.suggestionOperat = ""
    },
    setSuggestionStatus (state, status) {
        state.suggestionStatus = status
    },



}
