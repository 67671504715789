export default {

    //initHome: {},
    // 0:waiting 1:loading 2:success 3:failed
    //initHomeLoadStatus: 0,

    /** 個人情報 **/
    privateInfo: {},
    // create/create_confirm/show/edit/edit_confirm
    privateOperat: "",
    // 0:waiting 1:loading 2:success 3:failed
    savePrivateInfoStatus: 0,
    // 0:waiting 1:loading 2:success 3:failed
    privateInfoLoadStatus: 0,

    /** 履歴情報 **/
    resumeInfo: {},
    // create/create_confirm/show/edit/edit_confirm
    resumeOperat: "",
    // 0:waiting 1:loading 2:success 3:failed
    saveResumeInfoStatus: 0,
    // 0:waiting 1:loading 2:success 3:failed
    resumeInfoLoadStatus: 0,

    /** 本人確認 **/
    identityInfo: {},
    identityInfoLoadStatus: 0,
    sendIdentityMailStatus: 0,

    /** 口座情報 **/

    // 登録済み口座情報
    bankInfo: {},
    // create/create_confirm/show/edit/edit_confirm
    bankOperat: "",
    // 0:waiting 1:loading 2:success 3:failed
    saveBankInfoStatus: 0,
    // 0:waiting 1:loading 2:success 3:failed
    bankInfoLoadStatus: 0,

    /** 応募履歴詳細 **/
    applyHisDetail: {},
    // 0:waiting 1:loading 2:success 3:failed
    applyHisDetailLoadStatus: 0,
    agreeIntvPlan: "",
    // 0:waiting 1:loading 2:success 3:failed
    agreeIntvPlanStatus: 0,

    /** お気に入り削除 **/
    // 0:waiting 1:loading 2:success 3:failed
    deleteFavoriteStatus: 0,


}
