
export default {
    /** 共通 **/
    redirectCheck (state, data) {
        if (data.code == 403) {location.href= data.to} 
    },

    /** 応募状況画面 **/
    setApplyStatusDetail (state, data) {
        state.applyStatusDetail = data
    },
    setApplyStatusDetailLoadStatus (state, status) {
        state.applyStatusDetailLoadStatus = status
    },
    resetApplyStatusDetail (state) {
        state.applyStatusDetail = {}
        state.applyStatusDetailLoadStatus = 0
    },

    /** 応募一覧画面 **/
   // setAppPostCnt (state, data) {
   //     let proc = data.proc
   //     let proj_id = data.id
   //     let app = state.applies.data.data.find((item)=>item.proj_id == proj_id)
   //     if (proc == 'DocOK') {
   //         app.apply_cnt_unopen--
   //         app.apply_cnt_opened++
   //         app.intv_cnt++
   //     }
   //     if (proc == 'DocNG') {
   //         app.apply_cnt_unopen--
   //         app.apply_cnt_opened++
   //     }
   //     if (proc == 'IntvOK' || proc == 'IntvNG') {
   //         app.intv_cnt--
   //     }
   //     
   // },

    /** 応募詳細画面 **/

    setAppDetail (state, appDetail) {
        state.appDetail = appDetail
    },
    resetAppDetail (state) {
        state.appDetail = {}
        state.appDetailLoadStatus = 0
    },
    setAppDetailLoadStatus (state, status) {
        state.appDetailLoadStatus = status
    },
    setNewApplyPost (state, resp) {
        state.newApplyPost = resp
    },
    setAddedInterview (state, interview) {
        state.addedInterview = interview
    },
    setAddInterviewStatus (state, status) {
        state.addInterviewStatus = status
    },
    setAddedEntrance (state, entrance) {
        state.addedEntrance = entrance
    },
    setAddedEntranceToIntv (state, entrance) {
        state.addedInterview.inputPlace = entrance.inputPlace
        state.addedInterview.inputName = entrance.inputName
        state.addedInterview.inputPhone = entrance.inputPhone
    },
    setAddNoteStatus (state, status) {
        state.addNoteStatus = status
    },
    setSendMailDocNGStatus (state, status) {
        state.sendMailDocNGStatus = status
    },
    setSendMailIntvNGStatus (state, status) {
        state.sendMailIntvNGStatus = status
    },
    setSendMailIntvOKStatus (state, status) {
        state.sendMailIntvOKStatus = status
    },
    setSendMailIntvOKWithNextStatus (state, status) {
        state.sendMailIntvOKWithNextStatus = status
    },

    // 入場予定詳細
    setEnterDetail (state, resp) {
        state.enterDetail = resp
    },
    resetEnterDetail (state) {
        state.enterDetail = {}
        state.enterDetailLoadStatus = 0
    },
    setEnterDetailLoadStatus (state, status) {
        state.enterDetailLoadStatus = status
    },


}
