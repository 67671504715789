<template>
    <div>
        <!-- <list-header istop page-name="案件一覧"></list-header> -->
        <!-- 検索 -->
        <div class="container my-1" :style="searchBarStyle">
            <div class="card px-lg-4 py-lg-3 p-md-3 com-card">
                <div class="card-body px-2 pb-2">
                    <div class="row">
                        <div class="col-sm mb-1 d-flex">
                            <div class="mr-1 flex-fill">
                                <input v-model.trim="freeword"  type="text" class="form-control" maxlength="100" spellcheck=false placeholder="フリーキーワード"></input>
                            </div>
                            <div class="px-1">
                                <button type="button" class="btn btn-primary" @click="searchProj">
                                    <i class="fas fa-search"></i>
                                </button>
                            </div>
                        </div>
                    </div>
            
                    <div class="card card-body p-1 border-0">
                        <div class="d-flex">
                            <div class="form-group form-check mb-0 mr-3">
                                <input type="checkbox" v-model="foreigner" class="form-check-input">
                                <small>外国籍OK</small>
                            </div>
                            <div class="form-group form-check mb-0 mr-2">
                                <input type="checkbox" v-model="remote" class="form-check-input">
                                <small>リモートOK</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <posts-list 
            ref="list"
            :has-header="false"
            :istop="true"
            mt
            >
            <div v-if="getListObj">
                <div v-if="getListObj.data.length > 0">
                    <div class="py-1" v-for="(item, idx) in getListObj.data" :key="idx">
                        <row :item="item" :class="{'com-card-focus':selectedProj == item.id}"></row>
                    </div>
                </div>
            </div>
        </posts-list>

    </div>
</template>

<script>
    //import ListHeader from 'pages/commons/header/Header'
    import PostsList from 'pages/commons/List/List'
    import Row from './components/Row'
    import {mapGetters} from 'vuex'

    export default {
        components: {
            //ListHeader,
            PostsList,
            Row,
        },
        data () {
            return {
                selectedProj: "",
                freeword: '',
                foreigner: false,
                remote: false,
                // 検索用
                freewordFind: '',
                foreignerFind: false,
                remoteFind: false,
                // search bar
                barInit:0,
                barUp:0,
                barDown:0,
                barUpOn:true,
                barDownOn:true,
                searchBarStyle:{
                    position:'sticky',
                    top:'-240px',
                }
            }
        },
        mounted () {
            this.$refs.list.loadListObj({url:'projs.all', data:{
                freeword: JSON.stringify(this.freewordFind),
                foreigner: JSON.stringify(this.foreignerFind),
                remote: JSON.stringify(this.remoteFind),
                skip: '0'
            }})
            this.$refs.list.resetRefresh('projsAll')
        },
        created () {
            this.viewedProjsCheckDate()
        },
        activated () {
            this.$refs.list.isGetList('projsAll', { url:'projs.all'})
            this.$refs.list.resetRefresh('projsAll')
            window.addEventListener('scroll', this.handleScroll)
        },
        deactivated () {
            window.removeEventListener('scroll', this.handleScroll)
        },
        watch: {
            $route (to, from) {
                this.getViewingProj()
            },
            barInit (newVal, oldVal) {
                if (this.barInit != 0) {
                    // scroll up
                    if (newVal > oldVal) {
                        this.barUp++
                        this.barDown = 0
                        if (this.barUp >= 10) {
                            if (this.barUpOn) {
                                this.searchBarStyle = {
                                    position:'sticky',
                                    top: '-240px',
                                    transition:'1s',
                                    zIndex:'100'
                                    }
                                this.barUpOn = false
                                this.barDownOn = true
                            }
                        }
                    }
                    // scroll down
                    if (newVal < oldVal) {
                        this.barDown++
                        this.barUp = 0
                        if (this.barDown >= 10) {
                            if (this.barDownOn) {
                                this.searchBarStyle = {
                                    position:'sticky',
                                    top: '75px',
                                    transition:'1s',
                                    zIndex:'100'
                                    }
                                this.barDownOn = false
                                this.barUpOn = true
                            }
                        }

                    }
                }
            }
        },
        computed: {
            ...mapGetters(['getListObj'])
        },
        methods: {
            handleScroll () {
                const top = document.documentElement.scrollTop
                if (top == 0) {
                    // いろんな操作後、スクロールを上に戻るとposiotion設定
                    this.barInit = 0
                    this.searchBarStyle = {
                        position:'unset',
                        transition:'1s',
                        zIndex:'100'
                    }
                }
                if (top >= 240) {
                    this.barInit = top
                }

            },
            searchProj () {
                this.freewordFind = this.freeword,
                this.foreignerFind = this.foreigner
                this.remoteFind = this.remote
                this.$refs.list.loadListObj({url:'projs.all', data:{
                    freeword: JSON.stringify(this.freewordFind),
                    foreigner: JSON.stringify(this.foreignerFind),
                    remote: JSON.stringify(this.remoteFind),
                    skip: '0'
                }})
            },
            seeMoreMt () {
                this.$store.dispatch('loadListObjSeeMore', {
                    url:'projs.all',
                    skip: {
                        freeword: JSON.stringify(this.freewordFind),
                        foreigner: JSON.stringify(this.foreignerFind),
                        remote: JSON.stringify(this.remoteFind),
                        skip: this.getListObj.data.slice(-1)[0].id
                    }
                })
            },
            getViewingProj () {
                try {
                    this.selectedProj = localStorage.getItem('selectedProj')
                } catch(e) { }
            },
            viewedProjsCheckDate () {
                let today = this.$moment(new Date()).format('YYYYMMDD')
                let checkDate = localStorage.getItem('viewedProjsCheckDate')
                if (checkDate) {
                    if (today != checkDate) {
                        this.viewedProjsCheck()
                        localStorage.setItem('viewedProjsCheckDate', today)
                    }
                } else {
                    localStorage.setItem('viewedProjsCheckDate', today)
                }
            },
            viewedProjsCheck () {
                let viewedProjs = []
                let viewedNew = []
                try {
                    let list = localStorage.getItem('viewedProjs')
                    if (list) {
                        viewedProjs = JSON.parse(list)
                        viewedNew = viewedProjs.filter(item => {
                            let arr = item.split('-')
                            return this.showNewMark(arr[1])
                        })
                        localStorage.setItem('viewedProjs',JSON.stringify(viewedNew))
                    }
                } catch (e) {}
            },
            showNewMark (created_at) {
                let create = this.$moment(created_at)
                let now = this.$moment(new Date())
                return now.diff(create, 'days') <= 2
            },
        }
    }
</script>

<style lang="stylus" scoped>
.card-color-cust
    background-color: #4dc0b521
</style>
