<template>
    <div>
        <form-header v-if="opt == 'create'" :page-name="pageName"></form-header>
        <form-header v-else :page-name="pageName" do-cust @cust="backPage"></form-header>
        <div class="container">
            <div v-if="!form.identity_ok" class="card card-body com-card mb-2 p-md-5">
                <div class="d-flex">
                    <span>本人確認</span>
                    <div class="text-right ml-auto text-danger mr-2">
                        <i class="fas fa-exclamation-circle icon-size text-danger"></i>
                        <span><small>未完了 </small></span>
                    </div>
                </div>
            </div>
            <div class="card com-card mb-2 py-md-5">
                <div class="card-body">
                    <!-- 銀行名 -->
                    <form-input ref="bank_nm" :errs-nm="['bank_nm']" lab="銀行名" plhd="例：〇〇〇銀行" required :max-length="20"></form-input>
                    <!-- 支店名 -->
                    <form-input ref="branch_nm" :errs-nm="['branch_nm']" lab="支店名" plhd="例：〇〇〇支店" required :max-length="20"></form-input>
                    <!-- 支店番号 -->
                    <form-input ref="branch_id" :errs-nm="['branch_id']" lab="支店番号" plhd="例：012" required numeric :max-length="3" :length="[true,3]"></form-input>
                    <!-- 口座番号 -->
                    <form-input ref="account_id" :errs-nm="['account_id']" lab="口座番号" plhd="例：1234567" required numeric :max-length="7" :length="[true,7]"></form-input>
                    <!-- 口座名義フリガナ -->
                    <template v-if="form.first_kana">
                        <form-input ref="name_kana" ip-type="name" :errs-nm="['first_kana','last_kana']" 
                                    lab="口座名義" slab1="フリガナ(姓)" slab2="フリガナ(名)" :disb="true" :disb2="true"
                                    :dft="form.first_kana"
                                    :dft2="form.last_kana"
                                    ></form-input>
                        
                        <form-input ip-type="comment" py0>
                            <i class="fas fa-info-circle"></i>
                            口座名義の変更はできません
                        </form-input>
                    </template>
                </div>
            </div>
            <div class="card com-card mb-2 py-md-4">
                <div class="card-body">
                    <div class="row form-group justify-content-center mb-0">
                        <div class="col-sm-4">
                            <button class="btn btn-success btn-block btn-lg" @click="dataCheck" :disabled="!form.identity_ok">
                                <i class="fas fa-eye"></i>&nbsp;確認画面へ
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import FormInput from 'pages/commons/form/Input'
    import FormHeader from 'pages/commons/header/Header'

    import {mapGetters} from 'vuex'

    export default {
        props: {
            form: Object,
            opt: String
        },
        components: {
            FormInput,
            FormHeader
        },
        mounted() {
            if (this.getBankInfo.data.bank_info) {
                this.setDefaultVal(this.getBankInfo.data.bank_info)
            }
        },
        data () {
            return {
                reqForm: {},
                resultArr: [],
                formArr:[
                        'bank_nm'
                        ,'branch_nm'
                        ,'branch_id'
                        ,'account_id'
                        ,'name_kana'
                    ]
            }
        },
        computed: {
            pageName () {
                return this.opt == 'create' ? "口座情報入力" : "口座情報編集"
            },
            ...mapGetters(['getBankInfo'])
        },
        methods: {
            backPage () {
                this.$parent.changeOperat('show')
            },
            setDefaultVal (info) {
                // 編集ボダン押下
                this.$refs.bank_nm.setInputData(info.bank_nm)
                this.$refs.branch_nm.setInputData(info.branch_nm)
                this.$refs.branch_id.setInputData(info.branch_id)
                this.$refs.account_id.setInputData(info.account_id)
                this.$refs.name_kana.setInputData(info.first_kana, info.last_kana)
            },
            getChildData () {
                // ユーザー情報
                this.formArr.forEach(item => {
                    var formData = this.$refs[item].getData()
                    this.reqForm = Object.assign({},this.reqForm, formData)
                })
            },
            getData() {
                return this.reqForm
            },
            dataCheck () {

               // this.getChildData()
               // this.$parent.getChildData()
               // this.$parent.changeOperat('auto')

                this.checkForm()
                this.doDataCheck ()
            },
            doDataCheck () {
                Promise.all(this.resultArr)
                    .then((res) => {
                        this.getChildData()
                        this.$parent.getChildData()
                        this.$parent.changeOperat('auto')
                    })
            },
            checkForm () {
                this.resultArr = []
                this.formArr.forEach(formName => {
                    this.doCheckForm(formName)
                })
            },
            doCheckForm (formName) {
                var result = new Promise((resolve, reject) => {
                    let valid = this.$refs[formName].dataCheck()           
                    if (valid) { 
                        resolve() }
                })
                this.resultArr.push(result)
            },
            doSubmit () {
                this.$store.dispatch('createPrivateInfo', this.reqForm)
            }
        }
    }
</script>

<style>

</style>
