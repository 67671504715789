<template>
    <div class="card border border-top-0 border-bottom-0">
        <div class="card-header item-hover" role="tab" id="headingNG" data-toggle="collapse" href="#collapseNG" aria-expanded="true" aria-controls="collapseNG">
            <h5 class="mb-0">
                <a class="text-body d-block p-3 m-n3 text-decoration-none" >
                    NG通知
                </a>
            </h5>
            <p class="mb-0" v-if="intvId"> <small class="text-muted">応募者へ不採用通知のメールを送信します。 </small> </p>
            <p class="mb-0" v-else> <small class="text-muted">応募者へ書類選考不合格のメールを送信します。 </small> </p>
        </div>
        <div id="collapseNG" class="collapse" role="tabpanel" aria-labelledby="headingNG" data-parent="#accordion">
            <div class="card-body">
                <div class="row justify-content-center">
                    <div class="col-sm-5">
                        <button @click="sendMailNG" class="btn btn-danger btn-block btn-lg" type="button" data-dismiss="modal">
                            <i class="fas fa-envelope"></i>&nbsp;送信
                        </button>
                    </div>
                </div>
                <div class="text-danger pt-3">
                    <small>
                        <i class="fas fa-info-circle"></i>
                        [送信]ボタンを押すとすぐに送信されますのでご注意ください。
                    </small>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        props: ['applyId', 'intvId', 'postId'],
        data () {
            return {
                loadStatus: 0
            }
        },
        watch: {
            getSendMailDocNGStatus () {
                this.loadStatus = this.getSendMailDocNGStatus
            },
            getSendMailIntvNGStatus () {
                this.loadStatus = this.getSendMailIntvNGStatus
            },
            loadStatus () {
                if (this.loadStatus == 2) {
                    // 最新のapplyPostを一覧へ反映
                    this.$store.dispatch('reflectList', this.getNewApplyPost.data)
                    this.$parent.closeThis()
                    this.$store.dispatch('doneSpinner')
                }
                if (this.loadStatus == 3) {
                    this.$store.dispatch('doneSpinner')
                }
            }
        },
        computed: {
            ...mapGetters(['getSendMailDocNGStatus', 'getSendMailIntvNGStatus', 'getNewApplyPost'])
        },
        methods: {
            sendMailNG () {

                this.$store.dispatch('startSpinner')

                if (this.intvId) {
                    // 面接不合格
                    this.$store.dispatch('sendMailIntvNG', {
                        intvId: this.intvId,
                        applyId: this.applyId,
                        postId: this.postId
                    })
                } else {
                    // 書類選考不合格
                    this.$store.dispatch('sendMailDocNG', {
                        applyId: this.applyId,
                        postId: this.postId
                    })
                }
            },
        }
    }
</script>

<style lang="stylus" scoped>

</style>
