<template>
    <div class="pb-3">
        <div class="card card-body com-card msg-bg px-2 py-3 p-md-4">
            <div v-for="msg in msgs" :key="msg.id" class="row mb-2 mb-md-3 let-space-1" 
                :class="{'justify-content-end': msg.sender_type == senderType}">
                <div class="col-md-8 col-lg-7">
                    <div class="d-flex text-muted">
                        <div v-if="msg.sender_type == senderType">{{msg.user_nm}}</div>
                        <div v-else>Promeraia</div>
                    </div>
                    <div class="card card-body px-2 pt-2 pb-1 px-md-3 pt-md-3 pb-md-2 msg" 
                         :class="{'my-msg': msg.sender_type == senderType}">
                        <div class="break-row pb-2" v-html="msg.message"></div>
                        <div class="text-right">
                            <small> {{msg.created_at | dformatm}} </small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card card-body com-card mt-2 px-2 py-3 p-md-4">
            <span class="mr-2 text-muted"><small>({{commentSize}}/2000)</small></span>
            <textarea ref="cmt" v-model.trim="comment" class="form-control" :style="commentStyle"  spellcheck=false  maxlength="2000"></textarea>
            <div class="ml-auto mt-3">
                <button class="btn btn-primary" :disabled="disableSend" @click="sendMsg">
                    <i class="fas fa-paper-plane"></i>&nbsp;送信
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        props: {
            msgs: Array,
            userNm: String,
            applyId: String,
            senderType: String,
        },
        data() {
            return {
                comment: "",
                commentHeight: "80px"
            }
        },
        watch: {
            comment () {
                this.resize()
            },
            getAddApplyHisMsgStatus (status) {
                if (status == 1) {
                    this.$store.dispatch('startSpinner')
                }
                if (status == 2) {
                    this.pushCommentToList()
                    this.$store.dispatch('doneSpinner')
                }
                if (status == 3) {
                    this.$store.dispatch('doneSpinner')
                }
            }
        },
        computed: {
            disableSend () {
                return this.comment.length > 0 ? false : true
            },
            commentSize () {
                return this.comment.length
            },
            commentStyle () {
                return {
                    "height": this.commentHeight,
                }
            },
            ...mapGetters(['getAddApplyHisMsgStatus'])
        },
        methods: {
            resize () {
                let height = this.$refs.cmt.scrollHeight
                if (height > 60) {
                    this.commentHeight = "auto"
                    this.$nextTick(()=> {
                        this.commentHeight = this.$refs.cmt.scrollHeight + 20 + 'px'
                    })
                }
            },
            sendMsg () {
                this.$store.dispatch('addApplyHisMsg', {
                    applyId: this.applyId ,
                    senderType: this.senderType,
                    msg: this.comment
                })
            },
            pushCommentToList () {
                this.$parent.pushCommentToList()
                this.resetComment()
            },
            resetComment () {
                this.comment = ""
            }

        }
    }
</script>

<style lang="stylus" scoped>
    .msg-bg
        background-color: white
    .msg
        border-radius: 0.5rem;
        background-color: white;
    .my-msg
        border-radius: 0.5rem;
        background-color: #b0fbd0;
        border:none;
    .break-row
        white-space:pre-wrap
        word-wrap:break-word
</style>
