<template>
    <div class="container let-space-1">
        <div class="card card-body com-card mb-2 py-md-5">
            <div class="text-center">
                <i class="fas fa-id-card icon-hd text-success"></i>
                <hr>
            </div>
            <div class="text-center mb-2 text-success">
                <div><span>本人確認は完了しています</span></div>
            </div>
            <div class="text-center mb-2">
                <div>
                    <span>
                        <small class="text-muted">{{approvalDate | dformato}}に完了</small>
                    </span>
                </div>
            </div>
            <div class="text-center">
                <span>
                    <small class="text-muted">
                        本人確認情報に変更がある場合は、再度本人確認書類を提出する必要があります。
                    </small>
                </span>
            </div>
        </div>
        <div class="card card-body com-card py-md-5">
            <div class="row d-flex justify-content-center">
                <div class="col-sm-4">
                    <button type="button" class="btn btn-primary btn-block" @click="sendIdentityMail">
                        書類を再提出する
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props:['approvalDate'],
        methods: {
            sendIdentityMail () {
                this.$parent.sendIdentityMail()
            }
        }
    }
</script>

<style lang="stylus" scoped>
    .icon-hd
        font-size 3rem
</style>
