export default {
    /**
     * Post 案件ホーム情報取得
     */
    loadCmpProjHome () {
        return axios.get(route('cmp.projs.home'))
    },

    /**
     * Post 案件情報登録/更新
     */
    saveCmpProjInfo (data) {
        return axios.post(route('cmp.proj.save'), data)
    },

    /**
     * Get 案件情報詳細取得
     */
    loadCmpProjInfo (data) {
        return axios.get(route('cmp.proj.detail', data.id))
    },
    
    /**
     * Post 案件終了/削除
     */
    cmpProjOff (data) {
        return axios.post(route('cmp.proj.off'), data)
    },

}
