var moment = require('moment');

// 案件開始時期編集
export const editProjStartDate = function (val) {
    let res = parseInt(String(val).substring(4,6))
    return res == '99' ? '即日～' : res + '月～'
}

// 案件終了予定日編集
export const editProjFinishDate = function (val, p=0) {
    let newDate = ""
    if (p == 0) {
        newDate = moment(val).format('MM/DD')
    }
    if (p == 1) {
        newDate = moment(val).format('MM/DD(dd)')
    }
    if (p == 2) {
        newDate = moment(val).format('YYYY/MM/DD(dd)')
    }
    return newDate
}

// 案件終了予定日警告
export const isWarningProjFinishDate = function (val) {
    let res = {}
    let td = moment(new Date).format('YYYYMMDD')
    let vd = moment(val)
    //console.log(vd.isAfter(td))
    // vd - td >= -1
    if (vd.diff(td, 'days') <= 1) {
        return true
    }
    return false
}

// 案件終了予定日期限判定
export const isOverProjFinishDate = function (val) {
    let res = {}
    let td = moment(new Date).format('YYYYMMDD')
    let vd = moment(val)
    //console.log(vd.isAfter(td))
    // vd - td >= 0
    if (vd.diff(td, 'days') < 0) {
        return true
    }
    return false
}

// 案件開始時期期限判定
export const isOverProjStartDate = function (val) {
    let res = {}
    let td = moment(new Date).format('YYYYMMDD')
    let vd = moment(val + '01')
    // vd - td >= 0
    if (vd.diff(td, 'months') < 0) {
        return true
    }
    return false
}

// 公開単価フォーマット(999,999,999)
export const numCommaFormat = function (val) {
    let full = val + '0000'
    return Number(full).toLocaleString()
}

// 数値フォーマット(999,999,999)
export const commaFormat = function (val) {
    return Number(val).toLocaleString()
}

// 契約期限過ぎたか判定
export const isOverContractEnd = function (val) {
    let td = moment(new Date).format('YYYYMMDD')
    let vd = moment(val)
    if (vd.diff(td, 'days') < 0) {
        return true
    }
    return false
}

// 時間比較
export const isAfter = function (val, val2) {
    let vd = moment(val)
    let vd2 = moment(val2)
    return vd.isAfter(vd2)
}
