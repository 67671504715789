<template>
    <div v-if="getCmpInviteHomeLoadStatus == 2">
        <invite-home-header page-name="招待" :istop="true"> </invite-home-header>

        <invite-home-total :item="getCmpInviteHome"></invite-home-total>

        <invite-home-list :item="getCmpInviteHome"></invite-home-list>

        <info-msg ref="infoMsg" :lev="lev" :msg="msg"></info-msg>
    </div>
</template>

<script>
    import InviteHomeHeader from 'pages/commons/header/Header'
    import InviteHomeTotal from './components/Total'
    import InviteHomeList from './components/List'
    import InfoMsg from 'pages/commons/modal/infoMsg/InfoMsg'

    import {mapGetters} from 'vuex'

    export default {
        components: {
            InviteHomeHeader,
            InviteHomeTotal,
            InviteHomeList,
            InfoMsg
        },
        data () {
            return {
                lev: 0,
                msg : ""
            }
        },
        mounted () {
            //this.$store.dispatch('resetListObj')
            this.loadCmpInviteHome()
        },
        watch: {
            getCmpInviteHomeLoadStatus (status) {
                if (status == 1) {
                    this.$store.dispatch('startProgress')
                }

                if (status == 2) {
                    this.$store.dispatch('doneProgress')
                }

                if (status == 3) {
                    this.$store.dispatch('doneProgress')
                }
            }
            
        },
        computed: {
            ...mapGetters(['getCmpInviteHomeLoadStatus', 'getCmpInviteHome'])
        },
        methods: {
            loadCmpInviteHome () {
                this.$store.dispatch('loadCmpInviteHome')
            },
            openInfoMsg (lev, msg) {
                this.lev = lev
                this.msg = msg
                this.$refs.infoMsg.openThis()
            },
        }
    }
</script>

<style></style>
