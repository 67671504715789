<template>
    <div class="text-muted">
        <div>
            <span class="text-nowrap mb-2">
                <i class="display-3" :class="ico" style="opacity:0.5;"></i>
            </span>
            <span class="text-nowrap py-2">
                <!--  err code -->
                <span class="font-size-cust">
                    <slot name="status"></slot>
                </span>

                <small>
                    <!-- err name -->
                    <slot name="en_text"></slot>
                </small>

            </span>
        </div>
        <div class="mt-3">
            <small>
                <!-- ja name -->
                <slot name="jp_text"></slot><br>

                <!-- reason -->
                <slot name="reason"></slot>

            </small>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            ico: String
        }
    }
</script>

<style lang="stylus" scoped>
    .font-size-cust
        font-size: 1.5rem
</style>
