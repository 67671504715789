<template>
    <div class="card-body p-0">
        <ul class="list-group list-group-flush">

            <li class="list-group-item border-top">
                <div class="mb-3">
                    以下情報でメール送信しますか？
                </div>
                <small>
                    <template v-if="processId.id == 'Intv'" >
                        <div class="mb-2">
                            面接結果：&nbsp;&nbsp;<span class="text-success">合格</span>
                        </div>
                        <div class="mb-2">
                            次回面接：&nbsp;
                            <span v-show="processId.child == 'not'" class="text-success">なし</span>
                            <span v-show="processId.child == 'has'" class="text-danger">あり</span>
                        </div>
                        <div v-show="processId.child == 'not'" class="mb-2">入場日・連絡先：</div>
                        <div v-show="processId.child == 'has'" class="mb-2">次回面接日程：</div>
                    </template>
                    <div v-else class="mb-2"> 面接日程：</div>

                    <div class="mb-2">
                        <i class="far fa-calendar-check"></i>&nbsp;&nbsp;{{items.inputDay | dformatw}}
                    </div>
                    <div class="mb-2">
                        <i class="far fa-clock"></i>&nbsp;&nbsp;{{items.inputHour}}:{{items.inputMinute}}
                    </div>
                    <div class="mb-2 all-break-cust">
                        <i class="fas fa-map-marked-alt"></i>&nbsp;&nbsp;{{items.inputPlace}}
                    </div>
                    <div class="mb-2">
                        <i class="fas fa-id-badge"></i>&nbsp;&nbsp;{{items.inputName}}さん
                    </div>
                    <div class="mb-2">
                        <i class="fas fa-phone"></i>&nbsp;&nbsp;{{items.inputPhone}}
                    </div>
                </small>
            </li>
            <div class="card-body row d-flex justify-content-center">
                <div class="col-sm-4 mb-2">
                    <button @click="toPrev" class="btn btn-primary btn-block btn-lg" type="button">
                        <i class="fas fa-angle-left"></i>&nbsp;戻る
                    </button>
                </div>
                <div class="col-sm-4">
                    <button @click="doSubmit" class="btn btn-danger btn-block btn-lg" type="button">
                        <i class="fas fa-envelope"></i>&nbsp;送信
                    </button>
                </div>
            </div>
        </ul>
    </div>
</template>

<script>
    export default {
        props: {
            items: Object,
            processId: Object
        },
        methods: {
            doSubmit () {
                this.$emit('doSubmit')
            },
            toPrev () {
                this.$emit('toPrev')
            }
        }
    }
</script>

<style lang="stylus" scoped></style>
