export default {

    cmpProjHome: {},
    // 0:waiting 1:loading 2:success 3:failed
    cmpProjHomeLoadStatus: 0,

    /** 案件情報情報 **/
    cmpProjInfo: {},
    // create/create_confirm/show/edit/edit_confirm
    cmpProjOperat: "",
    // 0:waiting 1:loading 2:success 3:failed
    saveCmpProjInfoStatus: 0,
    // 0:waiting 1:loading 2:success 3:failed
    cmpProjInfoLoadStatus: 0,
    // 0:waiting 1:loading 2:success 3:failed
    cmpProjOffStatus: 0,

}
