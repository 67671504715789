<template>
    <div>
        <home-header page-name="マイページ" :istop="true"> </home-header>
        <home-list></home-list>
    </div>
</template>

<script>
    import HomeHeader from 'pages/commons/header/Header'
    import HomeList from './components/List'

    import {mapGetters} from 'vuex'

    export default {
        mounted () {
            this.$store.dispatch('resetListObj')
        },
        components: {
            HomeHeader,
            HomeList,
        }
    }
</script>

<style></style>
