export default {

    projDetail: {},
    // 0:waiting 1:loading 2:success 3:failed
    projDetailLoadStatus: 0,

    /** 蠢懷供 **/
    projApplyOperat: "",
    // 0:waiting 1:loading 2:success 3:failed
    saveProjApplyStatus: 0,

    /** お気に入り**/
    // 0:waiting 1:loading 2:success 3:failed
    addFavoriteStatus: 0,
}
