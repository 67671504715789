<template>
    <div id="modal-info-msg" class="modal fade show">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <!-- <div class="modal-header"> -->
                <!--     <h5> -->
                <!--         <i v-if="lev == 0 || lev == 2" class="far fa-comment"></i> -->
                <!--         <i v-if="lev == 1" class="fas fa-exclamation-circle"></i> -->
                <!--         &nbsp;確認</h5> -->
                <!--     <button class="close" type="button" data-dismiss="modal"> -->
                <!--         <span aria-hidden="true">&times;</span> -->
                <!--     </button> -->
                <!-- </div> -->
                <div class="modal-body">
                    <div class="text-center display-4 py-3">
                        <span>
                            <!-- 完了/OK -->
                            <i v-if="lev == 0" class="text-success fas fa-check-circle"></i>
                            <!-- 警告 -->
                            <i v-if="lev == 1" class="text-danger fas fa-exclamation-circle"></i>
                            <!-- 確認 -->
                            <i v-if="lev == 2" class="text-success fas fa-question-circle"></i>
                            <!-- お気に入り(add) -->
                            <i v-if="lev == 3" class="text-danger fas fa-heart"></i>
                            <!-- お気に入り(delete) -->
                            <i v-if="lev == 4" class="text-muted fas fa-heart"></i>
                        </span>
                    </div>
                    <div class="text-center">
                        {{this.msg}}
                    </div>
                </div>
                <div v-if="lev == 0" class="modal-footer justify-content-center">
                    <button class="btn btn-success px-5" type="button" data-dismiss="modal">
                        閉じる
                    </button>
                </div>
                <div v-if="lev == 1" class="modal-footer justify-content-center">
                    <button class="btn btn-danger px-5" type="button" data-dismiss="modal">
                        閉じる
                    </button>
                </div>
                <div v-if="lev == 2" class="modal-footer px-2">
                    <div class="container">
                        <div class="row">
                            <div class="col-6">
                                <button class="btn btn-danger btn-block m-0" type="button" @click="handleOKBtn">
                                    次へ
                                </button>
                            </div>
                            <div class="col-6">
                                <button class="btn btn-primary btn-block m-0" type="button" data-dismiss="modal">
                                    閉じる
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="lev == 3" class="modal-footer justify-content-center">
                    <button class="btn btn-danger px-5" type="button" data-dismiss="modal">
                        閉じる
                    </button>
                </div>
                <div v-if="lev == 4" class="modal-footer justify-content-center">
                    <button class="btn btn-secondary px-5" type="button" data-dismiss="modal">
                        閉じる
                    </button>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            msg: String,
            lev: {
                type: Number,
                default: 0
            }
        },
        methods: {
            openThis () {
                $('#modal-info-msg').modal('show')
            },
            hideThis () {
                $('#modal-info-msg').modal('hide')
            },
            handleOKBtn () {
                this.hideThis()
                this.$emit('ok')
            }
        }
    }
</script>

<style>

</style>

