<template>
    <div>
        <act-list 
            ref="list"
            :header-text="headerText"
            :has-add="hasAdd"
            mt
            @add="addProj">
            <div v-if="getListObj">
                <div v-if="getListObj.data.length > 0">
                    <div class="card p-md-4 com-card mb-2" v-for="item in getListObj.data" :key="item.id" :class="{'com-card-focus': selectPj === item.id}">
                        <div class="card-header p-2 pt-3">
                            <div class="mb-2 text-muted d-flex">
                                <div>
                                    <span v-if="item.proj_price_set == '0'" class="text-muted">
                                        <i class="fas fa-yen-sign"></i>
                                        <span class="icon-size text-danger-cust">
                                            <span v-if="item.proj_price_start == '0'">~ {{item.proj_price_end}}</span>
                                            <span v-else-if="item.proj_price_start == item.proj_price_end">{{item.proj_price_end}}</span>
                                            <span v-else>{{item.proj_price_start}} ~ {{item.proj_price_end}}</span>
                                        </span>
                                        <span>万</span>
                                    </span>
                                    <span v-else class="text-nowrap text-danger-cust">
                                        スキル見合い
                                    </span>
                                </div>
                                <div class="text-right flex-fill pt-1 item-hover">
                                    <span class="text-muted pl-2 ml-auto" @click="openMenu(item)">
                                        <i class="fas fa-ellipsis-v"></i>
                                    </span>
                                </div>
                            </div>
                            <div class="mb-1 text-muted">
                                <span v-if="item.proj_remote_ok == '0'" class="text-nowrap border rounded px-1">
                                    <small>リモートOK</small>
                                </span>
                                <span v-if="item.proj_foreign_ok == '0'" class="text-nowrap border rounded px-1">
                                    <small>外国籍OK</small>
                                </span>
                                <span v-if="item.proj_is_adjust == '0'" class="text-nowrap border rounded px-1">
                                    <small>精算あり</small>
                                </span>
                            </div>
                            <div class="d-flex">
                                <div class="text-truncate text-muted">
                                    <span class="let-space-1">
                                        <span :class="{'text-danger-cust':isWarnFinishDate(item.proj_finish_date)}">
                                            <info-item v-if="isOverFinish(item.proj_finish_date)" left>
                                                <small>期限切れ</small>
                                            </info-item>
                                            <info-item v-else icn="fas fa-hourglass-half" left>
                                                <small>{{editFinishDate(item.proj_finish_date, 1)}}</small>
                                            </info-item>
                                        </span>
                                        <info-item class="text-nowrap" icn="fas fa-calendar-alt">
                                            <small>{{editStartDate(item.proj_start_date)}}</small>
                                        </info-item>
                                        <info-item v-if="item.proj_place" icn="fas fa-map-marker">
                                            <small>{{item.proj_place}}</small>
                                        </info-item>
                                        <info-item v-if="item.proj_members" icn="fas fa-user">
                                            <small>{{item.proj_members}}名</small>
                                        </info-item>
                                        <info-item v-if="item.proj_min_exper" icn="far fa-lightbulb">
                                            <small>exp.{{item.proj_min_exper}}</small>
                                        </info-item>
                                        <info-item v-if="item.proj_position" icn="fas fa-sitemap">
                                            <small>{{converVal('proj_position', item.proj_position)}}</small>
                                        </info-item>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="card-body pb-1 pt-3 px-2 item-hover" @click="toProjDetail(item.id)">
                            <div class="mb-2 text-muted">
                                <span v-if="item.proj_language" class="text-nowrap px-1 border rounded-pill">
                                    <small>{{item.proj_language}}</small>
                                </span>
                            </div>
                            <div>
                                {{item.proj_nm}}
                            </div>
                            <div class="d-flex text-muted let-space-1 pt-2 pt-md-3">
                                <span class="text-truncate pr-2">
                                        <small>
                                            <small>
                                                <i class="fas fa-pen"></i>
                                                {{item.user_nm}}
                                            </small>
                                        </small>
                                </span>
                                <span class="flex-fill text-nowrap text-right">
                                        <small>
                                            <small>
                                                <i class="fas fa-sync"></i>
                                                {{item.updated_at | dformatm}}
                                            </small>
                                        </small>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </act-list>
        <!-- メニュー -->
        <proj-menu ref="menu"></proj-menu>
        <!-- シェア -->
        <proj-share ref="share"></proj-share>
    </div>
</template>

<script>
    import {editProjStartDate
           ,editProjFinishDate
           ,isOverProjFinishDate
           ,isWarningProjFinishDate
           } from 'pages/commons/form/comFunc'
    import {converVal} from 'pages/commons/form/selectList'
    import ActList from 'pages/commons/List/List'
    import ProjMenu from './components/Menu'
    import ProjShare from 'pages/commons/projShare/ProjShare'

    import InfoItem from 'pages/commons/projList/CardHeadInfoItem'
    import {mapGetters} from 'vuex'

    export default {
        components: {
            ActList,
            ProjMenu,
            ProjShare,
            InfoItem
        },
        data () {
            return {
                selectPj: ""
            }
        },
        mounted () {
            this.$refs.list.loadListObj({
                url:'cmp.projs.act',
                data: '0' + '-' + this.$route.params.do
            })
            this.$refs.list.resetRefresh('cmpActProjs')
        },
        activated () {
            this.$refs.list.isGetList('cmpActProjs', { url:'cmp.projs.act', data: '0' + '-' + this.$route.params.do},'reset')
            this.$refs.list.resetRefresh('cmpActProjs')
        },
        computed: {
            headerText () {
                return this.$route.params.do == 'open' ? '募集中' : '募集終了'
            },
            hasAdd () {
                return this.$route.params.do == 'open' ? true : false
            },
            ...mapGetters(['getListObj'])
        },
        methods: {
            resetThis () {
                this.selectPj = ""
            },
            toProjDetail (id) {
                this.selectPj = id
                this.$router.push({name: 'CmpProjCreate', params:{ id: id }})
            },
            addProj () {
                this.$router.push({name: 'CmpProjCreate'})
            },
            openMenu (item) {
                this.$refs.menu.openThis(item)
            },
            projShare (item) {
                this.$refs.share.openThis(item)
            },
            seeMoreMt () {
                this.$store.dispatch('loadListObjSeeMore', {
                    url:'cmp.projs.act',
                    skip: this.$refs.list.lastId + '-' + this.$route.params.do
                })
            },
            editStartDate (val) {
                return editProjStartDate(val)
            },
            editFinishDate (val, p) {
                return editProjFinishDate(val, p)
            },
            isWarnFinishDate (val) {
                    return isWarningProjFinishDate(val)               
            },
            isOverFinish (val) {
                return isOverProjFinishDate(val)
            },
            converVal (name, val) {
                return converVal(name,val)
            },
        }
    }
</script>

<style lang="stylus" scoped>
    .card-color-cust
        background-color: #4dc0b521
</style>
