<template>
    <div id="modal-get-point" class="modal fade">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header py-2">
                    <h6 class="modal-title">友達紹介でポイントゲット</h6>
                    <button type="button" class="close" data-dismiss="modal" aria-label="閉じる">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body p-2">
                    <div class="card card-body p-2 border-0">
                        <point-item lab="STEP01">
                            <p> お友達を招待します。 </p>
                            <p>例）AさんがBさんを招待します。</p>
                            <p>例）BさんがCさんを招待します。</p>
                            <i class="fas fa-user-alt icon-size"></i>(Aさん)
                            <i class="fas fa-angle-double-right px-2"></i>
                            <i class="fas fa-user-alt icon-size"></i>(Bさん)
                            <i class="fas fa-angle-double-right px-2"></i>
                            <i class="fas fa-user-alt icon-size"></i>(Cさん)
                        </point-item>
                        <point-item lab="STEP02">
                            <p> 例）Bさんご自身よりにPromeraia案件に参画します。</p>
                            <i class="fas fa-user-alt icon-size"></i>(Aさん)
                            <i class="fas fa-angle-double-right px-2"></i>
                            <i class="fas fa-user-alt icon-size text-success"></i>(Bさん)
                            <i class="fas fa-angle-double-right px-2"></i>
                            <i class="fas fa-user-alt icon-size"></i>(Cさん)
                        </point-item>
                        <point-item lab="STEP03">
                            <p>Bさん参画期間中に<span class="text-danger-cust">毎月末頃</span>Aさんに10,000ptを加算いたします。 </p>
                            <p>例）Bさん参画期間は4月～5月の場合、Aさん獲得したポイントは下表通りです。</p>
                            <table class="table table-bordered">
                                <tr>
                                    <td>日付</td>
                                    <td>ポイント獲得量<br>(Bさん紹介分)</td>
                                    <td>ポイント獲得量<br>(Cさん紹介分)</td>
                                </tr>
                                <tr>
                                    <td>4月</td>
                                    <td>0pt</td>
                                    <td>0pt</td>
                                </tr>
                                <tr>
                                    <td>5月</td>
                                    <td>10,000pt</td>
                                    <td>0pt</td>
                                </tr>
                                <tr>
                                    <td>6月</td>
                                    <td>10,000pt</td>
                                    <td>0pt</td>
                                </tr>
                                <tr>
                                    <td>7月</td>
                                    <td>0pt</td>
                                    <td>0pt</td>
                                </tr>
                            </table>
                            <i class="fas fa-user-alt icon-size"></i>(Aさん)
                            <i class="fas fa-angle-double-right px-2"></i>
                            <i class="fas fa-user-alt icon-size text-success"></i>(Bさん)
                            <i class="fas fa-angle-double-right px-2"></i>
                            <i class="fas fa-user-alt icon-size"></i>(Cさん)
                        </point-item>
                        <point-item lab="STEP04">
                            <p> 例）CさんもPromeraia案件に参画した場合、Cさん参画期間中に、<span class="text-danger-cust">毎月末頃</span>Aさんに10,000ptを加算いたします。 </p>
                            <p> 例）Bさん参画期間は4月～5月とします。</p>
                            <p> 例）Cさん参画期間は5月～6月とします。</p>
                            <p> Aさん獲得したポイントは下表通りです。</p>
                            <table class="table table-bordered">
                                <tr>
                                    <td>日付</td>
                                    <td>ポイント獲得量<br>(Bさん紹介分)</td>
                                    <td>ポイント獲得量<br>(Cさん紹介分)</td>
                                </tr>
                                <tr>
                                    <td>4月</td>
                                    <td>0pt</td>
                                    <td>0pt</td>
                                </tr>
                                <tr>
                                    <td>5月</td>
                                    <td>10,000pt</td>
                                    <td>0pt</td>
                                </tr>
                                <tr>
                                    <td>6月</td>
                                    <td>10,000pt</td>
                                    <td>10,000pt</td>
                                </tr>
                                <tr>
                                    <td>7月</td>
                                    <td>0pt</td>
                                    <td>10,000pt</td>
                                </tr>
                                <tr>
                                    <td>8月</td>
                                    <td>0pt</td>
                                    <td>0pt</td>
                                </tr>
                            </table>
                            <i class="fas fa-user-alt icon-size"></i>(Aさん)
                            <i class="fas fa-angle-double-right px-2"></i>
                            <i class="fas fa-user-alt icon-size text-success"></i>(Bさん)
                            <i class="fas fa-angle-double-right px-2"></i>
                            <i class="fas fa-user-alt icon-size text-success"></i>(Cさん)
                        </point-item>
                        <point-item lab="注意事項">
                            <p>以下の項目に該当する場合はサービスの対象外とさせていただきます。ポイント付与後であっても、それを取り消す場合があります。</p>
                            <p>・自分で複数のメールアドレスを使用し、登録を行った場合</p>
                            <p>・明らかに不正とみなされるような行為を行った場合</p>
                            <p>・当サイトイメージを著しく損なう様な内容・表現方法での紹介を行った場合</p>
                            <p>不正防止のため、上記の項目以外にもいくつかチェックをさせていただいている項目がございます。予めご了承ください。</p>
                        </point-item>
                    </div>
                </div>
                <div class="modal-footer justify-content-center">
                    <button class="btn btn-primary" type="button" @click="hideThis">
                        &nbsp;&nbsp;閉じる&nbsp;&nbsp;
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PointItem from './GetPointLayout'
    export default {
        components: {
           PointItem 
        },
        methods: {
            openThis () {
                $('#modal-get-point').modal('show')
            },
            hideThis () {
                $('#modal-get-point').modal('hide')
            },
        }
    }
</script>

<style lang="stylus" scoped>
    p
        margin-bottom: 0.3rem !important
    table
        td
            padding-left: 0.3rem
            padding-right: 0.3rem

</style>
