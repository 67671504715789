<template>
        <div>
            <form-input ref="zipcode" ip-type="zipcode" :errs-nm="zipcodeNm" lab="郵便番号" required numeric :max-length="7" :length="[true,7]" zipcode @loadAddress="loadAddress"></form-input>
            <form-input ref="address1" :errs-nm="address1Nm" lab="都道府県/市区町村" plhd="例：〇〇県〇〇市" required :maxLength="20"></form-input>
            <form-input ref="address2" :errs-nm="address2Nm" lab="町名・番地など" plhd="例：〇〇町2-1-1" required :maxLength="30"></form-input>
            <form-input ref="address3" :errs-nm="address3Nm" lab="ビル・マンション名など" plhd="例：〇〇ビル101号室" required :maxLength="50"></form-input>
        </div>
</template>

<script>
    import FormInput from 'pages/commons/form/Input'
    import {mapGetters} from 'vuex'

    export default {
        props: {
            myNm: {
                type: String,
                default: ""
            }
        },
        components: {
            FormInput
        },
        data () {
            return {
                zipcodeNm: [this.myNm + '_zipcode'],
                address1Nm: [this.myNm + '_address1'],
                address2Nm: [this.myNm + '_address2'],
                address3Nm: [this.myNm + '_address3'],
                reqForm: {},
                resultArr: [],
                formArr:['zipcode'
                        ,'address1'
                        ,'address2'
                        ,'address3'
                ],
                address1Kana: "",
                address2Kana: "",
            }
        },
        watch: {
            getAddressInfo (info) {
                if (this.isMeCall(info)) {
                    this.setAddress(info)
                }
            },
            getZipcodeErrors (errs) {
                this.$refs.zipcode.setErrs(errs)
            }
        },
        computed: {
            ...mapGetters(['getAddressInfo', 'getZipcodeErrors'])
        },
        methods: {
            loadAddress (zipcode) {
                let data = {}
                data[this.myNm + '_zipcode'] = zipcode
                data['myNm'] = this.myNm
                this.$store.dispatch('loadAddress', data)
            },
            setAddress (info) {
                if (info.results) {
                    let obj = info.results[0]
                    this.$refs.address1.setInputData(obj.address1 + obj.address2)
                    this.$refs.address2.setInputData(obj.address3)
                    this.address1Kana = obj.kana1 + obj.kana2
                    this.address2Kana = obj.kana3
                }
            },
            setDefaultVal (zipcode, address1, address2, address3, address1_kana, address2_kana) {
                this.$refs.zipcode.setInputData(zipcode)
                this.$refs.address1.setInputData(address1)
                this.$refs.address2.setInputData(address2)
                this.$refs.address3.setInputData(address3)
                this.address1Kana = address1_kana
                this.address2Kana = address2_kana
                this.$refs.zipcode.zipcodeAsyn()
            },
            isMeCall (info) {
                return info.myNm == this.myNm
            },
            getData () {
                this.getChildData()
                let form = {}
                form[this.myNm + '_zipcode'] = this.reqForm[this.myNm + '_zipcode']
                form[this.myNm + '_address1'] = this.reqForm[this.myNm + '_address1']
                form[this.myNm + '_address2'] = this.reqForm[this.myNm + '_address2']
                form[this.myNm + '_address3'] = this.reqForm[this.myNm + '_address3']
                form[this.myNm + '_address1_kana'] = this.address1Kana
                form[this.myNm + '_address2_kana'] = this.address2Kana
                return form
            },
            getChildData () {
                this.formArr.forEach(item => {
                    var formData = this.$refs[item].getData()
                    this.reqForm = Object.assign({},this.reqForm, formData)
                })
            },
            checkForm () {
                this.resultArr = []
                this.formArr.forEach(formName => {
                    var result = new Promise((resolve, reject) => {
                        let valid = this.$refs[formName].dataCheck()           
                        if (valid) { 
                            resolve() }
                    })
                    this.resultArr.push(result)
                })
            },
            getResultArr () {
                return this.resultArr
            }
        }
    }

</script>

<style>
</style>
