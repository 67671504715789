<template>
    <div class="container p-2 card p-md-4 com-card">
        <div class="row">
            <div class="col-md-7">
                <div class="card-header text-muted let-space-1 p-2 pt-3">
                    <!-- 単価 -->
                    <head-price :item="item"></head-price>
                    <!-- 基本情報 -->
                    <head-info :item="item"></head-info>
                </div>
                <div class="card-body pt-3 px-2 item-hover item-big" @click="toProjDetail(item.id)">
                    <!-- 案件名 -->
                    <body-info :item="item" has-new :has-time="false"></body-info>
                </div>
            </div>
            <!-- <div class="col-md-5 p-2"> -->
            <!--     <div class="text-muted"><small><i class="fas fa-code icon-size"></i> 必須スキル </small></div> -->
            <!--     <div class="break-row"><small>{{item.proj_skill_need}} </small></div> -->
            <!-- </div> -->
            <div class="col-md-5">
                <div class="card-body py-0 px-2">
                    <div class="text-muted"><small>必須スキル </small></div>
                    <div class="break-row text-muted"><small>{{item.proj_skill_need}} </small></div>
                </div>
            </div>
        </div>
        <div class="text-right text-muted let-space-1 pt-2">
            <small>
                <i class="fas fa-sync"></i>
                {{item.updated_at | dformato}}
            </small>
        </div>
    </div>
</template>

<script>
    import HeadPrice from 'pages/commons/projList/CardHeadPrice'
    import HeadInfo from 'pages/commons/projList/CardHeadInfo'
    import BodyInfo from 'pages/commons/projList/CardBodyInfo'

    export default {
        components: {
            HeadPrice,
            HeadInfo,
            BodyInfo,
        },
        props: {
            item: Object
        },
        methods: {
            toProjDetail (id) {
                this.viewing(id)
                this.viewed(id)
                this.selectPj = id
                this.$router.push({name:'ProjDetail', params:{id: id}})
            },
            viewed (id) {
                let viewedProjs = []
                let proj = id + '-' + this.$moment(this.item.created_at).format('YYYYMMDD')
                try {
                    let list = localStorage.getItem('viewedProjs')
                    if (list) {
                       viewedProjs = JSON.parse(list)
                    }
                    if (!viewedProjs.includes(proj)) {
                        viewedProjs.push(proj)
                        localStorage.setItem('viewedProjs',JSON.stringify(viewedProjs))
                    }
                } catch (e) {}
            },
            viewing (id) {
                try {
                    localStorage.setItem('selectedProj',id)
                } catch (e) { }
            }
        }
    }

</script>

<style lang="stylus" scoped>
.item-big:hover
    font-weight:bold
.break-row
    white-space: pre-wrap
    word-wrap: break-word

    overflow: hidden
    display: -webkit-box
    -webkit-box-orient: vertical
    -webkit-line-clamp: 5
</style>
