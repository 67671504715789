<template>
    <div>
        <p :style="{marginBottom: mbH + 'rem'}">以下のいずれか１つ</p>
        <p :style="{marginBottom: mbH + 'rem'}">・有効な運転免許証（表・裏）</p>
        <p :style="{marginBottom: mbH + 'rem'}">・有効なパスポート（顔写真・所持人記入ページ）</p>
        <p :style="{marginBottom: mbH + 'rem'}">・健康保険証（表・裏）</p>
        <p :style="{marginBottom: mbH + 'rem'}">・住民票の写し（発行日から３か月以内）</p>
        <p :style="{marginBottom: mbH + 'rem'}">・在留カード（表・裏）</p>
        <p :style="{marginBottom: mbH + 'rem'}">・特別永住者証明書（表・裏）</p>
    </div>
</template>

<script>
    export default {
        props:['mbH']
    }
</script>

<style lang="stylus" scoped>

</style>
