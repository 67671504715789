<template>
    <div class="container p-0 m-0" :style="{minHeight:minHeight + 'px'}">
        <nav>
            <ol class="breadcrumb p-1 my-1">
                <span class="ml-auto mr-2 text-muted"> {{ getInterviewsCnt }} 件</span>
            </ol>
        </nav>
        <div class="accordion mb-2" id="accordion-interviews" role="tablist" aria-multiselectable="true">
            <div v-for="(dayItems,index) in getInterviewDays" :key="index" class="card border border-bottom mb-2">
                <a class="card-header text-dark text-decoration-none" role="tab" id="headingOne" data-toggle="collapse" :href="'#collapseOne' + index" aria-expanded="false" aria-controls="collapseOne">
                    <div class="row py-3 pl-1">
                        <div class="col-6 p-0">
                            {{dayItems.day | dformatw}}
                        </div>
                        <div class="col p-0">
                            <div class="wrapper">
                                <div class="maru badge-danger"><span>{{dayItems.in_NG}}</span></div>
                            </div>
                        </div>
                        <div class="col p-0">
                            <div class="wrapper">
                                <div class="maru badge-success"><span>{{dayItems.in_OK}}</span></div>
                            </div>
                        </div>
                        <div class="col p-0">
                            <div class="wrapper">
                                <div class="maru badge-warning"><span>{{dayItems.in_Wait}}</span></div>
                            </div>
                        </div>
                    </div>
                </a>
                <div :id="'collapseOne' + index" class="collapse" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion-interviews">
                    <div v-for="item in dayItems.list" :key="item.intv.id" class="card-body p-2">
                        <div class="card">
                            <div class="card-header text-muted let-space-1 p-2 pt-3 pb-1">
                                <div class="p-0 pb-1">
                                    <span v-if="item.intv.price_pub == '0'">
                                        <i class="fas fa-yen-sign"></i>
                                        <small v-if="item.intv.price_pub_start == item.intv.price_pub_end"> {{ item.intv.price_pub_end }}万円&nbsp; </small>
                                        <small v-else>{{ item.intv.price_pub_start }} ~ {{ item.intv.price_pub_end }}万円&nbsp; </small>
                                    </span>
                                    <span v-if="item.intv.gender == '0'" class="text-nowrap">
                                        <i class="fas fa-male"></i>
                                        <small>男性&nbsp;</small>
                                    </span>
                                    <span v-else class="text-nowrap">
                                        <i class="fas fa-female"></i>
                                        <small>女性&nbsp;</small>
                                    </span>
                                    <span class="text-nowrap">
                                        <i class="fas fa-user-graduate"></i>
                                        <small> {{ item.intv.age }}歳&nbsp; </small>
                                    </span>
                                    <span class="text-nowrap">
                                        <i class="fas fa-user-tie"></i>
                                        <small> {{ item.intv.exper }}年経験&nbsp; </small>
                                    </span>
                                </div>
                                <div>
                                    <span class="text-nowrap border rounded px-1">
                                        <small>稼働：{{editStartDate(item.intv.work_start_date)}}</small>
                                    </span>
                                    <span class="text-nowrap border rounded px-1">
                                        <small>最寄駅：{{item.intv.nearest_station}}</small>
                                    </span>
                                </div>
                                <div>
                                    <span class="text-nowrap">
                                        <i class="far fa-clock"></i>
                                        <small> {{item.intv.in_h}}:{{item.intv.in_m}}&nbsp; </small>
                                    </span>
                                    <span class="text-nowrap">
                                        <i class="fas fa-id-badge"></i>
                                        <small> {{item.intv.in_name}}さん&nbsp; </small>
                                    </span>
                                    <span class="text-nowrap">
                                        <i class="fas fa-phone"></i>
                                        <small> {{item.intv.in_phone}}&nbsp; </small>
                                    </span>
                                </div>
                                <div>
                                    <p class="wrap-place m-0" data-toggle="tooltip" :title="item.intv.in_place">
                                        <span class="intv-place">
                                            <small>
                                                <i class="fas fa-map-marked-alt"></i>&nbsp;{{item.intv.in_place}}
                                            </small>
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div class="card-body px-2" :class="{'pb-1': item.intv.in_flg != '0' && item.intv.in_flg != 'x'}">
                                <div class="text-right text-muted d-flex">
                                    <span v-if="item.intv.in_flg == 'x'">
                                        <i class="fas fa-calendar-times text-danger"></i>
                                        <small class="text-danger"> 取消済み </small>
                                    </span>
                                    <span class="flex-fill">
                                        <small> 面接番号：{{item.intv.intv_num}} </small>
                                    </span>
                                </div>
                                <div v-if="item.intv.in_flg == '2'">
                                    <small class="text-muted">
                                        名前：{{item.intv.first_nm_ok}} {{item.intv.last_nm_ok}} ({{item.intv.first_kana_ok}} {{item.intv.last_kana_ok}})
                                    </small>
                                </div>
                                <div v-else>
                                    <small v-if="item.intv.name_pub == '0'" class="text-muted">
                                        名前：{{item.intv.first_nm}} {{item.intv.last_nm}} ({{item.intv.first_kana}} {{item.intv.last_kana}})
                                    </small>
                                </div>
                                <div class="card-body p-0">
                                    <p class="wrap-name m-0">
                                        <span class="intv-name" data-toggle="tooltip" :title="item.intv.name">
                                            {{item.intv.name}}
                                        </span>
                                        <span>さん</span>
                                    </p>
                                </div>
                                <div class="card-body p-0 pr-1 text-primary d-flex let-space-1">
                                    <span v-if="item.intv.in_flg == '1'">
                                        <small> <i class="far fa-times-circle text-danger"></i>&nbsp;<span class="text-danger">不合格</span> </small>
                                    </span>
                                    <span v-else-if="item.intv.in_flg == '2'">
                                        <small> <i class="far fa-check-circle text-success"></i>&nbsp;<span class="text-success">合格</span> </small>
                                    </span>
                                    <span v-else-if="item.intv.in_flg == '3'">
                                        <small> <i class="far fa-check-circle text-success"></i>&nbsp;<span class="text-success">合格<small>&nbsp;次回{{item.intv.next_d | dformatw}}</small></span> </small>
                                    </span>
                                    <span v-show="item.hists.length" @click=toggleIcon(item.intv.id) class="ml-auto" >
                                        <small>面接履歴</small>
                                        <i class="fas fa-angle-down" :id="'icon' + item.intv.id"></i>
                                    </span>
                                </div>
                            </div>
                            <ul class="list-group list-group-flush collapse" :id="'hist-list' + item.intv.id">
                                <li v-for="hist in item.hists" :key="hist.id" class="list-group-item px-2 pb-1">
                                    <small>
                                        <p class="d-flex m-0">
                                            <span>
                                                {{hist.in_d | dformatw }}&nbsp;{{hist.in_h}}:{{hist.in_m}}&nbsp;
                                                <i class="far fa-check-circle text-success"></i>
                                            </span>
                                            <span @click="openIntvNote(hist.in_note, hist.id, hist.name, false)" class="text-primary flex-fill text-center">備考</span>
                                        </p>
                                        <p class="wrap-place m-0 mt-1" data-toggle="tooltip" :title="hist.in_place">
                                            <span class="intv-place">
                                                <i class="fas fa-map-marked-alt"></i>&nbsp;{{hist.in_place}}
                                            </span>
                                        </p>
                                    </small>
                                </li>
                            </ul>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item px-2 bg-light">
                                    <div class="justify-content-between d-flex text-muted">
                                        <span class="px-2">
                                            <a class="text-muted text-decoration-none" v-bind:href="item.intv.resume" target="_blank">
                                                <i class="fas fa-external-link-alt icon-size"></i>
                                                <small> 履歴書 </small>
                                            </a>
                                        </span>
                                        <span v-show="item.intv.in_flg == '0' || item.intv.in_flg == '4'" class="px-2" @click="openReplyTech(item.intv.id, item.intv.apply_id, item.intv.tech_id, item.intv.user_id)" >
                                            <i class="fas fa-paper-plane icon-size"></i>
                                            <small> 返信 </small>
                                        </span>
                                        <span class="px-2" @click="openIntvNote(item.intv.in_note, item.intv.id, item.intv.name , true)">
                                            <i class="fas fa-pen icon-size"></i>
                                            <small> 備考 </small>
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div><!-- /.card-body -->
                </div>
            </div>
        </div><!-- /#accordion -->

        <!-- 0件メッセージ -->
        <not-found-msg v-if="getInterviewsCnt == 0"></not-found-msg>

        <!-- 面接メモ -->
        <note></note>

    </div>
</template>

<script>
    import NotFoundMsg from 'pages/commons/notFoundMsg/NotFoundMsg'
    import Note from './Note'
    import {mapGetters} from 'vuex'

    export default {
        components: {
            Note,
            NotFoundMsg
        },
        mounted () {
            this.setTooltip()
        },
        updated () {
            this.setTooltip()
        },
        computed: {
            minHeight () {
                return $(window).height() - 54.22 - 63 - 111 - 55
            },
            ...mapGetters(['getInterviewsCnt', 'getInterviewDays'])
        },
        methods: {
            openReplyTech (intvId, applyId, techId, userId) {
                this.$store.dispatch('openReplyTech', {
                    intvId: intvId,
                    applyId: applyId,
                    techId: techId,
                    userId: userId
                })
            },
            toggleIcon (id) {
                let item = '#icon' + id
                let list = '#hist-list' + id
                if ($(list).hasClass('show')) {
                    $(list).collapse('hide')
                    $(item).removeClass('fa-angle-up').addClass('fa-angle-down')
                } else {
                    $(list).collapse('show')
                    $(item).removeClass('fa-angle-down').addClass('fa-angle-up')
                }
            },
            openIntvNote (note, intvId, name , editFlg) {
                this.$store.dispatch('openIntvNote', {
                    note: note,
                    intvId: intvId,
                    name: name,
                    editFlg: editFlg
                })
            },
            setTooltip () {
                $('[data-toggle="tooltip"]').tooltip()
            },
            editStartDate (val) {
                return this.$parent.editStartDate(val)
            },
        }
    }
</script>

<style lang="stylus" scoped>
    @import '~stylus/base/mixins.styl'
    .maru
       height:42px
       width:42px
       border-radius:50%
       line-height:42px
       text-align:center
       font-size:20px
    .wrapper
       display: flex
       justify-content: center
       align-items: center
       position:absolute
       height:100%
       width:100%
    .wrap-name, .wrap-place
        display:flex
        white-space: nowrap
        .intv-name, .intv-place
            ellipsis()
</style>

