<template>
    <div class="container">
        <div class="card card-body com-card py-md-5">
            <p class="display-4 text-center">
                <i class="text-success fas fa-check-circle"></i>
            </p>
            <span class="text-success text-center">
                ポイント交換申込完了
            </span>
            <hr>
            <span class="text-center text-muted">
                <small>
                    ※申込完了後のキャンセル・変更はできません。<br>
                    ポイント交換の履歴は「ポイント交換履歴」にて確認ください。<br>
                </small>
            </span>
        </div>
    </div>
</template>
<script>
    export default {

    }
</script>

<style lang="stylus" scoped>
</style>

