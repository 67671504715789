<template>
    <div class="row">
        <!-- <div class="col-3 pr-0"> -->
        <!--     <small> -->
        <!--         <b> -->
        <!--             {{lab}} -->
        <!--         </b> -->
        <!--     </small> -->
        <!-- </div> -->
        <div class="col">
            <div><small>{{lab}}</small></div>
            <div class="card card-body p-1 border-0">
                <small>
                    <slot></slot>
                </small>
            </div>
            <hr class="mt-1 mb-1 hr-dashed">
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            lab: String
        }
    }
</script>

<style></style>
