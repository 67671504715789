export default {

    /** Point情報 **/
    point: {},
    // 0:waiting 1:loading 2:success 3:failed
    pointLoadStatus: 0,

    /** ポイント換金 **/
    exchangeInit: 0,
    // 0:waiting 1:loading 2:success 3:failed
    exchangeInitLoadStatus: 0,
    // 0:waiting 1:loading 2:success 3:failed
    exchangePointStatus: 0,

    /** 招待情報取得 **/
    inviteInfo: {},
    // 0:waiting 1:loading 2:success 3:failed
    inviteInfoLoadStatus: 0,

    // 0:waiting 1:loading 2:success 3:failed
    // shareInviteCodeStatus: 0,

    
}
