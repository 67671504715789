export default {

    // 応募状況詳細
    applyStatusDetail: {},
    // 0:waiting 1:loading 2:success 3:failed
    applyStatusDetailLoadStatus: 0,

    /** 応募詳細画面 **/
    // 応募詳細
    appDetail: {},
    // 0:waiting 1:loading 2:success 3:failed
    appDetailLoadStatus: 0,
    // 対応後の最新apply_postを一覧に反映
    newApplyPost: {},
    addedInterview: {},
    // 0:waiting 1:loading 2:success 3:failed
    addInterviewStatus: 0,

    addedEntrance: {},

    // 0:waiting 1:loading 2:success 3:failed
    addNoteStatus: 0,

    // 0:waiting 1:loading 2:success 3:failed
    sendMailDocNGStatus: 0,
    // 0:waiting 1:loading 2:success 3:failed
    sendMailIntvNGStatus: 0,
    // 0:waiting 1:loading 2:success 3:failed
    sendMailIntvOKStatus: 0,
    // 0:waiting 1:loading 2:success 3:failed
    sendMailIntvOKWithNextStatus: 0,

    // 入場予定詳細
    enterDetail: {},
    // 0:waiting 1:loading 2:success 3:failed
    enterDetailLoadStatus: 0,





    
}
