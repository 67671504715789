<template>
    <div v-if="showPage">
        <!-- home->create->confirm1->create->home -->
        <!-- home->show->edit->confirm2->edit->show->home -->
        <confirm-header v-if="opt == 'create_confirm'" :page-name="pageName" do-cust @cust="backPage"></confirm-header>
        <confirm-header v-else-if="opt == 'edit_confirm'" :page-name="pageName" do-cust @cust="backPage"></confirm-header>
        <confirm-header v-else :page-name="pageName"></confirm-header>
        <div v-if="showItem" class="container">
            <div class="card com-card pt-md-4 mb-2 mb-md-3">
                <div class="card-body">
                    <form-item v-if="form.proj_open_flg == '0'" class="text-success" 
                               lab="求人状況">{{converVal('proj_open_flg', form.proj_open_flg)}}</form-item>
                    <form-item v-else class="text-danger" 
                               lab="求人状況">{{converVal('proj_open_flg', form.proj_open_flg)}}</form-item>
                    <form-item lab="募集終了予定日" :class="{'text-danger':isWarnFinish(form.proj_finish_date)}">{{converVal('proj_finish_date', form.proj_finish_date)}}</form-item>
                    <form-item lab="案件名">{{form.proj_nm}}</form-item>
                    <form-item v-if="form.proj_language" lab="開発言語">{{converVal('proj_lang', form.proj_language)}}</form-item>
                    <form-item v-if="form.proj_position" lab="ポジション">{{converVal('proj_position', form.proj_position)}}</form-item>
                    <form-item lab="開始時期">{{converVal('proj_start_date', form.proj_start_date)}}</form-item>
                    <form-item v-if="form.proj_interview" lab="面談回数">{{converVal('proj_intv', form.proj_interview)}}</form-item>

                    <form-item v-if="form.proj_price_set == '1'" 
                               lab="月額単価">スキル見合い</form-item>
                    <form-item v-else-if="form.proj_price_start == '0'" 
                               lab="月額単価">~ {{converVal('proj_price_end', form.proj_price_end)}}</form-item>
                    <form-item v-else-if="form.proj_price_start == form.proj_price_end" 
                               lab="月額単価">{{converVal('proj_price_end', form.proj_price_end)}}</form-item>
                    <form-item v-else 
                               lab="月額単価">{{converVal('proj_price_start', form.proj_price_start)}} ~ {{converVal('proj_price_end', form.proj_price_end)}} </form-item>

                    <form-item v-if="form.proj_is_adjust == '0'" lab="精算条件">{{converVal('proj_time_start', form.proj_time_start)}} ~ {{converVal('proj_time_end', form.proj_time_end)}}</form-item>
                    <form-item v-else-if="form.proj_is_adjust == '1'" lab="精算条件">固定</form-item>
                    <form-item v-else-if="form.proj_is_adjust == '2'" lab="精算条件">あり(精算幅確認中)</form-item>

                    <form-item v-if="form.proj_remote_ok" lab="リモートワーク">{{converVal('proj_remote_ok', form.proj_remote_ok)}}</form-item>

                    <form-item v-if="form.proj_place" lab="最寄り駅">{{form.proj_place}}</form-item>
                    <form-item v-if="form.proj_members" lab="募集人数">{{converVal('proj_members', form.proj_members)}}</form-item>
                    <form-item v-if="form.proj_min_exper" lab="経験年数">{{converVal('exper', form.proj_min_exper)}}</form-item>
                    <form-item v-if="form.proj_start_time" lab="始業時間">{{converVal('proj_start_time', form.proj_start_time)}}</form-item>
                    <form-item v-if="form.proj_foreign_ok" lab="外国籍">{{converVal('proj_foreign_ok', form.proj_foreign_ok)}}</form-item>

                    <form-item lab="案件概要">{{form.proj_detail}}</form-item>
                    <form-item lab="必須スキル">{{form.proj_skill_need}}</form-item>
                    <form-item v-if="form.proj_skill_option" lab="歓迎スキル">{{form.proj_skill_option}}</form-item>
                    <form-item v-if="form.proj_note" lab="備考">{{form.proj_note}}</form-item>
        
                    <div class="card mb-2 mt-5 border-0">
                        <div class="card-body">
                            <div v-if="opt == 'create_confirm'" class="row form-group justify-content-center mb-0">
                                <div class="col-sm-4">
                                    <button class="btn btn-danger btn-block btn-lg" @click="doSubmit">
                                        <i class="fas fa-save"></i>&nbsp;案件情報登録
                                    </button>
                                </div>
                            </div>
                            <div v-if="opt == 'edit_confirm'" class="row form-group justify-content-center mb-0">
                                <div class="col-sm-4">
                                    <button class="btn btn-danger btn-block btn-lg" @click="doSubmit">
                                        <i class="fas fa-save"></i>&nbsp;案件情報更新
                                    </button>
                                </div>
                            </div>
                            <div v-if="opt == 'show'" class="row form-group justify-content-center mb-0">
                                <div class="col-sm-4">
                                    <button class="btn btn-success btn-block btn-lg" @click="doEdit">
                                        <i class="fas fa-edit"></i>&nbsp;案件情報編集
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <not-found-msg v-else></not-found-msg>
    </div>
</template>

<script>
    import ConfirmHeader from 'pages/commons/header/Header'
    import FormItem from 'pages/commons/form/Confirm'

    import NotFoundMsg from 'pages/commons/notFoundMsg/NotFoundMsg'
    import {mapGetters} from 'vuex'

    export default {
        props: {
            form: Object,
            opt: String
        },
        components: {
            ConfirmHeader,
            FormItem,
            NotFoundMsg
        },
        computed: {
            // 複数担当者で操作する場合、A担当はListから詳細画面を開く直前、
            // 他の担当より案件が削除された場合、NotFound表示させる
            showPage () {
                // サーバーデータ取得完了までに画面を表示さえない制御用
                if (this.opt == 'show') {
                    return this.getComProjInfo.data
                } else {
                    return true
                }
            },
            showItem () {
                return Object.keys(this.form).length > 0
            },
            pageName () {
                if (this.opt == 'create_confirm') {
                    return "入力内容確認"
                }
                if (this.opt == 'edit_confirm') {
                    return "編集内容確認"
                }
                return "案件情報"
            },
            ...mapGetters(['getComProjInfo'])
        },
        methods: {
            backPage () {
                if (this.opt == 'create_confirm') {
                    this.$parent.changeOperat('create')
                }
                if (this.opt == 'edit_confirm') {
                    this.$parent.changeOperat('edit')
                }
            },
            converVal (name,value) {
                return this.$parent.converVal(name,value)
            },
            doSubmit () {
                this.$parent.doSubmit()
            },
            doEdit () {
                this.$parent.changeOperat('edit')
            },
            isWarnFinish (val) {
                return this.$parent.isWarnFinish(val)
            }
        }
    }
</script>

<style>

</style>
