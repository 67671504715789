export default {

    /** 応募状況画面 **/
    getApplyStatusDetail (state) {
        return state.applyStatusDetail 
    },
    getApplyStatusDetailLoadStatus (state) {
        return state.applyStatusDetailLoadStatus
    },

    /** 応募詳細画面 **/
    
    getAppDetail (state) {
        return state.appDetail.data
    },
    getSignedUrl (state) {
        return state.appDetail.data.post.proj_signed_url_2
    },
    getPost (state) {
        return state.appDetail.data.post
    },
    getTechs (state) {
        return state.appDetail.data.techs
    },
    getInterviewsCnt (state) {
        return state.appDetail.data.interviews.intv_cnt
    },
    getInterviewDays (state) {
        return state.appDetail.data.interviews.intv_list
    },
    getTimeCnt (state) {
        return state.appDetail.data.in_time_cnt
    },
    getEntrCnt (state) {
        return state.appDetail.data.in_entr_cnt
    },
    getPlaceCnt (state) {
        return state.appDetail.data.in_place_cnt
    },
    getContactCnt (state) {
        return state.appDetail.data.in_contact_cnt
    },
    getTimes (state) {
        return state.appDetail.data.in_times
    },
    getEntrs (state) {
        return state.appDetail.data.in_entrs
    },
    getPlaces (state) {
        return state.appDetail.data.in_places
    },
    getContacts (state) {
        return state.appDetail.data.in_contacts
    },
    getAppDetailLoadStatus (state) {
        return state.appDetailLoadStatus
    },
    getAddedInterview (state) {
        return state.addedInterview
    },
    getAddInterviewStatus (state) {
        return state.addInterviewStatus
    },
    getNewApplyPost (state) {
        return state.newApplyPost
    },
    getAddedEntrance (state) {
        return state.addedEntrance
    },
    getAddNoteStatus (state) {
        return state.addNoteStatus
    },
    getSendMailDocNGStatus (state) {
        return state.sendMailDocNGStatus
    },
    getSendMailIntvNGStatus (state) {
        return state.sendMailIntvNGStatus
    },
    getSendMailIntvOKStatus (state) {
        return state.sendMailIntvOKStatus
    },
    getSendMailIntvOKWithNextStatus (state) {
        return state.sendMailIntvOKWithNextStatus
    },

    // ?????\???ڍ?
    getEnterDetail (state) {
        return state.enterDetail
    },
    getEnterDetailLoadStatus (state) {
        return state.enterDetailLoadStatus
    }
}
