<template>
    <div>
        <form-header v-if="opt == 'create'" :page-name="pageName"></form-header>
        <form-header v-else :page-name="pageName" do-cust @cust="backPage"></form-header>
        <div class="container">
            <div class="card mb-2 com-card pt-md-4">
                <div class="card-body">

                    <!-- ニックネーム -->
                    <form-input ref="name" :errs-nm="['name']" lab="ニックネーム" plhd="例：ぷろめい 太郎 => PT" 
                        required upunder :max-length="20" @name_blur="nameUniqueCheck"></form-input>

                    <form-input ip-type="comment" py0>
                        <i class="fas fa-info-circle"></i>
                        <span class="let-space-1">
                            イニシャル（または姓）のみにしてください。
                        </span>
                    </form-input>

                    <!-- 性別 -->
                    <form-input ref="gender" ip-type="gender" :errs-nm="['gender']" lab="性別" required></form-input>

                    <!-- 年齢 -->
                    <form-input ref="age" ip-type="select" :errs-nm="['age']" :s-opt="ageList" val-nm="val"
                                lab-nm="lab" lab="年齢" required numeric :max-length="2"></form-input>

                    <!-- 経験年数 -->
                    <form-input ref="exper" ip-type="select" :errs-nm="['exper']" :s-opt="experList" val-nm="val"
                                lab-nm="lab" lab="経験年数" required></form-input>

                    <!-- 国籍 -->
                    <form-input ref="country" :errs-nm="['country']" lab="国籍" plhd="例：日本" required :max-length="20"></form-input>

                    <!-- 稼働開始 -->
                    <form-input ref="work_start_date" ip-type="select" :errs-nm="['work_start_date']" :s-opt="workStartDateList" val-nm="val"
                                lab-nm="lab" dft="100099" lab="稼働開始" required></form-input>

                    <!-- 最寄り駅 -->
                    <form-input ref="nearest_station" :errs-nm="['nearest_station']" lab="最寄り駅" plhd="例：品川駅" required :max-length="20"></form-input>

                    <!-- 希望単価(start) -->
                    <form-input ref="price_start" ip-type="select" :errs-nm="['hope_price_start']" :s-opt="priceList" val-nm="val"
                                lab-nm="lab" lab="希望単価(start)" required numeric :max-length="3" @hope_price_startChange="priceStartChange"></form-input>

                    <!-- 希望単価(end) -->
                    <form-input ref="price_end" ip-type="select" :errs-nm="['hope_price_end']" :s-opt="priceList" val-nm="val"
                                lab-nm="lab" lab="希望単価(end)" required numeric :max-length="3" @hope_price_endChange="priceEndChange"></form-input>

                    <form-input ip-type="comment" py0>
                        <span class="text-danger">
                            <i class="fas fa-exclamation-circle"></i>
                            現在所属会社経由の場合、所属会社から請求することになるので、自社の責任者と合意した上で単価を選択してくささい。
                        </span>
                    </form-input>
                    <hr>

                    <!-- 日本語レベル -->
                    <form-input ref="jpLevel" ip-type="select" :errs-nm="['jp_level']" :s-opt="jpList" val-nm="val"
                                lab-nm="lab" dft="0" lab="日本語レベル" required numeric :max-length="1"></form-input>

                    <!-- 英語レベル -->
                    <form-input ref="enLevel" ip-type="select" :errs-nm="['en_level']" :s-opt="enList" val-nm="val"
                                lab-nm="lab" lab="英語レベル" numeric :max-length="1"></form-input>

                    <!-- スキル -->
                    <form-input ref="skill" ip-type="textarea" :errs-nm="['skill']" lab="スキル" :row="2" 
                                plhd="必要に応じて記入してくさだい。" :max-length="500" has-len :has-trim="false" :no-blank="false"></form-input>

                    <!-- 希望 -->
                    <form-input ref="hope" ip-type="textarea" :errs-nm="['hope']" lab="希望" :row="2" 
                                plhd="必要に応じて記入してくさだい。" :max-length="500" has-len :has-trim="false" :no-blank="false"></form-input>

                    <!-- 備考 -->
                    <form-input ref="note" ip-type="textarea" :errs-nm="['note']" lab="備考" :row="5" 
                                plhd="必要に応じて記入してくさだい。" :max-length="500" has-len :has-trim="false" :no-blank="false"></form-input>

                    <div class="card mb-2 mt-5 border-0">
                        <div class="card-body">
                            <div class="row form-group justify-content-center mb-0">
                                <div class="col-sm-4">
                                    <button class="btn btn-success btn-block btn-lg" @click="dataCheck" :disabled="btnDisable">
                                        <i class="fas fa-eye"></i>&nbsp;確認画面へ
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import FormInput from 'pages/commons/form/Input'
    import FormHeader from 'pages/commons/header/Header'

    import {mapGetters} from 'vuex'

    export default {
        props: {
            opt: String
        },
        components: {
            FormInput,
            FormHeader
        },
        data () {
            return {
                experList: this.makeExperList(),
                workStartDateList: this.makeWorkStartDateList(),
                ageList: this.makeAgeList(),
                priceStartInit: "",
                priceEndInit: "",
                priceList: this.makePriceList(),
                jpList: this.makeJpList(),
                enList: this.makeEnList(),
                reqForm: {},
                resultArr: [],
                formArr:[
                        'name'
                        ,'gender'
                        ,'age'
                        ,'exper'
                        ,'country'
                        ,'work_start_date'
                        ,'nearest_station'
                        ,'price_start'
                        ,'price_end'
                        ,'jpLevel'
                        ,'enLevel'
                        ,'skill'
                        ,'hope'
                        ,'note'
                    ],
                uniqueId: null,
                btnDisable: false,
                errStyle: {
                    border: '1px solid #f7c6c5',
                    color: '#761b18',
                }
            }
        },
        watch: {
            getResumeInfo (info) {
                if (info.data.resume) {
                    this.setDefaultVal(info.data.resume)
                }
            },
            getUniqueCheckStatus (status) {
                if (status == 2) {
                    if (this.getUniqueCheckErr.err) {
                        this.btnDisable = true
                        this.$refs.name.setErrs(this.getUniqueCheckErr.err)
                        this.$refs.name.setStyle(this.errStyle)
                    } else {
                        this.btnDisable = false
                        this.$refs.name.clearErrs()
                        this.$refs.name.setStyle({})
                    }
                }
            }
        },
        computed: {
            pageName () {
                return this.opt == 'create' ? "履歴情報入力" : "履歴情報編集"
            },
            ...mapGetters(['getResumeInfo', 'getUniqueCheckStatus', 'getUniqueCheckErr'])
        },
        methods: {
            backPage () {
                this.$parent.changeOperat('show')
            },
            setDefaultVal (info) {
                // 編集ボダン押下
                this.$refs.name.setInputData(info.name)
                this.$refs.gender.setInputData(info.gender)
                this.$refs.age.setInputData(info.age)
                this.$refs.exper.setInputData(info.exper)
                this.$refs.country.setInputData(info.country)

                if (!this.$parent.isOverStart(info.work_start_date)) {
                    this.$refs.work_start_date.setInputData(info.work_start_date)
                }
                if (info.nearest_station) {
                    this.$refs.nearest_station.setInputData(info.nearest_station)
                }
                this.$refs.price_start.setInputData(info.hope_price_start)
                this.$refs.price_end.setInputData(info.hope_price_end)
                this.$refs.jpLevel.setInputData(info.jp_level)
                if (info.en_level) {
                    this.$refs.enLevel.setInputData(info.en_level)
                }
                if (info.skill) {
                    this.$refs.skill.setInputData(info.skill)
                }
                if (info.hope) {
                    this.$refs.hope.setInputData(info.hope)
                }
                if (info.note) {
                    this.$refs.note.setInputData(info.note)
                }
                // uniqueチェック用
                this.uniqueId = info.id ? info.id : null
            },
            getChildData () {
                this.formArr.forEach(item => {
                    var formData = this.$refs[item].getData()
                    this.reqForm = Object.assign({},this.reqForm, formData)
                })
            },
            getData() {
                return this.reqForm
            },
            dataCheck () {

                  /** test 用start **/
               // this.getChildData()
               // this.$parent.getChildData()
               // this.$parent.changeOperat('auto')
                  /** test 用end **/

                this.checkForm()
                this.doDataCheck ()
            },
            doDataCheck () {
                Promise.all(this.resultArr)
                    .then((res) => {
                        this.getChildData()
                        this.$parent.getChildData()
                        this.$parent.changeOperat('auto')
                    })
            },
            checkForm () {
                this.resultArr = []
                this.formArr.forEach(formName => {
                    this.doCheckForm(formName)
                })
            },
            doCheckForm (formName) {
                var result = new Promise((resolve, reject) => {
                    let valid = this.$refs[formName].dataCheck()           
                    if (valid) { 
                        resolve() }
                })
                this.resultArr.push(result)
            },
            priceStartChange (val) {
                let obj_end = this.$refs.price_end.getData()
                let end = obj_end['hope_price_end']
                if (val > end && end) {
                    this.$parent.openMsg('希望単価(start)には希望単価(end)より小さい金額を指定してください。', 1)
                    this.$refs.price_start.setInputData(this.priceStartInit)
                } else {
                    this.priceStartInit = val
                }
            },
            priceEndChange (val) {
                let obj_start = this.$refs.price_start.getData()
                let start = obj_start['hope_price_start']
                if (val < start && start) {
                    this.$parent.openMsg('希望単価(end)には希望単価(start)より大きい金額を指定してください。', 1)
                    this.$refs.price_end.setInputData(this.priceEndInit)
                } else {
                    this.priceEndInit = val
                }
            },
            nameUniqueCheck (val) {
                let opt = ""
                if (this.opt == 'create') {
                    opt = '0'
                }
                if (this.opt == 'edit') {
                    opt = '1'
                }
                this.$store.dispatch('uniqueCheck', {
                    val: val,
                    lab: 'ニックネーム',
                    table: 'teches',
                    col: 'name' , 
                    key: this.uniqueId ,
                    opt: opt 
                })
            },
            makeExperList () {
                return this.$parent.getList('exper')
            },
            makeWorkStartDateList () {
                return this.$parent.getList('work_start_date')
            },
            makeAgeList () {
                return this.$parent.getList('age')
            },
            makePriceList () {
                return this.$parent.getList('price')
            },
            makeJpList () {
                return this.$parent.getList('jp')
            },
            makeEnList () {
                return this.$parent.getList('en')
            },
        }
    }
</script>

<style lang="stylus" scoped>
    

</style>
