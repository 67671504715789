<template>
    <applys-list 
        ref="list"
        header-text="応募一覧"
        >
        <div v-if="getListObj">
            <div v-if="getListObj.data.length > 0">
                <div class="card mb-2" v-for="item in getListObj.data" :key="item.id">
                    <div class="card-header text-muted let-space-1 p-2 pt-2">
                        <div class="mb-1">
                            <span v-if="item.proj_open_flg == '0'">
                                <span v-if="isOverFinish(item.proj_finish_date)" class="text-danger">
                                    <small>期限切れ</small>
                                </span>
                                <span v-else class="text-success">
                                    <i class="fas fa-comment"></i>
                                    <small> 募集中</small>
                                </span>
                            </span>
                            <span v-else-if="item.proj_open_flg == '1'" class="text-danger">
                                <i class="fas fa-comment-slash"></i>
                                <small> 募集終了</small>
                            </span>
                            <span v-else class="text-muted">
                                <i class="fas fa-comment-slash"></i>
                                <small> 削除済み</small>
                            </span>
                            <span class="text-success" :class="{'text-danger':isWarnFinishDate(item.proj_finish_date)}">
                                &nbsp;<i class="fas fa-hourglass-half"></i>
                                <small>{{editFinishDate(item.proj_finish_date, 2)}}</small>
                            </span>
                        </div>
                        <div>
                            <span :class="{'text-danger': item.proj_open_flg == '0'}">
                                <i class="far fa-envelope"></i>
                                <small> &nbsp;{{item.apply_cmp_unopen}}&nbsp;&nbsp; </small>
                            </span>
                            <span :class="{'text-primary': item.proj_open_flg == '0'}" class="text-nowrap">
                                <i class="far fa-calendar-minus"></i>
                                <small> &nbsp;{{item.intv_cmp_cnt}}&nbsp;&nbsp; </small>

                                <i class="far fa-calendar-check"></i>
                                <small> &nbsp;{{item.intv_cmp_ok}}&nbsp;/&nbsp;{{item.intv_cmp_all}}&nbsp;&nbsp; </small>

                                <i class="far fa-calendar-plus"></i>
                                <small v-if="item.intv_cmp_cnt > 0">&nbsp;{{item.intv_d_new | dformats }}&nbsp;{{item.intv_h_new}}:{{item.intv_m_new}}&nbsp; </small>
                                <small v-else>&nbsp;なし</small>

                            </span>
                        </div>
                    </div>
                    <div class="card-body pb-1 pt-3 px-2" @click="toDetail(item.proj_id)">
                        <div>
                            {{ item.proj_nm }}
                        </div>
                        <div class="text-right text-muted let-space-1 pt-2">
                            <small><small>
                                <i class="fas fa-sync"></i>
                                {{item.updated_at | dformatm}}
                            </small></small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </applys-list>
</template>

<script>
import {editProjFinishDate
       ,isOverProjFinishDate
       ,isWarningProjFinishDate
       } from 'pages/commons/form/comFunc'
import ApplysList from 'pages/commons/List/List'
import {mapGetters} from 'vuex'

export default {
    components: {
        ApplysList
    },
    mounted () {
        this.$refs.list.loadListObj({url:'app.init'})
        this.$refs.list.resetRefresh('cmpApplys')
    },
    activated () {
        this.$refs.list.isGetList('cmpApplys', { url:'app.init'})
        this.$refs.list.resetRefresh('cmpApplys')
    },
    computed: {
        ...mapGetters(['getListObj'])
    },
    methods: {
        toDetail (id) {
            this.$router.push({name:'ApplyDetail', params: {id: id }})
        },
        editFinishDate (val, p) {
            return editProjFinishDate(val, p)
        },
        isWarnFinishDate (val) {
            return isWarningProjFinishDate(val)               
        },
        isOverFinish (val) {
            return isOverProjFinishDate(val)
        },
    }
}
</script>
