export default {
    /**
     * Get 応募案件詳細取得
     */
    loadProjDetail (data) {
        return axios.get(route('projs.detail', data))
    },

    /**
     * Post 案件応募する
     */
    saveProjApply (data) {
        return axios.post(route('projs.apply'), data)
    },

    /**
     * Post お気に入りに追加/削除
     */
    addFavorite (data) {
        return axios.post(route('projs.favorite', data))
    },
}
