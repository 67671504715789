<template>
    <div class="container let-space-1">
        <div class="card card-body com-card mb-2 py-md-5">
            <div class="text-center">
                <i class="far fa-envelope text-primary icon-hd"></i>
                <hr>
            </div>
            <div class="text-center mb-2">
                <div class="wrap-content justify-content-center">
                    <span class="text-primary inner-content">
                        {{email}}
                    </span>
                    <span> 宛に </span>
                </div>
                <div><span>本人確認のためのメールを送信しました。</span></div>
            </div>
            <div class="text-center">
                <span>
                    <small class="text-muted">
                        メールの受信にお時間がかかる場合があります。
                        メールが届かない場合は、以下より再送信してください。
                    </small>
                </span>
            </div>
        </div>
        <div class="card card-body com-card py-md-5">
            <div class="row d-flex justify-content-center">
                <div class="col-sm-4">
                    <button type="button" class="btn btn-primary btn-block" @click="sendIdentityMail">
                        メールを再送信する
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props:['email'],
        methods: {
            sendIdentityMail () {
                this.$parent.sendIdentityMail()
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import '~stylus/base/mixins.styl'
    .icon-hd
        font-size 3rem
    .wrap-content
        display: flex
        flex: 1
        white-space: nowrap
        overflow: hidden
        .inner-content
            ellipsis()

</style>
