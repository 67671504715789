<template>
    <li class="list-group-item">
      <div class="custom-control custom-switch p-0">
          <div class="form-group row m-0">
              <label class="col-3 col-form-label py-2 px-0" >
                  <small v-if="processId.id == 'Doc'"><span>面接日程</span></small>
                  <small v-else>
                      <span v-show="processId.child == 'not'">入場日付</span>
                      <span v-show="processId.child == 'has'">面接日程</span>
                  </small>

                  <div v-show="selectDateShow">
                      <div v-if="getTimeCnt != 0" class="custom-control custom-switch mt-4">
                         <input type="checkbox" v-model="dateChecked" class="custom-control-input" id="date-check">
                         <label class="custom-control-label" for="date-check"></label>
                      </div>
                  </div>

                  <div v-show="selectDateEntrShow">
                      <div v-if="getEntrCnt != 0" class="custom-control custom-switch mt-4">
                         <input type="checkbox" v-model="dateChecked" class="custom-control-input" id="date-check">
                         <label class="custom-control-label" for="date-check"></label>
                      </div>
                  </div>

              </label>
              <div class="col-9 p-0">

                  <div v-show="selectDateShow">
                      <select v-if="getTimeCnt != 0" v-model="dateSelect" :disabled="selectDateDisabled" :class="{disitem:selectDateDisabled}" class="form-control mb-2">
                          <option v-for="time in getTimes" :value="time.in_d + '$' + time.in_h + '$' + time.in_m" :key="time.id">{{time.in_d | dformatw}}&nbsp;{{time.in_h}}:{{time.in_m}}</option>
                      </select>
                  </div>

                  <div v-show="selectDateEntrShow">
                      <select v-if="getEntrCnt != 0" v-model="dateSelectEntr" :disabled="selectDateEntrDisabled" :class="{disitem:selectDateEntrDisabled}" class="form-control mb-2">
                          <option v-for="time in getEntrs" :value="time.in_d + '$' + time.in_h + '$' + time.in_m" :key="time.id">{{time.in_d | dformatw}}&nbsp;{{time.in_h}}:{{time.in_m}}</option>
                      </select>
                  </div>

                  <div class="container" :class="{disitem:inputDateDisabled}">
                      <div class="row">
                          <div class="col p-0">
                              <select v-model="inputDay" class="form-control mb-2" :disabled="inputDateDisabled">
                                  <option v-for="(op,index) in dayList" :value='op' :key="index">{{ op | dformatw }}</option>
                              </select>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-6 p-0">
                              <select v-model="inputHour" class="form-control" :disabled="inputDateDisabled">
                                  <option v-for="(n,index) in 24" :key="index" :value="addZero(n)" :selected="n == 12">{{n}}時</option>
                              </select>
                          </div>
                          <div class="col-6 p-0 pl-1">
                              <select v-model="inputMinute" class="form-control" :disabled="inputDateDisabled">
                                  <option v-for="(n,index) in 60" :key="index" :value="addZero(n-1)" :selected="n == 31">{{n-1}}分</option>
                              </select>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </li>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        props: ['processId'],
        data () {
            return {
                dateSelect: "",
                dateSelectEntr: "",
                dayList: [],
                inputDay: this.$moment(new Date).format('YYYY-MM-DD'),
                inputHour: 12,
                inputMinute: 30,
                dateChecked: false,
            }
        },
        mounted () {
            this.makeDayList();
            (this.getAddInterviewStatus == 2 || this.getSendMailIntvOKWithNextStatus == 2) ? this.updateDateSelected() : this.setDateSelected();
            this.getSendMailIntvOKStatus == 2 ? this.updateDateEntrSelected() : this.setDateEntrSelected();
        },
        computed: {
            selectDateShow () {
                if (this.processId.id == 'Doc' || this.processId.child == 'has') {
                    return true
                } else {
                    return false
                }
            },
            selectDateEntrShow () {
                return !this.selectDateShow
            },
            selectDateDisabled () {
                return this.dateChecked
            },
            selectDateEntrDisabled () {
                return this.dateChecked
            },
            inputDateDisabled () {
                if (this.processId.id == 'Doc' || this.processId.child == 'has') {
                    if (this.getTimeCnt == 0) {
                        return false
                    } else {
                        return !this.dateChecked
                    }
                } else {
                    if (this.getEntrCnt == 0) {
                        return false
                    } else {
                        return !this.dateChecked
                    }
                }
            },
            ...mapGetters([
                'getTimeCnt',
                'getTimes',
                'getEntrCnt',
                'getEntrs',
                'getAddedInterview',
                'getAddInterviewStatus',
                'getAddedEntrance',
                'getSendMailIntvOKStatus',
                'getSendMailIntvOKWithNextStatus'
            ]),
        },
        methods: {
            // 日付selectの選択肢作成
            makeDayList () {
                for (let i=0; i < 120; i++) {
                    this.dayList.push(this.$moment(new Date).add(i,'days').format('YYYY-MM-DD'))
                }
            },
            // 時、分の0埋め
            addZero (data) {
               return ('00' + data).slice(-2)
            },
            setDateSelected () {
                this.dateSelect = this.getTimeCnt > 0 ? this.getTimes[0].in_d + "$" + this.getTimes[0].in_h + "$" + this.getTimes[0].in_m : ""
            },
            setDateEntrSelected () {
                this.dateSelectEntr = this.getEntrCnt > 0 ? this.getEntrs[0].in_d + "$" + this.getEntrs[0].in_h + "$" + this.getEntrs[0].in_m : ""
            },
            updateDateSelected () {
                this.dateSelect = this.getAddedInterview.inputDay + "$" + this.getAddedInterview.inputHour + "$" + this.getAddedInterview.inputMinute
            },
            updateDateEntrSelected () {
                this.dateSelectEntr = this.getAddedEntrance.inputDay + "$" + this.getAddedEntrance.inputHour + "$" + this.getAddedEntrance.inputMinute
            },
            dataCheck () {
                return true
            },
            getData () {
                if (this.processId.id == 'Doc' || this.processId.child == 'has') {
                    return {
                        inputDay: this.inputDateDisabled ? this.dateSelect.split('$')[0].trim() : this.inputDay,
                        inputHour: this.inputDateDisabled ? this.dateSelect.split('$')[1].trim() : this.inputHour,
                        inputMinute: this.inputDateDisabled ? this.dateSelect.split('$')[2].trim() : this.inputMinute
                    }
                } else {
                    return {
                        inputDay: this.inputDateDisabled ? this.dateSelectEntr.split('$')[0].trim() : this.inputDay,
                        inputHour: this.inputDateDisabled ? this.dateSelectEntr.split('$')[1].trim() : this.inputHour,
                        inputMinute: this.inputDateDisabled ? this.dateSelectEntr.split('$')[2].trim() : this.inputMinute
                    }
                }
            }
        }
    }
</script>

<style lang="stylus" scoped>
    .disitem 
        opacity:0.4 !important
</style>
