<template>
    <div>
        <resume-form v-show="showForm" ref="userForm" :opt="operat"></resume-form>
        <resume-confirm v-show="!showForm" ref="userConfirm" :form="reqForm" :opt="operat"></resume-confirm>
        <success-msg ref="success" :lev="lev" :msg="msg"></success-msg>
    </div>
</template>

<script>
    import {isOverProjStartDate} from 'pages/commons/form/comFunc'
    import ResumeForm from './components/Form'
    import ResumeConfirm from './components/Confirm'
    import SuccessMsg from 'pages/commons/modal/infoMsg/InfoMsg'
    import {getList, converVal} from 'pages/commons/form/selectList'
    import {mapGetters} from 'vuex'

    export default {
        components: {
            ResumeForm,
            ResumeConfirm,
            SuccessMsg
        },
        data () {
            return {
                msg: "",
                lev: 0,
                operat: 'show',
                showForm: false,
                reqForm: {}
            }
        },
        mounted() {
            this.loadResume()
        },
        watch: {
            getResumeOperat (operat) {
                this.changeOperat(operat)
            },
            getResumeInfo (info) {
                if (info.data.resume) {
                    this.reqForm = info.data.resume
                } else {
                    this.operat = 'create'
                    this.showForm = true
                }
            },
            getResumeInfoLoadStatus (status) {
                if (status == '1') {
                    this.$store.dispatch('startProgress')
                }
                if (status == '2') {
                    this.$store.dispatch('doneProgress')
                }
                if (status == '3') {
                    this.$store.dispatch('doneProgress')
                }
            },
            getSaveResumeInfoStatus (status) {
                if (status == '1') {
                    this.$store.dispatch('startSpinner')
                }
                if (status == '2') {
                    this.$store.dispatch('doneSpinner')
                    this.openMsg()
                }
                if (status == '3') {
                    this.$store.dispatch('doneSpinner')
                }
            }
        },
        computed: {
            ...mapGetters(['getSaveResumeInfoStatus', 'getResumeOperat', 'getResumeInfoLoadStatus', 'getResumeInfo'])
        },
        methods: {
            loadResume () {
                this.$store.dispatch('loadResumeInfo')
            },
            doSubmit () {
                this.reqForm['file'] = this.getFile()
                this.reqForm['operat'] = this.operat
                let formData = this.makeFormData(this.reqForm)

                if (this.checkFile()) {
                    this.$store.dispatch('saveResumeInfo', formData)
                }
            },
            makeFormData (form) {
                let formData = new FormData();

                Object.keys(form).forEach(function (key) {
                    formData.append(key, form[key])
                })
                return formData
            },
            getChildData () {
                let userForm = this.$refs.userForm.getData()
                this.reqForm = Object.assign({}, this.reqForm, userForm)
            },
            getFile () {
                return this.$refs.userConfirm.getFile()
            },
            checkFile () {
                if (this.operat == 'edit_confirm') {
                    if (this.getFile()) {
                        if (this.checkFileSize()) {
                            return true
                        }
                    } else {
                        return true
                    }
                }
                if (this.operat == 'create_confirm') {
                    if ( this.checkFileExist() && this.checkFileSize()) {
                        return true
                    }
                }
                return false
            },
            checkFileSize () {
                return this.$refs.userConfirm.checkFileSize()
            },
            checkFileExist () {
                return this.$refs.userConfirm.checkFileExist()
            },
            openMsg (msg="完了しました。", lev=0) {
                this.msg = msg
                this.lev = lev
                this.$refs.success.openThis()
            },
            changeOperat (opt) {
                if (opt == 'auto') {
                    this.operat = (this.operat == 'create') ? 'create_confirm' : 'edit_confirm'
                } else {
                    this.operat = opt 
                }
                if (this.operat == 'create' || this.operat == 'edit') {
                    this.showForm = true
                }
                if (this.operat == 'create_confirm' || this.operat == 'edit_confirm' || this.operat == 'show') {
                    this.showForm = false
                }
                // スクロールをトップへ移動
                document.documentElement.scrollTop = 0
            },
            getList (name) {
                return getList(name)
            },
            converVal (name, val) {
                return converVal(name,val)
            },
            isOverStart (val) {
                return isOverProjStartDate(val)
            }
        }
    }
</script>

<style></style>
