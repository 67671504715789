<template>
    <div class="container">
        <div class="card item-hover mb-2 com-card py-md-5">
            <div class="card-body px-2 px-md-5">
                <div class="card-body p-2 text-muted text-center">
                    <b> あなたのポイント </b>
                </div>
                <div class="card-body p-2 text-center text-success ">
                    <span v-if="getPoint.points == 0" class="point-num"><b>{{toLocaleString(getPoint.points)}}</b></span>
                    <span v-else @click="toPointDetail" class="point-num"><b>{{toLocaleString(getPoint.points)}}</b></span>
                    <span class="pt-2"><b>点</b></span>
                </div>
                <div class="card-body p-2">
                    <span @click="openGetPoint" class="pr-2">
                        <i class="fas fa-piggy-bank text-success"></i>
                        <small class="text-muted">
                            ポイント獲得
                        </small>
                    </span>
                    <span @click="exchangeCheck" class="text-nowrap">
                        <i v-if="can_exchange" class="fas fa-sync-alt text-success"></i>
                        <i v-else class="fas fa-sync-alt text-muted"></i>
                        <small class="text-muted">
                            ポイント交換
                        </small>
                    </span>
                    <p class="pt-2 mb-0">
                        <span @click="openExchangeWarning">
                            <i class="fas fa-info-circle text-primary"></i>
                            <small class="text-muted">
                                ポイント交換(現金)に関する注意事項
                            </small>
                        </span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import {commaFormat} from 'pages/commons/form/comFunc'

    export default {
        computed: {
            can_exchange () {
                return this.getPoint.is_accept_date_ok && this.getPoint.is_exchange_cnt_ok && this.getPoint.is_exchange_amount_ok
            },
            ...mapGetters(['getPoint'])
        },
        methods: {
            toLocaleString (num) {
                return Number(num).toLocaleString()
            },
            toPointDetail () {
                this.$router.push({name:'PointDetail'})
            },
            exchangeCheck () {
                if (this.exchangeCheckDo()) {
                    this.$parent.openInfoMsg(2, 'ポイントを現金に換金しますか？')
                }
            },
            exchangeCheckDo () {
                if (!this.getPoint.is_accept_date_ok) {
                    this.$parent.openInfoMsg(1, '申込受付期間(6日～20日/月)外のため、ポイント交換を受付できません。')
                    return false;
                }
                if (!this.getPoint.is_exchange_cnt_ok) {
                    this.$parent.openInfoMsg(1, 'ポイント交換の申込は1ヶ月1回しかできません。')
                    return false;
                }
                if (!this.getPoint.is_exchange_amount_ok) {
                    let point_mini = commaFormat(this.getPoint.exchange_mini)
                    this.$parent.openInfoMsg(1, 'ポイントが' + point_mini + '以上ない場合、現金へ交換できません。')
                    return false;
                }

                return true;
            },
            openGetPoint () {
                this.$parent.openGetPoint();
            },
            openExchangeWarning () {
                this.$parent.openExchangeWarning();
            }
        }
    }
</script>

<style lang="stylus" scoped>
    .point-num
        font-size: 1.8rem;

</style>
