export default {

    /** 共通エラー **/
    getAllErrors (state) {
        return state.allErrors
    },

    /** 共通リスト **/
    getListObj (state) {
        return state.listObj.data
    },
    getListObjLoadStatus (state) {
        return state.listObjLoadStatus
    },
    getListObjSeeMoreLoadStatus (state) {
        return state.listObjSeeMoreLoadStatus
    },
    getListItemDeleteStatus (state) {
        return state.listItemDeleteStatus
    },

    /** ユーザー情報 **/
    getUser(state) {
        return state.user
    },
    getRole(state) {
        return state.user.role
    },
    getUserLoadStatus (state) {
        return state.userLoadStatus
    },
    getRefreshArr (state) {
        return state.refreshArr
    },

    /** 郵便住所 **/
    getAddressInfo (state) {
        return state.addressInfo.data
    },
    getZipcodeErrors (state) {
        return state.zipcodeErrors
    },
    getAddressInfoLoadStatus (state) {
        return state.addressInfoLoadStatus
    },

    /** 案件情報シエア詳細取得 **/
    getShareInfoDetail (state) {
        return state.shareInfoDetail
    },
    getShareInfoDetailLoadStatus (state) {
        return state.shareInfoDetailLoadStatus
    },

    /** Unique チェック **/
    getUniqueCheckErr (state) {
        return state.uniqueCheckErr
    },
    getUniqueCheckStatus (state) {
        return state.uniqueCheckStatus
    },
    /** 応募詳細メッセージ  **/
    getAddApplyHisMsg (state) {
        return state.addApplyHisMsg
    },
    getAddApplyHisMsgStatus (state) {
        return state.addApplyHisMsgStatus
    },

    /** 案件情報 **/
    getComProjOperat (state) {
        return state.comProjOperat
    },
    getComProjInfo (state) {
        return state.comProjInfo
    },
    getSaveComProjInfoStatus (state) {
        return state.saveComProjInfoStatus
    },
    getComProjInfoLoadStatus (state) {
        return state.comProjInfoLoadStatus
    },

    /** 案件・要員共通 **/
    getComMenuQuickOffStatus (state, status) {
        return state.comMenuQuickOffStatus
    },

    /** 履歴書詳細 **/
    getComTechOperat (state) {
        return state.comTechOperat
    },
    getComTechInfo (state) {
        return state.comTechInfo
    },
    getSaveComTechInfoStatus (state) {
        return state.saveComTechInfoStatus
    },
    getComTechInfoLoadStatus (state) {
        return state.comTechInfoLoadStatus
    },

    // 営業中要員・案件リスト取得
    getActSalesInfo (state) {
        return state.actSalesInfo.data 
    },
    getActSalesInfoStatus (state) {
        return state.actSalesInfoStatus
    },

    /** 個人・法人提案共通**/
    getSuggestionOperat (state) {
        return state.suggestionOperat
    },
    getSuggestionStatus (state) {
        return state.suggestionStatus
    }

}
