export default {

    /**
     * Get 応募状況詳細取得(案件)
     */
    loadApplyStatusDetail (id) {
        return axios.get(route('app.status.detail', id))
    },

    /**
     * Get 応募詳細取得
     */
    loadAppDetail (id) {
        return axios.get(route('app.detail', id))
    },

    /**
     * Post 面接追加
     */
    addInterview (data) {
        return axios.post(route('interview.add'), data)
    },

    /**
     * Post 面接備考追加
     */
    addNote (data) {
        return axios.post(route('note.add'), data)
    },

    /**
     * Post 書類選考NG通知
     */
    sendMailDocNG (data) {
        return axios.post(route('document.ng'), data)
    },

    /**
     * Post 面接NG通知
     */
    sendMailIntvNG (data) {
        return axios.post(route('interview.ng'), data)
    },

    /**
     * Post 面接OK通知
     */
    sendMailIntvOK (data) {
        return axios.post(route('interview.ok'), data)
    },

    /**
     * Post 面接OK通知(次回面接あり)
     */
    sendMailIntvOKWithNext (data) {
        return axios.post(route('interview.ok.next'), data)
    },

    /**
     * Get 入場予定詳細
     */
    loadEnterDetail (day) {
        return axios.get(route('app.enter.detail', day))
    },
}
