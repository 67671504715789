import CmpInvitesAPI from 'js/api/cmpInvites'

export default {

    /**  招待ホーム画面表示 **/

    loadCmpInviteHome ({commit}) {
        commit('setCmpInviteHomeLoadStatus', 1)

        CmpInvitesAPI.loadCmpInviteHome()
            .then(resp => {
                commit('setCmpInviteHome',resp)
                commit('setCmpInviteHomeLoadStatus', 2)
            })
            .catch(err => {
                commit('setCmpInviteHome', {})
                commit('setCmpInviteHomeLoadStatus', 3)
            })
    },

    /**  招待コード表示 **/

    loadCmpInviteInfo ({commit}) {
        commit('setCmpInviteInfoLoadStatus', 1)

        CmpInvitesAPI.loadCmpInviteInfo()
            .then(resp => {
                commit('setCmpInviteInfo',resp)
                commit('setCmpInviteInfoLoadStatus', 2)
            })
            .catch(err => {
                commit('setCmpInviteInfo', {})
                commit('setCmpInviteInfoLoadStatus', 3)
            })
    },

    /** リンクコピー用30分期限署名URL取得 **/

    loadQrUrlChatLimit ({commit}) {
        commit('setQrUrlChatLimitLoadStatus', 1)

        CmpInvitesAPI.loadQrUrlChatLimit()
            .then(resp => {
                commit('setQrUrlChatLimit',resp)
                commit('setQrUrlChatLimitLoadStatus', 2)
            })
            .catch(err => {
                commit('setQrUrlChatLimit', {})
                commit('setQrUrlChatLimitLoadStatus', 3)
            })
    },

    /** メール送信用期限付き署名URL取得 **/

    shareQrUrlMail ({commit}, data) {
        commit('setShareQrUrlMailStatus', 1)

        CmpInvitesAPI.shareQrUrlMail(data)
            .then(resp => {
                commit('setAllErrors', {})
                commit('setShareQrUrlMailStatus', 2)
            })
            .catch(err => {
                commit('setAllErrors', err.response.data.errors)
                commit('setShareQrUrlMailStatus', 3)
            })
    },

    /**  通常メンバーをオーナーへ変更 **/

    cmpInvitedChangeOwner ({commit}, data) {
        commit('setCmpInvitedChangeOwnerStatus', 1)

        CmpInvitesAPI.cmpInvitedChangeOwner(data)
            .then(resp => {
                commit('setCmpInvitedListLaterChangeOwner',resp)
                commit('setCmpInvitedChangeOwnerStatus', 2)
            })
            .catch(err => {
                commit('setCmpInvitedListLaterChangeOwner', {})
                commit('setCmpInvitedChangeOwnerStatus', 3)
            })
    },

    /**  通常メンバーから権限委譲依頼 **/

    cmpInvitedTransferOwner ({commit}, data) {
        commit('setCmpInvitedTransferOwnerStatus', 1)

        CmpInvitesAPI.cmpInvitedTransferOwner(data)
            .then(resp => {
                commit('setCmpInvitedTransferOwnerStatus', 2)
            })
            .catch(err => {
                commit('setCmpInvitedTransferOwnerStatus', 3)
            })
    },

    /**  利用者連携解除 **/

    cmpInvitedUserUnlink ({commit}, data) {
        commit('setCmpInvitedUserUnlinkStatus', 1)

        CmpInvitesAPI.cmpInvitedUserUnlink(data)
            .then(resp => {
                commit('setCmpInvitedListLaterUserUnlink',resp)
                commit('setCmpInvitedUserUnlinkStatus', 2)
            })
            .catch(err => {
                commit('setCmpInvitedListLaterUserUnlink', {})
                commit('setCmpInvitedUserUnlinkStatus', 3)
            })
    },

    /**  利用者連携 **/

    cmpInvitedUserLink ({commit}, data) {
        commit('setCmpInvitedUserLinkStatus', 1)

        CmpInvitesAPI.cmpInvitedUserLink(data)
            .then(resp => {
                commit('setAllErrors', {})
                commit('setCmpInvitedListLaterUserLink',resp)
                commit('setCmpInvitedUserLinkStatus', 2)
            })
            .catch(err => {
                commit('setAllErrors', err.response.data.errors)
                commit('setCmpInvitedListLaterUserLink', {})
                commit('setCmpInvitedUserLinkStatus', 3)
            })
    },

}
