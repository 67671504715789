export default {

    /** ホーム情報 **/
    getCmpInviteHome (state) {
        return state.cmpInviteHome.data
    },
    getCmpInviteHomeLoadStatus (state) {
        return state.cmpInviteHomeLoadStatus
    },

    /** 招待code情報 **/
    getCmpInviteInfo (state) {
        return state.cmpInviteInfo.data
    },
    getCmpInviteInfoLoadStatus (state) {
        return state.cmpInviteInfoLoadStatus
    },

    /** 招待コード情報取得(30分期限) **/
    getQrUrlChatLimit (state) {
        return state.qrUrlChatLimit.data
    },
    getQrUrlChatLimitLoadStatus (state) {
        return state.qrUrlChatLimitLoadStatus
    },
    /** 招待コード情報取得(法人：24時間期限) **/
    getShareQrUrlMailStatus (state) {
        return state.shareQrUrlMailStatus
    },
    /** 通常メンバーをオーナーへ変更 **/
    getCmpInvitedListLaterChangeOwner (state) {
        return state.cmpInvitedListLaterChangeOwner
    },
    getCmpInvitedChangeOwnerStatus (state) {
        return state.cmpInvitedChangeOwnerStatus
    },

    /**  通常メンバーから権限委譲依頼 **/
    getCmpInvitedTransferOwnerStatus (state) {
        return state.cmpInvitedTransferOwnerStatus
    },
    /**  利用者連携解除 **/
    getCmpInvitedListLaterUserUnlink (state) {
        return state.cmpInvitedListLaterUserUnlink
    },
    getCmpInvitedUserUnlinkStatus (state) {
        return state.cmpInvitedUserUnlinkStatus
    },

    /**  利用者連携 **/
    getCmpInvitedListLaterUserLink (state) {
        return state.cmpInvitedListLaterUserLink
    },
    getCmpInvitedUserLinkStatus (state) {
        return state.cmpInvitedUserLinkStatus
    },
}

