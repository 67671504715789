<template>
    <div>
        <detail-header page-name="応募詳細"></detail-header>
        <div class="container">
            <!-- Nav tabs -->
            <ul class="nav nav-tabs tab-sticky breadcrumb p-0 mb-1 nav-justified com-card" role="tablist">
                <li class="nav-item">
                    <a class="nav-link text-center px-2 py-md-3 border-0" data-toggle="tab" href="#project">
                        案件
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link text-center px-2 py-md-3 border-0" data-toggle="tab" href="#tech">
                        要員
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link active text-center px-2 py-md-3 border-0" data-toggle="tab" href="#message">
                        通知
                    </a>
                </li>
            </ul>
            <div class="tab-content">
                <div id="project" class="container tab-pane fade p-0">
                    <!-- 案件情報 -->
                    <proj-info :item="getApplyStatusDetail.proj"></proj-info>
                </div>

                <div id="tech" class="container tab-pane fade p-0">
                    <!-- 要員情報 -->
                    <tech-info :item="getApplyStatusDetail.tech"></tech-info>
                </div>

                <div id="message" class="container tab-pane active p-0">
                    <!-- メッセージ -->
                    <msg-content></msg-content>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DetailHeader from 'pages/commons/header/Header'
    import ProjInfo from './components/ProjInfo'
    import TechInfo from './components/TechInfo'
    import MsgContent from './components/MsgContent'
    import {mapGetters} from 'vuex'

    export default {
        components:{
            DetailHeader,
            ProjInfo,
            TechInfo,
            MsgContent
        },
        mounted () {
            this.loadApplyStatusDetail()
        },
        destroyed () {
            this.$store.dispatch('resetApplyStatusDetail')
        },
        watch: {
            getApplyStatusDetailLoadStatus (status) {
                if (status == 1) {
                    this.$store.dispatch('startProgress')
                }
                if (status == 2) {
                    this.$store.dispatch('doneProgress')
                }
                if (status == 3) {
                    this.$store.dispatch('doneProgress')
                }
            },
        },
        computed: {
            ...mapGetters(['getApplyStatusDetail'
                          ,'getApplyStatusDetailLoadStatus'
            ])
        },
        methods: {
            loadApplyStatusDetail () {
                this.$store.dispatch('loadApplyStatusDetail',{
                    id: this.$route.params.id
                })
            },
        }

    }
</script>

<style lang="stylus" scoped>
 .tab-sticky
    position:sticky!important
    top:122px!important
    z-index:98
</style>
