<template>
    <div id="modal-favorite-menu" class="modal fade show">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item item-hover" @click="deleteFavorite">
                            <i class="text-muted fas fa-trash icon-size"></i>
                            <span class="pl-2"> お気に入りから削除 </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        data () {
            return {
                id: ""
            }
        },
        watch: {
            getDeleteFavoriteStatus (status) {
                if (status == 0) {
                    this.$store.dispatch('startSpinner')
                }
                if (status == 2) {
                    this.$store.dispatch('delItemFromList', {
                        listItem: 'fa_id',
                        pageId: this.id
                    })
                    this.hideThis()
                    this.$store.dispatch('doneSpinner')
                }
                if (status == 3) {
                    this.hideThis()
                    this.$store.dispatch('doneSpinner')
                }
            }
        },
        computed: {
            ...mapGetters(['getDeleteFavoriteStatus'])
        },
        methods: {
            openThis (id) {
                this.id = id
                $('#modal-favorite-menu').modal('show')
            },
            hideThis () {
                this.resetThis()
                $('#modal-favorite-menu').modal('hide')
            },
            deleteFavorite () {
                this.$store.dispatch('deleteFavorite', {
                    id: this.id
                })
            },
            resetThis () {
                this.id = ""
            }
        }
    }
</script>

<style>

</style>
