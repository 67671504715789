<template>
    <div v-if="getCmpProjHome.data">
        <home-header page-name="案件" :istop="true"> </home-header>
        <home-total></home-total>
        <home-list></home-list>
    </div>
</template>

<script>
    import HomeHeader from 'pages/commons/header/Header'
    import HomeTotal from './components/Total'
    import HomeList from './components/List'

    import {mapGetters} from 'vuex'

    export default {
        created () {
            this.initHome()
        },
        mounted () {
            this.$store.dispatch('resetListObj')
        },
        components: {
            HomeHeader,
            HomeTotal,
            HomeList,
        },
        watch: {
            getCmpProjHomeLoadStatus (status) {
                if (status == 1) {
                    this.$store.dispatch('startProgress')
                }
                if (status == 2) {
                    this.$store.dispatch('doneProgress')
                }
                if (status == 3) {
                    this.$store.dispatch('doneProgress')
                }
            }
        },
        computed: {
            ...mapGetters(['getCmpProjHomeLoadStatus', 'getCmpProjHome'])
        },
        methods: {
            initHome () {
                this.$store.dispatch('loadCmpProjHome')
            },
        }
    }
</script>

<style></style>
