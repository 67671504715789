<template>
    <div class="container">
        <div class="row">
            <div class="col-sm-4 mb-2">
                <page-nav icn="fas fa-comment text-primary" lab="募集中の案件" @do_click="toActProj"></page-nav>
            </div>
            <div class="col-sm-4 mb-2">
                <page-nav icn="fas fa-comment-slash text-primary" lab="募集終了の案件" @do_click="toInactProj">
                    <small class="text-muted">
                        360日間保存
                    </small>
                </page-nav>
            </div>
            <div class="col-sm-4 mb-2">
                <page-nav icn="fas fa-user-check text-primary" lab="営業中の要員" @do_click="toActTech"></page-nav>
            </div>
            <div class="col-sm-4 mb-2">
                <page-nav icn="fas fa-user-times text-primary" lab="営業終了の要員" @do_click="toInactTech"></page-nav>
            </div>
        </div>
    </div>
</template>

<script>
    import PageNav from 'pages/commons/nav/PageNav'

    export default {
        components:{
            PageNav
        },
        methods: {
            toActProj () {
                this.$store.dispatch('setRefreshArr', 'comActProjs')
                this.$router.push({name: 'ServiceProj', params: {do: 'open'}})
            },
            toInactProj () {
                this.$store.dispatch('setRefreshArr', 'comActProjs')
                this.$router.push({name: 'ServiceProj', params: {do: 'close'}})
            },
            toActTech () {
                this.$store.dispatch('setRefreshArr', 'comActTech')
                this.$router.push({name: 'ServiceTech', params: {do: 'open'}})
            },
            toInactTech () {
                this.$store.dispatch('setRefreshArr', 'comActTech')
                this.$router.push({name: 'ServiceTech', params: {do: 'close'}})
            }
        }
    }
</script>

<style lang="stylus" scoped>

</style>
