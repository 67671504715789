<template>
    <li class="list-group-item border-top">
        <div class="custom-control custom-switch p-0">
            <div class="form-group row m-0">
                <label for="next" class="col-3 col-form-label p-0">
                    <small>
                        次回面接
                    </small>
                </label>
                <div class="col-9 p-0 d-flex">
                    <div class="form-check form-check-inline col">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" value="0" v-model="hasNext">
                        <label class="form-check-label">なし</label>
                    </div>
                    <div class="form-check form-check-inline col">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" value="1" v-model="hasNext">
                        <label class="form-check-label">あり</label>
                    </div>
                </div>
            </div>
        </div>
    </li>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        data () {
            return {
                hasNext: '0'
            }
        },
        watch: {
            hasNext () {
                this.$emit('hasNext', this.hasNext)
            }
        }
    }
</script>

<style lang="stylus" scoped>
</style>
