import {EventBus} from 'js/event-bus'
import ComsAPI from 'js/api/commons'

export default {

    /** $emit **/

    routerToHome () {
        EventBus.$emit('routerToHome')
    },
    startProgress () {
        EventBus.$emit('startProgress')
    },
    doneProgress () {
        EventBus.$emit('doneProgress')
    },
    startSpinner () {
        EventBus.$emit('startSpinner')
    },
    doneSpinner () {
        EventBus.$emit('doneSpinner')
    },
    openReplyTech ({commit}, data) {
        EventBus.$emit('openReplyTech', data)
    },
    openIntvNote ({commit}, data) {
        EventBus.$emit('openIntvNote', data)
    },
    getUser ({commit}) {
        commit('setUserLoadStatus', 1)
        ComsAPI.getUser()
            .then(resp => {
                commit('setUser', resp.data)
                commit('setUserLoadStatus', 2)
            })
            .catch(err => {
                commit('setUser', {})
                commit('setUserLoadStatus', 3)
            })
    },
    loadUserGuard () {
        return ComsAPI.getUser()
    },
    setRefreshArr ({commit}, item) {
        commit('setRefreshArr', item)
    },
    resetRefreshArr ({commit}, item) {
        commit('resetRefreshArr', item)
    },

    /** リスト画面共通 **/
    loadListObj ({commit}, data) {
        commit('setListObjLoadStatus', 1)

        ComsAPI.loadListObj(data)
            .then(resp => {
                commit('setListObj',resp)
                commit('setListObjLoadStatus', 2)
            })
            .catch(err => {
                commit('setListObj', {})
                commit('setListObjLoadStatus', 3)
            })
    },
    // 特定対象更新
    setListObjSpecificItem ({commit}, data) {
        commit('setListObjSpecificItem', data)
    },
    // リスト全体を更新
    setListObj ({commit}, data) {
        commit('setListObj',data)
    },
    // 新規・更新をリストへ反映
    reflectList ({commit}, data) {
        commit('reflectList', data)
    },
    resetListObj ({commit}) {
        commit('resetListObj')
    },
    // 子画面もlistObj利用する時、親画面のデータが上書きされる為、保存して置く
    setListObjKeep ({commit}) {
        commit('setListObjKeep')
    },
    // 子画面で処理した結果を親画面へ反映必要の場合、
    // 子画面で画面リフレッシュしたら、親画面のデータが
    // なくなるとデータ反映の時エラー発生
    setListObjSession ({commit}) {
        commit('setListObjSession')
    },
    delItemFromList ({commit}, data) {
        commit('delItemFromList', data)
    },
    backListFromKeep ({commit}) {
        commit('backListFromKeep')
    },
    loadListObjSeeMore ({commit}, data) {
        commit('setListObjSeeMoreLoadStatus', 1)

        ComsAPI.loadListObjSeeMore(data)
            .then(resp => {
                commit('setListObjAddSeeMore',resp)
                commit('setListObjSeeMoreLoadStatus', 2)
            })
            .catch(err => {
                commit('setListObjSeeMoreLoadStatus', 3)
            })
    },
    /** 削除しかないメニューの場合  **/
    listItemDelete ({commit}, data) {
        commit('setListItemDeleteStatus', 1)

        ComsAPI.listItemDelete(data)
            .then(resp => {
                commit('setListItemDeleteStatus', 2)
            })
            .catch(err => {
                commit('setListItemDeleteStatus', 3)
            })
    },

    /** 郵便番号住所取得 **/
    loadAddress ({commit}, data) {
        commit('setAddressInfoLoadStatus', 1)

        ComsAPI.loadAddress(data)
            .then(resp => {
                if (resp.data.results) {
                    commit('clearZipCodeErrors', {})
                } else {
                    commit('setZipErrNotFound', data.myNm)
                }

                commit('setAddressInfo',resp)
                commit('setMyNm',data.myNm)
                commit('setAddressInfoLoadStatus', 2)
            })
            .catch(err => {
                commit('setZipCodeErrors', err.response.data.errors)
                commit('setAddressInfoLoadStatus', 3)
            })
    },
    
    /** 案件・要員情報シエア詳細取得 **/
    loadShareInfoDetail ({commit}, data) {
        commit('setShareInfoDetailLoadStatus', 1)
        ComsAPI.loadShareInfoDetail(data)
            .then(resp => {
                commit('setShareInfoDetail', resp)
                commit('setShareInfoDetailLoadStatus', 2)
            })
            .catch(err => {
                commit('setShareInfoDetail', {})
                commit('setShareInfoDetailLoadStatus', 3)
            })
    },

    /** Unique チェック
     * val:対象項目値 
     * info: 対象レコードkey + 操作(0:登録、1:更新)
     *
     */
    uniqueCheck ({commit}, data) {
        commit('setUniqueCheckStatus', 1)
        ComsAPI.uniqueCheck(data)
            .then(resp => {
                commit('setUniqueCheckErr', resp.data)
                commit('setUniqueCheckStatus', 2)
            })
            .catch(err => {
                commit('setUniqueCheckErr', {})
                commit('setUniqueCheckStatus', 3)
            })
    },

    /** 応募詳細メッセージ **/
    addApplyHisMsg ({commit}, data) {
        commit('setAddApplyHisMsgStatus', 1)
        ComsAPI.addApplyHisMsg(data)
            .then(resp => {
                commit('setAddApplyHisMsg', resp.data.msg)
                commit('setAddApplyHisMsgStatus', 2)
            })
            .catch(err => {
                commit('setAddApplyHisMsgStatus', 3)
            })
    },

    /** 新規案件追加・変更 **/
    saveComProjInfo ({commit}, data) {
        commit('resetComProjOperat')
        commit('setSaveComProjInfoStatus', 1)

        ComsAPI.saveComProjInfo(data)
            .then(resp => {
                commit('setAllErrors', {})
                commit('setComProjOperat', 'success')
                commit('setComProjInfo', resp)
                commit('setSaveComProjInfoStatus', 2)
            })
            .catch(err => {
                commit('setComProjOperat', data.operat)
                commit('setAllErrors', err.response.data.errors)
                commit('setSaveComProjInfoStatus', 3)
            })
    },

    loadComProjInfo ({commit}, data) {
        commit('setComProjInfoLoadStatus', 1)

        ComsAPI.loadComProjInfo(data)
            .then(resp => {
                commit('setComProjInfo', resp)
                commit('setComProjInfoLoadStatus', 2)
            })
            .catch(err => {
                commit('setComProjInfo', {})
                commit('setComProjInfoLoadStatus', 3)
            })
    },

    // 複数担当の場合、詳細画面初期表示制御(閲覧直前削除され)
    // URL欄のID修正して該当するデータ無い場合、NotFound表示
    resetComProjInfo ({commit}) {
        commit('resetComProjInfo')
    },


    comMenuQuickOff ({commit}, data) {
        commit('setComMenuQuickOffStatus', 1)

        ComsAPI.comMenuQuickOff(data)
            .then(resp => {
                commit('setComMenuQuickOffStatus', 2)
            })
            .catch(err => {
                commit('setComMenuQuickOffStatus', 3)
            })
    },

    /** 履歴書作成・更新 **/
    saveComTechInfo ({commit}, data) {
        commit('resetComTechOperat')
        commit('setSaveComTechInfoStatus', 1)

        ComsAPI.saveComTechInfo(data)
            .then(resp => {
                commit('setAllErrors', {})
                commit('setComTechOperat', 'success')
                commit('setComTechInfo', resp)
                commit('setSaveComTechInfoStatus', 2)
            })
            .catch(err => {
                commit('setComTechOperat', data.get('operat'))
                commit('setAllErrors', err.response.data.errors)
                commit('setSaveComTechInfoStatus', 3)
            })
    },

    /** 履歴書詳細 **/
    loadComTechInfo ({commit}, data) {
        commit('setComTechInfoLoadStatus', 1)

        ComsAPI.loadComTechInfo(data)
            .then(resp => {
                commit('setComTechInfo', resp)
                commit('setComTechInfoLoadStatus', 2)
            })
            .catch(err => {
                commit('setComTechInfo', {})
                commit('setComTechInfoLoadStatus', 3)
            })
    },

    // 複数担当の場合、詳細画面初期表示制御(閲覧直前削除され)
    // URL欄のID修正して該当するデータ無い場合、NotFound表示
    resetComTechInfo ({commit}) {
        commit('resetComTechInfo')
    },

    /** 営業中要員・案件リスト取得 **/
    loadActSalesInfo ({commit}, data) {
        commit('setActSalesInfoStatus', 1)

        ComsAPI.loadActSalesInfo(data)
            .then(resp => {
                commit('setActSalesInfo', resp)
                commit('setActSalesInfoStatus', 2)
            })
            .catch(err => {
                commit('setActSalesInfo', {})
                commit('setActSalesInfoStatus', 3)
            })
    },


    /** 個人・法人提案共通**/
    comSuggestion ({commit, dispatch, state}, data) {
        commit('resetSuggestionOperat')
        commit('setSuggestionStatus', 1)

        ComsAPI.comSuggestion(data)
            .then(resp => {
                if (resp.data.rest) {
                    commit('setSuggestionOperat', 'success')
                } else {
                    if (resp.data.is_deleted) {
                        commit('setSuggestionOperat', 'deleted')
                    }
                    if (resp.data.is_closed) {
                        commit('setSuggestionOperat', 'closed')
                    }
                }
                commit('setSuggestionStatus', 2)
                //dispatch('loadProjDetail',state.projDetail.data.project)
            })
            .catch(err => {
                commit('setSuggestionStatus', 3)
            })
    },



}
