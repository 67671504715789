<template>
    <div class="container">
        <div class="card mb-4 com-card py-md-5">
            <div class="card-body">
                <div class="card-body p-2 text-center">
                    <small>
                        招待するにはこのQRコード<br>を読み取ってもらってください
                    </small>
                </div>
                <div class="card-body p-0 text-center">
                    <qrcode :value="getInviteInfo.invite_url" :options="{width:200}"></qrcode>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        computed: {
            ...mapGetters(['getInviteInfo'])
        }
    }
</script>

<style lang="stylus" scoped>
</style>
