
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import router from './router'
import store from './store'
import Vuelidate from 'vuelidate'
import moment from 'moment'
import 'babel-polyfill'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import VueClipboard from 'vue-clipboard2'

require('./bootstrap')

window.Vue = require('vue')
Vue.use(Vuelidate)
Vue.use(VueClipboard)
Vue.component(VueQrcode.name, VueQrcode)

moment.locale('ja');
Vue.prototype.$moment = moment

Vue.filter('dformat', function (dateStr, pattern = 'YYYY/MM/DD HH:mm:ss') {
  return moment(dateStr).format(pattern)
})

Vue.filter('dformatm', function (dateStr, pattern = 'YYYY/MM/DD HH:mm') {
  return moment(dateStr).format(pattern)
})

Vue.filter('dformatf', function (dateStr, pattern = 'YYYY/MM/DD (dd) HH:mm:ss') {
  return moment(dateStr).format(pattern)
})

Vue.filter('dformats', function (dateStr, pattern = 'MM/DD (dd)') {
  return moment(dateStr).format(pattern)
})

Vue.filter('dformatw', function (dateStr, pattern = 'YYYY/MM/DD (dd)') {
  return moment(dateStr).format(pattern)
})

Vue.filter('dformato', function (dateStr, pattern = 'YYYY/MM/DD') {
  return moment(dateStr).format(pattern)
})

/**
 * interceptorで共通エラー処理対応
 * 401/500error/requestForm error
 * 401/500error(画面リフレッシュ)
 */
window.axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        //console.log(error.response)
        if (error.response.status == 401) {
            router.push({name:'HttpError',params:{status: 401}})
            // window.location.href ='/login'
        } else if (error.response.status == 500) {
            router.push({name:'HttpError', params:{status: 500}})
        } else {
            /*
             * error内の処理でreturn error;としてしまうと、.thenの処理に行ってしまいます。
             * そのため、catchに飛ぶように、Promise.rejectをしてあげましょう。
             */
            return Promise.reject(error)
        }
    }
);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const whiteList = ['login','register', 'passwordReset', 'HttpError', 'ProjShareDetail', 'TechShareDetail', 'qrcodeShare', 'Terms', 'Privacy']
const userRole = 'RBE-UR'

router.beforeEach((to, from, next) => {

    if (whiteList.indexOf(to.name) == -1) {
        if (!sessionStorage.getItem(userRole)) {

            store.dispatch('loadUserGuard').then(resp => {
                sessionStorage.setItem(userRole, window.btoa(resp.data.role))
                resp.data.role == to.meta.role ? next() : next('/error')
            }).catch(err => { })

        } else {

            let role_session = sessionStorage.getItem('RBE-UR')
            let role = window.atob(role_session)
            role == to.meta.role ? next() : next('/error')
        }
    } else {

        if (to.name == 'login') {
            sessionStorage.clear()
        }
        next()
    }
})

const app = new Vue({
    el: '#app',
    router,
    store,
    watch: {
        $route (to, from) {
            let isOpen = $('body').hasClass('modal-open') ? true : false
            if (isOpen) {
                //history.pushState(null, null, document.URL);
                $('.modal').modal('hide')
            }
        }
    }
});
