export default {

    /** Point情報 **/
    setPoint (state, data) {
        state.point = data
    },
    setPointLoadStatus (state, status) {
        state.pointLoadStatus = status
    },

    /** ポイント換金 **/
    setExchangeInit (state ,resp) {
        state.exchangeInit = resp
    },
    setExchangeInitLoadStatus (state, status) {
        state.exchangeInitLoadStatus = status
    },
    setExchangePointStatus (state, status) {
        state.exchangePointStatus = status
    },

    /** 招待情報 **/
    setInviteInfo (state, inviteInfo) {
        state.inviteInfo = inviteInfo
    },
    setInviteInfoLoadStatus (state, status) {
        state.inviteInfoLoadStatus = status
    },
    // setShareInviteCodeStatus (state, status) {
    //     state.shareInviteCodeStatus = status
    // },
    
}
