<template>
    <div class="card mb-2 com-card p-md-3 p-lg-4">
        <div class="card-header let-space-1 px-2 py-3 py-md-4">
            <div class="d-flex">
                <span class="text-truncate text-nowrap">
                    <i v-if="item.apply_type == '2'" class="fas fa-long-arrow-alt-right text-primary icon-size"></i>
                    <template v-else>
                        <i v-if="item.apply_mode == '0'" class="fas fa-long-arrow-alt-right text-primary icon-size"></i>
                        <i v-else class="fas fa-long-arrow-alt-left text-success"></i>&nbsp;
                    </template>
                    <span>{{item.proj_nm}}</span>
                </span>
            </div>
            <div class="text-right pt-2">
                <i class="fas fa-user icon-size"></i>&nbsp;
                {{item.te_nm}}
            </div>
        </div>
        <div class="card-body px-2 py-3 py-md-4 item-hover" @click="toApplyPjDetail(item.id)">
            <div>
                <span v-show="closed" class="badge badge-pill badge-secondary p-2">Closed</span>
                <span v-if="item.pj_msg == '0'">{{item.pj_ap_flg}}</span>
                <span v-else>
                    <span class="badge badge-pill badge-success p-2">未読メッセージ</span>
                </span>

                <div v-if="item.intv_type" class="pt-2">
                    <span v-if="item.intv_type == '0'">(現地)</span>
                    <span v-if="item.intv_type == '1'">(Web)</span>
                    {{item.pj_intv_d | dformats}}&nbsp;{{item.pj_intv_h}}:{{item.pj_intv_m}}
                </div>
            </div>
            <div class="d-flex text-muted let-space-1 pt-0">
                <span class="flex-fill text-nowrap text-right">
                        <small>
                            <small>
                                <i class="fas fa-calendar"></i>
                                {{item.created_at | dformatm}}
                            </small>
                        </small>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import {editProjFinishDate
           ,isOverProjFinishDate
           ,isWarningProjFinishDate
           } from 'pages/commons/form/comFunc'
    import {converVal} from 'pages/commons/form/selectList'
    import {mapGetters} from 'vuex'

    export default {
        props: {
            item: Object,
            closed: {
                type: Boolean,
                default: true
            },
            idx: Number
        },
        computed: {
            ...mapGetters(['getListObj'])
        },
        methods: {
            toApplyPjDetail (id) {
                this.projToSession(id)

                if (this.closed) {
                    this.getListObj.data[this.idx]['pj_msg'] = '0'
                } else {
                    this.getListObj.applyOpen[this.idx]['pj_msg'] = '0'
                }

                this.$router.push({name:'ApplyStatusDetail', params: {id: id }})
            },
            editFinishDate (val, p) {
                return editProjFinishDate(val, p)
            },
            isWarnFinishDate (val) {
                return isWarningProjFinishDate(val)               
            },
            isOverFinish (val) {
                return isOverProjFinishDate(val)
            },
            converVal (name, val) {
                return converVal(name,val)
            },
            projToSession (id) {
                sessionStorage.setItem('applyProjCmp', id)
            },
        }
    }
</script>

<style lang="stylus" scoped>

</style>
