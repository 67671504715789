<template>
    <applys-list 
        ref="list"
        header-text="入場予定"
        >
        <div v-if="getListObj">
            <div v-if="getListObj.data.length > 0">
                <div class="card mb-2" v-for="(item,index) in getListObj.data" :key="index">
                    <div class="card-body d-flex" @click="toEnterDetail(item.in_d)">
                        <span>
                            <i class="fas fa-calendar icon-size text-success"></i>
                            &nbsp;{{item.in_d | dformatw}}
                        </span>
                        <span class="ml-auto"> {{item.cnt}}人 </span>
                    </div>
                </div>
            </div>
        </div>
    </applys-list>
</template>

<script>
import ApplysList from 'pages/commons/List/List'
import {mapGetters} from 'vuex'

export default {
    components: {
        ApplysList
    },
    mounted () {
        this.$refs.list.loadListObj({url:'app.enter'})
        this.$refs.list.resetRefresh('enterPlan')
    },
    activated () {
        this.$refs.list.isGetList('enterPlan', { url:'app.enter'})
        this.$refs.list.resetRefresh('enterPlan')
    },
    computed: {
        ...mapGetters(['getListObj'])
    },
    methods: {
        toEnterDetail (day) {
            this.$router.push({name:'EnterDetail', params: {day: day }})
        }
    }
}
</script>
