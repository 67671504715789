<template>
    <div v-if="getProjDetail">
        <proj-detail-header v-if="appStep == '0'" :page-name="pageName"></proj-detail-header>
        <proj-detail-header v-if="appStep == '1' || appStep == '2'" :page-name="pageName" do-cust @cust="backPage"></proj-detail-header>

        <template v-if="getProjDetail.project">
            <proj-detail-content v-if="appStep == '0'" :proj="getProjDetail"></proj-detail-content>
            <proj-detail-apply v-else-if="appStep == '1'" :item="getProjDetail.resume" :proj="getProjDetail.project"></proj-detail-apply>
            <proj-tech-list v-else-if="appStep == '2'" page="proj" :obj="getProjDetail.project"></proj-tech-list>
            <!-- メッセージ -->
            <info-msg ref="msg" :msg="msg" :lev="lev"></info-msg>
        </template>
        <not-found-msg v-else :msg="msgDel"></not-found-msg>

        <!-- メッセージtitle -->
        <info-msg-title ref="msgt" :tmsg="tmsg" :bmsg="bmsg" :btype="2"></info-msg-title>
    </div>
</template>

<script>
    import ProjDetailHeader from 'pages/commons/header/Header'
    import ProjDetailContent from './components/ProjContent'
    import ProjDetailApply from './components/ProjApply'
    import ProjTechList from 'pages/commons/suggestList'
    import InfoMsg from 'pages/commons/modal/infoMsg/InfoMsg'
    import InfoMsgTitle from 'pages/commons/modal/infoMsg/InfoMsgTitle'

    import NotFoundMsg from 'pages/commons/notFoundMsg/NotFoundMsg'
    import {mapGetters} from 'vuex'

    export default {
        components:{
            ProjDetailHeader,
            ProjDetailContent,
            ProjDetailApply,
            ProjTechList,
            NotFoundMsg,
            InfoMsg,
            InfoMsgTitle
        },
        data () {
            return {
                // 0:proj/1:応募者情報/2:要員リスト
                appStep: '0',
                msg: "",
                lev: 0,
                tmsg:'',
                bmsg:'',
                msgDel: "この案件は既に削除されました。",
                applyType:'',
            }
        },
        mounted () {
            this.loadProjDetail()
        },
        destroyed () {
            //this.$store.dispatch('setRefreshArr', 'projsAll')
            this.$store.dispatch('resetProjDetail')
        },
        watch: {
            getProjDetailLoadStatus (status) {
                if (status == 1) {
                    this.$store.dispatch('startProgress')
                }
                if (status == 2) {
                    this.$store.dispatch('doneProgress')
                }
                if (status == 3) {
                    this.$store.dispatch('doneProgress')
                }
            },
            async getSuggestionStatus (status) {
                if (status == 1) {
                    this.$store.dispatch('startSpinner')
                }
                if (status == 2) {
                    await this.loadProjDetail()
                    this.$store.dispatch('doneSpinner')
                }
                if (status == 3) {
                    this.$store.dispatch('doneSpinner')
                }
            },
            getSuggestionOperat (opt) {
                if (opt == 'success') {
                    if (this.applyType == '0') {
                        this.openMsg('応募が完了しました。書類選考結果のご連絡まで今しばらくお待ちください。', 0)
                    } else {
                        this.openTitleMsg('ご応募ありがとうございます。', '進展がございましたらメールにてご連絡させていただきます。')
                    }
                    this.applyType = ''
                    this.changeStep('0')
                } else if (opt == 'closed') {
                    this.openMsg('この案件の募集を終了しました。', 1)
                    this.changeStep('0')
                } else if (opt == 'deleted') {
                    this.changeStep('0')
                } else if (opt == 'fa_added') {
                    this.openMsg('お気に入りに追加しました。', 3)
                } else if (opt == 'fa_deleted') {
                    this.openMsg('お気に入りから削除しました。', 4)
                }
            }

        },
        computed: {
            pageName () {
                if (this.appStep == '0') {
                    return '案件詳細'
                }
                if (this.appStep == '1') {
                    return '応募情報確認'
                }
                if (this.appStep == '2') {
                    return '要員選択'
                }
                return ''
            },
            ...mapGetters(['getProjDetail'
                          ,'getProjDetailLoadStatus'
                          ,'getSuggestionStatus'
                          ,'getSuggestionOperat'
            ])
        },
        methods: {
            loadProjDetail () {
                this.$store.dispatch('loadProjDetail',{
                    id: this.$route.params.id
                })
            },
            projApply () {
                if (this.canApply()) {
                    this.changeStep('1')
                }
            },
            techList () {
                this.changeStep('2')
            },
            suggestion (applyType, applyMode, techIdList) {
                this.applyType = applyType
                this.$store.dispatch('comSuggestion',{
                    applyType: applyType,
                    applyMode: applyMode,
                    projIdList: [this.getProjDetail.project.id],
                    techIdList: techIdList,
                })
            },
            canApply () {
                let isClosed = this.getProjDetail.is_closed
                let isNeedInfo = this.getProjDetail.need_private || this.getProjDetail.need_resume
                let isApMax = this.getProjDetail.is_ap_max
                let apMaxCnt = this.getProjDetail.ap_max_cnt
                let isPasted = this.getProjDetail.is_pasted

                if (isClosed) {
                    this.openMsg('この案件の募集を終了しました。', 1)
                    return false
                }
                if (isNeedInfo) {
                    this.openMsg('応募するには「マイページ」の「個人情報」と「履歴書」を完了してください。', 1)
                    return false
                }
                if (isApMax) {
                    this.openMsg('応募中の案件は最大' + apMaxCnt + '件までです。', 1)
                    return false
                }
                if (isPasted) {
                    this.openMsg('応募した案件に再度応募することができません。', 1)
                    return false
                }

                return true
            },
            addFavorite () {
                this.$store.dispatch('addFavorite', {
                    id: this.$route.params.id
                })
            },
            changeStep (step) {
                this.appStep = step
                this.moveScrollTop()
            },
            backPage () {
                this.changeStep('0')
            },
            openMsg (msg, lev) {
                this.msg = msg
                this.lev = lev
                this.$refs.msg.openThis()
            },
            openTitleMsg (tmsg, bmsg) {
                this.tmsg = tmsg
                this.bmsg = bmsg
                this.$refs.msgt.openThis()
            },
            moveScrollTop () {
                // スクロールをトップへ移動
                document.documentElement.scrollTop = 0
            }
        }

    }
</script>
