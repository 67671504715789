<template>
    <div v-if="show">
        <!-- home->create->confirm1->create->home -->
        <!-- home->show->edit->confirm2->edit->show->home -->
        <confirm-header v-if="opt == 'create_confirm'" :page-name="pageName" do-cust @cust="backPage"></confirm-header>
        <confirm-header v-else-if="opt == 'edit_confirm'" :page-name="pageName" do-cust @cust="backPage"></confirm-header>
        <confirm-header v-else :page-name="pageName"></confirm-header>
        <div class="container">
            <div class="card com-card pt-md-4 mb-2 mb-md-3">
                <div class="card-body">
                    <form-item lab="氏名">{{form.first_nm}}　{{form.last_nm}}</form-item>
                    <form-item lab="氏名フリガナ">{{form.first_kana}}　{{form.last_kana}}</form-item>
                    <form-item lab="性別">{{form.gender == '0' ? '男' : '女'}}</form-item>
                    <form-item lab="生年月日">{{form.year}}年{{form.month}}月{{form.day}}</form-item>
                    <form-item lab="郵便番号〒">{{form.user_zipcode}}</form-item>
                    <form-item lab="住所">{{form.user_address1 + form.user_address2 + form.user_address3}}</form-item>
                    <form-item lab="電話番号(携帯)">{{form.phone}}</form-item>
                    <form-item lab="希望契約種類">{{converVal('contract',form.contract)}}</form-item>
                    <div v-if="form.contract == '1'">
                        <form-input ip-type="comment" py0>
                            <span class="text-danger">
                                <i class="fas fa-exclamation-circle"></i>
                                会社情報
                            </span>
                        </form-input>
                        <hr>
                        <form-item lab="会社名">{{form.company_nm}}</form-item>
                        <form-item lab="郵便番号〒">{{form.company_zipcode}}</form-item>
                        <form-item lab="住所">{{form.company_address1 + form.company_address2 + form.company_address3}}</form-item>
                        <form-item v-if ="form.company_url" lab="会社ホームページURL">{{form.company_url}}</form-item>
                        <form-item lab="営業担当(氏名)">{{form.first_nm_sales}}　{{form.last_nm_sales}}</form-item>
                        <form-item lab="営業担当(氏名フリガナ)">{{form.first_kana_sales}}　{{form.last_kana_sales}}</form-item>
                        <form-item lab="営業担当(携帯)">{{form.sales_phone}}</form-item>
                        <form-item lab="営業担当(メール)">{{form.sales_mail}}</form-item>
                    </div>
        
                    <div class="card mb-2 border-0">
                        <div class="card-body">
                            <div v-if="opt == 'create_confirm'" class="row form-group justify-content-center mb-0">
                                <div class="col-sm-4">
                                    <button class="btn btn-danger btn-block btn-lg" @click="doSubmit">
                                        <i class="fas fa-save"></i>&nbsp;個人情報登録
                                    </button>
                                </div>
                            </div>
                            <div v-if="opt == 'edit_confirm'" class="row form-group justify-content-center mb-0">
                                <div class="col-sm-4">
                                    <button class="btn btn-danger btn-block btn-lg" @click="doSubmit">
                                        <i class="fas fa-save"></i>&nbsp;個人情報更新
                                    </button>
                                </div>
                            </div>
                            <div v-if="opt == 'show'" class="row form-group justify-content-center mb-0">
                                <div class="col-sm-4">
                                    <button class="btn btn-success btn-block btn-lg" @click="doEdit">
                                        <i class="fas fa-edit"></i>&nbsp;個人情報編集
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ConfirmHeader from 'pages/commons/header/Header'
    import FormItem from 'pages/commons/form/Confirm'
    import FormInput from 'pages/commons/form/Input'

    export default {
        props: {
            form: Object,
            opt: String
        },
        components: {
            ConfirmHeader,
            FormItem,
            FormInput
        },
        computed: {
            show () {
                return Object.keys(this.form).length > 0
            },
            pageName () {
                if (this.opt == 'create_confirm') {
                    return "入力内容確認"
                }
                if (this.opt == 'edit_confirm') {
                    return "編集内容確認"
                }
                return "個人情報"
            },
            showRegistBtn () {
                return (this.opt == 'create_confirm' || this.opt == 'edit_confirm') ? true : false
            }
        },
        methods: {
            backPage () {
                if (this.opt == 'create_confirm') {
                    this.$parent.changeOperat('create')
                }
                if (this.opt == 'edit_confirm') {
                    this.$parent.changeOperat('edit')
                }
            },
            converVal (name,value) {
                return this.$parent.converVal(name, value)
            },
            doSubmit () {
                this.$parent.doSubmit()
            },
            doEdit () {
                this.$parent.changeOperat('edit')
            }
        }
    }
</script>

<style>

</style>
