<template>
    <div>
        <private-user-form v-show="showForm" ref="userForm" :opt="operat"></private-user-form>
        <private-user-confirm v-show="!showForm" :form="reqForm" :opt="operat"></private-user-confirm>
        <success-msg ref="success" :msg="msg"></success-msg>
    </div>
</template>

<script>
    import PrivateUserForm from './components/Form'
    import PrivateUserConfirm from './components/Confirm'
    import SuccessMsg from 'pages/commons/modal/infoMsg/InfoMsg'
    import {getList, converVal} from 'pages/commons/form/selectList'
    import {mapGetters} from 'vuex'

    export default {
        components: {
            PrivateUserForm,
            PrivateUserConfirm,
            SuccessMsg
        },
        data () {
            return {
                msg: "",
                operat: 'show',
                showForm: false,
                reqForm: {}
            }
        },
        mounted() {
            this.loadPrivate()
        },
        watch: {
            // サーバーエラーで画面制御
            getPrivateOperat (operat) {
                this.changeOperat(operat)
            },
            getPrivateInfo (info) {
                if (info.data.private) {
                    this.reqForm = info.data.private
                } else {
                    this.operat = 'create'
                    this.showForm = true
                }
            },
            getPrivateInfoLoadStatus (status) {
                if (status == '1') {
                    this.$store.dispatch('startProgress')
                }
                if (status == '2') {
                    this.$store.dispatch('doneProgress')
                }
                if (status == '3') {
                    this.$store.dispatch('doneProgress')
                }
            },
            getSavePrivateInfoStatus (status) {
                if (status == '1') {
                    this.$store.dispatch('startSpinner')
                }
                if (status == '2') {
                    this.$store.dispatch('doneSpinner')
                    this.setMsg()
                    this.$refs.success.openThis()
                }
                if (status == '3') {
                    this.$store.dispatch('doneSpinner')
                }
            }
        },
        computed: {
            ...mapGetters(['getSavePrivateInfoStatus', 'getPrivateOperat', 'getPrivateInfoLoadStatus', 'getPrivateInfo'])
        },
        methods: {
            loadPrivate () {
                this.$store.dispatch('loadPrivateInfo')
            },
            doSubmit () {
                this.reqForm['operat'] = this.operat
                this.$store.dispatch('savePrivateInfo', this.reqForm)
            },
            getChildData () {
                let userForm = this.$refs.userForm.getData()
                this.reqForm = Object.assign({}, this.reqForm, userForm)
            },
            setMsg () {
                this.msg = "完了しました。"
            },
            changeOperat (opt) {
                if (opt == 'auto') {
                    this.operat = (this.operat == 'create') ? 'create_confirm' : 'edit_confirm'
                } else {
                    this.operat = opt 
                }
                if (this.operat == 'create' || this.operat == 'edit') {
                    this.showForm = true
                }
                if (this.operat == 'create_confirm' || this.operat == 'edit_confirm' || this.operat == 'show') {
                    this.showForm = false
                }
                // スクロールをトップへ移動
                document.documentElement.scrollTop = 0
            },
            getList (name) {
                return getList(name)
            },
            converVal (name, val) {
                return converVal(name,val)
            }
        }
    }
</script>

<style></style>
