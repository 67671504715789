<template>
    <div>
        <act-list 
            ref="list"
            :header-text="headerText"
            :has-add="hasAdd"
            mt
            @add="addTech">
            <div v-if="getListObj">
                <div v-if="getListObj.data.length > 0">
                    <div class="card p-md-4 com-card mb-2" v-for="item in getListObj.data" :key="item.id" :class="{'com-card-focus': selectTech === item.id}">
                        <div class="card-header p-2 pt-3">
                            <div class="d-flex mb-2">
                                <div>
                                    <span v-if="hasAdd && item.open_flg == '1'" class="text-nowrap border border-secondary rounded px-1 text-secondary">
                                        <small>営業終了</small>
                                    </span>
                                    <span v-if="!hasAdd && item.open_flg == '0'" class="text-nowrap border border-success rounded px-1 text-success">
                                        <small>営業中</small>
                                    </span>
                                    <info-item left icn="fas fa-yen-sign">
                                        <span v-if="item.hope_price_start == item.hope_price_end">{{ item.hope_price_end }}万</span>
                                        <span v-else>{{ item.hope_price_start }}~{{ item.hope_price_end }}万</span>
                                    </info-item>
                                </div>
                                <div class="text-right flex-fill pt-1">
                                    <span class="text-muted pl-2 ml-auto item-hover" @click="openMenu(item)">
                                        <i class="fas fa-ellipsis-v"></i>
                                    </span>
                                </div>
                            </div>
                            <div class="text-muted">
                                <span v-if="item.nearest_station" class="text-nowrap border rounded px-1 mr-1">
                                    {{item.nearest_station}}
                                </span>
                                <span v-if="item.work_start_date" class="text-nowrap border rounded px-1 mr-1">
                                    {{editStartDate(item.work_start_date)}}
                                </span>
                                <span v-if="item.exper" class="text-nowrap border rounded px-1 mr-1">
                                    exp.{{item.exper}}年 
                                </span>
                                <span v-if="item.relation_cmp" class="text-nowrap border rounded px-1 mr-1">
                                    {{converVal('relation_cmp', item.relation_cmp)}} 
                                </span>
                            </div>
                        </div>
                        <div class="card-body pb-1 pt-3 px-2 item-hover" @click="toTechDetail(item.id)">
                            <div class="mb-2 text-muted">
                                <div class="text-truncate">
                                    <info-item left>
                                        {{item.name}} ({{item.gender == '0'?'男性':'女性'}} {{item.age+'歳'}} {{item.country}})
                                    </info-item>
                                </div>
                            </div>
                            <div class="d-flex text-muted let-space-1 pt-2 pt-md-3">
                                <span class="flex-fill text-nowrap text-right d-none d-md-block">
                                    <small>
                                        <span>
                                            <i class="fas fa-calendar"></i>
                                            {{item.created_at | dformatm}}
                                        </span>
                                        <span class="pl-3">
                                            <i class="fas fa-sync"></i>
                                            {{item.updated_at | dformatm}}
                                        </span>
                                    </small>
                                </span>
                                <span class="flex-fill text-nowrap d-flex justify-content-between d-block d-md-none text-truncate">
                                    <div>
                                        <small>
                                            <small>
                                                <i class="fas fa-calendar"></i>
                                                {{item.created_at | dformatm}}
                                            </small>
                                        </small>
                                    </div>
                                    <div>
                                        <small>
                                            <small>
                                                <i class="fas fa-sync"></i>
                                                {{item.updated_at | dformatm}}
                                            </small>
                                        </small>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </act-list>
        <!-- メニュー -->
        <act-menu ref="menu"></act-menu>
        <!-- シェア -->
        <act-share ref="share"></act-share>
    </div>
</template>

<script>
    import {editProjStartDate} from 'pages/commons/form/comFunc'
    import {converVal} from 'pages/commons/form/selectList'
    import ActList from 'pages/commons/List/List'
    import ActMenu from './components/Menu'
    import ActShare from 'pages/commons/techShare/TechShare'

    import InfoItem from 'pages/commons/projList/CardHeadInfoItem'
    import {mapGetters} from 'vuex'

    export default {
        components: {
            ActList,
            ActMenu,
            ActShare,
            InfoItem
        },
        // 呼出元 type: sales/company
        props:['type'],
        data () {
            return {
                selectTech: ""
            }
        },
        mounted () {
            this.$refs.list.loadListObj({
                url:'com.tech.act',
                data: '0' + '-' + this.$route.params.do
            })
            this.$refs.list.resetRefresh('comActTech')
        },
        activated () {
            this.$refs.list.isGetList('comActTech', { url:'com.tech.act', data: '0' + '-' + this.$route.params.do},'reset')
            this.$refs.list.resetRefresh('comActTech')
        },
        computed: {
            headerText () {
                return this.$route.params.do == 'open' ? '営業中(要員)' : '営業終了(要員)'
            },
            hasAdd () {
                return this.$route.params.do == 'open' ? true : false
            },
            ...mapGetters(['getListObj'])
        },
        methods: {
            resetThis () {
                this.selectTech = ""
            },
            toTechDetail (id) {
                this.selectTech = id
                this.$router.push({name: 'ServiceTechCreate', params:{
                    do: 'show',
                    id: id 
                }})
            },
            addTech () {
                this.$router.push({name: 'ServiceTechCreate', params:{
                    do: 'create'
                }})
            },
            openMenu (item) {
                this.$refs.menu.openThis(item)
            },
            techShare (item) {
                this.$refs.share.openThis(item)
            },
            seeMoreMt () {
                this.$store.dispatch('loadListObjSeeMore', {
                    url:'com.tech.act',
                    skip: this.$refs.list.lastId + '-' + this.$route.params.do
                })
            },
            editStartDate (val) {
                return editProjStartDate(val)
            },
            converVal (name, val) {
                return converVal(name,val)
            },
        }
    }
</script>

<style lang="stylus" scoped>
    .card-color-cust
        background-color: #4dc0b521
</style>
