

// 数字チェック
export const isNumeric = function(flg) {
    return (value) => {
        if (flg) {
            let reg = /^[0-9]*$/
            return reg.test(value)
        }
        return true
    }
}

// 全角フリガナ
export const isKana = function(flg) {
    return (value) => {
        if (flg) {
            let reg = /^[ア-ン゛゜ァ-ォャ-ョー]+$/u
            return reg.test(value)
        }
        return true
    }
}

// メールチェック
export const isMail = function(flg) {
    return (value) => {
        if (flg) {
            var reg = /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/;
            return reg.test(value)
        }
        return true
    }
}

// 特殊文字以外
export const isNoMark = function(flg) {
    return (value) => {
        if (flg) {
            // let reg = /[!"#$%&'()\*\+\-\.,\/:;<=>?@\[\\\]^_`{|}~]/g;
            var reg = new RegExp(/[!"#$%&'()*+\-.,/:;<=>?@[\\\]^_`{|}~]/g);
            return !reg.test(value);
        }
        return true
    }
}

// 桁数限定
export const length = function(data) {
    return (value) => {
        if (data[0]) {
            return data[1] == value.length
        }
        return true
    }
}


// URLチェック
export const isUrl = function(flg) {
    return (value) => {
        if (flg && value) {
            var reg = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
            return reg.test(value)
        }
        return true
    }
}

// 郵便番号(有効性)チェック
export const isZipcode = function(flg) {
    return (value) => {
        if (flg) {
            return new Promise((resolve, reject) => {
                        axios.post(route('com.address'), {zipcode: value})
                                    .then(resp => {
                                        if (resp.data.results) {
                                            resolve(true)
                                        } else {
                                            resolve(false)
                                        }
                                    })
            })
        }
        return true
    }
}

// ニックネーム(大文字かつアーターバー限定)
export const isUpUnder = function(flg) {
    return (value) => {
        if (flg) {
            let reg = /^[A-Z.0-9]+$/
            return reg.test(value)
        }
        return true
    }
}
