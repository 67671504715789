<template>
    <li class="list-group-item">

        <!-- エラーメッセージ -->
        <err-msg-bar :name="['inputPlace']" :local-errs="errs"></err-msg-bar>

        <div class="custom-control custom-switch p-0">
            <div class="form-group row m-0">
                <label for="select" class="col-3 col-form-label py-2 px-0" id="in-p-l">
                    <small v-if="processId.id == 'Doc'"><span>面接場所</span></small>
                    <small v-else>
                        <span v-show="processId.child == 'not'">現場住所</span>
                        <span v-show="processId.child == 'has'">面接場所</span>
                    </small>
                    <small>
                        <br><small>({{inputLength}}/100)</small>
                    </small>
                    <div v-if="getPlaceCnt != 0" class="custom-control custom-switch mt-2">
                        <input type="checkbox" v-model="placeChecked" class="custom-control-input place-check " id="place-check">
                        <label class="custom-control-label" for="place-check"></label>
                    </div>
                </label>
                <div class="col-9 p-0">
                    <select v-if="getPlaceCnt != 0" v-model.trim="selectPlace" :disabled="selectPlaceDisabled" :class="{disitem:selectPlaceDisabled}" class="form-control mb-2" >
                        <option v-for="place in getPlaces" :value="place.in_place" :key="place.id">{{place.in_place}}</option>
                    </select>
                    <textarea v-model.trim="inputPlace" class="form-control" :class="{disitem:inputPlaceDisabled}" rows="2" :disabled="inputPlaceDisabled" spellcheck=false  maxlength="100" :placeholder="pholder"></textarea>
                </div>
            </div>
        </div>
    </li>
</template>

<script>
    import {mapGetters} from 'vuex'

    import {required, maxLength} from "vuelidate/lib/validators"
    import ErrMsgBar from 'pages/commons/err/ErrMsgBar.vue'

    export default {
        props: ['processId'],
        components: {
            ErrMsgBar
        },
        data () {
            return {
                selectPlace: "",
                inputPlace: "",
                placeChecked: false,
                errs: {}
            }
        },
        mounted () {
                this.getAddedInterview.inputPlace ? this.updatePlaceSelected() : this.setPlaceSelected()
        },
        computed: {
            pholder () {
                return this.processId == 'Intv' ? '現場住所を入力してください。' : '面接場所を入力してください。'
            },
            selectPlaceDisabled () {
                return this.placeChecked
            },
            inputPlaceDisabled () {
                if (this.getPlaceCnt == 0) {
                    return false
                } else {
                    return !this.placeChecked
                }
            },
            inputLength () {
                return this.inputPlace.length
            },
            ...mapGetters([
                'getPlaceCnt',
                'getPlaces',
                'getAddedInterview',
                'getAddInterviewStatus'
            ]),
        },
        methods: {
            setPlaceSelected () {
                this.selectPlace = this.getPlaceCnt > 0 ? this.getPlaces[0].in_place : ""
            },
            updatePlaceSelected () {
                this.selectPlace = this.getAddedInterview.inputPlace
            },
            doCheck () {
                if (!this.inputPlaceDisabled) {
                    this.$v.inputPlace.$touch()
                    if (!this.$v.$invalid) {
                        return true
                    }
                    return false
                } else {
                    return true
                }
            },
            dataCheck () {
                if (this.doCheck()) {
                    this.clearErrs()
                    return true
                } else {
                    this.showErrs()
                    return false
                }
            },
            showErrs () {
                this.clearErrs()
                let arr = []

                if (!this.$v.inputPlace.required) { arr.push("場所を入力してください。") }  
                if (!this.$v.inputPlace.maxLength) { arr.push("100文字以内を入力してください。") }  
                if (arr.length > 0) {this.errs.inputPlace = arr}
            },
            clearErrs () {
                this.errs = {}
            },
            getData () {
                let reqPlace = this.inputPlaceDisabled ? this.selectPlace : this.inputPlace
                return { inputPlace: reqPlace }
            }
        },
        validations: {
            inputPlace: {
                required,
                maxLength: maxLength(100)
            }
        }
        
    }
</script>

<style lang="stylus" scoped>
    .disitem 
        opacity:0.4 !important
</style>
