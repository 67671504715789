export default {
    /** ホーム情報取得 **/
    cmpInviteHome: {},
    // 0:waiting 1:loading 2:success 3:failed
    cmpInviteHomeLoadStatus: 0,

    /** 招待コード情報取得 **/
    cmpInviteInfo: {},
    // 0:waiting 1:loading 2:success 3:failed
    cmpInviteInfoLoadStatus: 0,

    /** 招待コード情報取得(30分期限) **/
    qrUrlChatLimit: {},
    qrUrlChatLimitLoadStatus: 0,

    /** 招待コードメール(法人：24時間期限) **/
    shareQrUrlMailStatus: 0,

    /** 通常メンバーをオーナーへ変更 **/
    cmpInvitedListLaterChangeOwner: {},
    cmpInvitedChangeOwnerStatus: 0,

    /**  通常メンバーから権限委譲依頼 **/
    cmpInvitedTransferOwnerStatus: 0,

    /**  利用者連携解除 **/
    cmpInvitedListLaterUserUnlink: {},
    cmpInvitedUserUnlinkStatus: 0,

    /**  利用者連携 **/
    cmpInvitedListLaterUserLink: {},
    cmpInvitedUserLinkStatus: 0,
}
