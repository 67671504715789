<template>
    <span class="text-nowrap mr-1 mr-lg-2 mr-md-2 px-1 text-center" 
        :class="{'ml-lg-2':!left, 'ml-md-2':!left}">
        <i :class="icn"></i>
        <slot></slot>
    </span>
</template>

<script>
export default {
    props:{
        icn:String,
        left:{
            type:Boolean,
            default:false
        }
    }
}
</script>

<style scoped>

</style>
