<template>
    <div class="card h-100 com-card item-hover" @click="doClick">
        <ul class="list-group list-group-flush">
            <li class="list-group-item px-2">
                <div class="row py-1 py-lg-2">
                    <div class="col-2 pr-0 text-center">
                        <i :class="icn" class="icon-size"></i>
                    </div>
                    <div class="col pl-2 d-flex">
                        <div>
                            <span> {{lab}} </span><br>
                            <small>
                                <small class="text-muted">{{lab2}}</small>
                            </small>
                        </div>
                        <div class="ml-auto text-nowrap"> 
                            {{lab3}}{{lab4}}
                            <slot></slot>
                            <i class="fas fa-angle-right text-muted"></i>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        props: {
            icn: String,
            lab: String,
            lab2: String,
            lab3: String,
            lab4: String,
        },
        methods: {
            doClick () {
                this.$emit('do_click')
            }
        }
    }
</script>

<style lang="stylus" scoped>
.card:hover .icon-size 
    opacity:0.6
    transition:0.5s

</style>
