export default {
    /**
     * Get ポイント取得
     */
    loadPoint () {
        return axios.get(route('invite.point'))
    },

    /**
     * Get ポイント換金初期情報取得
     */
    loadExchangeInit () {
        return axios.get(route('invite.point.exchange.init'))
    },

    /**
     * Post 換金を行う
     */
    exchangePoint () {
        return axios.post(route('invite.point.exchange'))
    },

    /**
     * Get 招待情報取得
     */
    loadInviteInfo () {
        return axios.get(route('invite.code'))
    },

    /**
     * Post　メールで招待コードをシェアする
     */
    // shareInviteCode (data) {
    //     return axios.post(route('invite.code.share'), data)
    // },

}
