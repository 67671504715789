<template>
    <div>
        <service-header page-name="貢献" :istop="true"> </service-header>
        <service-list></service-list>
    </div>
</template>

<script>
    import ServiceHeader from 'pages/commons/header/Header'
    import ServiceList from './components/List'

    import {mapGetters} from 'vuex'

    export default {
        mounted () {
            this.$store.dispatch('resetListObj')
        },
        components: {
            ServiceHeader,
            ServiceList,
        }
    }
</script>

<style></style>
