<template>
    <div id="modal-proj-note" class="modal fade" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <!-- エラー -->
                    <div class="pb-2">備考</div>
                    <small><span class="text-muted">({{this.noteLength}}/500)</span></small>
                    <textarea v-model.trim="note"  class="form-control" rows="7" maxlength="500" spellcheck=false placeholder="必要に応じて備考を記入してください。"></textarea>
                </div>
                <div class="modal-footer justify-content-center">
                    <button class="btn btn-primary px-5" type="button" @click="closeThis">
                        閉じる
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                note:""
            }
        },
        computed: {
            noteLength () {
                return this.note ? this.note.length : 0
            },
        },
        methods: {
            closeThis () {
                this.$parent.syncBtnNote(this.note)
                this.resetThis()
                $('#modal-proj-note').modal('hide')
            },
            openThis (note) {
                this.note = note
                $('#modal-proj-note').modal('show')
            },
            resetThis () {
                this.note = ""
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import '~stylus/base/mixins.styl'
    .wrap-name
        display: flex
        flex: 1
        white-space: nowrap
        overflow: hidden
        .intv-name
            ellipsis()
    .disabledBg
        background-color: #fff
</style>
