export default {

   // getInitHome (state) {
   //     return state.initHome.data
   // },
   // getInitHomeLoadStatus (state) {
   //     return state.initHomeLoadStatus
   // },
    /** 個人情報 **/
    getPrivateOperat (state) {
        return state.privateOperat
    },
    getPrivateInfo (state) {
        return state.privateInfo
    },
    getSavePrivateInfoStatus (state) {
        return state.savePrivateInfoStatus
    },
    getPrivateInfoLoadStatus (state) {
        return state.privateInfoLoadStatus
    },

    /** 履歴情報 **/
    getResumeOperat (state) {
        return state.resumeOperat
    },
    getResumeInfo (state) {
        return state.resumeInfo
    },
    getSaveResumeInfoStatus (state) {
        return state.saveResumeInfoStatus
    },
    getResumeInfoLoadStatus (state) {
        return state.resumeInfoLoadStatus
    },

    /** 本人確認 **/
    getIdentityInfo (state) {
        return state.identityInfo
    },
    getIdentityInfoLoadStatus (state) {
        return state.identityInfoLoadStatus
    },
    getSendIdentityMailStatus (state) {
        return state.sendIdentityMailStatus
    },

    /** 口座情報 **/
    getBankOperat (state) {
        return state.bankOperat
    },
    getBankInfo (state) {
        return state.bankInfo
    },
    getSaveBankInfoStatus (state) {
        return state.saveBankInfoStatus
    },
    getBankInfoLoadStatus (state) {
        return state.bankInfoLoadStatus
    },

    /** 応募履歴詳細 **/
    getApplyHisDetail (state) {
        return state.applyHisDetail.data
    },
    getApplyHisDetailLoadStatus (state) {
        return state.applyHisDetailLoadStatus
    },
    getAgreeIntvPlan (state) {
        return state.agreeIntvPlan
    },
    getAgreeIntvPlanStatus (state) {
        return state.agreeIntvPlanStatus
    },

    /** お気に入り **/
    getDeleteFavoriteStatus (state) {
        return state.deleteFavoriteStatus
    }

}

