<template>
    <invited-child-list 
        ref="list"
        header-text="友達詳細"
        lab="人"
    >
        <div v-if="getListObj">
            <div class="card com-card" v-if="getListObj.data.length > 0">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item p-md-4" v-for="item in getListObj.data" :key="item.id">
                        <div class="wrapper-name">
                            <span class="name">
                                {{item.invite_code}}
                            </span>
                            <span class="flex-fill text-right ml-2">
                                <i class="fas fa-user-alt icon-size" :class="{'text-success':item.status == '1'}"></i>
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </invited-child-list>
</template>

<script>
    import InvitedChildList from 'pages/commons/List/List'
    import {mapGetters} from 'vuex'

    export default {
        components: {
            InvitedChildList
        },
        created () {
            this.$store.dispatch('setListObjKeep')
        },
        mounted () {
            this.$refs.list.loadListObj({
                url: 'invite.status.child',
                data: this.$route.params.code
            })
        },
        computed: {
            ...mapGetters(['getListObj'])
        }
    }
</script>

<style lang="stylus" scoped>
    @import '~stylus/base/mixins.styl'
    .wrapper-name
        display:flex
        white-space: nowrap
        .name
            ellipsis()
</style>
