<template>
    <div>
        <apply-his-header v-if="getListObj" page-name="応募履歴"></apply-his-header>
        <div class="container">
            <!-- Nav tabs -->
            <ul v-show="getListObj" class="nav nav-tabs tab-sticky breadcrumb p-0 mb-1 nav-justified com-card" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active text-center px-2 py-md-3 border-0" data-toggle="tab" href="#open">
                        進行中
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link text-center px-2 py-md-3 border-0" data-toggle="tab" href="#closed">
                        終了
                    </a>
                </li>
            </ul>
            <div class="tab-content pb-3">
                <div id="open" class="container tab-pane active p-0">
                    <!-- Open -->
                    <div v-if="getListObj && getListObj.applyOpen">
                        <div v-if="getListObj.applyOpen.length > 0">
                            <div class="card mb-2 com-card p-md-3 p-lg-4" :class="{'com-card-focus':selectedProj == item.id}"
                                v-for="(item, idx) in getListObj.applyOpen" :key="item.id">
                                <div class="card-header px-2 py-3 py-md-4 d-flex">
                                    <span class="text-truncate text-nowrap">
                                        <i v-if="item.apply_type == '0'" class="fas fa-long-arrow-alt-left text-success icon-size"></i>
                                        <i v-else class="fas fa-long-arrow-alt-right text-primary icon-size"></i>&nbsp;
                                        {{item.proj_nm}}
                                    </span>
                                </div>
                                <div class="card-body px-2 py-3 py-md-4 item-hover" @click="toApplyHisDetail(item.id, idx)">
                                    <div v-if="item.te_msg == '0'">
                                        {{item.te_ap_flg}}
                                    </div>
                                    <div v-else>
                                        <span class="badge badge-pill badge-success p-2">未読メッセージ</span>
                                    </div>

                                    <div v-if="item.intv_type">
                                        <span v-if="item.intv_type == '0'">(現地)</span>
                                        <span v-if="item.intv_type == '1'">(Web)</span>
                                        {{item.te_intv_d | dformats}}&nbsp;{{item.te_intv_h}}:{{item.te_intv_m}}
                                    </div>
                                    <div class="text-right text-muted let-space-1 pt-2">
                                        <small><small>
                                            <i class="fas fa-calendar"></i>
                                            {{item.created_at | dformatm}}
                                        </small></small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="py-5 text-center text-muted">
                            <i class="fas fa-search h1"></i><br>
                            データが見つかりません。
                        </div>
                    </div>
                </div>
        
                <div id="closed" class="container tab-pane fade p-0">
                    <!-- Closed -->
                    <apply-his-closed-list 
                        ref="list"
                        :has-header="false"
                        mt
                        :mg="false"
                        >
                        <div v-if="getListObj">
                            <div v-if="getListObj.data.length > 0">
                                <div class="card mb-2 com-card p-md-3 p-lg-4 text-muted" :class="{'com-card-focus':selectedProj == item.id}"
                                    v-for="item in getListObj.data" :key="item.id">
                                    <div class="card-header px-2 py-3 py-md-4 d-flex">
                                        <span class="text-truncate text-nowrap">
                                            <i v-if="item.apply_type == '0'" class="fas fa-long-arrow-alt-left icon-size"></i>
                                            <i v-else class="fas fa-long-arrow-alt-right icon-size"></i>&nbsp;
                                            <!-- {{item.id}} -->
                                            {{item.proj_nm}}
                                        </span>
                                    </div>
                                    <div class="card-body px-2 py-3 py-md-4 item-hover" @click="toApplyHisDetail(item.id)">
                                        <span class="badge badge-pill badge-secondary p-2">Closed</span>
                                        <small>
                                            {{item.te_ap_flg}}
                                        </small>
                                        <div class="text-right pt-2">
                                            <small><small>
                                                <i class="fas fa-calendar"></i>
                                                {{item.created_at | dformatm}}
                                            </small></small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </apply-his-closed-list>
                </div>
            </div>
        </div>
    </div>


</template>

<script>
    import ApplyHisHeader from 'pages/commons/header/Header'
    import ApplyHisClosedList from 'pages/commons/List/List'
    import {mapGetters} from 'vuex'

    export default {
        components: {
            ApplyHisHeader,
            ApplyHisClosedList
        },
        mounted () {
            this.$refs.list.loadListObj({
                url:'my.apply.his',
                data:'0'
            })
            this.$refs.list.resetRefresh('applyHis')
        },
        data () {
            return {
                selectedProj: ''
            }
        },
        activated () {
            this.$refs.list.isGetList('applyHis', { url:'my.apply.his', data:'0'})
            this.$refs.list.resetRefresh('applyHis')
        },
        watch: {
            $route (to, from) {
                if (from.name == 'ApplyHisDetail') {
                    this.projFromSession()
                } else {
                    this.selectedProj = ''
                }
            }
        },
        computed: {
            ...mapGetters(['getListObj'])
        },
        methods: {
            seeMoreMt () {
                this.$store.dispatch('loadListObjSeeMore', {
                    url:'my.apply.his',
                    skip: this.getListObj.data.slice(-1)[0].id
                })
            },
            toApplyHisDetail (id, idx=null) {
                this.projToSession(id)

                if (idx != null) {
                    this.getListObj.applyOpen[idx]['te_msg'] = '0'
                }
                this.$router.push({name:'ApplyHisDetail', params:{id:id}})
            },
            projToSession (id) {
                sessionStorage.setItem('applyProj', id)
            },
            projFromSession () {
                this.selectedProj = sessionStorage.getItem('applyProj')
            }
        }
    }
</script>

<style lang="stylus" scoped>
 .tab-sticky
    position:sticky!important
    top:122px!important
    z-index:98
</style>
