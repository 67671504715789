<template>
<div id="overlay" v-show="show">
    <div id="content">
        <div class="col-sm-12 d-flex justify-content-center align-items-center">
                <div class="spinner-grow text-success" style="width: 4rem; height: 4rem;" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
        </div>
    </div>
</div>
</template>

<script>
    import {EventBus} from 'js/event-bus'

    export default {
        data () {
            return {
                show: false
            }
        },
        mounted () {
            EventBus.$on('startSpinner', () => {
                this.show = true
            })

            EventBus.$on('doneSpinner', () => {
                this.show = false
            })
        }
    }
</script>

<style>
#overlay{
  /*　要素を重ねた時の順番　*/
  z-index:9999;

  /*　画面全体を覆う設定　*/
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color:rgba(0,0,0,0.5);

  /*　画面の中央に要素を表示させる設定　*/
  display: flex;
  align-items: center;
  justify-content: center;

}
</style>
